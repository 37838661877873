import React, { useState, useEffect } from "react";
import CreateDialogForCustonInv from "../../layoutComponents/CreateDialogForCustonInv";
import TableComponent from "../../layoutComponents/TableComponent";
import { filterData } from "../../Regex";
import { CssTextField, CssTextField85, ShowDetails } from "../../styles/StyledComponents";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import ShowSpecifications from "../../layoutComponents/ShowSpecifications";
import ItemTableWIthINpitDetails from "./ItemTableWIthINpitDetails";
import moment from "moment";

function CreateCustomInvoice(props) {
  const { onClose, editList, fetchAgain, postData, masters, singlelistView,PreCarrierList, customOperation } = props;
  const [selectedSalesDate, setSelectedSalesDate] = useState(false);

  const [type_ci, setType_ci] = useState("WIGST");
  const [alldisabled, setDisabled] = useState(editList && editList.status ? editList.status === "CLOSED" : false);
  const [unit, setUnit] = useState(false);
  const [newPaymentTerm,setnewPaymentTerm]=useState()
  const [loader, setLoad] = useState(false);
  const [so, setSO] = useState(false);
  const [submitted, setSubmitted] = useState({ "Container Size": 26 });
  const [submittedDuty, setSubmittedDuty] = useState({});
  const [soItems, setSoItems] = useState([]);
  const [errorMsg, setErrorMsg] = useState({});
  const [LcNo,setLcNo]=useState(false);
  const [draftToggle,setDraftToggle]=useState(true);
  console.log( editList," editList.precarrie")
  useEffect(() => {
    if (so && so !== false) {
      fetchSoItems(so.id);
      setSubmitted({});
      setnewPaymentTerm( editList && editList.sales_order_details.so_payment_term
      ? filterData(masters && masters.soPaymentTermsList, "id", editList.sales_order_details.so_payment_term, "onlyOne")
        ? filterData(masters && masters.soPaymentTermsList, "id", editList.sales_order_details.so_payment_term, "onlyOne").term
        : ""
      :so && filterData(masters && masters.soPaymentTermsList, "id", so.so_payment_term, "onlyOne")
      ? filterData(masters && masters.soPaymentTermsList, "id", so.so_payment_term, "onlyOne").term
      :"")
    }
  }, [so]);
  useEffect(() => {
    if (editList && editList.sales_order_date) setSelectedSalesDate(editList.sales_order_date);
    if (editList && editList.unit) setUnit(editList.unit);
    if (editList) {
      setSO(
        editList && editList.sales_order
          ? filterData(masters && masters.salesOrderList, "id", Number(editList.sales_order), "onlyOne")
            ? filterData(masters && masters.salesOrderList, "id", Number(editList.sales_order), "onlyOne")
            : false
          : false
      );
    }
    if (editList) {
      setLoad(true);
      setType_ci(editList && editList.invoice_type ? editList.invoice_type : "WIGST");
      setLoad(false);
      console.log("FETCH FOB VALUES", editList)
      setSubmittedDuty({
        total_fob_pmt_in_other: editList?.total_fob_pmt_in_other ? Number(editList.total_fob_pmt_in_other) : "",
        total_fob_value_in_other: editList?.total_fob_value_in_other ? Number(editList.total_fob_value_in_other) : "",
        total_fob_value_in_inr: editList?.total_fob_value_in_inr ? Number(editList.total_fob_value_in_inr) : "",
        duty_percentage_of_fob: editList?.duty_percentage_of_fob ? Number(editList.duty_percentage_of_fob) : "",
        duty_of_fob_pmt_in_other: editList?.duty_of_fob_pmt_in_other ? Number(editList.duty_of_fob_pmt_in_other) : "",
        duty_of_fob_value_in_other: editList?.duty_of_fob_value_in_other ? Number(editList.duty_of_fob_value_in_other) : "",
        duty_of_fob_value_in_inr: editList?.duty_of_fob_value_in_inr ? Number(editList.duty_of_fob_value_in_inr) : "",
        payable_export_duty_pmt_in_other: editList?.payable_export_duty_pmt_in_other ? Number(editList.payable_export_duty_pmt_in_other) : "",
        payable_export_duty_value_in_other: editList?.payable_export_duty_value_in_other ? Number(editList.payable_export_duty_value_in_other) : "",
        payable_export_duty_value_in_inr: editList?.payable_export_duty_value_in_inr ? Number(editList.payable_export_duty_value_in_inr) : "",
        duty_paid_under_protest_pmt_in_other: editList?.duty_paid_under_protest_pmt_in_other ? Number(editList.duty_paid_under_protest_pmt_in_other) : "",
        duty_paid_under_protest_value_in_other: editList?.duty_paid_under_protest_value_in_other ? Number(editList.duty_paid_under_protest_value_in_other) : "",
        duty_paid_under_protest_value_in_inr: editList?.duty_paid_under_protest_value_in_inr ? Number(editList.duty_paid_under_protest_value_in_inr) : "",
      })
    }
    if (editList && editList.status) {
      setDisabled(editList && editList.status ? editList.status === "CLOSED" : false);
    }
  }, [editList]);
  const fetchSoItems = (soId) => {
    if (soId && postData) {
      setLoad(true);
      postData("get", "dispatch/so-items/list", null, "PortCIs", null, {
        so_id: soId,
      }).then((soItems) => {
        if (editList && editList.sales_order) {
          const finaladata = {};
          const ids = [];

          editList.custom_invoice_item_details.map((s) => {
            const data = {};
            ids.push(s.so_item);
            const singleSo = filterData(soItems, "id", Number(s.so_item), "onlyOne")
              ? filterData(soItems, "id", Number(s.so_item), "onlyOne")
              : "";
              console.log(so,"soooo")
            const rowHeader = [
              "Exchange rate",
              `Price (${
                filterData(masters["CurrencyList"], "id", so.currency, "onlyOne")
                  ? filterData(masters["CurrencyList"], "id", so.currency, "onlyOne").name
                  : ""
              }) PER ${unit}`,
              `Amount in ${
                filterData(masters["CurrencyList"], "id", so.currency, "onlyOne")
                  ? filterData(masters["CurrencyList"], "id", so.currency, "onlyOne").name
                  : ""
              }`,
              `PRICE (INR) PER ${unit}`,
              `Amount in (INR)`,
            ];
            singleSo.packaging &&
              singleSo.packaging.length > 0 &&
              singleSo.packaging.map((sP) => {
                data[`${sP}-bags`] = s.pkg_counter[sP] ? s.pkg_counter[sP] : "";
                return sP;
              });
            data.exchange_rate = s.exchange_rate;
            data[`Fob Value ${rowHeader[2]}`] = s.fob_value_in_other;
            data[`Fob Value ${rowHeader[3]}`] = s.fob_value_per_unit;
            data[`Fob Value ${rowHeader[4]}`] = s.fob_value_in_inr;
            data[`Ocean Freight ${rowHeader[2]}`] = s.ocean_freight_in_other;
            data[`Ocean Freight ${rowHeader[3]}`] = s.ocean_freight_per_unit;
            data[`Ocean Freight ${rowHeader[4]}`] = s.ocean_freight_in_inr;
            data[`Insurance ${rowHeader[2]}`] = s.insurance_in_other;
            data[`Insurance ${rowHeader[3]}`] = s.insurance_per_unit;
            data[`Insurance ${rowHeader[4]}`] = s.insurance_in_inr;
            data["Gross Weight"] = s.gross_weight;
            data["Net Weight"] = s.net_weight;
            data["custom_invoice_item_id"] = s.id;
            data["rate_of_igst"] = s.rate_of_igst;
            data[`IGST Amount ${rowHeader[4]}`] = s.igst_amount_in_inr;
            data[`Final ${rowHeader[2]}`] = s.final_shipped_amount_in_other;
            data[`Final ${rowHeader[4]}`] = s.final_shipped_amount_in_inr;
            data[`Total ${rowHeader[2]}`] = s.total_amount_in_other;
            data[`Total ${rowHeader[3]}`] = s.total_amount_per_unit;
            data[`Total ${rowHeader[4]}`] = s.total_amount_in_inr;
            finaladata[s.so_item] = data;
            return s;
          });
          if (editList.custom_invoice_item_details.length === ids.length) {
            setSubmitted(finaladata);
            setSelectedBrandsArr(ids);
          }
        }
        setSoItems(soItems);
        setLoad(false);
      });
    }
  };
  const validate = (data) => {
    const acc = [];
    if (data) {
      const detail = filterData(soItems, "id", Number(data), "onlyOne")
        ? filterData(soItems, "id", Number(data), "onlyOne")
        : "";
      const rowHeader = [
        "Exchange rate",
        `Price (${
          filterData(masters["CurrencyList"], "id", so.currency, "onlyOne")
            ? filterData(masters["CurrencyList"], "id", so.currency, "onlyOne").name
            : ""
        }) PER ${unit}`,
        `Amount in ${
          filterData(masters["CurrencyList"], "id", so.currency, "onlyOne")
            ? filterData(masters["CurrencyList"], "id", so.currency, "onlyOne").name
            : ""
        }`,
        `PRICE (INR) PER ${unit}`,
        `Amount in (INR)`,
      ];
      if (submitted[data]) {
        if (type_ci === "WIGST") {
          acc.push(submitted[data]);
        } else if (submitted[data].rate_of_igst) {
            if (submitted[data][`IGST Amount ${rowHeader[4]}`]) {
              acc.push(submitted[data]);
            
          }
        }
      }
    } else {
      soItems
        .sort((a, b) => b.id - a.id)
        .map((s) => {
          const details = s;
          const rowHeader = [
            "Exchange rate",
            `Price (${
              filterData(masters["CurrencyList"], "id", so.currency, "onlyOne")
                ? filterData(masters["CurrencyList"], "id", so.currency, "onlyOne").name
                : ""
            }) PER ${unit}`,
            `Amount in ${
              filterData(masters["CurrencyList"], "id", so.currency, "onlyOne")
                ? filterData(masters["CurrencyList"], "id", so.currency, "onlyOne").name
                : ""
            }`,
            `PRICE (INR) PER ${unit}`,
            `Amount in (INR)`,
          ];
          if (submitted[details.id]) {
            if (type_ci === "WIGST") {
              acc.push(submitted[details.id]);
            } else if (submitted[details.id].rate_of_igst) {
             if (submitted[details.id][`IGST Amount ${rowHeader[4]}`]) {
                  acc.push(submitted[details.id]);
                }
              
            }
          }
          return s;
        });
    }
    if (acc.length > 0) {
      return "success";
    } else {
      return false;
    }
  };
  const [selectedBrandsArr, setSelectedBrandsArr] = useState([]);
  const soItemTable = (submittedData) => {
    {console.log("soItemtable",customOperation,submittedData,editList, editList?.so_items , editList?.so_items?.length, soItems)}
    let editSalesOrder = editList?.sales_order_details;
    let tableDATA = customOperation === 'add' ? soItems : editSalesOrder?.so_items;
    let salesData = customOperation === 'add' ? so : editSalesOrder;
    return (
      tableDATA?.length > 0 && (
        <div className=" margin10" style={{ width: "calc(100% - 20px)", height: "fit-content" }}>
          <TableComponent
            padding={"0px"}
            noAddAnother={true}
            nobottomBarAction={true}
            confirmToSelect={(data) => {
              return "success";
            }}
            selectedListArray={selectedBrandsArr}
            setSelectedListArray={(list) => {
              setSelectedBrandsArr(list);
            }}
            confirmToSelectDenied={(data) => {
              setErrorMsg((prev) => {
                return data ? { ...prev, [`${data.id}addDetail`]: true } : { ...prev, addDetail: true };
              });
            }}
            setSelectedListArrayErrorSet={() => {
              setErrorMsg((prev) => {
                return { ...prev, selectedBrandsArr: false };
              });
            }}
            setSelectedListArrayError={errorMsg["selectedBrandsArr"] ? errorMsg["selectedBrandsArr"] : false}
            checkbox={true}
            singleCheckBox={true}
            loading={loader}
            heightNew="800px"
            maxHeight="calc(100vh - 40px)"
            widthNew="100%"
            module={"Bag details"}
            actions={[]}
            noAddButton={true}
            header={[
              "Details",
              "Brand",
              "Packaging",
              "Total Item Quantity",
              "Contract Rate",
              "HSN Code",
              "Buyer Marking",
              "Specifications",
            ]}
            noSearchBar={true}
            {...props}
            tableList={
              tableDATA && tableDATA?.length
                ? tableDATA
                    .sort((a, b) => b.id - a.id)
                    .map((s) => {
                      const details = s;
                      console.log("submittedDuty", submittedDuty)
                      return {
                        ...s,
                        Details: "Update Details ",
                        DetailsUI: () => {
                          console.log(so,filterData(masters["CurrencyList"], "id", salesData.currency, "onlyOne")
                          ? filterData(masters["CurrencyList"], "id", salesData.currency, "onlyOne").name
                          : "","curency checking",submitted[details.id])
                          return (
                            <ItemTableWIthINpitDetails
                              ci_type={submittedData["Type"] ? submittedData["Type"] : ""}
                              editData={submitted[details.id] ? submitted[details.id] : false}
                              editLeftData={submittedDuty ? submittedDuty : false}
                              errorMsg={errorMsg}
                              setData={(data,dutyData) => {
                                console.log(data,dutyData, "data");
                                setSubmittedDuty(dutyData)
                                setSubmitted((prev) => {
                                  return { ...prev, [details.id]: data };
                                });
                              }}
                              setErrorMsg={() => {
                                setErrorMsg({});
                              }}
                              masters={masters}
                              selected={
                                customOperation === "add"
                                ? {
                                  ...s,
                                  Brand: s.brand_details.brand_name,
                                  "Item Category": filterData(masters.ItemCateoryList,"id",Number(s.item_category),"onlyOne")?.name || "",
                                  "Item Type": `${Number(filterData(masters.ItemList, "id", Number(s.item_type), "onlyOne")?.broken_percentage ).toFixed(2)} ` || "",
                                  "HSN Code": filterData(masters.HSNlist, "id", details.hsn_code, "onlyOne")?.hsn_code || "",
                                  "Total Item Quantity": details.total_quantity,
                                  "Contract Rate": `${details.contract_rate} / ${unit ? unit : ""}`,
                                  "Buyer Marking": details.buyers_marking ? details.buyers_marking : "-",
                                  Currency: filterData(masters["CurrencyList"], "id", so.currency, "onlyOne")?.name || "",
                                }
                                : {
                                ...s,
                                Brand: s.brand.name || "",
                                "Item Category": s.item_category.name || "",
                                "Item Type": s.item_type?.broken_percentage || "",
                                "HSN Code": s.hsn_code.hsn_code || "",
                                "Total Item Quantity": s.total_quantity || "",
                                "Contract Rate": `${s.contract_rate} / ${editList.sales_order_details.unit ? editList.sales_order_details.unit : ""}`,
                                "Buyer Marking": s.buyers_marking ? s.buyers_marking : "-",
                                Currency: filterData(masters["CurrencyList"], "id", editList.sales_order_details.currency, "onlyOne")
                                  ? filterData(masters["CurrencyList"], "id", editList.sales_order_details.currency, "onlyOne").name
                                  : "",
                              }}
                              selectedSo={so}
                              alldisabled={alldisabled}
                            />
                          );
                        },
                        Brand: customOperation === 'add'
                          ?`${s.brand_details.brand_name} - 
                            ${filterData(masters.ItemCateoryList, "id", s.item_category, "onlyOne").name || ""} - 
                            ${ `${Number(filterData(masters.ItemList, "id", s.item_type, "onlyOne").broken_percentage).toFixed(2)}%` || ""}`
                          : `${s.brand.name || ""} - ${s.item_category?.name || ""} - ${Number(s.item_type?.broken_percentage).toFixed(2) +'%'  || ""}`,

                        "Item Category": customOperation === 'add'
                          ? filterData(masters.ItemCateoryList, "id", s.item_category, "onlyOne").name || ""
                          : s.item_category?.name || "",
                        "Item Type": customOperation === 'add'
                          ? `${Number(filterData(masters.ItemList, "id", s.item_type, "onlyOne").broken_percentage).toFixed(2)}%` || ""
                          : s.item_type?.broken_percentage || "",
                        Packaging: "deferferfeeeeeeeeeee",
                        PackagingUI: (
                          <ShowPackaging
                            noTotalbags={true}
                            returned_bags={false}
                            noBags={true}
                            details={s.packaging.map((sP) => {
                              const pVal = customOperation === 'add'
                                ? filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
                                : sP || "";
                                

                              return { ...pVal };
                            })}
                          />
                        ),
                        "HSN Code": customOperation === 'add'
                        ? filterData(masters.HSNlist, "id", details.hsn_code, "onlyOne")?.hsn_code || ""
                        : s.hsn_code.hsn_code || "",
                        "Total Item Quantity": customOperation === 'add'
                        ? details.total_quantity || ""
                        : s.total_quantity + ` ${editList.sales_order_details.unit ? editList.sales_order_details.unit : ""}` || "",
                        "Contract Rate": customOperation === 'add'
                        ? `${details.contract_rate} / ${unit ? unit : ""}`
                        : `${Number(s.contract_rate).toFixed(2)}/${editList.sales_order_details.unit ? editList.sales_order_details.unit : ""}`,
                        "Buyer Marking": customOperation === 'add'
                        ? details.buyers_marking ? details.buyers_marking : "-"
                        : s.buyers_marking ? s.buyers_marking : "-",
                        Currency: customOperation === 'add'
                        ? filterData(masters["CurrencyList"], "id", so.currency, "onlyOne")?.name || ""
                        : filterData(masters["CurrencyList"], "id", editList.sales_order_details.currency, "onlyOne").name || "",
                        Specifications: "WEFERFRT4G56YH6",
                        SpecificationsUI: (
                          <ShowSpecifications
                            details={
                              (customOperation === 'add' ? details : salesData).so_specifications?.length > 0
                                ? (customOperation === 'add' ? details : salesData).so_specifications.map((sP) => {
                                    return sP;
                                  })
                                : []
                            }
                          />
                        ),
                      };
                    })
                : []
            }
            filterChild={false}
          />
        </div>
      )
    );
  };
  console.log(type_ci, "type_ci", masters, masters['PortList']);
  return (
    <CreateDialogForCustonInv
      noAddAnother={true}
      //fullScreen={true}
      pendingDataToAdd={() => {
        const pkg_counter = {};
        const itemData = selectedBrandsArr
          .map((s) => {
            const details = filterData(soItems, "id", Number(s), "onlyOne")
              ? filterData(soItems, "id", Number(s), "onlyOne")
              : "";
            const submittedDataForItems = submitted[details.id] ? submitted[details.id] : "";
            details.packaging &&
              details.packaging.length > 0 &&
              details.packaging.map((sP) => {
                pkg_counter[sP] =
                  submitted && submitted[details.id][`${sP}-bags`] ? submitted[details.id][`${sP}-bags`] : "";
                return sP;
              });
            const oldData = submitted[details.id]["custom_invoice_item_id"]
              ? filterData(
                  editList.custom_invoice_item_details,
                  "id",
                  submitted[details.id]["custom_invoice_item_id"],
                  "onlyOne"
                )
                ? filterData(
                    editList.custom_invoice_item_details,
                    "id",
                    submitted[details.id]["custom_invoice_item_id"],
                    "onlyOne"
                  )
                : ""
              : "";
            const rowHeader = [
              "Exchange rate",
              `Price (${
                filterData(masters["CurrencyList"], "id", so.currency, "onlyOne")
                  ? filterData(masters["CurrencyList"], "id", so.currency, "onlyOne").name
                  : ""
              }) PER ${unit}`,
              `Amount in ${
                filterData(masters["CurrencyList"], "id", so.currency, "onlyOne")
                  ? filterData(masters["CurrencyList"], "id", so.currency, "onlyOne").name
                  : ""
              }`,
              `PRICE (INR) PER ${unit}`,
              `Amount in (INR)`,
            ];
            const itemObj = {
              price_per_mt_usd: null,
              price_per_mt_inr: null,
              final_shipped_amount_in_other: submittedDataForItems
                ? submittedDataForItems[`Final ${rowHeader[2]}`]
                ? submittedDataForItems[`Final ${rowHeader[2]}`]
                  : ""
                : "",
              final_shipped_amount_in_inr: submittedDataForItems
                ? submittedDataForItems[`Final ${rowHeader[4]}`]
                ? submittedDataForItems[`Final ${rowHeader[4]}`]
                  : ""
                : "",

              total_amount_in_other: submittedDataForItems
                ? submittedDataForItems[`Total ${rowHeader[2]}`]
                ? submittedDataForItems[`Total ${rowHeader[2]}`]
                  : ""
                : "",
              total_amount_per_unit: submittedDataForItems
                ? submittedDataForItems[`Total ${rowHeader[3]}`]
                ? submittedDataForItems[`Total ${rowHeader[3]}`]
                  : ""
                : "",
              total_amount_in_inr: submittedDataForItems
                ? submittedDataForItems[`Total ${rowHeader[4]}`]
                ? submittedDataForItems[`Total ${rowHeader[4]}`]
                  : ""
                : "",

              gross_weight: submittedDataForItems
                ? submittedDataForItems["Gross Weight"]
                  ? submittedDataForItems["Gross Weight"]
                  : ""
                : "",
              net_weight: submittedDataForItems
                ? submittedDataForItems["Net Weight"]
                  ? submittedDataForItems["Net Weight"]
                  : ""
                : "",
              so_item: s,

              pkg_counter: pkg_counter,
              exchange_rate: submittedDataForItems
                ? submittedDataForItems["exchange_rate"]
                  ? submittedDataForItems["exchange_rate"]
                  : ""
                : "",

              fob_value_in_other: submittedDataForItems
                ? submittedDataForItems[`Fob Value ${rowHeader[2]}`]
                  ? Number(submittedDataForItems[`Fob Value ${rowHeader[2]}`]).toFixed(2)
                  : ""
                : "",
              fob_value_per_unit: submittedDataForItems
                ? submittedDataForItems[`Fob Value ${rowHeader[3]}`]
                  ? Number(submittedDataForItems[`Fob Value ${rowHeader[3]}`]).toFixed(2)
                  : ""
                : "",
              fob_value_in_inr: submittedDataForItems
                ? submittedDataForItems[`Fob Value ${rowHeader[4]}`]
                  ? Number(submittedDataForItems[`Fob Value ${rowHeader[4]}`]).toFixed(2)
                  : ""
                : "",
              ocean_freight_in_other: submittedDataForItems
                ? submittedDataForItems[`Ocean Freight ${rowHeader[2]}`]
                  ? Number(submittedDataForItems[`Ocean Freight ${rowHeader[2]}`]).toFixed(2)
                  : ""
                : "",
              ocean_freight_per_unit: submittedDataForItems
                ? submittedDataForItems[`Ocean Freight ${rowHeader[3]}`]
                  ? Number(submittedDataForItems[`Ocean Freight ${rowHeader[3]}`]).toFixed(2)
                  : ""
                : "",
              ocean_freight_in_inr: submittedDataForItems
                ? submittedDataForItems[`Ocean Freight ${rowHeader[4]}`]
                  ? Number(submittedDataForItems[`Ocean Freight ${rowHeader[4]}`]).toFixed(2)
                  : ""
                : "",

              insurance_in_other: submittedDataForItems
                ? submittedDataForItems[`Insurance ${rowHeader[2]}`]
                  ? Number(submittedDataForItems[`Insurance ${rowHeader[2]}`]).toFixed(2)
                  : ""
                : "",
              insurance_per_unit: submittedDataForItems
                ? submittedDataForItems[`Insurance ${rowHeader[3]}`]
                  ? Number(submittedDataForItems[`Insurance ${rowHeader[3]}`]).toFixed(2)
                  : ""
                : "",
              insurance_in_inr: submittedDataForItems
                ? submittedDataForItems[`Insurance ${rowHeader[4]}`]
                  ? Number(submittedDataForItems[`Insurance ${rowHeader[4]}`]).toFixed(2)
                  : ""
                : "",
                igst_amount_in_other:null
            };
            // if (submittedDataForItems && submittedDataForItems["rate_of_igst"]) {
              itemObj.rate_of_igst = submittedDataForItems["rate_of_igst"]? submittedDataForItems["rate_of_igst"]:null;
            // }
          
          
              itemObj.igst_amount_in_inr = submittedDataForItems && submittedDataForItems[`IGST Amount ${rowHeader[4]}`]?
              submittedDataForItems[`IGST Amount ${rowHeader[4]}`]:null;
            
            if (editList && editList["id"]) {
              itemObj.custom_invoice = editList.id;
            }
            if (!oldData) {
              return itemObj;
            } else {
              return false;
            }
          })
          .filter((s) => s !== false);
        console.log(itemData, "itemData");
        return itemData.length > 0 ? itemData : [];
      }}
      addApi2="arrival/custom-invoice-items/bulk-update"
      confirmToSelect={(data) => {
        if (data === "all") {
          return validate();
        } else {
          return validate(data);
        }
      }}
      confirmToSelectDenied={(data) => {
        setErrorMsg((prev) => {
          return data ? { ...prev, [`${data}addDetail`]: true } : { ...prev, addDetail: true };
        });
      }}
      formatData={(submittedData, editList, item) => {
        
        console.log(newPaymentTerm?newPaymentTerm:so.so_payment_term ? so.so_payment_term : null, "selectedBrandsArr");
        const idsArr = [];
        const grossWEightsArr = [];
        const netWEightsArr = [];
        const itemData = selectedBrandsArr
          .map((s) => {
            const pkg_counter = {};
            const details = filterData(soItems, "id", Number(s), "onlyOne")
              ? filterData(soItems, "id", Number(s), "onlyOne")
              : "";
            const submittedDataForItems = submitted[details.id] ? submitted[details.id] : "";
            details.packaging &&
              details.packaging.length > 0 &&
              details.packaging.map((sP) => {
                pkg_counter[sP] =
                  submitted && submitted[details.id][`${sP}-bags`] ? submitted[details.id][`${sP}-bags`] : "";
                return sP;
              });
            console.log( submittedData["Place of Receipt by PrecarrierId"] ? submittedData["Place of Receipt by PrecarrierId"] : "", "wefreteryrt");
            const oldData = submitted[details.id]["custom_invoice_item_id"]
              ? filterData(
                  editList.custom_invoice_item_details,
                  "id",
                  submitted[details.id]["custom_invoice_item_id"],
                  "onlyOne"
                )
                ? filterData(
                    editList.custom_invoice_item_details,
                    "id",
                    submitted[details.id]["custom_invoice_item_id"],
                    "onlyOne"
                  )
                : ""
              : "";
            const rowHeader = [
              "Exchange rate",
              `Price (${
                filterData(masters["CurrencyList"], "id", so.currency, "onlyOne")
                  ? filterData(masters["CurrencyList"], "id", so.currency, "onlyOne").name
                  : ""
              }) PER ${unit}`,
              `Amount in ${
                filterData(masters["CurrencyList"], "id", so.currency, "onlyOne")
                  ? filterData(masters["CurrencyList"], "id", so.currency, "onlyOne").name
                  : ""
              }`,
              `PRICE (INR) PER ${unit}`,
              `Amount in (INR)`,
            ];
            if (!idsArr.includes(s)) {
              idsArr.push(s);
              grossWEightsArr.push(
                Number(
                  submittedDataForItems
                    ? submittedDataForItems["Gross Weight"]
                      ? submittedDataForItems["Gross Weight"]
                      : 0
                    : 0
                )
              );
              netWEightsArr.push(
                Number(
                  submittedDataForItems
                    ? submittedDataForItems["Net Weight"]
                      ? submittedDataForItems["Net Weight"]
                      : 0
                    : 0
                )
              );
            }
            const itemObj = {
              final_shipped_amount_in_other: submittedDataForItems
                ? submittedDataForItems[`Final ${rowHeader[2]}`]
                ? Number(submittedDataForItems[`Final ${rowHeader[2]}`]).toFixed(2)
                  : 0
                : 0,
              final_shipped_amount_in_inr: submittedDataForItems
                ? submittedDataForItems[`Final ${rowHeader[4]}`]
                ? Number(submittedDataForItems[`Final ${rowHeader[4]}`]).toFixed(2)
                : 0
                : 0,

              total_amount_in_other: submittedDataForItems
                ? submittedDataForItems[`Total ${rowHeader[2]}`]
                ? Number(submittedDataForItems[`Final ${rowHeader[2]}`]).toFixed(2)
                : 0
                : 0,
              total_amount_per_unit: submittedDataForItems
                ? submittedDataForItems[`Total ${rowHeader[3]}`]
                ? Number(submittedDataForItems[`Total ${rowHeader[3]}`]).toFixed(2)
                : 0
                : 0,
              total_amount_in_inr: submittedDataForItems
                ? submittedDataForItems[`Total ${rowHeader[4]}`]
                ? Number(submittedDataForItems[`Final ${rowHeader[4]}`]).toFixed(2)
                : 0
                : 0,

              gross_weight: submittedDataForItems
                ? submittedDataForItems["Gross Weight"]
                  ? submittedDataForItems["Gross Weight"]
                  : 0
                : 0,
              net_weight: submittedDataForItems
                ? submittedDataForItems["Net Weight"]
                  ? submittedDataForItems["Net Weight"]
                  : 0
                : 0,
              so_item: s,

              pkg_counter: pkg_counter,
              exchange_rate: submittedDataForItems
                ? submittedDataForItems["exchange_rate"]
                  ? submittedDataForItems["exchange_rate"]
                  : ""
                : "",

              fob_value_in_other: submittedDataForItems
                ? submittedDataForItems[`Fob Value ${rowHeader[2]}`]
                  ? Number(submittedDataForItems[`Fob Value ${rowHeader[2]}`]).toFixed(2)
                  : 0
                : 0,
              fob_value_per_unit: submittedDataForItems
                ? submittedDataForItems[`Fob Value ${rowHeader[3]}`]
                  ? Number(submittedDataForItems[`Fob Value ${rowHeader[3]}`]).toFixed(2)
                  : 0
                : 0,
              fob_value_in_inr: submittedDataForItems
                ? submittedDataForItems[`Fob Value ${rowHeader[4]}`]
                  ? Number(submittedDataForItems[`Fob Value ${rowHeader[4]}`]).toFixed(2)
                  : 0
                : 0,
              ocean_freight_in_other: submittedDataForItems
                ? submittedDataForItems[`Ocean Freight ${rowHeader[2]}`]
                  ? Number(submittedDataForItems[`Ocean Freight ${rowHeader[2]}`]).toFixed(2)
                  : 0
                : 0,
              ocean_freight_per_unit: submittedDataForItems
                ? submittedDataForItems[`Ocean Freight ${rowHeader[3]}`]
                  ? Number(submittedDataForItems[`Ocean Freight ${rowHeader[3]}`]).toFixed(2)
                  : 0
                : 0,
              ocean_freight_in_inr: submittedDataForItems
                ? submittedDataForItems[`Ocean Freight ${rowHeader[4]}`]
                  ? Number(submittedDataForItems[`Ocean Freight ${rowHeader[4]}`]).toFixed(2)
                  : 0
                : 0,

              insurance_in_other: submittedDataForItems
                ? submittedDataForItems[`Insurance ${rowHeader[2]}`]
                  ? Number(submittedDataForItems[`Insurance ${rowHeader[2]}`]).toFixed(2)
                  : 0
                : 0,
              insurance_per_unit: submittedDataForItems
                ? submittedDataForItems[`Insurance ${rowHeader[3]}`]
                  ? Number(submittedDataForItems[`Insurance ${rowHeader[3]}`]).toFixed(2)
                  : 0
                : 0,
              insurance_in_inr: submittedDataForItems
                ? submittedDataForItems[`Insurance ${rowHeader[4]}`]
                  ? Number(submittedDataForItems[`Insurance ${rowHeader[4]}`]).toFixed(2)
                  : 0
                : 0,
            };
            if (submittedDataForItems && submittedDataForItems["rate_of_igst"]) {
              itemObj.rate_of_igst = submittedDataForItems["rate_of_igst"];
            }
          
            if (submittedDataForItems && submittedDataForItems[`IGST Amount ${rowHeader[4]}`]) {
              itemObj.igst_amount_in_inr = submittedDataForItems[`IGST Amount ${rowHeader[4]}`];
            }

            if ((editList && item && oldData && oldData.id) || !editList) {
              return editList && item ? { ...oldData, ...itemObj } : itemObj;
            } else {
              return false;
            }
          })
          .filter((s) => s !== false)
          .filter((s) => (editList ? s.id : true));
        const totalGrossWeight = grossWEightsArr
          .reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue ? currentValue : 0), 0)
          .toFixed(2);
        const totalNetWeight = netWEightsArr
          .reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue ? currentValue : 0), 0)
          .toFixed(2);
        const ciData = {
          document_details: [],
          net_weight: totalNetWeight,
          gross_weight: totalGrossWeight,
          invoice_number: submittedData["Invoice Number"] ? submittedData["Invoice Number"] : "",
          invoice_type: submittedData["Type"] ? submittedData["Type"] : "",
          invoice_date: submittedData[["Invoice Date"]]
            ? moment(submittedData[["Invoice Date"]]).format("YYYY-MM-DD")
            : "",
          exporter_ref_no: submittedData[["Exporter Ref No."]] ? submittedData[["Exporter Ref No."]] : "",
          sales_order_date: submittedData["Contract Date"]
            ? moment(submittedData["Contract Date"]).format("YYYY-MM-DD")
            : selectedSalesDate
            ? moment(selectedSalesDate).format("YYYY-MM-DD")
            : "",
          lc_no: submittedData["LC Number"] ? submittedData["LC Number"] : "",
          lc_date: submittedData["LC Date"]
            ? moment(submittedData["LC Date"]).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
          discharge_port: submittedData["Discharge Port"] ? submittedData["Discharge Port"] : "",
          consignee: submittedData["Type"] ? submittedData["Type"] : "",
          notify_1: submittedData["Notify 1Id"] ? submittedData["Notify 1Id"] : "",
          notify_2: submittedData["Notify 2Id"] ? submittedData["Notify 2Id"] : "",
          notify_3: submittedData["Notify 3Id"] ? submittedData["Notify 3Id"] : "",
          origin_country: submittedData["origin_country"] ? submittedData["origin_country"] : "india",
          delivery_place: submittedData["Delivery Place"] ? submittedData["Delivery Place"] : "",
          customer_handling_agent : submittedData["CHA"] ? submittedData["CHA"] : "",
          rail_out_status : submittedData["Rail Out Status"] ? submittedData["Rail Out Status"] : "",
          total_container_count: submittedData["Total Container Count"] ? submittedData["Total Container Count"] : "",
          vessel_number: submittedData["Vessel/Flight Name"] ? submittedData["Vessel/Flight Name"] : "",          
          flight_number: submittedData["Vessel/Flight number"] ? submittedData["Vessel/Flight number"] : "",
          transit_code: submittedData["Transit Code"] ? submittedData["Transit Code"] : "",
          rodtep: submittedData["RoDTEP"] ? submittedData["RoDTEP"] : "",
          precarrier: submittedData["Place of Receipt by PrecarrierId"] ? submittedData["Place of Receipt by PrecarrierId"] : "",
          unit: unit,
          is_active: true,
          status: submittedData["Status"] ? submittedData["Status"] : "",
          shipment_process_status: editList.shipment_process_status
            ? editList.shipment_process_status
            : "CUSTOM INVOICE GENERATED",
          terms: null,
          payment_terms: newPaymentTerm?newPaymentTerm: (customOperation === 'add' ? so : editList.sales_order_details).so_payment_term ? (customOperation === 'add' ? so : editList.sales_order_details).so_payment_term : so.so_payment_term ? so.so_payment_term : null,
          shipper: submittedData["ShipperId"] ? submittedData["ShipperId"] : "",
          sales_order: so.id ? so.id : "",
          loading_port: singlelistView && singlelistView.id,
          buyer: so.buyer ? so.buyer : "",
          beneficiary: submittedData["BeneficiaryId"] ? submittedData["BeneficiaryId"] : "",
          total_fob_pmt_in_other: submittedDuty["Total Fob Value OTHER PMT"] ? Number(submittedDuty["Total Fob Value OTHER PMT"]) : "",
          total_fob_value_in_other: submittedDuty["Total Fob Value OTHER TOTAL"] ? Number(submittedDuty["Total Fob Value OTHER TOTAL"]) : "",
          total_fob_value_in_inr: submittedDuty["Total Fob Value INR TOTAL"] ? Number(submittedDuty["Total Fob Value INR TOTAL"]) : "",
          duty_percentage_of_fob: submittedDuty["Duty Percent Of FOB"] ? Number(submittedDuty["Duty Percent Of FOB"]) : "",
          duty_of_fob_pmt_in_other: submittedDuty["Duty Percent OTHER PMT"] ? Number(submittedDuty["Duty Percent OTHER PMT"]) : "",
          duty_of_fob_value_in_other: submittedDuty["Duty Percent OTHER TOTAL"] ? Number(submittedDuty["Duty Percent OTHER TOTAL"]) : "",
          duty_of_fob_value_in_inr: submittedDuty["Duty Percent INR TOTAL"] ? Number(submittedDuty["Duty Percent INR TOTAL"]) : "",
          payable_export_duty_pmt_in_other: submittedDuty["Total Export Duty OTHER PMT"] ? Number(submittedDuty["Total Export Duty OTHER PMT"]) : "",
          payable_export_duty_value_in_other: submittedDuty["Total Export Duty OTHER TOTAL"] ? Number(submittedDuty["Total Export Duty OTHER TOTAL"]) : "",
          payable_export_duty_value_in_inr: submittedDuty["Total Export Duty INR TOTAL"] ? Number(submittedDuty["Total Export Duty INR TOTAL"]) : "",
          duty_paid_under_protest_pmt_in_other: submittedDuty["Duty paid OTHER PMT"] ? Number(submittedDuty["Duty paid OTHER PMT"]) : "",
          duty_paid_under_protest_value_in_other: submittedDuty["Duty paid OTHER TOTAL"] ? Number(submittedDuty["Duty paid OTHER TOTAL"]) : "",
          duty_paid_under_protest_value_in_inr: submittedDuty["Duty paid INR TOTAL"] ? Number(submittedDuty["Duty paid INR TOTAL"]) : "",
        };
        console.log("FORMATDATA",{
              custom_invoice: ciData,
              custom_invoice_items: itemData,
            } )
        return editList
          ? item
            ? itemData
            : ciData
          : {
              custom_invoice: ciData,
              custom_invoice_items: itemData,
            };
      }}
      reset={() => {
        setSO(false);
        setSubmitted({});
        setSubmittedDuty({});
        setSoItems([]);
        setUnit(false);
        setSelectedSalesDate(false);
      }}
      isSetLoad={loader}
      setError={() => {
        setErrorMsg({ ...errorMsg, selectedBrandsArr: true });
      }}
      extraDetial={soItemTable}
      extraDetialValue={selectedBrandsArr}
      addFieldsToAdd={[
        newPaymentTerm&& {name:"payment_terms",value:newPaymentTerm},
        { name: "is_active", value: true },
        {
          name: "shipment_process_status",
          value: editList.shipment_process_status ? editList.shipment_process_status : "CUSTOM INVOICE GENERATED",
        },
        { name: "loading_port", value: singlelistView && singlelistView.id },
        // {
        //   name: "container_details",
        //   value: containerArrCopy.map((ss) => {
        //     return {
        //       number_of_containers: ss["number_of_containers"],
        //       container_size: "26",
        //       container_number: ss["container_number"],
        //       container_brand: ss["container_brand"],
        //     };
        //   }),
        // },
      ]}
      module={"Custom Invoice"}
      closeOnSave={true}
      selectedList={editList ? editList : false}
      editList={editList ? editList : false}
      handleClose={() => {
        onClose(false);
      }}
      fetchAgain={() => {
        fetchAgain();
      }}
      postApi="arrival/custom-invoice/list"
      editApi="arrival/custom-invoice/"
      textFieldStyle={{ width: "calc(25% - 20px)", margin: "10px" }}
      heightNew="calc(100vh - 40px)"
      maxHeight="calc(100vh - 40px)"
      widthNew="90%"
      detailUI={() => {
        return (
          <ShowDetails
            isMobile={false}noFixHeight={true}
            width="100%"
            lists={[
              {
                name: "Loading Port",
                value: customOperation === 'add' 
                  ? singlelistView?.name || ""
                  : editList && editList.sales_order_details
                    ? editList.sales_order_details?.load_ports?.map(sP => {
                      return sP !==  "Any Indian Port" 
                      ? filterData(masters && masters['PortList'], "id", Number(sP), "onlyOne")
                        ? filterData(masters && masters['PortList'], "id", Number(sP), "onlyOne").name + " "
                        : ""
                      :  "Any Indian Port " })
                    : "",
              },
              {
                name: "Contract Date",
                value: editList && editList.sales_order_details?.contract_date ? editList.sales_order_details?.contract_date : selectedSalesDate,
              },
              {
                name: "Unit",
                value: editList && editList.sales_order_details?.unit ? editList.sales_order_details?.unit : unit,
              },
              {
                name: "Buyer",
                value:
                (customOperation === 'add' ? so : editList ) && filterData(masters && masters.buyersList, "id", (customOperation === 'add' ? so : editList.sales_order_details)?.buyer, "onlyOne")
                    ? filterData(masters && masters.buyersList, "id", (customOperation === 'add' ? so : editList.sales_order_details)?.buyer, "onlyOne").client_name
                    : editList && editList.sales_order_details?.buyer
                    ? filterData(masters && masters.buyersList, "id", editList.sales_order_details?.buyer, "onlyOne")
                      ? filterData(masters && masters.buyersList, "id", editList.sales_order_details?.buyer, "onlyOne").client_name
                      : ""
                    : "",
              },
              {
                name: "Payment Term",
                value:
                 <div>
                  {/* setnewPaymentTerm("new value") */}
                  <CssTextField85
                  required={true}
                  type="text"
                  autoComplete="off"
                  style={
                    { width: "calc(100% - 10px)", margin: "10px 10px 0 10px" } //disabled={tp}
                  }
                  inputProps={{ min: 0 }}
                  error={errorMsg["Payment Term"] ? true : false}
                
                  id="outlined-basic"
                  label="Payment Term"
                  variant="outlined"
                  name="Payment Term"
                  value={newPaymentTerm?? ""}
                  onChange={(ev)=>{

                    setnewPaymentTerm(ev.target.value)
                  }}
                />
                  </div>
              },
            ]}
            height={"fit-content"}
            margin="10px"
          />
        );
      }}

      inputArray={[
        (!editList || editList?.status  === 'DRAFT') &&{
          name: "DRAFT",
          type: "toggle",
          defaultValue: {
            value: editList && editList?.status  === 'DRAFT' ? true : draftToggle,
          },
          disabled: !Boolean(editList) || editList?.status  === 'DRAFT' ? false : true,
          onChange: async(submittedData) => {
            console.log("submittedData", submittedData);
            setDraftToggle(submittedData['DRAFT']);
            submittedData['Status'] = submittedData['DRAFT'] ? "DRAFT" : 'OPEN';
            submittedData['StatusId'] = submittedData['DRAFT'] ? "DRAFT" : 'OPEN';
            return submittedData;
          },
        },
        {
          name: "Type",
          type: "radio",
          array: ["GST", "WIGST"],
          api: "status",
          disabled: editList && editList.invoice_type ? false : true,
          defaultValue: {
            value: editList && editList.invoice_type ? editList.invoice_type : type_ci ? type_ci : "WIGST",
          },
          onChange: async(data) => {
            setType_ci(data["Type"]);
            return data;
          },
        },
        {
          name: "Status",
          type: "select",
          dataType: "text",
          array: [
            { name: "DRAFT", id: "DRAFT" },
            { name: "OPEN", id: "OPEN" },
            { name: "CLOSED", id: "CLOSED" },
            { name: "CANCELLED", id: "CANCELLED" },
          ],
          value: "name",
          api: "status",
          disabled: !editList 
          ? true
          : editList.status ? false : true,
          defaultValue: {
            value: editList && editList.status ? editList.status : draftToggle ? "DRAFT" : "OPEN",
            id: editList && editList.status ? editList.status :  draftToggle ? "DRAFT" : "OPEN",
          },
          onChange: async (submittedData) => {
            if (submittedData["Status"]) {
              console.log(submittedData["Status"],submittedData, "submittedData");
              setDisabled(submittedData["Status"] === "CLOSED");
              submittedData['DRAFT'] = submittedData["Status"] === 'DRAFT' ? true : false;
            }

            return submittedData;
          },
        },
        {
          name: "Invoice Date",
          type: "Month",
          dataType: "text",
          disabled: alldisabled,
          api: "invoice_date",
          defaultValue: {
            value: editList && editList.invoice_date ? editList.invoice_date : new Date(),
          },
        },

        {
          name: "Invoice Number",
          type: "textField",
          dataType: "text",
          disabled: alldisabled,
          api: "invoice_number",
          defaultValue: {
            value: editList && editList.invoice_number ? editList.invoice_number : "",
          },
        },
        {
          name: "Sales Order",
          type: "select",
          dataType: "text",
          array:
            masters && masters.salesOrderList && masters.salesOrderList.length > 0
              ? masters.salesOrderList.sort((a, b) => b.id - a.id)
              : [],
          value: "so_number",
          subMenuTitle: "Remaining",
          subMenuValue: ["remaining_quantity","unit"],
          defaultValue: {
            value:
              editList && editList.sales_order
                ? filterData(masters && masters.salesOrderList, "id", Number(editList.sales_order), "onlyOne")
                  ? filterData(masters && masters.salesOrderList, "id", Number(editList.sales_order), "onlyOne")
                      .so_number
                  : ""
                : "",
            id: editList && editList.sales_order ? editList.sales_order : "",
          },
          api: "sales_order",
          onChange: async (submittedData) => {
            if (submittedData["Sales Order"]) {
              const selectedSAles = submittedData["Sales OrderId"];
              const selectedSO = filterData(masters && masters.salesOrderList, "id", Number(selectedSAles), "onlyOne")
                ? filterData(masters && masters.salesOrderList, "id", Number(selectedSAles), "onlyOne")
                : "";
              // fetchSoItems(selectedSAles);
              submittedData["Contract Date"] = selectedSO ? selectedSO.contract_date : "";
              submittedData["Unit"] = selectedSO ? selectedSO.unit : "";

              submittedData["Buyer"] = filterData(masters && masters.buyersList, "id", selectedSO.buyer, "onlyOne")
                ? filterData(masters && masters.buyersList, "id", selectedSO.buyer, "onlyOne").client_name
                : "";
              submittedData["BuyerId"] = selectedSO && selectedSO.buyer ? selectedSO.buyer : "";

              //   submittedData["Currency"] = filterData(masters && masters.CurrencyList, "id", selectedSO.currency, "onlyOne")
              //   ? filterData(masters && masters.selectedSO, "id", selectedSO.currency, "onlyOne").name
              //   : "";
              // submittedData["CurrencyId"] = selectedSO && selectedSO.currency ? selectedSO.currency : "";
              setSO(selectedSO);
              setErrorMsg({});
              setSelectedSalesDate(selectedSO ? selectedSO.contract_date : "");
              setUnit(selectedSO ? selectedSO.unit : "");
              setSubmitted({});
            }

            return submittedData;
          },
          disabled: alldisabled,
        },
        
        {
          name: "LC Number",
          type: "textField",
          dataType: "text",
          not_required:true,
          disabled: alldisabled,
          api: "lc_no",
          onChange:async(data)=>{
            setLcNo(data["LC Number"]?true:false)
            return data
          },
          update: true,
          defaultValue: {
            value: editList && editList.lc_no ? editList.lc_no : "",
          },
        },
       LcNo&& {
          name: "LC Date",
          type: "Month",
          dataType: "text",
          disabled: false,
          not_required:true,
          api: "lc_date",
          update: true,
          defaultValue: {
            value: editList && editList.lc_date ? editList.lc_date : new Date(),
          },
        },
       
        
        {
          name: "Shipper",
          type: "select",
          dataType: "text",
          array: masters && masters.shippersList && masters.shippersList.length > 0 ? masters.shippersList : [],
          value: "name",
          api: "shipper",
          onChange: async (submittedData) => {
            if (submittedData["ShipperId"]) {
              if (filterData(masters && masters.shippersList, "id", Number(submittedData["ShipperId"]), "onlyOne")) {
                submittedData["Beneficiary"] = filterData(
                  masters && masters.benificiarysList,
                  "id",
                  filterData(masters && masters.shippersList, "id", Number(submittedData["ShipperId"]), "onlyOne")
                    .benificiary,
                  "onlyOne"
                ).name;
                submittedData["BeneficiaryId"] = filterData(
                  masters && masters.shippersList,
                  "id",
                  Number(submittedData["ShipperId"]),
                  "onlyOne"
                ).benificiary;
              }
            }

            return submittedData;
          },
          defaultValue: {
            value:
              editList && editList.shipper
                ? filterData(masters && masters.shippersList, "id", editList.shipper, "onlyOne")
                  ? filterData(masters && masters.shippersList, "id", editList.shipper, "onlyOne").name
                  : ""
                : "",
            id: editList && editList.shipper ? editList.shipper : "",
          },
          disabled: alldisabled,
          not_required: draftToggle
        },
        {
          name: "Beneficiary",
          type: "textField",
          dataType: "text",
          api: "beneficiary",
          disabled: true,
          defaultValue: {
            value:
              editList && editList.beneficiary
                ? filterData(masters && masters.benificiarysList, "id", editList.beneficiary, "onlyOne")
                  ? filterData(masters && masters.benificiarysList, "id", editList.beneficiary, "onlyOne").name
                  : ""
                : "",
            id: editList && editList.beneficiary ? editList.beneficiary : "",
          },
          not_required: draftToggle
        },
        {
          name: "Exporter Ref No.",
          type: "textField",
          dataType: "text",
          api: "exporter_ref_no",
          defaultValue: {
            value: "6308000272",
          },
          // defaultValue: {
          //   value: editList && editList.exporter_ref_no,
          // },
          disabled: true,
          not_required: draftToggle
        },
        {
          name: "Discharge Port",
          type: "textField",
          dataType: "text",
          api: "discharge_port",
          defaultValue: {
            value: editList && editList.sales_order_details.discharge_port,
          },
          disabled: alldisabled,
          not_required: draftToggle
        },
        {
          name: "Delivery Place",
          type: "textField",
          dataType: "text",
          api: "delivery_place",
          defaultValue: {
            value: editList && editList.delivery_place,
          },
          disabled: alldisabled,
          not_required: draftToggle
        },
        {
          name: "CHA",
          type: "textField",
          dataType: "text",
          api: "customer_handling_agent ",
          defaultValue: {
            value: editList && editList.customer_handling_agent ,
          },
          disabled: alldisabled,
          not_required: draftToggle
        },
        {
          name: "Rail Out Status",
          type: "textField",
          dataType: "text",
          api: "rail_out_status ",
          defaultValue: {
            value: editList && editList.rail_out_status ,
          },
          disabled: alldisabled,
          not_required: draftToggle
        },
        {
          name: "Place of Receipt by Precarrier",
          not_required: true,
          type: "select",
          dataType: "text",
          array:
            masters && masters.PreCarrierList && masters.PreCarrierList.length > 0
              ? masters.PreCarrierList.sort((a, b) => b.id - a.id)
              : [],
          value: "precarrier",
          defaultValue: {
            value:
              editList && editList.precarrier_details?.id
                ? filterData(masters && masters.PreCarrierList, "id", Number(editList.precarrier_details?.id), "onlyOne")
                  ? filterData(masters && masters.PreCarrierList, "id", Number(editList.precarrier_details?.id), "onlyOne").precarrier
                  : ""
                : "",
            id: editList && editList.precarrier_details?.id ? editList.precarrier_details?.id : "",
          },
          disabled: alldisabled,
        },
        {
          name: "Total Container Count",
          type: "textField",
          dataType: "text",
          // disabled: alldisabled,
          api: "total_container_count",
          defaultValue: {
            value: editList && editList.total_container_count ? editList.total_container_count : "",
          },
          not_required: draftToggle
        },
        {
          name: "Origin Country",
          type: "textField",
          dataType: "text",
          api: "origin_country",
          disabled: alldisabled,
          defaultValue: {
            value: "India",
          },
          not_required: draftToggle
        },
        {
          name: "Notify 1",
          not_required: true,
          type: "select",
          dataType: "text",
          array:
            masters && masters.notifyList && masters.notifyList.length > 0
              ? masters.notifyList.sort((a, b) => b.id - a.id)
              : [],
          value: "notify",
          defaultValue: {
            value:
              editList && editList.notify_1
                ? filterData(masters && masters.notifyList, "id", Number(editList.notify_1), "onlyOne")
                  ? filterData(masters && masters.notifyList, "id", Number(editList.notify_1), "onlyOne").notify
                  : ""
                : "",
            id: editList && editList.notify_1 ? editList.notify_1 : "",
          },
          disabled: alldisabled,
        },
        {
          name: "Notify 2",
          not_required: true,
          type: "select",
          dataType: "text",
          array:
            masters && masters.notifyList && masters.notifyList.length > 0
              ? masters.notifyList.sort((a, b) => b.id - a.id)
              : [],
          value: "notify",
          defaultValue: {
            value:
              editList && editList.notify_2
                ? filterData(masters && masters.notifyList, "id", Number(editList.notify_2), "onlyOne")
                  ? filterData(masters && masters.notifyList, "id", Number(editList.notify_2), "onlyOne").notify
                  : ""
                : "",
            id: editList && editList.notify_2 ? editList.notify_2 : "",
          },
          disabled: alldisabled,
        },
        {
          name: "Notify 3",
          not_required: true,
          type: "select",
          dataType: "text",
          array:
            masters && masters.notifyList && masters.notifyList.length > 0
              ? masters.notifyList.sort((a, b) => b.id - a.id)
              : [],
          value: "notify",
          defaultValue: {
            value:
              editList && editList.notify_3
                ? filterData(masters && masters.notifyList, "id", Number(editList.notify_3), "onlyOne")
                  ? filterData(masters && masters.notifyList, "id", Number(editList.notify_3), "onlyOne").notify
                  : ""
                : "",
            id: editList && editList.notify_3 ? editList.notify_3 : "",
          },
          disabled: alldisabled,
        },
        {
          name: "Vessel/Flight Name",
          type: "textField",
          dataType: "text",
          not_required: true,
          api: "vessel_number",
          defaultValue: {
            value: editList && editList.vessel_number,
          },
        },
        {
          name: "Vessel/Flight number",
          type: "textField",
          dataType: "text",
          not_required: true,
          api: "flight_number",
          defaultValue: {
            value: editList && editList.flight_number,
          },
        },
        {
          name: "Transit Code",
          type: "textField",
          dataType: "text",
          not_required: true,
          api: "transit_code",
          defaultValue: {
            value: editList && editList.transit_code,
          },
        },
        {
          name: "RoDTEP",
          type: "textField",
          dataType: "text",
          not_required: true,
          api: "rodtep",
          defaultValue: {
            value: editList && editList.rodtep,
          },
        },
      ]}
      postData={postData}
    />
  );
}

export default CreateCustomInvoice;