import moment from "moment";
import {
  calculateGstAmount,
  calculatePercentage,
  filterData,
} from "../../../Regex";
import converter, { toWords } from "number-to-words";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts?.pdfMake?.vfs;

function CommercialInvoicePDF({
  masters,
  downloadCI,
  tableList,
  comInLists,
  soItems,
}) {
  console.log("PDF", downloadCI, tableList, soItems)

  const numberToInternationalWords = (number) => {
    const billion = 1000000000;
    const million = 1000000;
    const thousand = 1000;

    let result = "";
    let remaining = number;

    if (remaining >= billion) {
        const billionPart = Math.floor(remaining / billion);
        result += toWords(billionPart) + " billion ";
        remaining = remaining % billion;
    }

    if (remaining >= million) {
        const millionPart = Math.floor(remaining / million);
        result += toWords(millionPart) + " million ";
        remaining = remaining % million;
    }

    if (remaining >= thousand) {
        const thousandPart = Math.floor(remaining / thousand);
        result += toWords(thousandPart) + " thousand ";
        remaining = remaining % thousand;
    }

    if (remaining > 0) {
        result += toWords(remaining);
    }

    return result.trim();
  };
  const numberToWordsWithDecimalsInternational = (number) => {
    const integerPart = Math.floor(number);
    const decimalPart = Math.round((number - integerPart) * 100);

    let result = numberToInternationalWords(integerPart);

    if (decimalPart > 0) {
        result += " and " + toWords(decimalPart) + " cents";
    }

    return result.trim().replace(/\b\w/g, char => char.toUpperCase());
  };

  const selectedSo =
    downloadCI &&
    downloadCI !== false &&
    downloadCI &&
    downloadCI.custom_invoice_details &&
    downloadCI.custom_invoice_details.length > 0 &&
    downloadCI.custom_invoice_details[0].sales_order_details &&
    downloadCI.custom_invoice_details[0].sales_order_details
      ? downloadCI.custom_invoice_details[0].sales_order_details
      : false;
  // const TOTALAMOUNT = Number(Number(downloadCI?.total_fob_value || 0) +
  // Number(downloadCI?.duty || 0) + Number(downloadCI?.freight || 0) + Number(downloadCI?.insurance)).toFixed(2)
  const TOTALAMOUNT = Number(downloadCI?.amount_in_other || 0).toFixed(2)
  console.log("TOTALAMOUNT", TOTALAMOUNT)
  const list = tableList[0];
  const selectedShipper =
          list && list.shipper
            ? filterData(
                masters && masters.shippersList,
                "id",
                list.shipper,
                "onlyOne"
              )
              ? filterData(
                  masters && masters.shippersList,
                  "id",
                  list.shipper,
                  "onlyOne"
                )
              : ""
            : "";
        const selectedBenificiary =
          list && list.beneficiary
            ? filterData(
                masters && masters.benificiarysList,
                "id",
                list.beneficiary,
                "onlyOne"
              )
              ? filterData(
                  masters && masters.benificiarysList,
                  "id",
                  list.beneficiary,
                  "onlyOne"
                )
              : ""
            : "";

        const buyer =
          list && list.buyer
            ? filterData(
                masters && masters.buyersList,
                "id",
                list.buyer,
                "onlyOne"
              )
              ? filterData(
                  masters && masters.buyersList,
                  "id",
                  list.buyer,
                  "onlyOne"
                )
              : ""
            : "";
        const items = list?.subList && list.subList.length > 0 && list.subList;
        console.log("L", list, list?.subList);
        
        const soItemDetail = (soItemID) => {
          return filterData(downloadCI.sales_order_details?.so_items, "id", soItemID, "onlyOne")
            ? filterData(downloadCI.sales_order_details?.so_items, "id", soItemID, "onlyOne")
            : false;
        };
        const currency = filterData(
          masters["CurrencyList"],
          "id",
          selectedSo.currency,
          "onlyOne"
        )
          ? filterData(masters["CurrencyList"], "id", selectedSo.currency, "onlyOne")
              .name
          : "";
          console.log("downaloadCI", downloadCI, downloadCI.advance_amount_in_other, downloadCI && Boolean(Number(downloadCI.advance_amount_in_other)))

          const additionalRows = downloadCI && Boolean(Number(downloadCI.advance_amount_in_other))
          ? [
              // Row for LESS ADVANCE
              [
                {
                  text: 'LESS ADVANCE',
                  style: "basicCenter",
                  colSpan: 5,
                  border: [true, false, true, true],
                },
                { text: '', border: [false, false, false, false] }, // Correct empty cell
                { text: '', border: [false, false, false, false] }, // Correct empty cell
                { text: '', border: [false, false, false, false] }, // Correct empty cell
                { text: '', border: [false, false, false, false] }, // Correct empty cell
                {
                  text: Number(downloadCI.advance_amount_in_other).toFixed(2),
                  style: "basicCenter",
                  border: [true, false, true, true],
                },
              ],
              // Row for BALANCE TO RECEIVE
              [
                {
                  text: 'BALANCE TO RECEIVE',
                  style: "basicCenter",
                  colSpan: 5,
                  border: [true, false, true, true],
                },
                { text: '', border: [false, false, false, false] }, // Correct empty cell
                { text: '', border: [false, false, false, false] }, // Correct empty cell
                { text: '', border: [false, false, false, false] }, // Correct empty cell
                { text: '', border: [false, false, false, false] }, // Correct empty cell
                {
                  text: Number(downloadCI.pending_amount_in_other).toFixed(2),
                  style: "basicCenter",
                  border: [true, false, true, true],
                },
              ],
            ]
          : [];
  const dd1 = [
    {
      columns: [
        {
          width: "*",
          text: "COMMERCIAL INVOICE (TAX INVOICE)",
          alignment: "center",
          bold: true,
          fontSize: 14,
          fontFamily: "Arial",
        },
      ],
      margin: [0, 0, 0, 5], // Optional: Add some bottom margin for spacing
    },
    {
      columns: [
        {
          width: "*",
          text:
            downloadCI && downloadCI?.invoice_type === "GST"
              ? "Supply meant for export on payment of integrated tax".toUpperCase()
              : "Supply meant for export under bond or letter of undertaking without payment of IGST".toUpperCase(),
          alignment: "center",
          bold: true,
          fontSize: 9.2,
          fontFamily: "Arial",
        },
      ],
      margin: [0, 0, 0, 15],
    },
    {
          columns: [
            {
              width: "*",
              margin: [0, 0, 0, 0],
              stack: [
                {
                  table: {
                    widths: [214, 100, 100, 110],
                    body: [
                      [
                        {
                          text: "SHIPPER :",
                          style: "basicLeft",
                          border: [true, true, true, false],
                        },
                        {
                          text: "INVOICE NO.:",
                          style: "basicLeft",
                          border: [true, true, false, false],
                        },
                        {
                          text: "DATE :",
                          style: "basicLeft",
                          border: [true, true, false, false],
                        },
                        {
                          text: "EXPORTER REF :",
                          style: "basicLeft",
                          border: [true, true, true, false],
                        },
                      ],
                      [
                        {
                          text: selectedShipper && selectedShipper.name,
                          style: "basicLeft",
                          margin: [0, -5, 0, 0],
                          border: [true, false, false, false],
                        },
                        {
                          text: downloadCI && downloadCI.invoice_number,
                          style: "basicLeft",
                          margin: [0, -5, 0, 0],
                          border: [true, false, false, true],
                        },
                        {
                          text:
                            downloadCI?.invoice_date &&
                            moment(downloadCI?.invoice_date).format("DD.MM.YYYY"),
                          style: "basicLeft",
                          margin: [0, -5, 0, 0],
                          border: [true, false, false, true],
                        },
                        {
                          text:
                            list && list.exporter_ref_no
                              ? list.exporter_ref_no
                              : "",
                          style: "basicLeft",
                          margin: [0, -5, 0, 0],
                          border: [true, false, true, true],
                        },
                      ],
                      [
                        {
                          text:
                            selectedShipper &&
                            selectedShipper?.address.includes(
                              "C/O. ANAND OIL MILL,"
                            )
                              ? selectedShipper?.address
                                  .replace("C/O. ANAND OIL MILL,", "")
                                  .replace(/\n/g, " ")
                              : selectedShipper.address.replace(/\n/g, " "),
                          style: "basicLeft",
                          margin: [0, -4, 0, 0],
                          border: [true, false, false, false],
                        },
                        {
                          colSpan: 2,
                          text: `CONTRACT NUMBER:\n${
                          selectedSo && selectedSo.so_number ? selectedSo.so_number : "" }`,
                          style: "basicLeft",
                          border: [true, true, true, true],
                        },
                        {},
                        {
                          text: `DATED :\n${
                          selectedSo && selectedSo?.contract_date && moment(selectedSo?.contract_date).format("DD.MM.YYYY")}`,
                          style: "basicLeft",
                          border: [true, true, true, true],
                        },
                      ],
                      [
                        {
                          text: `GST Number: ${
                            selectedShipper && selectedShipper.gstin
                              ? selectedShipper.gstin
                              : ""
                          }`,
                          style: "basicLeft",
                          margin: [0, -5, 0, 0],
                          border: [true, false, false, true],
                        },
                        {
                          text: `BL NO. & Date :\n${
                          downloadCI.custom_invoice_details[0].bl_number || ""}\t${downloadCI.custom_invoice_details[0].bl_date ? moment(downloadCI.custom_invoice_details[0].bl_date).format("DD/MM/YYYY") : "" }`,
                          style: "basicLeft",
                          border: [true, false, false, true],
                        },
                        {
                          text: `CUSTOM INVOICE :\n${
                           list && list.invoice_number ? list.invoice_number : ""}`,
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                        {
                          text: `DATE :\n${
                          list?.invoice_date && moment(list.invoice_date).format("DD.MM.YYYY")
                          }`,
                          style: "basicLeft",
                          border: [true, false, true, true],
                        } 
                      ],
                      [
                        {
                          text: "CONSIGNEE :",
                          style: "basicLeft",
                          border: [true, false, false, false],
                        },
                        {
                          text: "BUYER :",
                          colSpan: 3,
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text:
                            selectedSo && selectedSo.consignee
                              ? selectedSo.consignee
                              : "",
                          style: "basicLeft",
                          margin: [0, -2, 0, 0],
                          border: [true, false, false, false],
                        },
                        {
                          text: buyer ? buyer.client_name : "",
                          margin: [0, -2, 0, 0],
                          style: "basicLeft",
                          colSpan: 3,
                          border: [true, false, true, false],
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: "",
                          style: "basicLeft",
                          margin: [0, -2, 0, 0],
                          border: [true, false, false, true],
                        },
                        {
                          text:(buyer ? [buyer?.address.replace(/\n/g, " ").replace(/,\s*$/, ""), buyer.city?.replace(/,\s*$/, ""), buyer.state?.replace(/,\s*$/, ""), buyer.country?.replace(/,\s*$/, "")].filter(Boolean).join(", ")
                          : "" ) +
                          `\n${buyer.email ? 'Email:' + buyer.email : ""}`,
                          style: "basicLeft",
                          margin: [0, -2, 0, 0],
                          colSpan: 3,
                          border: [true, false, true, true],
                        },
                        {},
                        {},
                      ],
                    ],
                  },
                  layout: {
                    hLineColor: function (i, node) {
                      return "#222";
                    },
                    vLineColor: function (i, node) {
                      return "#222";
                    },
                  },
                },
                {
                  table: {
                    widths: [100, 105, 129, 190],
                    body: [
                      [
                        {
                          text: "NOTIFY 1 :",
                          style: "basicLeft",
                          border: [true, false, false, false],
                        },
                        {
                          text: "NOTIFY 2 :",
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                        {
                          text: "NOTIFY 3 :",
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                        {
                          text: " COUNTRY OF ORIGIN OF GOODS: INDIA",
                          style: "basicCenter",
                          border: [true, false, true, true],
                        },
                      ],
                      [
                        {
                          text: `${
                            downloadCI &&
                            downloadCI.notify_1 &&
                            filterData(
                              masters && masters.notifyList,
                              "id",
                              downloadCI &&
                                downloadCI.notify_1
                                ? Number(
                                    downloadCI.notify_1
                                  )
                                : "",
                              "onlyOne"
                            )
                              ? filterData(
                                  masters && masters.notifyList,
                                  "id",
                                  downloadCI &&
                                    downloadCI.notify_1
                                    ? Number(
                                        downloadCI.notify_1
                                      )
                                    : "",
                                  "onlyOne"
                                ).notify.replace(/\n/g, " ")
                              : " "
                          }`,
                          style: "basicLeft",
                          fontSize: 6.5,
                          border: [true, false, false, true],
                        },
                        {
                          text: `${
                            downloadCI &&
                            downloadCI.notify_2 &&
                            filterData(
                              masters && masters.notifyList,
                              "id",
                              downloadCI &&
                                downloadCI.notify_2
                                ? Number(
                                    downloadCI
                                      .notify_2
                                  )
                                : "",
                              "onlyOne"
                            )
                              ? filterData(
                                  masters && masters.notifyList,
                                  "id",
                                  downloadCI &&
                                    downloadCI
                                      .notify_2
                                    ? Number(
                                        downloadCI
                                          .notify_2
                                      )
                                    : "",
                                  "onlyOne"
                                ).notify.replace(/\n/g, " ")
                              : " "
                          }`,
                          style: "basicLeft",
                          fontSize: 6.5,
                          border: [true, false, true, true],
                        },
                        {
                          text: `${
                            downloadCI &&
                            downloadCI.notify_3 &&
                            filterData(
                              masters && masters.notifyList,
                              "id",
                              downloadCI &&
                                downloadCI.notify_3
                                ? Number(
                                    downloadCI
                                      .notify_3
                                  )
                                : "",
                              "onlyOne"
                            )
                              ? filterData(
                                  masters && masters.notifyList,
                                  "id",
                                  downloadCI &&
                                    downloadCI
                                      .notify_3
                                    ? Number(
                                        downloadCI
                                          .notify_3
                                      )
                                    : "",
                                  "onlyOne"
                                ).notify.replace(/\n/g, " ")
                              : " "
                          }`,
                          style: "basicLeft",
                          fontSize: 6.5,
                          border: [true, false, true, true],
                        },
                        {
                          text: [
                            { text: "PLACE OF DELIVERY/SUPPLY: " + "\n" },
                            {
                              text:
                                list && list.delivery_place
                                  ? list.delivery_place.includes("CIF") ? list.delivery_place.slice(4) : list.delivery_place
                                  : "",
                              margin: [0, 8, 0, 0],
                            },
                          ],
                          style: "basicCenter",
                          margin: [0, 2, 0, 0],
                          border: [true, false, true, true],
                        },
                      ],
                    ],
                  },
                  layout: {
                    hLineColor: function (i, node) {
                      return "#222";
                    },
                    vLineColor: function (i, node) {
                      return "#222";
                    },
                  },
                },
                {
                  table: {
                    widths: [214, 129, 190],
                    body: [
                      [
                        {
                          text: "NAME OF CARRYING VESSEL/FLIGHT NO.",
                          style: "basicLeft",
                          border: [true, false, false, true],
                        },
                        {
                          text: "TERM OF DELIVERY & PAYMENT :",
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                        {
                          text: "BENEFICIARY NAME :",
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                      ],
                      [
                        {
                          text: `${list?.vessel_number ? list?.vessel_number : ""}  ${
                            list?.flight_number ? list?.flight_number : ""
                          }`,
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                        {
                          text: `Delivery : ${
                            filterData(
                              masters && masters.incoTermList,
                              "id",
                              list.sales_order_details.inco_term,
                              "onlyOne"
                            )
                              ? filterData(
                                  masters && masters.incoTermList,
                                  "id",
                                  list.sales_order_details.inco_term,
                                  "onlyOne"
                                ).name
                              : ""
                          }, ${
                            list && list.delivery_place
                              ? list.delivery_place.includes("CIF") ? list.delivery_place.slice(4) : list.delivery_place
                              : ""
                          } ,`,
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                        {
                          text: selectedBenificiary && selectedBenificiary.name,
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                      ],
                      [
                        {
                          text: "PORT OF LOADING:",
                          style: "basicLeft",
                          border: [true, false, true, false],
                        },
                        {
                          rowSpan: 3,
                          text: `Payment : ${
                            filterData(
                              masters && masters.soPaymentTermsList,
                              "id",
                              selectedSo && selectedSo.so_payment_term
                                ? Number(selectedSo.so_payment_term)
                                : "",
                              "onlyOne"
                            )
                              ? filterData(
                                  masters && masters.soPaymentTermsList,
                                  "id",
                                  selectedSo && selectedSo.so_payment_term
                                    ? Number(selectedSo.so_payment_term)
                                    : "",
                                  "onlyOne"
                                ).term
                              : "false"
                          } `,
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                        { rowSpan: 3,
                          text: `A/C NO.: ${
                            selectedBenificiary &&
                            selectedBenificiary.account_no
                          }\nBANK NAME : ${
                            selectedBenificiary && selectedBenificiary.bank_name
                          }\nBANK ADDRESS : ${
                            selectedBenificiary &&
                            selectedBenificiary.bank_address 
                          }\nSWIFT CODE : ${
                            selectedBenificiary &&
                            selectedBenificiary.swift_code
                          }`,
                          style: "basicLeft",
                          border: [false, false, true, true],
                        },
                      ],
                      [
                        {
                          text: `${
                            downloadCI &&
                            filterData(
                              masters && masters.PortList,
                              "id",
                              downloadCI?.custom_invoice_details?.[0]
                                ?.loading_port,
                              "onlyOne"
                            )
                              ? filterData(
                                  masters && masters.PortList,
                                  "id",
                                  downloadCI?.custom_invoice_details?.[0]
                                    ?.loading_port,
                                  "onlyOne"
                                )?.name.toUpperCase() + ", INDIA"
                              : ""
                          }`,
                          style: "basicLeft",
                          margin: [0, -3, 0, 0],
                          border: [true, false, true, true],
                        },
                        {},
                        {},
                      ],
                      [
                        {
                          text: `PORT OF DISCHARGE\n${
                          selectedSo && selectedSo.discharge_port
                              ? selectedSo.discharge_port.toUpperCase()
                              : ""}`,
                          style: "basicLeft",
                          border: [true, false, true, true],
                        },
                        {},
                        {},
                      ],
                    ],
                  },
                  layout: {
                    hLineColor: function (i, node) {
                      return "#222";
                    },
                    vLineColor: function (i, node) {
                      return "#222";
                    },
                  },
                },
                {
                      table: {
                        widths: [98, 107, 70, 50, 130, 51],
                        body: [
                          [
                            {
                              text: "MARKS AND NUMBER OF CONTAINER NO.",
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: "NO. AND KIND OF PACKAGE",
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: "DESCRIPTION OF GOODS",
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: `QUANTITY IN (${
                                downloadCI &&
                                downloadCI.custom_invoice_details[0].unit
                                  ? downloadCI.custom_invoice_details[0].unit
                                  : ""
                              })`,
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: `Price ${
                                currency ? currency.includes('Dollar') ? 'USD' : currency : ""
                              } PER ${
                                downloadCI && downloadCI.custom_invoice_details[0]?.unit
                                  ? downloadCI.custom_invoice_details[0]?.unit
                                  : ""
                              }`,
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: `Amount ${
                                currency ? currency.includes('Dollar') ? 'USD' : currency : ""
                              }`,
                              style: "basicCenter",
                              border: [true, false, true, true],
                            },
                          ],
                          [
                            {
                              text:items &&
                              items
                                .map((d, i) => {
                                  const soItem = soItemDetail(d.so_item);
                                  if (
                                    soItem &&
                                    soItem.brand &&
                                    soItem.brand?.name
                                  ) {
                                    return (
                                      soItem.brand?.name +
                                      (i !== items.length - 1 ? ", \n" : "")
                                    );
                                  }
                                  return "";
                                })
                                .filter(Boolean)
                                .join(""),
                              style: "basicCenter",
                              margin: [0, 10, 0, 0],
                              border: [true, false, false, false],
                            },
                            {
                              text:
                                items &&
                                selectedSo &&
                                (selectedSo?.total_no_of_containers
                                  ? `${selectedSo.total_no_of_containers} X  20' CONTS`
                                  : "") +
                                  "\n" +
                                  "\n" +
                                  `${
                                    items
                                      ? `Number of bags: ${items.reduce(
                                          (totalBags, d) => {
                                            const soItem = soItemDetail(d.so_item);
                                            if (soItem) {
                                              const numberOfBags = soItem.packaging
                                                ? soItem.packaging.reduce(
                                                    (prev, curr) =>
                                                      Number(prev) +
                                                      Number(
                                                        d.pkg_counter[curr?.id]
                                                          ? d.pkg_counter[curr?.id]
                                                          : 0
                                                      ),
                                                    0
                                                  )
                                                : 0;
                                              return totalBags + numberOfBags;
                                            }
                                            return totalBags;
                                          },
                                          0
                                        )}` // Initial totalBags is 0, accumulate the sum for each item
                                      : ""
                                  }`,
                              margin: [0, 10, 0, 0],
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: items && (soItemDetail(items[0].so_item).item_category?.name || ""),
                              margin: [0, 10, 0, 0],
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: `GROSS WT.\n${
                                    list && list.gross_weight
                                    // items.reduce(
                                    //   (prev, curr) =>
                                    //     Number(prev) + Number(curr.gross_weight || 0),
                                    //   0
                                    // )
                                  }`,
                              margin: [0, 10, 0, 0],
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: items && items.map((d,i) => {
                                return (
                                  d.delivery_terms
                                  ? `${d.delivery_terms[0].includes("Dollar") 
                                  ? "USD" 
                                  : d.delivery_terms[0]
                                }\t${Number(d.delivery_terms[1]).toFixed(2)}/${d.delivery_terms[2]
                                   }, ${d.delivery_terms[3]}${ i === items.length - 1 ? "" : ",\n" }`
                                  : ""
                                )
                              }),
                              // items &&
                              // items.reduce(
                              //   (prev, curr) =>
                              //     Number(prev) + Number(curr.contract_rate || 0),
                              //   0
                              // ) ,
                              margin: [0, 10, 0, 0],
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "",
                              margin: [0, 10, 0, 0],
                              style: "basicCenter",
                              border: [true, false, true, false],
                            },
                          ],
                          [
                            {
                              text: items && items.map((d, i) => {
                                return d.container_numbers && d.container_numbers.length > 0
                                  ? d.container_numbers.map((ss) => ss + "\n").join('')
                                  : "";
                              }),
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: items
                                ? `Packaging in\n${
                                    items.map(d => {
                                      const soItem = soItemDetail(d.so_item);
                                      if (soItem) {
                                        return soItem.packaging.map((sP, index, arr) => {
                                          const pVal = sP

                                            const size = pVal.size ? Number(pVal.size) : "";
                                            const unit = pVal.unit ? pVal.unit : "";
                                            const bagType = pVal.bag_type ? pVal.bag_type : "";

                                            // Join the parts together and trim the result
                                            const packagingString = `${size}${unit} ${bagType}`.trim();

                                            // Add a comma and a newline unless it's the last item
                                            return packagingString + (index !== arr.length - 1 ? ",\n" : "");
                                        }).join("") 
                                      }
                                    }).join("\n")
                                  }`
                                : "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: `HSN CODE :\n${items && soItemDetail(items[0].so_item).hsn_code?.hsn_code || ""}`,
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: `NET WT.\n${
                                  list && list.net_weight
                                  // items.reduce(
                                  //   (prev, curr) =>
                                  //     Number(prev) + Number(curr.net_weight || 0),
                                  //   0
                                  // )
                                }`,
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, true, false],
                            },
                          ],
                          [
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "TOTAL FOB VALUE",
                              style: "basicRight",
                              border: [true, true, false, true],
                            },
                            {
                              text: downloadCI?.total_fob_value ? Number(downloadCI?.total_fob_value).toFixed(2) : 'NIL',
                              style: "basicCenter",
                              border: [true, true, true, true],
                            },
                          ],
                          [
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "DUTY",
                              style: "basicRight",
                              border: [true, true, false, true],
                            },
                            {
                              text: downloadCI?.duty ? Number(downloadCI?.duty).toFixed(2) : 'NIL',
                              style: "basicCenter",
                              border: [true, true, true, true],
                            },
                          ],
                          [
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, false],
                            },
                            {
                              text: "FREIGHT",
                              style: "basicRight",
                              border: [true, true, false, true],
                            },
                            {
                              text: downloadCI?.freight ? Number(downloadCI?.freight).toFixed(2) : 'NIL',
                              style: "basicCenter",
                              border: [true, true, true, true],
                            },
                          ],
                          [
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: "INSURANCE",
                              style: "basicRight",
                              border: [true, true, false, true],
                            },
                            {
                              text: downloadCI?.insurance ? Number(downloadCI?.insurance).toFixed(2) : 'NIL',
                              style: "basicCenter",
                              border: [true, true, true, true],
                            },
                          ],
                          [
                            {
                              text: "TOTAL TAXABLE VALUE OF SHIPMENT",
                              style: "basicRight",
                              colSpan: 5,
                              border: [true, false, true, true],
                            },
                            {},
                            {},
                            {},
                            {},
                            {
                              text: downloadCI && Number(TOTALAMOUNT).toFixed(2),
                              style: "basicCenter",
                              border: [true, false, true, true],
                            },
                          ],
                          [
                            {
                              text: "",
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: `RATE OF IGST IN (%)`,
                              style: "basicCenter",
                              border: [false, false, false, true],
                            },
                            {
                              text: "",
                              style: "basicCenter",
                              border: [false, false, false, true],
                            },
                            {
                              text:
                                downloadCI && Number(downloadCI.igst_value) !== Number(0)
                                  ? `${Number(downloadCI.igst_value).toFixed(
                                      2
                                    )}%`
                                  : `NIL`,
                              style: "basicCenter",
                              border: [false, false, false, true],
                            },
                            {
                              text: `IGST AMOUNT`,
                              style: "basicRight",
                              border: [false, false, false, true],
                            },
                            {
                              text:
                                downloadCI && Number(downloadCI.igst_value) !== Number(0)
                                  ? Number(calculatePercentage(
                                    downloadCI ? Number(downloadCI?.total_fob_value || 0) : 0,
                                      downloadCI.igst_value
                                    )).toFixed(2)
                                  : `NIL`,
                              style: "basicCenter",
                              border: [true, false, true, true],
                            },
                          ],
                          [
                            {
                              text: `TOTAL ${
                                filterData(
                                  masters && masters.incoTermList,
                                  "id",
                                  list.sales_order_details.inco_term,
                                  "onlyOne"
                                )
                                  ? filterData(
                                      masters && masters.incoTermList,
                                      "id",
                                      list.sales_order_details.inco_term,
                                      "onlyOne"
                                    ).name
                                  : ""
                              } VALUE OF SHIPPED CARGO`,
                              style: "basicRight",
                              colSpan: 5,
                              border: [true, false, true, true],
                            },
                            {},
                            {},
                            {},
                            {},
                            {
                              text:
                                downloadCI && downloadCI.igst_value
                                  ? Number(Number(calculateGstAmount(
                                    downloadCI ? Number(TOTALAMOUNT) : 0,
                                      downloadCI.igst_value
                                    )) + Number(calculatePercentage(
                                      downloadCI ? Number(downloadCI?.total_fob_value || 0) : 0,
                                        downloadCI.igst_value
                                      ))).toFixed(2)
                                  :  'NIL',
                              style: "basicCenter",
                              border: [true, false, true, true],
                            },
                          ],
                          ...additionalRows,
                          [
                            {
                              text: `TAX IS PAYABLE ON REVERSE CHARGE BASIS - NO`,
                              style: "basicCenter",
                              colSpan: 4,
                              border: [true, false, true, true],
                            },
                            {},
                            {},
                            {},
                            {
                              text: `GST OF REVERSE CHARGE`,
                              style: "basicCenter",
                              border: [true, false, false, true],
                            },
                            {
                              text: `NIL`,
                              style: "basicCenter",
                              border: [true, false, true, true],
                            },
                          ],
                          [
                            {
                              text: `AMOUNT IN WORDS : ${
                                currency ? currency.includes('Dollar') ? 'USD' : currency : ""
                              } ${numberToWordsWithDecimalsInternational(
                                Number(
                                  downloadCI && downloadCI.igst_value
                                    ? calculateGstAmount(
                                        downloadCI ? Number(TOTALAMOUNT) : 0,
                                        downloadCI.igst_value
                                      )
                                    : downloadCI ? Number(TOTALAMOUNT) : 0,
                                )
                              )} ONLY`,
                              style: "basicLeft",
                              colSpan: 6,
                              border: [true, false, true, true],
                            },
                            {},
                            {},
                            {},
                            {},
                            {},
                          ],
                        ],
                      },
                      layout: {
                        hLineColor: function (i, node) {
                          return "#222";
                        },
                        vLineColor: function (i, node) {
                          return "#222";
                        },
                      },
                },
                {
                  table: {
                    dontBreakRows: true,
                    headerRows: 2, 
                    widths: [214, 328],
                    body: [
                      [
                        { text: "", border: [true, false, false, false] },
                        {
                          text: "SRI SAINATH INDUSTRY PVT LTD\n\n\n\n",
                          style: "basicRight",
                          margin: [0, 0, 20, 0],
                          border: [false, false, true, false],
                        },
                      ],
                      [
                        { text: "", border: [true, false, false, true] },
                        {
                          text: "Authorized Signatory Director",
                          fontSize: 7.5,
                          margin: [0, 0, 40, 0],
                          style: "basicRight",
                          border: [false, false, true, true],
                        },
                      ],
                    ],
                  },
                  layout: {
                    hLineColor: function (i, node) {
                      return "#222";
                    },
                    vLineColor: function (i, node) {
                      return "#222";
                    },
                  },
                },
                {
                  table: {
                        dontBreakRows: true,
                        headerRows: 4, 
                        widths: ["*"],
                        body: [
                          [
                            {
                              text: "\nCIN NUMBER : U14200CT2007PTC020290",
                              style: "basicLeft",
                              border: [false, false, false, false],
                            },
                          ],
                          [
                            {
                              text: "EMAIL : sainath@srisainath.com",
                              style: "basicLeft",
                              margin: [0,-5,0,0],
                              border: [false, false, false, false],
                            },
                          ],
                          [
                            {
                              text: "WEB : www.srisainath.com",
                              style: "basicLeft",
                              margin: [0,-5,0,0],
                              border: [false, false, false, false],
                            },
                          ],
                          [
                            {
                              text: "FAX : +91 771 4032240",
                              style: "basicLeft",
                              margin: [0,-5,0,0],
                              border: [false, false, false, false],
                            },
                          ],
                        ],
                      },
                },
              ],
            },
          ],
    },
  ];

  const documentDefinition = {
    pageMargins: [20, 20, 20, 10],
    content: [
      dd1, // First page content
    ],

    styles: {
      basicLeft: {
        color: "#333",
        fontSize: 8,
        alignment: "left",
      },
      basicRight: {
        color: "#333",
        fontSize: 8,
        alignment: "right",
      },
      basicCenter: {
        color: "#333",
        fontSize: 8,
        alignment: "center",
      },
    },
  };

  // pdfMake.createPdf(documentDefinition).download(`${downloadCI.invoice_number}.pdf`);
  pdfMake.createPdf(documentDefinition).open();
}

export default CommercialInvoicePDF;
