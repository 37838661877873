import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { CssTextField } from "../styles/StyledComponents";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox, Chip } from "@mui/material";

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 200, // Set the maximum height for the dropdown
    },
  },
  MenuListProps: {
    style: {
      overflowY: "auto", // Enable vertical scrolling
    },
  },
};
export const CustomAutocomplete = (props) => {

  return (
    <Autocomplete
      {...props}
      value={props?.value || null}
      onChange={(e, newValue) => {
        if (props?.onChange) {
          props?.onChange(e, newValue);
        }
      }}
      options={props?.options || []}
      getOptionLabel={(option) =>
        props?.getOptionLabel(option) || option?.title || ""
      }
      MenuProps={MenuProps}
      renderInput={(params) => (
        <CssTextField
          {...params}
          label={props?.label || ""}
          variant="outlined"
          required={props?.required || false}
          inputProps={{
            ...params.inputProps,
            style: {
              padding: "6px",
            },
          }}
        />
      )}
      style={{
        width: props.width || 200,
        ...(props?.style || {}),
      }}
    />
  );
};

export const CustomMultiSelectAutocomplete = (props) => {

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Autocomplete
      {...props}
      multiple
      id="checkboxes-tags-demo"
      value={props?.value || []}
      onChange={(e, newValue) => {
        if (props?.onChange) {
          props?.onChange(e, newValue);
        }
      }}
      MenuProps={MenuProps}
      options={props?.options || []}
      getOptionLabel={(option) =>
        props?.getOptionLabel(option) || option?.title || ""
      }
      disableCloseOnSelect
      renderOption={(p, option, state) => {
        const { key, ...optionProps } = p;
        if (!option) return null;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={option?.selected}
            />
            {p[props?.optionKey] || ""}
          </li>
        );
      }}
      renderTags={(value, getTagProps) => {
        return value && value.length > 0 && typeof value !== "string"
          ? value.map((option, index) => (
              <Chip
                key={value.toString()}
                variant="outlined"
                label={option[props?.optionKey]}
                size="small"
                style={{ fontFamily: "Poppins", padding: '0 5px', margin: '2px' }}
                {...getTagProps({ index })}
              />
            ))
          : "";
      }}
      style={{
        width: props.width || 200,
        ...(props?.style || {}),
      }}
      renderInput={(params) => (
        <CssTextField
          {...params}
          label={props?.label || ""}
          variant="outlined"
          required={props?.required || false}
          inputProps={{
            ...params.inputProps,
            style: {
              padding: "6px",
            },
          }}
        />
      )}
    />
  );
};

export const CustomSearchAutocomplete = (props) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      {...props}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
        if (props?.onInputChange) {
          props?.onInputChange(e, newInputValue);
        }
      }}
      MenuProps={MenuProps}
      value={props.value || null}
      options={props?.options || []}
      getOptionLabel={(option) =>
        props?.getOptionLabel(option) || option?.title || ""
      }
      freeSolo
      renderInput={(params) => (
        <CssTextField
          {...params}
          required={props?.required || false}
          label={props?.label || "Search as you type"}
          variant="outlined"
        />
      )}
      style={{ width: props.width || 200, ...props?.style }}
    />
  );
};
