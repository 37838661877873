import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { moduleURL } from "../../../http/config";
import { requests } from "../../../requests";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Backdrop } from "@material-ui/core";
import { StyledCircularProgress } from "../../../styles/StyledComponents";
import { stateArray } from "../../../Defaults";
import MenuItem from "@mui/material/MenuItem";
import { StopScroll } from "../../../Regex";

const classes = {
  container: {
    width: "100%",
    height: "auto",
  },
  form: {
    display: "flex",
    width: "100%",
    paddingTop: "15px",
    flexDirection: "column",
  },
  formGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
  textField: {
    width: "40%",
  },
  formActions: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
};

const NewForm = ({ getFn, setOpenPopup, status, setState }) => {
  // const [toggleState, setToggleState]= useState(status)

  const [fieldValues, setFieldValues] = useState({
    nameInput: "",
    addressInput: "",
    cityInput: "",
    stateInput: "",
    countryInput: "",
    emailInput: "",
    phoneInput: "",

    statusInput: true,
  });
  const [isValid, setIsValid] = useState({
    nameInput: true,
    addressInput: true,
    cityInput: true,
    stateInput: true,
    countryInput: true,
    emailInput: true,
    // phoneInput: true,
  });
  // const [formErrors, setFormErrors] = useState({});

  const [error, setError] = useState("");
  const [pushNoti, setPushNoti] = useState(false);
  const [pushNotier, setPushNotier] = useState(false);
  const [loading, setLoading] = useState(false);

  const addNewHandler = (e) => {
    e.preventDefault();
    if (fieldValues.nameInput.length === 0) {
      setIsValid((prev) => ({ ...prev, nameInput: false }));
    }
    if (fieldValues.cityInput.length === 0) {
      setIsValid((prev) => ({ ...prev, cityInput: false }));
    }
    if (fieldValues.countryInput.length === 0) {
      setIsValid((prev) => ({ ...prev, countryInput: false }));
    }
    if (fieldValues.addressInput.length === 0) {
      setIsValid((prev) => ({ ...prev, addressInput: false }));
    }
    // if (fieldValues.stateInput.length === 0) {
    //   setIsValid((prev) => ({ ...prev, stateInput: false }));
    // }
    // if (fieldValues.phoneInput.length < 10) {
    //   setIsValid((prev) => ({ ...prev, phoneInput: false }));
    // }
    // setFormErrors(validate(fieldValues));
    if (
      fieldValues.nameInput.length > 0 &&
      fieldValues.cityInput.length > 0 &&
      fieldValues.countryInput.length > 0 &&
      fieldValues.addressInput.length > 0
      //  && fieldValues.stateInput.length > 0
      // && fieldValues.phoneInput.length > 9
    ) {
      setLoading(true);
      const postUrl = moduleURL("masters/clients/list");
      requests({
        method: "post",
        url: postUrl,
        data: {
          client_name: fieldValues.nameInput,
          address: fieldValues.addressInput,
          city: fieldValues.cityInput,
          state: fieldValues.stateInput,
          country: fieldValues.countryInput,
          email: fieldValues.emailInput,
          phone: fieldValues.phoneInput,
          is_active: fieldValues.statusInput,
          // created_by: 0,
          // modified_by: 0,
        },
      }).then((res) => {
        if (res.error) {
          console.log(
            typeof res.data === "string"
              ? res.data
              : res.data.length > 0
              ? res.data
                  .map((s) => {
                    return `${s.field ? `${s.field} :` : ""} ${
                      typeof s.message === "string"
                        ? s.message
                        : s.message.length > 0 && s.message[0] && s.message[0]
                    }`;
                  })
                  .join(" ,")
              : res.data.response
              ? res.data.response.data.msg.error
                ? res.data.response.data.msg.error
                : res.data.response.data.msg
              : "Error !",
            "eeeeeeeeeeeeeee"
          );
          console.log(res, "response");
          // setError(res.data[0].message[0]);
          setError(
            typeof res.data === "string"
              ? res.data
              : res.data.length > 0
              ? res.data
                  .map((s) => {
                    return `${s.field ? `${s.field} :` : ""} ${
                      typeof s.message === "string"
                        ? s.message
                        : s.message.length > 0 && s.message[0] && s.message[0]
                    }`;
                  })
                  .join(" ,")
              : res.data.response
              ? res.data.response.data.msg.error
                ? res.data.response.data.msg.error
                : res.data.response.data.msg
              : "Error !"
          );
          console.log(error, "response error");
          setPushNotier(true);
        } else {
          getFn();
          setError("");
          setPushNoti(true);
        }
      });
    }
  };

  const handleReset = () => {
    setFieldValues((prev) => ({ ...prev, nameInput: "" }));
    setFieldValues((prev) => ({ ...prev, addressInput: "" }));
    setFieldValues((prev) => ({ ...prev, cityInput: "" }));
    setFieldValues((prev) => ({ ...prev, stateInput: "" }));
    setFieldValues((prev) => ({ ...prev, countryInput: "" }));
    setFieldValues((prev) => ({ ...prev, emailInput: "" }));
    setFieldValues((prev) => ({ ...prev, phoneInput: "" }));
  };

  // const validate = (values) => {
  //   const errors = {};
  //   if (!values.nameInput) {
  //     errors.nameInput = "Name is required!";
  //   }
  //   if (!values.cityInput) {
  //     errors.cityInput = "City is required!";
  //   }
  //   if (!values.CountryInput) {
  //     errors.CountryInput = "Country is required!";
  //   }
  //   if (!values.addressInput) {
  //     errors.addressInput = "Address is required!";
  //   }
  //   if (!values.stateInput) {
  //     errors.stateInput = "State is required!";
  //   }
  //   if (!values.phoneInput) {
  //     errors.phoneInput = "Phone is required!";
  //   }
  //   return errors;
  // };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div style={classes.container}>
      <form style={classes.form} onSubmit={addNewHandler}>
        <div style={classes.formGroup}>
          <TextField
            // required
            type="text"
            style={classes.textField}
            variant="outlined"
            label="Name *"
            onKeyPress={(e) => {
              const re = /[a-z A-Z0-9.]+/g;
              if (!re.test(e.key)) {
                e.preventDefault();
              }
            }}
            value={fieldValues.nameInput}
            onChange={({ target }) => {
              setFieldValues((prev) => ({ ...prev, nameInput: target.value }));
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, nameInput: true }));
              }
            }}
            error={!isValid.nameInput}
            helperText={isValid.nameInput ? "" : "Name should not be empty"}
          />
          <TextField
            type="text"
            multiline
            maxRows={3}
            style={classes.textField}
            variant="outlined"
            label="Address *"
            value={fieldValues.addressInput}
            onChange={({ target }) => {
              setFieldValues((prev) => ({
                ...prev,
                addressInput: target.value,
              }));
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, addressInput: true }));
              }
            }}
            error={!isValid.addressInput}
            helperText={
              isValid.addressInput ? "" : "Address should not be empty"
            }
          />
        </div>
        <div style={classes.formGroup}>
          <TextField
            type="text"
            style={classes.textField}
            variant="outlined"
            label="City *"
            value={fieldValues.cityInput}
            onKeyPress={(e) => {
              const re = /[a-z A-Z]+/g;
              if (!re.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, cityInput: true }));
              }
              setFieldValues((prev) => ({ ...prev, cityInput: target.value }));
            }}
            error={!isValid.cityInput}
            helperText={isValid.cityInput ? "" : "City should not be empty"}
          />
          <TextField
            type="text"
            style={classes.textField}
            // select
            variant="outlined"
            label="State"
            value={fieldValues.stateInput}
            onKeyPress={(e) => {
              const re = /[a-z A-Z]+/g;
              if (!re.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={({ target }) => {
              // if (fieldValues.stateInput.length > 0) {
              //   setIsValid((prev) => ({ ...prev, stateInput: true }));
              // }
              setFieldValues((prev) => ({ ...prev, stateInput: target.value }));
            }}
            // error={!isValid.stateInput}
            // helperText={isValid.stateInput ? "" : "State should not be empty"}
          />
        </div>
        <div style={classes.formGroup}>
          <TextField
            type="text"
            style={classes.textField}
            variant="outlined"
            label="Country *"
            value={fieldValues.countryInput}
            onKeyPress={(e) => {
              const re = /[a-z A-Z.]+/g;
              if (!re.test(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={({ target }) => {
              if (target.length !== 0) {
                setIsValid((prev) => ({ ...prev, countryInput: true }));
              }
              setFieldValues((prev) => ({
                ...prev,
                countryInput: target.value,
              }));
            }}
            error={!isValid.countryInput}
            helperText={
              isValid.countryInput ? "" : "Country should not be empty"
            }
          />
          <TextField
            type="number"
            onFocus={StopScroll}
            // inputProps={{
            //   // inputMode: "numeric",
            //   // pattern: "[0-9]*",
            //   maxLength: 10,
            // }}
            style={classes.textField}
            variant="outlined"
            label="Phone"
            value={fieldValues.phoneInput}
            onChange={({ target }) => {
              if (fieldValues.phoneInput.length > 0) {
                // setIsValid((prev) => ({ ...prev, phoneInput: true }));
              }
              setFieldValues((prev) => ({ ...prev, phoneInput: target.value }));
            }}
            onKeyDown={(evt) => {
              evt.key === "." && evt.preventDefault();

              evt.key === "e" && evt.preventDefault();

              evt.key === "E" && evt.preventDefault();
              evt.key === "-" && evt.preventDefault();
              evt.keyCode === 38 && evt.preventDefault();
              evt.keyCode === 40 && evt.preventDefault();
            }}
            // onInput={(e) => {
            //   e.target.value = Math.max(0, parseInt(e.target.value))
            //     .toString()
            //     .slice(0, 10);
            // }}
            // error={!isValid.phoneInput}
            // helperText={
            //   isValid.phoneInput ? "" : "Phone should be a 10 digit number"
            // }
          />
        </div>
        <div style={classes.formGroup}>
          <TextField
            type="email"
            style={classes.textField}
            variant="outlined"
            label="Email"
            value={fieldValues.emailInput}
            // onKeyPress={(e) => {
            //   const re = /[a-z A-Z]+/g;
            //   if (!re.test(e.key)) {
            //     e.preventDefault();
            //   }
            // }}
            onChange={({ target }) => {
              // if (fieldValues.emailInput.length > 0) {
              //   setIsValid((prev) => ({ ...prev, emailInput: true }));
              // }
              setFieldValues((prev) => ({
                ...prev,
                emailInput: target.value,
              }));
            }}
            // error={!isValid.emailInput}
            // helperText={isValid.emailInput ? "" : "Email should not be empty"}
          />
        </div>
        <div style={classes.formActions}>
          <FormControlLabel
            control={
              <Switch
                disabled
                style={{ color: "#B0B0B0" }}
                defaultChecked
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: true,
                    }));
                  } else {
                    setFieldValues((prev) => ({
                      ...prev,
                      statusInput: false,
                    }));
                  }
                }}
              />
            }
            label={fieldValues.statusInput ? "Active" : "Inactive"}
          />

          <div>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleReset}
              style={{
                marginRight: "20px",
                border: "none",
                textTransform: "capitalize",
              }}
            >
              Reset
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: "rgb(241 154 48)",
                color: "white",
                textTransform: "capitalize",
              }}
            >
              Add
            </Button>
          </div>
        </div>
      </form>
      {loading ? (
        <Backdrop
          style={{ zIndex: 1, color: "#fff", position: "absolute" }}
          open={loading}
        >
          <StyledCircularProgress color="rgb(241 154 48)" />
        </Backdrop>
      ) : (
        ""
      )}
      <Snackbar
        open={pushNoti}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="success"
        autoHideDuration={2000}
        onClose={() => {
          setOpenPopup(false);
          setPushNoti(false);
          setLoading(false);
          setError("");
          // closePopup();
        }}
      >
        <Alert style={{ backgroundColor: "rgb(241 154 48)" }}>New Client Added!</Alert>

        {/* message="Changes have been saved!" */}
      </Snackbar>
      <Snackbar
        open={pushNotier}
        style={{}}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        severity="error"
        autoHideDuration={5000}
        onClose={() => {
          // setOpenPopup(false);
          setPushNotier(false);
          setLoading(false);
          setError("");
          // closePopup();
        }}
      >
        <Alert severity="error">{error}</Alert>
        {/* message="Changes have been saved!" */}
      </Snackbar>
    </div>
  );
};

export default NewForm;
