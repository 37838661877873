import React, { useEffect, useRef, useState } from "react";
import TableComponent from "../../layoutComponents/TableComponent";
import {
  CardDetail,
  CssDialog,
  CssTextField95,
} from "../../styles/StyledComponents";
import { green, lightGreen, primary, red } from "../../styles/colors";
import { filterData, mediumScreen, StopScroll } from "../../Regex";
import {
  CssTextField,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import SailingOutlinedIcon from "@mui/icons-material/SailingOutlined";
import { divide, multiply, subtract, sum } from "lodash";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import { CleaningServices } from "@mui/icons-material";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import EventIcon from "@mui/icons-material/Event";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import { useSnackbar } from "../../snackbarContext";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StatusChip from "../../layoutComponents/StatusChip";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

export const CssKeyboardDatePicker = withStyles({
  root: {
    "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-input ": {
      padding: "14px 14px",
    },
    "& .MuiIconButton-root ": {
      padding: "0px",
    },
  },
})(KeyboardDatePicker);

const styles = {
  carouselWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  carouselContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    // margin: "15px 0",
    padding: "0 40px", // Add padding to create space for buttons
  },
  carouselTrackContainer: {
    overflow: "hidden",
    width: "100%",
  },
  carouselTrack: {
    display: "flex",
    transition: "transform 0.4s ease-in-out",
    willChange: "transform",
  },
  carouselItem: {
    flex: "0 0 auto",
    width: "150px",
    margin: "0 10px",
    backgroundColor: "#f2f2f2",
    borderRadius: "8px",
    padding: "20px",
    boxSizing: "border-box",
    textAlign: "center",
  },
  prevBtn: (showPrevButton) => ({
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    // color: "#fff",
    // border: "none",
    // borderRadius: "50%",
    // width: "40px",
    // height: "40px",
    padding: 0,
    cursor: "pointer",
    display: showPrevButton ? "flex" : "none",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 10,
    left: "10px",
  }),
  nextBtn: (showNextButton) => ({
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    // color: "#fff",
    // border: "none",
    // borderRadius: "50%",
    // width: "40px",
    // height: "40px",
    padding: 0,
    cursor: "pointer",
    display: showNextButton ? "flex" : "none",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 10,
    right: "10px",
  }),
};

function Inventory(props) {
  const {
    InvList,
    historyList,
    filterChild,
    BrandList,
    ItemCateoryList,
    ItemList,
    masters,
    PortsFunction,
    loadingDispatch,
    loadingGodown,
    onlyMoveToShipButton,
    selectGodown,
    GodownList,
    updateAgain,
    singlelistView,
  } = props;
  const [loading, setLoadingDispatch] = useState(false);
  useEffect(() => {
    console.log(loadingDispatch, loading, "loadingDispatch");
    setLoadingDispatch(loadingDispatch);
  }, [loadingDispatch]);
  useEffect(() => {
    console.log(loadingDispatch, loading, "loadingDispatch");
    setLoadingDispatch(loadingDispatch);
  }, []);
  const [customInvoices, setCustomInvoices] = useState([]);
  const [commercialList, setCommercialList] = useState([]);
  const [salesorderList, setsalesorderList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogNew, setOpenDialogNew] = useState(false);
  const [selctedSo, setSelctedSo] = useState({ id: "", value: "" });
  const [selctedCIUnit, setselctedCIUnit] = useState({ unit: "", value: "" });
  const [selctedCi, setSelctedCi] = useState({ id: "", value: "" });

  const [selctedCom, setSelctedCom] = useState({ id: "", value: "" });
  const [values, setValues] = useState([]);
  const [errorMsg, setErrorMsg] = useState({});
  const [success, setSuccess] = useState(false);
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [date, setDate] = useState("");
  const [unloadingAgent, setUnloadingAgent] = useState({ id: "", value: "" });
  const [mrLeoNo, setMrLeoNo] = useState("");
  const [isSpinning, setIsSpinning] = useState(
    Array(historyList?.length || 10).fill(false)
  );
  const [approveReject, setApproveReject] = useState({ status: "", data: "" });
  const [confirmStatus, setConfirmStatus] = useState("");
  const [selectedCheckBoxDataList, setSelectedCheckBoxDataList] = useState([]);
  const showSnackbar = useSnackbar();
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const trackRef = useRef(null);
  const trackContainerRef = useRef(null);

  let currentScrollPosition = 0;
  const scrollAmount = 160;

  function scrollCarousel(direction) {
    if (!trackRef.current || !trackContainerRef.current) return;

    const track = trackRef.current;
    const trackContainer = trackContainerRef.current;
    const maxScroll = track.scrollWidth - trackContainer.clientWidth;

    currentScrollPosition += direction * scrollAmount;

    if (currentScrollPosition < 0) {
      currentScrollPosition = 0;
    } else if (currentScrollPosition > maxScroll) {
      currentScrollPosition = maxScroll;
    }

    track.style.transform = `translateX(-${currentScrollPosition}px)`;

    setShowPrevButton(currentScrollPosition > 0);
    setShowNextButton(currentScrollPosition < maxScroll);
  }

  const checkOverflow = () => {
    const track = trackRef.current;
    const trackContainer = trackContainerRef.current;
    const isOverflowing = track?.scrollWidth > trackContainer?.clientWidth;

    // Show or hide buttons based on overflow
    setShowPrevButton(currentScrollPosition > 0);
    setShowNextButton(
      isOverflowing &&
        currentScrollPosition < track?.scrollWidth - trackContainer?.clientWidth
    );
  };

  useEffect(() => {
    console.table("paginationData", props?.paginationData);
  }, [props?.paginationData]);

  const pageChangeAPI = (url) => {
    props.fetchGodownHistory(props.selectGodown, url, "pagination");
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, []);

  useEffect(() => {
    setErrorMsg(errorMsg);
  }, [errorMsg]);
  useEffect(() => {
    if (onlyMoveToShipButton === true) {
    }
  }, [onlyMoveToShipButton]);
  useEffect(() => {
    console.log("calenderOpen", calenderOpen);
  }, [calenderOpen]);

  const selectedCheckBoxData = (data, checked = "") => {
    console.log(data, checked);
    let allData = [...selectedCheckBoxDataList];
    if (checked === "all") {
      setSelectedCheckBoxDataList(data);
    } else {
      if (allData && allData.some((d) => d.id === data.id)) {
        allData = allData.filter((d) => d.id !== data.id);
      } else {
        allData.push(data);
      }
      setSelectedCheckBoxDataList(allData);
    }
  };

  const handleConfirmApp = () => {
    console.log("PROPS IN", approveReject, props);
    let singleInventory = approveReject.data;

    let newArr = [];
    singleInventory?.total_packaging.map((sP, i) => {
      let usedWeight = {};
      usedWeight[sP.id] = changeUnit(
        sP.unit,
        singleInventory.sales_order_number.so_unit,
        multiply(
          sP.bags_for_packaging ? sP.bags_for_packaging : 0,
          sP.size ? Number(sP.size) : 0
        )
      );
      newArr.push({
        is_active: true,
        shipping_date: moment(singleInventory.date || new Date()).format(
          "YYYY-MM-DD"
        ),
        custom_invoice: Number(singleInventory.custom_invoice_number?.id),
        commercial_invoice: singleInventory.commercial_invoice_number?.id
          ? Number(singleInventory.commercial_invoice_number?.id)
          : null,
        unloading_agent: singleInventory?.unloading_agent_details?.id || "",
        mr_or_leo_no: singleInventory?.mr_or_leo_no || "",
        packaging_count: JSON.parse(singleInventory.shipping_bags_packaging),
        used_weight: usedWeight,
        brand: singleInventory.brand,
        item_category: singleInventory.item_category,
        item_type: singleInventory.item_type,
        item_qty: singleInventory.item_qty,
        godown: singleInventory ? singleInventory.godown : "",
        port: singlelistView.id,
        sales_order: singleInventory.sales_order_number?.id,
        bl_number: singleInventory?.bl_number || "",
        godown_history_id: singleInventory.id,
        coming_from: "VIA GODOWN",
      });
    });
    console.log(newArr, approveReject.status);
    props
      .PortsFunction(
        "patch",
        "arrival/godown-history/",
        singleInventory.id,
        null,
        {
          approval_status:
            approveReject.status === "APPROVE" ? "ACCEPTED" : "REJECTED",
        },
        null,
        null,
        "noeditparam"
      )
      .then((res) => {
        setConfirmStatus(false);
        setApproveReject({ status: "", data: "" });
        showSnackbar(
          `${
            approveReject.status === "APPROVE"
              ? "Approved successfully"
              : "Rejected successfully"
          }`,
          "success"
        );
        if (approveReject.status === "APPROVE") {
          props
            .PortsFunction(
              "post",
              "arrival/commercial-invoice/packing-list/bulk-create",
              null,
              "post",
              newArr
            )
            .then((res2) => {
              if (res2.error) {
                setLoad(false);
              }
            })
            .catch((e) => {
              console.log("error500", e);
              setLoad(false);
              setError("Shipping details are mismatched");
            });
        }
        if (props.updateAgain) {
          setTimeout(() => {
            props.updateAgain();
          }, 1000);
        }
      });
  };

  const handleUnloadingAgentChange = (e) => {
    setUnloadingAgent({
      id: filterData(
        masters.UnloadingAgentList,
        "name",
        e.target.value,
        "onlyOne"
      )?.id,
      value: e.target.value,
    });
  };

  const handleIconClick = (index, type) => {
    setIsSpinning((prevSpinning) => {
      const updatedSpinning = prevSpinning.map((d, i) =>
        i === index ? type : false
      );
      return updatedSpinning;
    });
  };

  const InvLists =
    InvList && InvList.length > 0
      ? InvList.map((singleInv) => {
          const brandSelected = filterData(
            BrandList,
            "id",
            singleInv.brand,
            "onlyOne"
          )
            ? filterData(BrandList, "id", singleInv.brand, "onlyOne")
            : "";
          singleInv.groups[0].bags_details.map((sP) => {
            console.log(sP);
            return sP.remaining_bags ? sP.remaining_bags : 0;
          });
          return {
            ...singleInv,
            Brand: brandSelected.brand_name,
            "Item Category":
              singleInv.groups[0]?.item_category_details?.item_category,
            "Item Type":
              singleInv.groups[0]?.item_type_details?.broken_percentage,
            "Total Bags":
              singleInv.groups[0].bags_details &&
              singleInv.groups[0].bags_details.length > 0
                ? singleInv.groups[0].bags_details
                    .map((sP) => {
                      return sP.remaining_bags ? sP.remaining_bags : 0;
                    })
                    .reduce(
                      (previousValue, currentValue) =>
                        Number(previousValue) + Number(currentValue),
                      0
                    )
                : 0,
            bags:
              singleInv.groups[0].bags_details &&
              singleInv.groups[0].bags_details.length > 0
                ? singleInv.groups[0].bags_details
                    .filter(
                      (s) => (s.remaining_bags ? s.remaining_bags : 0) !== 0
                    )
                    .map((sP) => {
                      return {
                        ...sP,
                        "Total Bags": `${
                          sP.remaining_bags ? sP.remaining_bags : 0
                        }`,
                        "Bag Number": sP.bag_number ? sP.bag_number : "",
                        "Bag Type": sP.bag_type,
                        Size: `${sP.size} ${sP.unit}`,
                        "Total Quantity": multiply(
                          sP.remaining_bags ? sP.remaining_bags : 0,
                          sP.size ? Number(sP.size) : 0
                        ),
                        "Total QuantityUnit": `${multiply(
                          sP.remaining_bags ? sP.remaining_bags : 0,
                          sP.size ? Number(sP.size) : 0
                        )} ${sP.unit}`,
                      };
                    })
                : [],
          };
        })
      : [];
  const [selectedBrandsArr, setSelectedBrandsArr] = useState([]);
  const [selectedCheckBoxObjArr, setSelectedCheckBoxObjArr] = useState({});
  const [submittedData, setSubmittedData] = useState({});
  const moveToShipHeader = ["Size", "Bag Type", "Total Bags", "Total Quantity"];
  const moveToShipHeader2 = [
    "Size",
    "Bag Type",
    "Total Bags",
    "Total Quantity",
    "Shipped Bags",
    "Shipped Quantity",
  ];
  const [error, setError] = useState(false);

  const [loader, setLoad] = useState(false);

  const clearFields = () => {
    setSelctedSo({
      value: "",
      id: "",
    });
    setselctedCIUnit({ unit: "", value: "" });
    setSelctedCi({
      value: "",
      id: "",
    });
    setCommercialList({ id: "", value: "" });
    setErrorMsg({});
    setDate("");
  };
  const changeUnit = (from, to, weigths, opposite) => {
    //default in kg

    if (opposite) {
      if (from === "MT") {
        return changeTo(to, divide(weigths, 1000), opposite);
      } else if (from === "Kgs") {
        return changeTo(to, weigths);
      } else if (from === "Qtl") {
        return changeTo(to, divide(weigths, 100), opposite);
      }
    } else {
      if (from === "MT") {
        return changeTo(to, multiply(weigths, 1000));
      } else if (from === "Kgs") {
        return changeTo(to, weigths);
      } else if (from === "Qtl") {
        return changeTo(to, multiply(weigths, 100));
      }
    }
  };

  const changeTo = (to, weigths, opposite) => {
    if (opposite) {
      if (to === "MT") {
        return multiply(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return multiply(weigths, 100);
      }
    } else {
      if (to === "MT") {
        return divide(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return divide(weigths, 100);
      }
    }
  };
  const net_value = (used, net) => {
    return subtract(Number(net ? net : 0), Number(used ? used : 0));
  };
  return loading && loading === true ? (
    <div className="alignC justiyC d-flex width-100 height-100">
      <StyledCircularProgress color="inherit" />
    </div>
  ) : loadingGodown && loadingGodown === true ? (
    <div className="alignC justiyC d-flex width-100 height-100">
      <StyledCircularProgress color="inherit" />
    </div>
  ) : (
    <div
      className={`${
        onlyMoveToShipButton ? "" : "width-100 height-100 d-flex d-flex-column"
      }`}
    >
      {error ? (
        <SnackbarComponent
          error={error}
          autoHideDuration={5000}
          // success={success}
          snackbarClose={() => {
            setError(false);
          }}
        />
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0 10px",
          width: "calc(100% - 20px)",
        }}
      >
        <div
          className="widthFC fontWeight400 alignC d-flex"
          style={{
            fontSize: mediumScreen() ? "0.9rem" : "20px",
            whiteSpace: "nowrap",
          }}
        >
          All Brands
        </div>
        {openDialogNew === true ? (
          <CssDialog
            onSubmit={() => {
              if (selctedSo && selctedSo.id) {
                if (selctedCi && selctedCi.id) {
                  if (selectedBrandsArr && selectedBrandsArr.length > 0) {
                    if (
                      submittedData &&
                      Object.values(submittedData) &&
                      Object.values(submittedData).length > 0 &&
                      Object.values(submittedData).reduce(
                        (previousValue, currentValue) =>
                          Number(previousValue) +
                          Number(currentValue ? currentValue : 0),
                        0
                      ) > 0
                    ) {
                      setLoad(true);
                      const newArr = [];
                      const newOtherArr = [];
                      console.log("NEWARR", newArr);
                      const id = [];
                      const checkCustomInvoice = (cI, packaging, noBags) => {
                        console.log(packaging, noBags, "cI, packaging, noBags");
                        const array = [];
                        const requires = [];
                        cI.custom_invoice_item_details.map((item) => {
                          if (
                            item.pkg_counter[packaging.id] &&
                            (Number(item.pkg_counter[packaging.id]) >
                              Number(noBags) ||
                              Number(item.pkg_counter[packaging.id]) ===
                                Number(noBags))
                          ) {
                            array.push("succes");
                          }
                          requires.push(item.pkg_counter);
                          return item;
                        });
                        if (array.length > 0) {
                          return "success";
                        } else {
                          return {
                            packagings: packaging,
                            bags: noBags,
                            ciPackagings: requires,
                          };
                        }
                      };
                      const selectedCiDetail = filterData(
                        customInvoices,
                        "id",
                        Number(selctedCi.id),
                        "onlyone"
                      )
                        ? filterData(
                            customInvoices,
                            "id",
                            Number(selctedCi.id),
                            "onlyone"
                          )
                        : "";
                      const all_nums = [];
                      const errors = [];
                      selectedBrandsArr.map((single) => {
                        const sData = filterData(
                          values.groups[0].bags_details,
                          "id",
                          Number(single),
                          "onlyOne"
                        )
                          ? filterData(
                              values.groups[0].bags_details,
                              "id",
                              Number(single),
                              "onlyOne"
                            )
                          : "";

                        console.log(
                          single,
                          "single",
                          values.groups[0].bags_details
                        );
                        if (sData) {
                          if (
                            !id.includes(single) &&
                            checkCustomInvoice(
                              selectedCiDetail,
                              sData,
                              submittedData[`${single}Shipped`]
                                ? Number(submittedData[`${single}Shipped`])
                                : ""
                            ) === "success"
                          ) {
                            all_nums.push(
                              changeUnit(
                                sData.unit,
                                selctedCIUnit &&
                                  selctedCIUnit.unit &&
                                  selctedCIUnit.unit,
                                multiply(
                                  submittedData[`${single}Shipped`]
                                    ? Number(submittedData[`${single}Shipped`])
                                    : "",
                                  sData.size ? Number(sData.size) : 0
                                )
                                  ? multiply(
                                      submittedData[`${single}Shipped`]
                                        ? Number(
                                            submittedData[`${single}Shipped`]
                                          )
                                        : "",
                                      sData.size ? Number(sData.size) : 0
                                    )
                                  : 0
                              )
                            );
                            console.log(selctedCi);
                            newArr.push({
                              is_active: true,
                              shipping_date: moment(date || new Date()).format(
                                "YYYY-MM-DD"
                              ),
                              unloading_agent: unloadingAgent.id,
                              mr_or_leo_no: mrLeoNo,
                              custom_invoice: Number(selctedCi.id),
                              sales_order: Number(selctedSo.id),
                              item_category: Number(
                                selectedCheckBoxDataList.find(
                                  (s) => Number(s.id) === Number(single)
                                )["Item CategoryId"]
                              ),
                              item_type: Number(
                                selectedCheckBoxDataList.find(
                                  (s) => Number(s.id) === Number(single)
                                )["Item TypeId"]
                              ),
                              packaging_count: {
                                [single]: submittedData[`${single}Shipped`]
                                  ? Number(submittedData[`${single}Shipped`])
                                  : "",
                              },
                              coming_from: "VIA GODOWN",
                              used_weight: {
                                [single]: changeUnit(
                                  sData.unit,
                                  selctedCIUnit &&
                                    selctedCIUnit.unit &&
                                    selctedCIUnit.unit,
                                  multiply(
                                    submittedData[`${single}Shipped`]
                                      ? Number(
                                          submittedData[`${single}Shipped`]
                                        )
                                      : "",
                                    sData.size ? Number(sData.size) : 0
                                  )
                                    ? multiply(
                                        submittedData[`${single}Shipped`]
                                          ? Number(
                                              submittedData[`${single}Shipped`]
                                            )
                                          : "",
                                        sData.size ? Number(sData.size) : 0
                                      )
                                    : 0
                                ),
                              },
                              brand: values.brand,
                              godown: selectGodown ? selectGodown : "",
                              port: singlelistView.id,
                            });
                            let shipping_bags_packaging = {};
                            shipping_bags_packaging[String(single)] =
                              submittedData[`${single}Shipped`]
                                ? Number(submittedData[`${single}Shipped`])
                                : "";
                            newOtherArr.push({
                              is_active: true,
                              date: moment(date || new Date()).format(
                                "YYYY-MM-DD"
                              ),
                              unloading_agent: unloadingAgent.id,
                              mr_or_leo_no: mrLeoNo,
                              product_of_cust_inv: Number(selctedCi.id),
                              product_of_sales_order: Number(selctedSo.id),
                              item_category: Number(
                                selectedCheckBoxDataList.find(
                                  (s) => Number(s.id) === Number(single)
                                )["Item CategoryId"]
                              ),
                              item_type: Number(
                                selectedCheckBoxDataList.find(
                                  (s) => Number(s.id) === Number(single)
                                )["Item TypeId"]
                              ),
                              shipping_bags_packaging: JSON.stringify(
                                shipping_bags_packaging
                              ),
                              shipping_bags: submittedData[`${single}Shipped`]
                                ? Number(submittedData[`${single}Shipped`])
                                : "",
                              brand: values.brand,
                              godown: selectGodown ? selectGodown : "",
                              status: "OUT",
                              approval_status: "PENDING",
                              // port: singlelistView.id,
                            });
                            if (Boolean(selctedCom?.id)) {
                              newArr.product_of_comm_inv = Number(
                                selctedCom.id
                              );
                              newArr.bl_number = selectedCiDetail.bl_number
                                ? selectedCiDetail.bl_number
                                : "";
                            }
                            console.log(
                              newOtherArr,
                              sData.unit,
                              selctedCIUnit.unit,
                              "newOtherArr"
                            );
                            id.push(single);
                          } else {
                            errors.push(
                              checkCustomInvoice(
                                selectedCiDetail,
                                sData,
                                submittedData[`${single}Shipped`]
                                  ? Number(submittedData[`${single}Shipped`])
                                  : ""
                              )
                            );
                          }
                        }

                        return single;
                      });
                      console.log(
                        all_nums.length > 0 &&
                          selctedCIUnit &&
                          selctedCIUnit.value &&
                          all_nums.reduce(
                            (previousValue, currentValue) =>
                              Number(previousValue) + Number(currentValue),
                            0
                          ) <= selctedCIUnit.value,
                        "errors"
                      );
                      console.log(
                        "newA",
                        submittedData,
                        selectedCheckBoxDataList,
                        newArr,
                        newOtherArr[0],
                        selctedCi,
                        submittedData,
                        values
                      );
                      if (
                        errors.length === 0 &&
                        all_nums.length > 0 &&
                        selctedCIUnit &&
                        selctedCIUnit.value &&
                        all_nums.reduce(
                          (previousValue, currentValue) =>
                            Number(previousValue) + Number(currentValue),
                          0
                        ) <= selctedCIUnit.value
                      ) {
                        if (newArr.length === selectedBrandsArr.length) {
                          console.log(newArr, newOtherArr[0]);
                          if (
                            props?.user?.userData?.roles
                              ?.toString()
                              .toLowerCase() === "admin"
                          ) {
                            props
                              .PortsFunction(
                                "post",
                                "arrival/commercial-invoice/packing-list/bulk-create",
                                null,
                                "post",
                                newArr
                              )
                              .then((res2) => {
                                if (res2.error) {
                                  setLoad(false);
                                } else {
                                  if (selctedCi) {
                                    if (updateAgain) {
                                      updateAgain();
                                    }
                                    setOpenDialogNew(false);
                                    setSubmittedData({});
                                    clearFields();
                                    setSuccess("Bags are shipped!");
                                  }
                                }
                              });
                          } else {
                            props
                              .PortsFunction(
                                "post",
                                `arrival/godown-history/list`,
                                null,
                                "post",
                                newOtherArr[0]
                              )
                              .then((res3) => {
                                if (res3.error) {
                                  setLoad(false);
                                } else {
                                  if (selctedCi) {
                                    if (updateAgain) {
                                      updateAgain();
                                    }
                                    setOpenDialogNew(false);
                                    setSubmittedData({});
                                    clearFields();
                                    setSuccess("Wait for Admin Approval!");
                                  }
                                }
                              });
                          }
                        }
                      } else {
                        setLoad(false);
                        console.log(errors, "errors");
                        setError(
                          errors.length > 0
                            ? `${errors.map((s) => {
                                return `${s.groups[0].bags_details} bags of ${s.packagings.size}${s.packagings.unit}`;
                              })} is not available in this custom invoice!`
                            : `Only ${selctedCIUnit.value} ${
                                selctedCIUnit &&
                                selctedCIUnit.unit &&
                                selctedCIUnit.unit
                              } is available in selected Custom Invoice!`
                        );
                      }
                    } else {
                      setErrorMsg((prev) => {
                        return { ...prev, addBags: true };
                      });
                    }
                  } else {
                    setErrorMsg((prev) => {
                      return {
                        ...prev,
                        selectedBrandsArr: "Please select brands!",
                      };
                    });
                  }
                } else {
                  setErrorMsg((prev) => {
                    return {
                      ...prev,
                      selctedCi: "Please select custom invoice!",
                    };
                  });
                }
              } else {
                // const errorMsgs = errorMsg;
                // errorMsgs.selctedSo = "Please select sales order!";
                // console.log(errorMsgs, "errorMsgs");
                setErrorMsg((prev) => {
                  return { ...prev, selctedSo: "Please select sales order!" };
                });
              }
            }}
            reset={() => {
              setSubmittedData({}); //state bags
              setSelectedBrandsArr([]); //check
              setSelectedCheckBoxObjArr({})
              clearFields();
            }}
            headerPrimary={true}
            height="calc(100% - 120px)"
            width="96%"
            maxWidth="1200px"
            header={`Move to shipping`}
            addButtonWidth="170px"
            // noActions={true}
            addButton={"Move to shipping"}
            onClose={() => {
              setOpenDialogNew(false);
              setSubmittedData({});
              clearFields();
              setSelectedBrandsArr([]); //check
              setSelectedCheckBoxObjArr({})
            }}
            snackbarClose={() => {
              setOpenDialogNew(false);
              setSubmittedData({});
              clearFields();
              setSelectedBrandsArr([]); //check
              setSelectedCheckBoxObjArr({})
            }}
            children={() => {
              return (
                <>
                  {error || success !== false ? (
                    <SnackbarComponent
                      autoHideDuration={error ? 5000 : 3000}
                      error={error}
                      success={success}
                      snackbarClose={() => {
                        setError(false);
                        if (success !== false) {
                          setLoad(false);
                          setOpenDialogNew(false);
                          setSubmittedData({});
                          clearFields();
                        }
                        setSuccess(false);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {loader && loader === true ? (
                    <div className="alignC justiyC d-flex width-100 height-100">
                      <StyledCircularProgress color="inherit" />
                    </div>
                  ) : (
                    <div className="width-100 height-100 d-flex d-flex-column">
                      <div className="width-100 d-flex flexWrap padding15">
                        {console.log("CA", calenderOpen)}
                        <MuiPickersUtilsProvider
                          libInstance={moment()}
                          utils={DateFnsUtils}
                        >
                          <CssKeyboardDatePicker
                            label={"Shipping Date"}
                            variant="inline"
                            onKeyUp={(ev) => {
                              ev.preventDefault();
                            }}
                            onKeyDown={(ev) => {
                              ev.preventDefault();
                            }}
                            onOpen={() => {
                              setCalenderOpen(true);
                            }}
                            onClose={() => {
                              setCalenderOpen(false);
                            }}
                            // minDate={"2022-01-22"}
                            error={errorMsg.date ? true : false}
                            helperText={errorMsg.date ? errorMsg.date : ""}
                            invalidLabel=""
                            onKeyPress={(ev) => {
                              ev.preventDefault();
                            }}
                            allowKeyboardControl={false}
                            keyboardIcon={
                              calenderOpen ? <EventIcon /> : <EventIcon />
                            }
                            autoOk
                            inputVariant="outlined"
                            style={
                              {
                                width: "calc(33% - 13px)",
                                marginRight: "20px",
                                pointer: "cursor",
                              } //disabled={tp}
                            }
                            format="do MMM, yyyy"
                            value={date ? date : new Date()}
                            //views={['year', 'month']}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={(newValue) => {
                              setDate(newValue);
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{
                            width: "calc(33% - 13px)",
                            marginRight: "20px",
                          }}
                          id="outlined-basic"
                          label={`Brand`}
                          variant="outlined"
                          name="singleFileName"
                          value={values["Brand"] ? values["Brand"] : ""}
                        />
                        {errorMsg.selctedSo && errorMsg.selctedSo !== false ? (
                          <CssTextField
                            required={true}
                            autoComplete="off"
                            value={
                              selctedSo
                                ? selctedSo.value
                                  ? selctedSo.value
                                  : ""
                                : "" //
                            }
                            error={true}
                            helperText={errorMsg.selctedSo}
                            onChange={(e) => {
                              const errorMsgs = errorMsg;
                              errorMsgs.selctedSo = false;
                              if (e.target.value) {
                                setLoad(true);
                                setCustomInvoices([]);
                                setSelctedCi({ value: "", id: "" });
                                setselctedCIUnit({ unit: "", value: "" });
                                PortsFunction(
                                  "get",
                                  "arrival/custom-invoice/list",
                                  // "arrival/commercial-invoice/list",
                                  null,
                                  "PortCIs",
                                  null,
                                  {
                                    sales_order: e.currentTarget.id,
                                    // shipment_status: "EXPENSES CALCULATED",
                                    is_active: "True",
                                    brand: values.brand,
                                    // port_id: singlelistView && singlelistView.id && singlelistView.id,
                                    status: "DRAFT,OPEN",
                                  }
                                  // ).then((commercialList) => {
                                  //   if (commercialList) {
                                  //     setCommercialList(commercialList);
                                  //   } else {
                                  //     setCommercialList([]);
                                  //   }
                                  //   setLoad(false);
                                  // });
                                ).then((customInvoices) => {
                                  if (customInvoices) {
                                    // console.log("P", props);
                                    const portID =
                                      props.singlelistView &&
                                      props.singlelistView?.id;
                                    const filterCustomInvoiceData =
                                      customInvoices.filter(
                                        (c) => c.loading_port === portID
                                      );
                                    console.log(
                                      "FILTER 1",
                                      filterCustomInvoiceData,
                                      customInvoices
                                    );
                                    setCustomInvoices(
                                      filterCustomInvoiceData
                                        ? filterCustomInvoiceData
                                        : customInvoices
                                    );
                                  } else {
                                    setCustomInvoices([]);
                                  }
                                  setLoad(false);
                                });
                              }

                              setSelctedSo({
                                value: e.target.value,
                                id: e.currentTarget.id,
                              });

                              setErrorMsg(errorMsgs);
                            }}
                            style={{
                              width: "calc(33% - 14px)",
                              // marginRight: "20px",
                            }}
                            id="outlined-select-currency-native"
                            select
                            label="Sales Order"
                            variant="outlined"
                            InputLabelProps={{}}
                            SelectProps={{
                              MenuProps: MenuProps,
                            }}
                          >
                            {salesorderList && salesorderList.length > 0 ? (
                              salesorderList
                                .sort((a, b) => b.id - a.id)
                                .map((option) => (
                                  <MenuItem
                                    key={option.so_number}
                                    value={option.so_number}
                                    id={option.id}
                                  >
                                    <div className="d-flex d-flex-column ">
                                      <span>{option.so_number}</span>
                                      <span
                                        style={{ fontSize: 12, color: "#888" }}
                                      >
                                        Remaining :{" "}
                                        {option?.remaining_quantity || ""}{" "}
                                        {option?.unit || ""}
                                      </span>
                                    </div>
                                  </MenuItem>
                                ))
                            ) : (
                              <MenuItem
                                disabled={true}
                                key={"No data!"}
                                value={"No data!"}
                                id={"No data!"}
                              >
                                No data!
                              </MenuItem>
                            )}
                          </CssTextField>
                        ) : (
                          <CssTextField
                            required={true}
                            autoComplete="off"
                            value={
                              selctedSo
                                ? selctedSo.value
                                  ? selctedSo.value
                                  : ""
                                : "" //
                            }
                            error={errorMsg.selctedSo ? true : false}
                            helperText={
                              errorMsg.selctedSo ? errorMsg.selctedSo : ""
                            }
                            onChange={(e) => {
                              const errorMsgs = errorMsg;
                              errorMsgs.selctedSo = false;
                              if (e.target.value) {
                                setLoad(true);
                                console.log("PROP", props);
                                setCustomInvoices([]);
                                setSelctedCi({ value: "", id: "" });
                                setselctedCIUnit({ unit: "", value: "" });
                                PortsFunction(
                                  "get",
                                  "arrival/custom-invoice/list",
                                  // "arrival/commercial-invoice/list",
                                  null,
                                  "PortCIs",
                                  null,
                                  {
                                    sales_order: e.currentTarget.id,
                                    // shipment_status: "EXPENSES CALCULATED",
                                    is_active: "True",
                                    brand: values.brand,
                                    // port_id: singlelistView && singlelistView.id && singlelistView.id,
                                    status: "DRAFT,OPEN",
                                  }
                                ).then((customInvoices) => {
                                  if (customInvoices) {
                                    console.log("P", props);
                                    const portID =
                                      props.singlelistView &&
                                      props.singlelistView?.id;
                                    const filterCustomInvoiceData =
                                      customInvoices.filter(
                                        (c) => c.loading_port === portID
                                      );
                                    console.log(
                                      "FILTER 2",
                                      filterCustomInvoiceData,
                                      customInvoices
                                    );
                                    setCustomInvoices(
                                      filterCustomInvoiceData
                                        ? filterCustomInvoiceData
                                        : customInvoices
                                    );
                                  } else {
                                    setCustomInvoices([]);
                                  }
                                  setLoad(false);
                                });
                              }

                              setSelctedSo({
                                value: e.target.value,
                                id: e.currentTarget.id,
                              });

                              setErrorMsg(errorMsgs);
                            }}
                            style={{
                              width: "calc(33% - 14px)",
                              // marginRight: "20px",
                            }}
                            id="outlined-select-currency-native"
                            select
                            label="Sales Order"
                            variant="outlined"
                            InputLabelProps={{}}
                            SelectProps={{
                              MenuProps: MenuProps,
                            }}
                          >
                            {salesorderList && salesorderList.length > 0 ? (
                              salesorderList
                                .sort((a, b) => b.id - a.id)
                                .map((option) => (
                                  <MenuItem
                                    key={option.so_number}
                                    value={option.so_number}
                                    id={option.id}
                                  >
                                    <div className="d-flex d-flex-column ">
                                      <span>{option.so_number}</span>
                                      <span
                                        style={{ fontSize: 12, color: "#888" }}
                                      >
                                        Remaining :{" "}
                                        {option?.remaining_quantity || ""}{" "}
                                        {option?.unit || ""}
                                      </span>
                                    </div>
                                  </MenuItem>
                                ))
                            ) : (
                              <MenuItem
                                disabled={true}
                                key={"No data!"}
                                value={"No data!"}
                                id={"No data!"}
                              >
                                No data!
                              </MenuItem>
                            )}
                          </CssTextField>
                        )}
                        {/* <CssTextField
                        disabled={true}
                        autoComplete="off"
                        style={{
                          width: "calc(34% - 14px)",
                          // marginRight: "20px",
                        }}
                        id="outlined-basic"
                        label={"Item Category"}
                        variant="outlined"
                        name="singleFileName"
                        value={
                          values["Item Category"] ? values["Item Category"] : ""
                        }
                      /> */}
                      </div>
                      <div className="width-100 d-flex padding15 flexWrap">
                        {/* <CssTextField
                        disabled={true}
                        autoComplete="off"
                        style={{
                          width: "calc(33% - 13px)",
                          marginRight: "20px",
                        }}
                        id="outlined-basic"
                        label={"Item Type"}
                        InputProps={{
                          endAdornment: "%",
                        }}
                        variant="outlined"
                        name="singleFileName"
                        value={values["Item Type"] ? values["Item Type"] : ""}
                      /> */}
                        {errorMsg.selctedCi && errorMsg.selctedCi !== false ? (
                          <CssTextField
                            required={true}
                            // disabled={
                            //   selctedCom
                            //     ? selctedCom.value
                            //       ? false
                            //       : true
                            //     : true
                            // }
                            autoComplete="off"
                            value={
                              selctedCi
                                ? selctedCi.value
                                  ? selctedCi.value
                                  : ""
                                : "" //
                            }
                            error={true}
                            helperText={errorMsg.selctedCi}
                            onChange={(e) => {
                              setselctedCIUnit({ unit: "", value: "" });
                              const errorMsgs = errorMsg;
                              errorMsgs.selctedCi = false;
                              const selectedV = filterData(
                                customInvoices,
                                "id",
                                Number(e.currentTarget.id),
                                "onlyOne"
                              )
                                ? filterData(
                                    customInvoices,
                                    "id",
                                    Number(e.currentTarget.id),
                                    "onlyOne"
                                  )
                                : false;
                              setselctedCIUnit({
                                value:
                                  selectedV && selectedV.net_weight
                                    ? net_value(
                                        selectedV.used_weight
                                          ? selectedV.used_weight
                                          : 0,
                                        selectedV.net_weight
                                      )
                                    : "",
                                unit:
                                  selectedV && selectedV.net_weight
                                    ? selectedV.unit
                                    : "",
                              });
                              setSelctedCi({
                                value: e.target.value,
                                id: e.currentTarget.id,
                              });

                              setErrorMsg(errorMsgs);
                            }}
                            style={{
                              width: "calc(33% - 13px)",
                              marginRight: "20px",
                            }}
                            id="outlined-select-currency-native"
                            select
                            label="Custom Invoice"
                            variant="outlined"
                            InputLabelProps={{}}
                            SelectProps={{
                              MenuProps: MenuProps,
                            }}
                          >
                            {customInvoices && customInvoices.length > 0 ? (
                              customInvoices
                                .sort((a, b) => b.id - a.id)
                                .map((option) => (
                                  <MenuItem
                                    key={option.invoice_number}
                                    value={option.invoice_number}
                                    id={option.id}
                                  >
                                    {option.invoice_number}
                                  </MenuItem>
                                ))
                            ) : (
                              <MenuItem
                                disabled={true}
                                key={"No data!"}
                                value={"No data!"}
                                id={"No data!"}
                              >
                                No data!
                              </MenuItem>
                            )}
                          </CssTextField>
                        ) : (
                          <CssTextField
                            required={true}
                            // disabled={
                            //   selctedCom
                            //     ? selctedCom.value
                            //       ? false
                            //       : true
                            //     : true
                            // }
                            autoComplete="off"
                            value={
                              selctedCi
                                ? selctedCi.value
                                  ? selctedCi.value
                                  : ""
                                : "" //
                            }
                            error={errorMsg.selctedCi ? true : false}
                            helperText={
                              errorMsg.selctedCi ? errorMsg.selctedCi : ""
                            }
                            onChange={(e) => {
                              setselctedCIUnit({ unit: "", value: "" });
                              const errorMsgs = errorMsg;
                              errorMsgs.selctedCi = false;
                              const selectedV = filterData(
                                customInvoices,
                                "id",
                                Number(e.currentTarget.id),
                                "onlyOne"
                              )
                                ? filterData(
                                    customInvoices,
                                    "id",
                                    Number(e.currentTarget.id),
                                    "onlyOne"
                                  )
                                : false;

                              setselctedCIUnit({
                                value:
                                  selectedV && selectedV.net_weight
                                    ? net_value(
                                        selectedV.used_weight
                                          ? selectedV.used_weight
                                          : 0,
                                        selectedV.net_weight
                                      )
                                    : "",
                                unit:
                                  selectedV && selectedV.net_weight
                                    ? selectedV.unit
                                    : "",
                              });
                              if (e.target.value) {
                                console.log("URL", selectedV);
                                setCommercialList([]);
                                selectedV?.commercial_invoice &&
                                  PortsFunction(
                                    "get",
                                    `arrival/commercial-invoice/${selectedV?.commercial_invoice}`,
                                    null,
                                    "PortCIs",
                                    null,
                                    {}
                                  ).then((commercialList) => {
                                    if (commercialList) {
                                      setCommercialList(commercialList);
                                    } else {
                                      setCommercialList([]);
                                    }
                                    setLoad(false);
                                  });
                              }
                              setSelctedCi({
                                value: e.target.value,
                                id: e.currentTarget.id,
                              });
                              setErrorMsg(errorMsgs);
                            }}
                            style={{
                              width: "calc(33% - 13px)",
                              marginRight: "20px",
                            }}
                            id="outlined-select-currency-native"
                            select
                            label="Custom Invoice"
                            variant="outlined"
                            InputLabelProps={{}}
                            SelectProps={{
                              MenuProps: MenuProps,
                            }}
                          >
                            {customInvoices && customInvoices.length > 0 ? (
                              customInvoices
                                .sort((a, b) => b.id - a.id)
                                .map((option) => (
                                  <MenuItem
                                    key={option.invoice_number}
                                    value={option.invoice_number}
                                    id={option.id}
                                  >
                                    {option.invoice_number}
                                  </MenuItem>
                                ))
                            ) : (
                              <MenuItem
                                disabled={true}
                                key={"No data!"}
                                value={"No data!"}
                                id={"No data!"}
                              >
                                No data!
                              </MenuItem>
                            )}
                          </CssTextField>
                        )}
                        {selctedCIUnit && selctedCIUnit.unit ? (
                          <CssTextField
                            disabled={true}
                            autoComplete="off"
                            style={{
                              width: "calc(33% - 13px)",
                              marginRight: "20px",
                            }}
                            id="outlined-basic"
                            label={`Custom Invoice Quantity`}
                            variant="outlined"
                            InputProps={{
                              endAdornment: selctedCIUnit.unit
                                ? selctedCIUnit.unit
                                : "",
                            }}
                            value={
                              selctedCIUnit.value ? selctedCIUnit.value : ""
                            }
                          />
                        ) : (
                          ""
                        )}
                        {errorMsg.selctedCom &&
                        errorMsg.selctedCom !== false ? (
                          <CssTextField
                            // required={true}
                            disabled={
                              selctedSo
                                ? selctedSo.value
                                  ? false
                                  : true
                                : true
                            }
                            autoComplete="off"
                            value={
                              selctedCom
                                ? selctedCom.value
                                  ? selctedCom.value
                                  : ""
                                : "" //
                            }
                            error={true}
                            helperText={errorMsg.selctedCom}
                            onChange={(e) => {
                              const errorMsgs = errorMsg;
                              errorMsgs.selctedCom = false;
                              setSelctedCom({
                                value: e.target.value,
                                id: e.currentTarget.id,
                              });

                              setErrorMsg(errorMsgs);
                            }}
                            style={{
                              width: "calc(33% - 14px)",
                              // marginRight: "20px",
                            }}
                            id="outlined-select-currency-native"
                            select
                            label="Commercial Invoice"
                            variant="outlined"
                            InputLabelProps={{}}
                            SelectProps={{
                              MenuProps: MenuProps,
                            }}
                          >
                            {commercialList?.id ? (
                              <MenuItem
                                key={commercialList.invoice_number}
                                value={commercialList.invoice_number}
                                id={commercialList.id}
                              >
                                {commercialList.invoice_number}
                              </MenuItem>
                            ) : (
                              <MenuItem
                                disabled={true}
                                key={"No data!"}
                                value={"No data!"}
                                id={"No data!"}
                              >
                                No data!
                              </MenuItem>
                            )}
                          </CssTextField>
                        ) : (
                          <CssTextField
                            // required={true}
                            disabled={
                              selctedSo
                                ? selctedSo.value
                                  ? false
                                  : true
                                : true
                            }
                            autoComplete="off"
                            value={
                              selctedCom
                                ? selctedCom.value
                                  ? selctedCom.value
                                  : ""
                                : "" //
                            }
                            error={errorMsg.selctedCom ? true : false}
                            helperText={
                              errorMsg.selctedCom ? errorMsg.selctedCom : ""
                            }
                            onChange={(e) => {
                              const errorMsgs = errorMsg;
                              errorMsgs.selctedCom = false;

                              // if (e.target.value) {
                              //   setLoad(true);
                              //   PortsFunction(
                              //     "get",
                              //     "arrival/custom-invoice/list",
                              //     null,
                              //     "PortCIs",
                              //     null,
                              //     {
                              //       commercial_invoice_id: e.currentTarget.id,
                              //     }
                              //   ).then((customInvoices) => {
                              //     if (customInvoices) {
                              //       setCustomInvoices(customInvoices);
                              //     } else {
                              //       setCustomInvoices([]);
                              //     }
                              //     setLoad(false);
                              //   });
                              // }
                              setSelctedCom({
                                value: e.target.value,
                                id: e.currentTarget.id,
                              });

                              setErrorMsg(errorMsgs);
                            }}
                            style={{
                              width: "calc(33% - 14px)",
                              // marginRight: "20px",
                            }}
                            id="outlined-select-currency-native"
                            select
                            label="Commercial Invoice"
                            variant="outlined"
                            InputLabelProps={{}}
                            SelectProps={{
                              MenuProps: MenuProps,
                            }}
                          >
                            {commercialList?.id ? (
                              <MenuItem
                                key={commercialList.invoice_number}
                                value={commercialList.invoice_number}
                                id={commercialList.id}
                              >
                                {commercialList.invoice_number}
                              </MenuItem>
                            ) : (
                              <MenuItem
                                disabled={true}
                                key={"No data!"}
                                value={"No data!"}
                                id={"No data!"}
                              >
                                No data!
                              </MenuItem>
                            )}
                          </CssTextField>
                        )}{" "}
                      </div>
                      <div className="width-100 d-flex padding15 flexWrap">
                        {console.log(unloadingAgent, "unloadingAgent")}
                        <CssTextField
                          autoComplete="off"
                          value={unloadingAgent.value}
                          onChange={(e) => {
                            handleUnloadingAgentChange(e);
                          }}
                          style={{
                            width: "calc(33% - 13px)",
                            marginRight: "20px",
                          }}
                          id="outlined-select-currency-native"
                          select
                          label="Unloading Agent"
                          variant="outlined"
                          InputLabelProps={{}}
                          SelectProps={{ MenuProps: MenuProps }}
                        >
                          {masters &&
                          masters.UnloadingAgentList &&
                          masters.UnloadingAgentList.length > 0 &&
                          masters.UnloadingAgentList.length > 0 ? (
                            masters.UnloadingAgentList.sort(
                              (a, b) => b.id - a.id
                            ).map((option) => (
                              <MenuItem
                                key={option.name}
                                value={option.name}
                                id={option.id}
                              >
                                {option.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem
                              disabled={true}
                              key={"No data!"}
                              value={"No data!"}
                              id={"No data!"}
                            >
                              No data!
                            </MenuItem>
                          )}
                        </CssTextField>
                        <CssTextField
                          autoComplete="off"
                          style={{
                            width: "calc(33% - 13px)",
                            marginRight: "20px",
                          }}
                          id="outlined-basic"
                          label={`MR/LEO No.`}
                          variant="outlined"
                          value={mrLeoNo}
                          onChange={(e) => {
                            setMrLeoNo(e.target.value);
                          }}
                        />
                      </div>
                      {/* <Divider /> */}
                      {values.groups.map((dataValue) => (
                        <>
                          <div>
                            <Typography
                              style={{
                                padding: "15px 15px 0 15px",
                                fontWeight: 500,
                                fontSize: mediumScreen() ? "0.75rem" : "0.9rem",
                              }}
                            >
                              {dataValue.item_category_details?.item_category
                                ? dataValue.item_category_details
                                    ?.item_category +
                                  " - " +
                                  (dataValue.item_type_details
                                    ?.broken_percentage
                                    ? dataValue.item_type_details
                                        ?.broken_percentage + " % "
                                    : "")
                                : dataValue.item_type_details?.broken_percentage
                                ? dataValue.item_type_details
                                    ?.broken_percentage + " % "
                                : ""}
                            </Typography>
                          </div>
                          {console.log("submittedData12", submittedData, selectedBrandsArr)}
                          <div className="width-100">
                            <TableComponent
                              singleUnit={
                                selctedCIUnit &&
                                selctedCIUnit.unit &&
                                selctedCIUnit.unit
                              }
                              weight={
                                selctedCIUnit &&
                                selctedCIUnit.unit &&
                                selctedCIUnit.value
                              }
                              changeUnit={(value, unit) => {
                                return changeUnit(
                                  unit,
                                  selctedCIUnit &&
                                    selctedCIUnit.unit &&
                                    selctedCIUnit.unit,
                                  value
                                );
                              }}
                              bottomBarheader={[
                                "Shipped Bags",
                                "Shipped Quantity",
                              ]}
                              nobottomBarAction={true}
                              bottomBar={true}
                              setSelectedListArray={(list,id) => {
                                let selectedCheckBoxObjArrData = {
                                  ...selectedCheckBoxObjArr,
                                  [id]: list,
                                };
                                setSelectedCheckBoxObjArr(selectedCheckBoxObjArrData)
                                setSelectedBrandsArr(selectedCheckBoxObjArrData[dataValue.item_category_details?.id] || []);
                              }}
                              selectedListArray={selectedCheckBoxObjArr[dataValue.item_category_details?.id]}
                              setSelectedListArrayErrorSet={() => {
                                setErrorMsg((prev) => {
                                  return { ...prev, selectedBrandsArr: false };
                                });
                              }}
                              setSelectedListArrayError={
                                errorMsg["selectedBrandsArr"]
                                  ? errorMsg["selectedBrandsArr"]
                                  : false
                              }
                              checkbox={true}
                              selectedCheckBoxData={selectedCheckBoxData}
                              checkboxId={dataValue.item_category_details?.id}
                              loading={loading}
                              heightNew="500px"
                              maxHeight="calc(100vh - 40px)"
                              tablePaperWidth="98%"
                              tablePaperHeight="auto"
                              widthNew="900px"
                              module={"Bag Data"}
                              actions={[]}
                              noAddButton={true}
                              header={moveToShipHeader2}
                              noSearchBar={true}
                              {...props}
                              tableList={
                                dataValue && dataValue.bags_details.length
                                  ? dataValue.bags_details
                                      .sort((a, b) => b.id - a.id)
                                      .map((d) => {
                                        return {
                                          ...d,
                                          "Item TypeId":
                                            dataValue.item_type_details?.id,
                                          "Item Type":
                                            dataValue.item_type_details
                                              ?.broken_percentage,
                                          "Item CategoryId":
                                            dataValue.item_category_details?.id,
                                          "Item Category":
                                            dataValue.item_category_details
                                              ?.item_category,
                                          Size: d.size
                                            ? `${d.size} ${
                                                d.unit ? d.unit : ""
                                              }`
                                            : "",
                                          "Bag Type": d.bag_type,
                                          "Total Bags": d.remaining_bags,
                                          "Total Quantity": multiply(
                                            d.remaining_bags
                                              ? d.remaining_bags
                                              : 0,
                                            d.size ? Number(d.size) : 0
                                          ),
                                          "Shipped Bags": submittedData[
                                            `${d.id}Shipped`
                                          ]
                                            ? submittedData[`${d.id}Shipped`]
                                            : 0,
                                          "Shipped BagsUI": () => {
                                            return (
                                              <CssTextField95
                                                required={true}
                                                onInput={(e) => {
                                                  if (
                                                    Number(e.target.value) >
                                                    d["Total Bags"]
                                                      ? Number(d["Total Bags"])
                                                      : 0
                                                  ) {
                                                    setError(
                                                      `Only ${d["Total Bags"]} bags are available.`
                                                    );
                                                    setSubmittedData((prev) => {
                                                      return {
                                                        ...prev,
                                                        [`${d.id}Shipped`]: d[
                                                          "Total Bags"
                                                        ]
                                                          ? Number(
                                                              d["Total Bags"]
                                                            )
                                                          : 0,
                                                      };
                                                    });
                                                  }
                                                }}
                                                error={
                                                  errorMsg.addBags
                                                    ? true
                                                    : false
                                                }
                                                InputProps={{
                                                  inputProps: {
                                                    min: 0,
                                                    max: d["Total Bags"]
                                                      ? Number(d["Total Bags"])
                                                      : 0,
                                                  },
                                                }}
                                                type="number"
                                                onFocus={StopScroll}
                                                autoComplete="off"
                                                id="outlined-basic"
                                                style={{
                                                  width: "100%",
                                                  margin: "10px 0",
                                                }}
                                                variant="outlined"
                                                value={
                                                  submittedData[
                                                    `${d.id}Shipped`
                                                  ]
                                                    ? submittedData[
                                                        `${d.id}Shipped`
                                                      ]
                                                    : ""
                                                }
                                                onChange={(e) => {
                                                  setErrorMsg((prev) => {
                                                    return {
                                                      ...prev,
                                                      addBags: false,
                                                    };
                                                  });
                                                  if (
                                                    Number(e.target.value) >
                                                    d["Total Bags"]
                                                      ? Number(d["Total Bags"])
                                                      : 0
                                                  ) {
                                                    setSubmittedData((prev) => {
                                                      return {
                                                        ...prev,
                                                        [`${d.id}Shipped`]: d[
                                                          "Total Bags"
                                                        ]
                                                          ? Number(
                                                              d["Total Bags"]
                                                            )
                                                          : 0,
                                                      };
                                                    });
                                                  } else {
                                                    setSubmittedData((prev) => {
                                                      return {
                                                        ...prev,
                                                        [`${d.id}Shipped`]:
                                                          e.target.value,
                                                      };
                                                    });
                                                  }
                                                }}
                                              />
                                            );
                                          },
                                          "Shipped Quantity": multiply(
                                            submittedData[`${d.id}Shipped`]
                                              ? Number(
                                                  submittedData[
                                                    `${d.id}Shipped`
                                                  ]
                                                )
                                              : 0,
                                            d.size ? Number(d.size) : 0
                                          ),
                                          "Shipped QuantityUnit": `${multiply(
                                            submittedData[`${d.id}Shipped`]
                                              ? Number(
                                                  submittedData[
                                                    `${d.id}Shipped`
                                                  ]
                                                )
                                              : 0,
                                            d.size ? Number(d.size) : 0
                                          )} ${d.unit}`,
                                        };
                                      })
                                  : []
                              }
                              filterChild={false}
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </>
              );
            }}
          />
        ) : (
          ""
        )}
        <div style={styles.carouselContainer}>
          <IconButton
            onClick={() => scrollCarousel(-1)}
            style={styles.prevBtn(showPrevButton)}
          >
            <NavigateBeforeOutlinedIcon />
          </IconButton>
          <div className="scrollBluePrimary" style={styles.carouselTrackContainer} ref={trackContainerRef}>
            <div style={styles.carouselTrack} ref={trackRef}>
              {console.log("InvLists", InvLists)}
              {InvLists &&
              InvLists.length > 0 &&
              InvLists.map((s) => s.groups[0].bags_details) &&
              InvLists.map((s) => s.groups[0].bags_details).length > 0 ? (
                InvLists.map((singleList, i) =>
                  singleList.groups[0].bags_details &&
                  singleList.groups[0].bags_details.length > 0
                    ? Number(singleList.port) === singlelistView.id && (
                        <CardDetail
                          keyId={i}
                          height="50px"
                          width="max-content"
                          minWidth="140px"
                          margin="10px"
                          pointer="cursor"
                          padding="4px 8px"
                          onClick={() => {
                            setValues(singleList);
                            setOpenDialog(true);
                          }}
                          children={() => {
                            return (
                              <div
                                style={{
                                  width: "90%",
                                  height: "100%",
                                  justifyContent: "flex-start",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {" "}
                                <div className="d-flex alignC justifyFE fontSize09 width-100">
                                  <Tooltip
                                    title={singleList["Brand"]}
                                    placement="right-start"
                                  >
                                    <div
                                      style={{
                                        width: "calc(100% - 20px)",
                                        fontSize: mediumScreen()
                                          ? "0.7rem"
                                          : "0.8rem",
                                      }}
                                      className="textOEllipse"
                                    >
                                      {singleList["Brand"]}
                                    </div>
                                  </Tooltip>

                                  <Tooltip
                                    title={"Move to Shipping"}
                                    placement="right-start"
                                  >
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (
                                          singleList &&
                                          singleList.groups[0].bags_details
                                            .length > 0
                                        ) {
                                          setOpenDialogNew(true);
                                          setSubmittedData({});
                                          console.log("SINGLELIST", singleList);
                                          setValues(singleList);
                                          let Obj = {}
                                          singleList.groups.map((s) => {
                                            Obj[s.item_category_details?.id] =  []
                                          })
                                          setSelectedCheckBoxObjArr(Obj)
                                          clearFields();
                                        } else {
                                          setError(
                                            "No bags are pending to be shipped"
                                          );
                                        }

                                        PortsFunction(
                                          "get",
                                          "dispatch/sales-order/list",
                                          null,
                                          "PortCIs",
                                          null,
                                          {
                                            status: "OPEN",
                                            brand: singleList.brand,
                                          }
                                        ).then((salesorderFilterList) => {
                                          if (salesorderFilterList) {
                                            setsalesorderList(
                                              salesorderFilterList
                                            );
                                          } else {
                                            setsalesorderList([]);
                                          }
                                          setLoad(false);
                                        });
                                      }}
                                      className="d-flex alignC justifyFE fontSize09 width-100"
                                      style={{
                                        width: "20px",
                                        minWidth: "20px",
                                        color: green,
                                        maxWidth: "20px",
                                        marginLeft: 8,
                                        transform: mediumScreen()
                                          ? "scale(0.8)"
                                          : "scale(0.9)",
                                      }}
                                    >
                                      <SailingOutlinedIcon
                                        style={{
                                          color:
                                            singleList &&
                                            singleList.groups[0].bags_details
                                              .length > 0
                                              ? primary
                                              : "black",
                                          height: "22px",
                                          width: "22px",
                                          cursor:
                                            singleList &&
                                            singleList.groups[0].bags_details
                                              .length > 0
                                              ? "pointer"
                                              : "",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                                <div
                                  className="width-100 textColorGrey"
                                  style={{
                                    margin: "0px 0",
                                    fontSize: mediumScreen()
                                      ? "0.68rem"
                                      : "0.75rem",
                                  }}
                                >
                                  Bags :{" "}
                                  {singleList.bag_number
                                    ? singleList.bag_number
                                    : "-"}
                                </div>
                              </div>
                            );
                          }}
                        />
                      )
                    : ""
                )
              ) : (
                <div className="d-flex justifyC alignC height-100 width-100">
                  No brands available in this godown !{" "}
                </div>
              )}
            </div>
          </div>
          <IconButton
            onClick={() => scrollCarousel(1)}
            style={styles.nextBtn(showNextButton)}
          >
            <NavigateNextOutlinedIcon />
          </IconButton>
        </div>
      </div>
      {onlyMoveToShipButton ? (
        ""
      ) : (
        <div style={{ height: "calc(100% - 160.44px)", width: "100%" }}>
            {openDialog === true ? (
              <CssDialog
                headerPrimary={true}
                height="calc(100% - 120px)"
                width="80%"
                header={`${
                  values && values["Brand"] && values["Brand"]
                } Details`}
                addButtonWidth="75px"
                noActions={true}
                // addButton={editList ? "Update" : "Add"}
                onClose={() => {
                  setOpenDialog(false);
                }}
                snackbarClose={() => {
                  setOpenDialog(false);
                }}
                onSubmit={() => {}}
                children={() => {
                  return (
                    <div className="width-100 height-100 d-flex d-flex-column scrollBluePrimary overflowYA">
                      <div className="width-100 d-flex flexWrap padding15 pNone">
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{ width: "calc(33% - 13.33px)" }}
                          id="outlined-basic"
                          label={"Port Name"}
                          variant="outlined"
                          value={
                            singlelistView && singlelistView.name
                              ? singlelistView.name
                              : ""
                          }
                        />
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{
                            width: "calc(33% - 13.33px)",
                            margin: "0 20px",
                          }}
                          id="outlined-basic"
                          label={"Godown Name"}
                          variant="outlined"
                          value={
                            selectGodown
                              ? filterData(
                                  GodownList,
                                  "id",
                                  Number(selectGodown),
                                  "onlyOne"
                                )
                                ? filterData(
                                    GodownList,
                                    "id",
                                    Number(selectGodown),
                                    "onlyOne"
                                  ).name
                                : "Godown"
                              : "Godown"
                          }
                        />
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{ width: "calc(34% - 13.34px)" }}
                          id="outlined-basic"
                          label={"Total Bag"}
                          variant="outlined"
                          value={
                            values["Total Bags"] ? values["Total Bags"] : ""
                          }
                        />
                      </div>
                      <div className="width-100 d-flex flexWrap padding15 pNone">
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{ width: "calc(33% - 13.33px)" }}
                          id="outlined-basic"
                          label={`Brand`}
                          variant="outlined"
                          value={values["Brand"] ? values["Brand"] : ""}
                        />
                        {/* <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{
                            width: "calc(33% - 13.33px)",
                            margin: "0 20px",
                          }}
                          id="outlined-basic"
                          label={"Item Category"}
                          variant="outlined"
                          value={
                            values["Item Category"]
                              ? values["Item Category"]
                              : ""
                          }
                        />
                        <CssTextField
                          disabled={true}
                          autoComplete="off"
                          style={{ width: "calc(34% - 13.34px)" }}
                          id="outlined-basic"
                          label={"Item Type"}
                          InputProps={{
                            endAdornment: "%",
                          }}
                          variant="outlined"
                          value={values["Item Type"] ? values["Item Type"] : ""}
                        /> */}
                      </div>
                      <div
                        className="width-100"
                        style={
                          values && values.groups[0].bags_details.length
                            ? {}
                            : { height: "calc(100% - 157.96px)" }
                        }
                      >
                      {console.log(values, "values")}
                      {values.groups.map((dataValue) => (
                        <>
                          <div>
                            <Typography
                              style={{
                                padding: "15px 15px 0 15px",
                                fontWeight: 500,
                                fontSize: mediumScreen() ? "0.75rem" : "0.9rem",
                              }}
                            >
                              {dataValue.item_category_details?.item_category
                                ? dataValue.item_category_details
                                    ?.item_category +
                                  " - " +
                                  (dataValue.item_type_details
                                    ?.broken_percentage
                                    ? dataValue.item_type_details
                                        ?.broken_percentage + " % "
                                    : "")
                                : dataValue.item_type_details?.broken_percentage
                                ? dataValue.item_type_details
                                    ?.broken_percentage + " % "
                                : ""}
                            </Typography>
                          </div>
                          <TableComponent
                            loading={loading}
                            heightNew="500px"
                            maxHeight="calc(100vh - 40px)"
                            tablePaperWidth="98%"
                            tablePaperHeight="auto"
                            widthNew="900px"
                            module={"Bag Details"}
                            actions={[]}
                            noAddButton={true}
                            header={moveToShipHeader}
                            noSearchBar={true}
                            {...props}
                            // tableList={
                            //   values && values.groups[0].bags_details.length
                            //     ? values.groups[0].bags_details.sort(
                            //         (a, b) => b.id - a.id
                            //       )
                            //     : []
                            // }
                            tableList={
                                  dataValue && dataValue.bags_details.length
                                    ? dataValue.bags_details
                                        .sort((a, b) => b.id - a.id)
                                        .map((d) => {
                                          return {
                                            ...d,
                                            "Item TypeId":
                                              dataValue.item_type_details?.id,
                                            "Item Type":
                                              dataValue.item_type_details
                                                ?.broken_percentage,
                                            "Item CategoryId":
                                              dataValue.item_category_details?.id,
                                            "Item Category":
                                              dataValue.item_category_details
                                                ?.item_category,
                                            Size: d.size
                                              ? `${d.size} ${
                                                  d.unit ? d.unit : ""
                                                }`
                                              : "",
                                            "Bag Type": d.bag_type,
                                            "Total Bags": d.remaining_bags,
                                            "Total Quantity": multiply(
                                              d.remaining_bags
                                                ? d.remaining_bags
                                                : 0,
                                              d.size ? Number(d.size) : 0
                                            ),
                                          };
                                        })
                                    : []
                                }
                            filterChild={false}
                          />
                        </>
                      ))}
                      </div>
                    </div>
                  );
                }}
              />
            ) : (
              ""
            )}
            {confirmStatus === true ? (
              <Dialog
                open={true}
                onClose={() => {
                  setConfirmStatus(false);
                  setApproveReject({
                    status: "",
                    data: "",
                  });
                }}
                aria-labelledby="confirm-dialog"
              >
                <DialogTitle id="confirm-dialog">Make Changes ?</DialogTitle>
                <DialogContent>
                  Are you sure you want to{" "}
                  {approveReject.status === "APPROVE" ? "approve" : "reject"}{" "}
                  this Shipping ?
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setConfirmStatus(false);
                      setApproveReject({
                        status: "",
                        data: "",
                      });
                    }}
                    style={{
                      backgroundColor: "rgb(241 154 48)",
                      color: "white",
                      padding: "2.5px",
                    }}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleConfirmApp}
                    style={{
                      backgroundColor: "rgb(241 154 48)",
                      color: "white",
                      padding: "2.5px",
                    }}
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              ""
            )}
            <TableComponent
              loading={loading}
              heightNew="calc(100vh - 40px)"
              maxHeight="calc(100vh - 40px)"
              Title={`${
                selectGodown
                  ? filterData(
                      GodownList,
                      "id",
                      Number(selectGodown),
                      "onlyOne"
                    )
                    ? filterData(
                        GodownList,
                        "id",
                        Number(selectGodown),
                        "onlyOne"
                      ).name
                    : "Godown"
                  : "Godown"
              } History`}
              widthNew="900px"
              module={"Inventory"}
              actions={[]}
              noAddButton={true}
              subMenu={true}
              searchFiled={["status", "Date", "Custom Invoice", "Total Bags"]}
              header={[
                "Sr.No.",
                "Entry Date",
                // "Status",
                "Approval",
                "Arrival Date / Shipping Date",
                // "Unloading Agent",
                // "MR/LEO No.",
                "Miller",
                // "Miller Bill Number",
                "Sales Order",
                // "Custom Invoice",
                "Commercial Invoice",
                "Total Bags",
              ]}
              secondLineHeaderData={{
                "Arrival Date / Shipping Date": {
                  secondLiner: "",
                  secondLinerHover: ["Unloading Agent", "MR/LEO No."],
                },
                "Entry Date": {
                  secondLiner: "Status",
                  secondLinerHover: [],
                  secondLinerComp: {
                    visible: false,
                    sameLine: true,
                    component: "Status",
                    componentHover: "",
                  },
                },
                Miller: {
                  secondLiner: "Miller Bill Number",
                  secondLinerHover: [],
                },
                "Sales Order": {
                  secondLiner: "Custom Invoice",
                  secondLinerHover: [],
                },
              }}
              tableHeight="calc(100% - 2px)"
              headerSearchMiddleComp={
                Boolean(onlyMoveToShipButton) ? "" : filterChild
              }
              tablePagination={props?.TablePagination}
              paginationData={props?.paginationData}
              pageChangeAPI={pageChangeAPI}
              resetPage={props?.resetPage}
              subMenuheader={[
                "Sr.No.",
                "Brand",
                "Item Type",
                "Item Category",
                "Size",
                "Bag Type",
                "Total Bags",
                "Total Quantity",
              ]}
              {...props}
              tableList={
                historyList && historyList.length
                  ? historyList
                      .map((singleInventory, i) => {
                        singleInventory["Status"] = () => {
                          return singleInventory.status === "IN" ? (
                            <Chip
                              label="IN"
                              style={{
                                backgroundColor: "#d94e43",
                                color: "white",
                                height: mediumScreen() ? 15 : 18,
                                fontSize: mediumScreen() ? 8 : 10,
                                padding: '0'
                              }}
                            />
                          ) : (
                            // <div
                            //   className="d-flex d-flex-column"
                            //   style={{ color: "red" }}
                            // >
                            //   <ArrowDropUpIcon />
                            //   <ArrowDropDownIcon style={{ marginTop: '-15px' }} />
                            // </div>
                            <Chip
                              label="OUT"
                              style={{
                                backgroundColor: "#4CAF50",
                                color: "white",
                                height: mediumScreen() ? 15 : 18,
                                fontSize: mediumScreen() ? 8 : 10,
                                padding: '0'
                              }}
                            />
                            // <StatusChip status="OUT" />
                            // <div
                            // <div
                            //   className="d-flex d-flex-column"
                            //   style={{ color: "green" }}
                            // >
                            //   <ArrowDropUpIcon />
                            //   <ArrowDropDownIcon style={{ marginTop: '-15px' }} />
                            // </div>
                          );
                        };
                        singleInventory["Approval"] = () => {
                          return singleInventory.approval_status ===
                            "PENDING" &&
                            props?.user?.userData?.roles
                              ?.toString()
                              .toLowerCase() === "admin" ? (
                            // return 1 > 0 ? (
                            <>
                              <Tooltip
                                title="Approve this Arrival"
                                arrow
                                placement="top"
                              >
                                <Button
                                  onClick={() => {
                                    setConfirmStatus(true);
                                    setApproveReject({
                                      status: "APPROVE",
                                      data: singleInventory,
                                    });
                                  }}
                                  size="small"
                                  style={{
                                    fontSize: mediumScreen()
                                      ? "0.7rem"
                                      : "0.8rem",
                                    boxShadow:
                                      "rgba(128, 128, 128, 0.53) 1px 1px 7px",
                                    margin: "0 10px 4px 0",
                                    color: "green",
                                    fontWeight: 500,
                                    fontSize: "0.7rem",
                                    padding: "4px 10px",
                                  }}
                                >
                                  Approve
                                </Button>
                              </Tooltip>

                              <Tooltip
                                title="Reject this Arrival"
                                arrow
                                placement="top"
                              >
                                <Button
                                  onClick={() => {
                                    setConfirmStatus(true);
                                    setApproveReject({
                                      status: "REJECT",
                                      data: singleInventory,
                                    });
                                  }}
                                  size="small"
                                  style={{
                                    fontSize: mediumScreen()
                                      ? "0.7rem"
                                      : "0.8rem",
                                    boxShadow:
                                      "rgba(128, 128, 128, 0.53) 1px 1px 7px",
                                    margin: "0 10px 4px 0",
                                    color: "red",
                                    fontWeight: 500,
                                    fontSize: "0.7rem",
                                    padding: "4px 10px",
                                  }}
                                >
                                  Reject
                                </Button>
                              </Tooltip>
                            </>
                          ) : (
                            singleInventory.approval_status
                          );
                        };

                        singleInventory["Custom Invoice"] =
                          singleInventory.status === "IN"
                            ? ""
                            : singleInventory.custom_invoice_number
                                ?.invoice_number
                            ? singleInventory.custom_invoice_number
                                ?.invoice_number
                            : "";

                        singleInventory["Commercial Invoice"] = () =>
                          singleInventory.status === "IN" ? (
                            ""
                          ) : (
                            <div className="d-flex alignC justifyC textC">
                              <span
                                style={{
                                  color: !singleInventory?.is_synced
                                    ? "rgba(0,0,0,0.4)"
                                    : "inherit",
                                }}
                              >
                                {singleInventory.commercial_invoice_number
                                  ?.invoice_number || ""}
                              </span>
                              <span
                                style={{
                                  display:
                                    singleInventory.commercial_invoice_number
                                      ?.invoice_number &&
                                    !singleInventory?.is_synced
                                      ? "block"
                                      : "none",
                                  // margin: "0 auto",
                                  // padding: "4px 10px 0",
                                  paddingTop: 4,
                                  color: isSpinning[i] ? lightGreen : primary,
                                  animation:
                                    isSpinning[i] && "spin 1s infinite",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleIconClick(i, true);
                                  let newArr = [];
                                  singleInventory?.total_packaging.map(
                                    (sP, i) => {
                                      let usedWeight = {};
                                      console.log(
                                        "ID",
                                        singleInventory,
                                        Number(
                                          singleInventory.packing_list_ids.split(
                                            ","
                                          )[i]
                                        )
                                      );
                                      usedWeight[sP.id] = changeUnit(
                                        sP.unit,
                                        singleInventory.sales_order_number
                                          .so_unit,
                                        multiply(
                                          sP.bags_for_packaging
                                            ? sP.bags_for_packaging
                                            : 0,
                                          sP.size ? Number(sP.size) : 0
                                        )
                                      );
                                      newArr.push({
                                        id:
                                          singleInventory.packing_list_ids !==
                                          ""
                                            ? Number(
                                                singleInventory.packing_list_ids.split(
                                                  ","
                                                )[i]
                                              )
                                            : null,
                                        is_active: true,
                                        shipping_date: moment(
                                          singleInventory.date || new Date()
                                        ).format("YYYY-MM-DD"),
                                        // unloading_agent: singleInventory.unloading_agent?.id || "",
                                        // mr_or_leo_no: singleInventory.mr_or_leo_no || "",
                                        custom_invoice: Number(
                                          singleInventory.custom_invoice_number
                                            ?.id
                                        ),
                                        commercial_invoice: Number(
                                          singleInventory
                                            .commercial_invoice_number?.id
                                        ),
                                        packaging_count: JSON.parse(
                                          singleInventory.shipping_bags_packaging
                                        ),
                                        used_weight: usedWeight,
                                        brand: singleInventory.brand,
                                        godown: singleInventory
                                          ? singleInventory.godown
                                          : "",
                                        port: singlelistView.id,
                                        sales_order:
                                          singleInventory.sales_order_number
                                            ?.id,
                                        bl_number:
                                          singleInventory?.bl_number || "",
                                        godown_history_id: singleInventory.id,
                                        coming_from: "VIA GODOWN",
                                      });
                                    }
                                  );
                                  console.log("newArr", newArr);
                                  PortsFunction(
                                    "put",
                                    "arrival/commercial-invoice/packing-list/bulk-update",
                                    null,
                                    "put",
                                    newArr
                                  )
                                    .then((res2) => {
                                      if (res2.error) {
                                        handleIconClick(i, false);
                                        setLoad(false);
                                      } else {
                                        handleIconClick(i, false);
                                        setSuccess("Sync successfully");
                                        if (updateAgain) {
                                          setTimeout(() => {
                                            updateAgain();
                                          }, 2000);
                                        }
                                      }
                                    })
                                    .catch((e) => {
                                      console.log("error500", e);
                                      setLoad(false);
                                      setError(
                                        "Shipping details are mismatched"
                                      );
                                    });
                                }}
                              >
                                {singleInventory.commercial_invoice_number
                                  ?.invoice_number &&
                                !singleInventory?.is_synced ? (
                                  <Tooltip title="Sync" placement="right-start">
                                    <AutorenewOutlinedIcon
                                      style={{ transform: "scale(0.9)" }}
                                    />
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          );
                        // : ""

                        singleInventory["Total Bags"] =
                          singleInventory.shipping_bags &&
                          singleInventory.shipping_bags !== null
                            ? singleInventory.shipping_bags
                            : singleInventory.total_bags
                            ? singleInventory.total_bags
                            : 0;
                        singleInventory["Entry Date"] = moment(
                          singleInventory.created_at ?? new Date()
                        ).format("ddd, MMM Do YYYY");
                        singleInventory["Arrival Date / Shipping Date"] =
                          singleInventory.date || singleInventory.arrival_date
                            ? moment(
                                singleInventory.date ||
                                  singleInventory.arrival_date
                              ).format("ddd, MMM Do YYYY")
                            : "";
                        singleInventory["Sales Order"] = singleInventory
                          .sales_order_number?.so_number
                          ? singleInventory.sales_order_number.so_number
                          : "";
                        singleInventory["Unloading Agent"] = singleInventory
                          .unloading_agent_details?.name
                          ? singleInventory.unloading_agent_details.name
                          : "";
                        singleInventory["MR/LEO No."] =
                          singleInventory.mr_or_leo_no
                            ? singleInventory.mr_or_leo_no
                            : "";
                        singleInventory["Miller"] = singleInventory.miller
                          ? singleInventory.miller
                          : "";
                        singleInventory["Miller Bill Number"] =
                          singleInventory.miller_bill_number
                            ? singleInventory.miller_bill_number
                            : "";

                        singleInventory["subList"] =
                          singleInventory.total_packaging.length > 0
                            ? singleInventory.total_packaging.map((sP) => {
                                const brandSelected = filterData(
                                  BrandList,
                                  "id",
                                  singleInventory.brand,
                                  "onlyOne"
                                )
                                  ? filterData(
                                      BrandList,
                                      "id",
                                      singleInventory.brand,
                                      "onlyOne"
                                    )
                                  : "";
                                return {
                                  ...sP,
                                  Brand: brandSelected.brand_name,
                                  "Item Category": filterData(
                                    ItemCateoryList,
                                    "id",
                                    singleInventory.item_category,
                                    "onlyOne"
                                  )
                                    ? filterData(
                                        ItemCateoryList,
                                        "id",
                                        singleInventory.item_category,
                                        "onlyOne"
                                      ).name
                                    : "",
                                  "Item Type": filterData(
                                    ItemList,
                                    "id",
                                    singleInventory.item_type,
                                    "onlyOne"
                                  )
                                    ? filterData(
                                        ItemList,
                                        "id",
                                        singleInventory.item_type,
                                        "onlyOne"
                                      ).broken_percentage
                                    : "",

                                  "Total Bags": `${
                                    sP.bags_for_packaging
                                      ? sP.bags_for_packaging
                                      : 0
                                  }`,
                                  "Bag Type": sP.bag_type,
                                  Size: `${sP.size} ${sP.unit}`,
                                  "Total Quantity": multiply(
                                    sP.bags_for_packaging
                                      ? sP.bags_for_packaging
                                      : 0,
                                    sP.size ? Number(sP.size) : 0
                                  ),
                                  "Total QuantityUnit": `${multiply(
                                    sP.bags_for_packaging
                                      ? sP.bags_for_packaging
                                      : 0,
                                    sP.size ? Number(sP.size) : 0
                                  )} ${sP.unit}`,
                                };
                              })
                            : [];
                        singleInventory.datetime =
                          singleInventory.datetime ?? new Date();
                        // console.log(
                        //   singleInventory,
                        //   singleInventory.modified_at,
                        //   "s.datetime ?? new Date()SSSSSSSSSSSSSSSSSSSSS"
                        // );
                        return singleInventory;
                      })
                      .sort((a, b) => {
                        var dateA = b.id;
                        var dateB = a.id;
                        return dateA - dateB;
                      })
                  : // .filter((s) => s.godown === Number(selectGodown))
                    []
              }
              filterChild={false}
            />
        </div>
      )}
    </div>
  );
}

export default Inventory;
