import { Divider, Tooltip, Typography } from "@mui/material";
import { mediumScreen } from "../Regex";

const styles = {
  highlightText: () => ({
    color: "rgb(88,88,88)",
    fontWeight: "bold",
    width: "80%",
    fontSize: mediumScreen() ? "0.7rem" : "0.8rem",
    marginRight: "5px",
    width: "46%",
    whiteSpace: "nowrap",
  }),
  lightText: () => ({
    color: "#575656",
    textAlign: "right",
    fontWeight: 500,
    fontSize: mediumScreen() ? "0.7rem" : "0.8rem",
    width: "54%",
    whiteSpace: "wrap",
  }),
  highlightMapText: (index, totalLength) => ({
    color: "rgb(88,88,88)",
    fontWeight: "bold",
    fontSize: mediumScreen() ? "0.7rem" : "0.8rem",
    flex: 1,
    textAlign:
      index === 0 ? "left" : index === totalLength ? "right" : "center",
    whiteSpace: "nowrap",
    minWidth: 100,
  }),
  lightMapText: (index, totalLength) => ({
    color: "#575656",
    flex: 1,
    textAlign:
      index === 0 ? "left" : index === totalLength ? "right" : "center",
    fontWeight: 500,
    fontSize: mediumScreen() ? "0.7rem" : "0.8rem",
    whiteSpace: "wrap",
    minWidth: 100,
  }),
};
export const ListMapUI = ({ header, data }) => {
  return (
    <div
      style={{
        width: "100%",
        minWidth: ((header.length * 100) + 50)  || 200,
        fontFamily: "Poppins",
        padding: "1% 2%",
      }}
    >
      {console.log(header, data)}
      <div
        className="d-flex"
        style={{ fontFamily: "Poppins", marginBottom: 5 }}
      >
        {header &&
          header?.map((h, i) => (
            <span style={styles.highlightMapText(i, header.length - 1)}>
              {h.label}
            </span>
          ))}
      </div>
      <Divider variant="middle" style={{ margin: "2px 0", padding: '1px 0' }} />
      {data ? (
        data?.map((item, i) => (
          <div className="d-flex d-flex-column" style={{ marginTop: 5 }} key={i}>
            <div className="d-flex justifySB">
              {header.map((h, index) => (
                <span style={styles.lightMapText(index, header.length - 1)}>
                    {h.key2 && item[h?.key2]
                      ? item[h.key] + " " + item[h.key2]
                      : h.orKey
                      ? item[h.key] || item[h.orKey]
                      : item[h.key]}
                  </span>
              ))}
            </div>
            {i !== data.length - 1 && (
              <Divider variant="middle" style={{ margin: "2% 0" }} />
            )}
          </div>
        ))
      ) : (
        <Typography>No Data Found</Typography>
      )}
    </div>
  );
};

export const ListUI = ({ data }) => {
  return (
    <div
      style={{
        width: "100%",
        minWidth: 260,
        fontFamily: "Poppins",
        padding: "4% 3%",
      }}
    >
      {data.map((item, i) => (
        <div>
          <Typography
            className="d-flex justifySB"
            style={{ fontFamily: "Poppins", whiteSpace: "nowrap" }}
          >
            <span style={styles.highlightText()}>{item.label}</span>
            <span style={styles.lightText()}>{item.value}</span>
          </Typography>
          {i !== data.length - 1 && (
            <Divider variant="middle" style={{ margin: "2% 0" }} />
          )}
        </div>
      ))}
    </div>
  );
};