import { Paper } from "@mui/material";
import Layout from "../../layouts/Layout";

const ReportsComponent = (props) => {
  return (
    <Layout>
      <Paper>
        Report Componnet
      </Paper>
    </Layout>
  )
}

export default ReportsComponent;