import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import TabComponent from "../../layoutComponents/TabComponent";
import { StyledCircularProgress } from "../../styles/StyledComponents";
import TableComponent from "../../layoutComponents/TableComponent";
import CreateDialog from "../../layoutComponents/CreateDialog";
import NoData from "../../styles/Illustrations/NoData";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { EndPoints } from "../../utils/EndPoints";
import BasicDateRangePicker from "../../layoutComponents/BasicDateRangePicker";
import { primary } from "../../styles/colors";
import { CrossIcon } from "../../styles/Icons";
import {
  Paper,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  DialogContent,
  Button,
} from "@material-ui/core";
import ReactDOM from "react-dom";
import { CssTextField, StyledButton } from "../../styles/StyledComponents";
import { filterData, StopScroll } from "../../Regex";
import InvoiceCardUi from "../../layoutComponents/InvoiceCardUi";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import { stateArray } from "../../Defaults";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "300px",
    height: "38px",
    fontSize: "0.5rem",
    backgroundColor: "rgba(158, 158, 158, 0.18)",
    color: "white",
  },
};
export class BrokersComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Brokers"],
        tooltip: `Click to approve pending ${EndPoints["Brokers"]}`,
        data: [],
        route: `/Brokers/${EndPoints["Brokers"].split("/")[0]}`,
        goBackRoute: "/Brokers",
        primaryField: "name",
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",
        status: false,
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#1a9520", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      summaryData: [],
      Brokers: [],
      singlelistView: null,
      listViewArray: [],
      loading: false,
      openDialog: false,
      selectedRange: [null, null],

      selectedRange1: [null, null],
      dispatchList: [],
      dispatchListCopy: [],
      invoiceListArr: [],
      invoiceListArrCopy: [],
      openStatusDialog: false,
      broken_amount: "",
      loader: false,
      showInvoice: false,
      downloadInvoicePdf: false,
      selctedTab: "unpaid",
      userRole:
        this.props.user && this.props.user.roles
          ? this.props.user.roles.toString().toLowerCase()
          : "",
    };
  }
  filterDataAccDate = (dispatchList) => {
    const val = this.state.selectedRange;
    const oldArray =
      dispatchList && dispatchList.length > 0 ? dispatchList : [];

    const dateFrom =
      val && val[0]
        ? moment(val[0]).format("MMM D YYYY")
        : moment(new Date()).format("MMM D YYYY");

    const dateTo =
      val && val[1]
        ? moment(val[1]).format("MMM D YYYY")
        : moment(new Date()).format("MMM D YYYY");
    const newArr = oldArray.filter((singleObj) => {
      const createdAt = moment(singleObj.created_at).format("MMM D YYYY");

      return (
        createdAt === dateFrom ||
        createdAt === dateTo ||
        (new Date(createdAt) > new Date(dateFrom) &&
          new Date(dateTo) > new Date(createdAt))
      );
    });
    return newArr ? newArr : [];
  };
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    this.fetchAllData();
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Brokers"] && next.params.id) {
        this.listClickHandler(this.state.tabs[0].data[next.params.id]);
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  listClickHandler = (value) => {
    this.singlelistView && this.fetchDispatchList("UNPAID");
    const { selctedTab } = this.state;
    // this.fetchAllData();
    this.setState(
      {
        singlelistView: value,
      },

      () => {
        if (this.state.singlelistView && this.state.singlelistView.id) {
          if (selctedTab === "unpaid") {
            this.fetchDispatchList("UNPAID");
          }
          if (selctedTab === "pending") {
            this.fetchDispatchList("PENDING");
          }
          if (selctedTab === "paid") {
            this.fetchDispatchList("paid");
          }
        }
      }
    );
  };

  onEditUpdate = (res) => {
    if (res) {
      this.setState({ singlelistView: res });
    }
  };
  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data =
        props[EndPoints["Brokers"]] && props[EndPoints["Brokers"]].length > 0
          ? props[EndPoints["Brokers"]]
              //  .sort((a, b) => a.name.localeCompare(b.name))
              .sort((a, b) => {
                var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
                var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
                return new Date(dateA) - new Date(dateB);
              })
          : [];
      return tab;
    });

    this.setState({
      userRole: this.props.user.roles?this.props.user.roles.toString().toLowerCase():"admin",
      listViewArray:
        props[EndPoints["Brokers"]] && props[EndPoints["Brokers"]].length > 0
          ? props[EndPoints["Brokers"]]
          : [],
      Brokers: props.Brokers,
      tabs,
      loading: props.loading,
    });
  }

  fetchAllData = () => {
    this.setState({ loader: true });
    this.props
      .BrokersFunction(
        "get",
        "masters/item-category/list",
        null,
        "ItemCateoryList",
        null,
        {
          is_active: "True",
        }
      )
      .then((ItemCateoryList) => {
        if (ItemCateoryList) {
          this.setState({ ItemCateoryList }, () => {
            this.props
              .BrokersFunction(
                "get",
                "masters/item-type/list",
                null,
                "ItemList",
                null,
                {
                  is_active: "True",
                }
              )
              .then((ItemList) => {
                if (ItemList) {
                  this.setState({ ItemList }, () => {
                    this.props
                      .BrokersFunction(
                        "get",
                        "masters/brand/list",
                        null,
                        "BrandList",
                        null,
                        {
                          is_active: "True",
                        }
                      )
                      .then((BrandList) => {
                        if (BrandList) {
                          this.setState({
                            loader: false,
                            BrandList: BrandList.map((sBrand) => {
                              if (
                                this.state.ItemList.length > 0 &&
                                this.state.ItemCateoryList.length > 0
                              ) {
                                sBrand.item_category_name =
                                  this.state.ItemCateoryList.filter(
                                    (singleItem) =>
                                      singleItem.id === sBrand.item_category
                                  )
                                    ? this.state.ItemCateoryList.filter(
                                        (singleItem) =>
                                          singleItem.id === sBrand.item_category
                                      )[0].name
                                    : "";
                                sBrand.item_type_name =
                                  this.state.ItemList.filter(
                                    (singleItem) =>
                                      singleItem.id === sBrand.item_type
                                  )
                                    ? this.state.ItemList.filter(
                                        (singleItem) =>
                                          singleItem.id === sBrand.item_type
                                      )[0].broken_percentage
                                    : "";

                                return sBrand;
                              } else {
                                return sBrand;
                              }
                            }),
                          });
                        } else {
                          this.setState({ loader: false });
                        }
                      });
                  });
                } else {
                  this.setState({ loader: false });
                }
              });
          });
        } else {
          this.setState({ loader: false });
        }
      });
  };
  filterDataAccDate1 = (invoiceList) => {
    const { selectedRange1 } = this.state;
    const oldArray = invoiceList && invoiceList.length > 0 ? invoiceList : [];

    const dateFrom =
      selectedRange1 && selectedRange1[0]
        ? moment(selectedRange1[0]).format("MMM D YYYY")
        : moment(new Date()).format("MMM D YYYY");

    const dateTo =
      selectedRange1 && selectedRange1[1]
        ? moment(selectedRange1[1]).format("MMM D YYYY")
        : moment(new Date()).format("MMM D YYYY");
    const newArr = oldArray.filter((singleObj) => {
      const createdAt = moment(singleObj.invoice_date).format("MMM D YYYY");

      return (
        createdAt === dateFrom ||
        createdAt === dateTo ||
        (new Date(createdAt) > new Date(dateFrom) &&
          new Date(dateTo) > new Date(createdAt))
      );
    });
    return newArr ? newArr : [];
  };
  fetchDispatchList = (type) => {
    this.setState({ loader: true });
    if (type === "paid") {
      this.props
        .BrokersFunction(
          "get",
          "dispatch/invoice/list",
          null,
          "invoice",
          null,
          {
            broker_id: this.state.singlelistView.id,
          }
        )
        .then((invoiceArr) => {
          this.setState({
            invoiceListArr:
              invoiceArr && invoiceArr.length > 0
                ? this.state.selectedRange1 &&
                  this.state.selectedRange1.length > 0 &&
                  ((this.state.selectedRange1[0] &&
                    this.state.selectedRange1[0] !== null) ||
                    (this.state.selectedRange1[1] &&
                      this.state.selectedRange1[1] !== null))
                  ? this.filterDataAccDate1(invoiceArr)
                  : invoiceArr
                : [],
            invoiceListArrCopy: invoiceArr,
            loader: false,
          });
        });
    } else {
      this.props
        .BrokersFunction("get", "dispatch/list", null, "dispatchList", null, {
          broker_id: this.state.singlelistView.id,
          payment_status: type,
        })
        .then((dispatchArr) => {
          const dispatchList = dispatchArr?.results.length > 0 ? dispatchArr?.results : dispatchArr;
          console.log(dispatchList, "dispatch list on fetch ");
          const newArrId = [];
          const newArr = [];
          if (dispatchList && dispatchList.length > 0) {
            dispatchList.map((sR) => {
              if (sR.id) {
                this.props
                  .BrokersFunction(
                    "get",
                    "dispatch/dispatch-items/list",
                    null,
                    "dispatch-item",
                    null,
                    {
                      dispatch_id: sR.id,
                    }
                  )
                  .then((res2) => {
                    if (res2) {
                      sR.subList = res2;
                      if (!newArrId.includes(sR.id)) {
                        newArr.push(sR);
                        newArrId.push(sR.id);
                      }

                      if (newArr.length === dispatchList.length) {
                        console.log(dispatchList, "dispatch list on if ");
                        console.log(newArr, "new arr on if ");

                        newArr.map((sN) => {
                          sN.broker = sN.purchase_order_details.broker;
                          return sN;
                        });

                        this.setState({
                          dispatchList: newArr,
                          dispatchListCopy: newArr,
                          // dispatchList: filterData(
                          //   newArr,
                          //   "broker",
                          //   this.state.singlelistView.id
                          // )
                          //   ? filterData(
                          //       filterData(
                          //         newArr,
                          //         "broker",
                          //         this.state.singlelistView.id
                          //       ),
                          //       "broker_payment",
                          //       type === "paid" ? true : false
                          //     )
                          //     ? this.state.selectedRange &&
                          //       this.state.selectedRange.length > 0 &&
                          //       ((this.state.selectedRange[0] &&
                          //         this.state.selectedRange[0] !== null) ||
                          //         (this.state.selectedRange[1] &&
                          //           this.state.selectedRange[1] !== null))
                          //       ? this.filterDataAccDate(
                          //           filterData(
                          //             filterData(
                          //               newArr,
                          //               "broker",
                          //               this.state.singlelistView.id
                          //             ),
                          //             "broker_payment",
                          //             type === "paid" ? true : false
                          //           )
                          //         )
                          //       : filterData(
                          //           filterData(
                          //             newArr,
                          //             "broker",
                          //             this.state.singlelistView.id
                          //           ),
                          //           "broker_payment",
                          //           type === "paid" ? true : false
                          //         )
                          //     : []
                          //   : [],

                          // dispatchListCopy: filterData(
                          //   newArr,

                          //   "broker",
                          //   this.state.singlelistView.id
                          // )
                          //   ? filterData(
                          //       filterData(
                          //         newArr,
                          //         "broker",
                          //         this.state.singlelistView.id
                          //       ),
                          //       "broker_payment",
                          //       type === "paid" ? true : false
                          //     )
                          //     ? filterData(
                          //         filterData(
                          //           newArr,
                          //           "broker",
                          //           this.state.singlelistView.id
                          //         ),
                          //         "broker_payment",
                          //         type === "UNPAID" ? "UNPAID" : false
                          //       )
                          //     : []
                          //   : [],

                          loader: false,
                        });

                        return sR;
                      }
                    } else {
                      console.log(dispatchList, "dispatch list on else ");

                      dispatchList.map((sN) => {
                        sN.broker = sN.purchase_order_details.broker;
                        return sN;
                      });
                      this.setState({
                        dispatchListCopy: dispatchList,

                        // dispatchList: filterData(
                        //   dispatchList,
                        //   "broker",
                        //   this.state.singlelistView.id
                        // )
                        //   ? filterData(
                        //       filterData(
                        //         dispatchList,
                        //         "broker",
                        //         this.state.singlelistView.id
                        //       ),
                        //       "broker_payment",
                        //       type === "paid" ? true : false
                        //     )
                        //     ? this.state.selectedRange &&
                        //       this.state.selectedRange.length > 0 &&
                        //       ((this.state.selectedRange[0] &&
                        //         this.state.selectedRange[0] !== null) ||
                        //         (this.state.selectedRange[1] &&
                        //           this.state.selectedRange[1] !== null))
                        //       ? this.filterDataAccDate(
                        //           filterData(
                        //             filterData(
                        //               newArr,
                        //               "broker",
                        //               this.state.singlelistView.id
                        //             ),
                        //             "broker_payment",
                        //             type === "paid" ? true : false
                        //           )
                        //         )
                        //       : filterData(
                        //           filterData(
                        //             dispatchList,
                        //             "broker",
                        //             this.state.singlelistView.id
                        //           ),
                        //           "broker_payment",
                        //           type === "paid" ? true : false
                        //         )
                        //     : []
                        //   : [],
                        // dispatchListCopy: filterData(
                        //   dispatchList,
                        //   "broker",
                        //   this.state.singlelistView.id
                        // )
                        //   ? filterData(
                        //       filterData(
                        //         dispatchList,
                        //         "broker",
                        //         this.state.singlelistView.id
                        //       ),
                        //       "broker_payment",
                        //       type === "paid" ? true : false
                        //     )
                        //     ? filterData(
                        //         filterData(
                        //           dispatchList,
                        //           "broker",
                        //           this.state.singlelistView.id
                        //         ),
                        //         "broker_payment",
                        //         type === "paid" ? true : false
                        //       )
                        //     : []
                        //   : [],

                        loader: false,
                      });
                    }
                  });
              }
            });
          } else {
            this.setState({
              dispatchList: [],
              dispatchListCopy: [],
              loader: false,
            });
          }
        });
    }
  };
  render() {
    const {
      tabs,
      listViewArray,
      singlelistView,
      loading,
      openDialog,
      editList,
      dispatchList,
      selectedRange,
      selectedRange1,
      dispatchListCopy,
      openStatusDialog,
      loader,
      showInvoice,
      BrandList,
      ItemCateoryList,
      ItemList,
      invoiceListArr,
      broken_amount,
      invoiceListArrCopy,
      downloadInvoicePdf,
      userRole,
      selectedTab,
    } = this.state;
    console.log(dispatchListCopy, "dispatch list copy  ");
    var tabb = "";
    const filterChild = (type) => {
      return (
        <BasicDateRangePicker
          selectedRange={selectedRange}
          onClear={() => {
            this.setState({
              dispatchList:
                dispatchListCopy.length > 0
                  ? filterData(
                      filterData(
                        dispatchListCopy,
                        "broker",
                        this.state.singlelistView.id
                      ),
                      "broker_payment",
                      false
                    )
                    ? filterData(
                        filterData(
                          dispatchListCopy,
                          "broker",
                          this.state.singlelistView.id
                        ),
                        "broker_payment",
                        false
                      )
                    : []
                  : [],
              selectedRange: [null, null],
            });
          }}
          onChange={(val) => {
            const oldArray =
              dispatchListCopy && dispatchListCopy.length > 0
                ? filterData(
                    filterData(
                      dispatchListCopy,
                      "broker",
                      this.state.singlelistView.id
                    ),
                    "broker_payment",
                    false
                  )
                  ? filterData(
                      filterData(
                        dispatchListCopy,
                        "broker",
                        this.state.singlelistView.id
                      ),
                      "broker_payment",
                      false
                    )
                  : []
                : [];

            const dateFrom =
              val && val[0]
                ? moment(val[0]).format("MMM D YYYY")
                : moment(new Date()).format("MMM D YYYY");

            const dateTo =
              val && val[1]
                ? moment(val[1]).format("MMM D YYYY")
                : moment(new Date()).format("MMM D YYYY");
            const newArr = oldArray.filter((singleObj) => {
              const createdAt = moment(singleObj.created_at).format(
                "MMM D YYYY"
              );

              return (
                createdAt === dateFrom ||
                createdAt === dateTo ||
                (new Date(createdAt) > new Date(dateFrom) &&
                  new Date(dateTo) > new Date(createdAt))
              );
            });
            this.setState({
              dispatchList: newArr.length > 0 ? newArr : [],
              selectedRange: val,
            });
          }}
        />
      );
    };
    const filterChild2 = () => {
      return (
        <BasicDateRangePicker
          selectedRange={selectedRange1}
          onClear={() => {
            this.setState({
              invoiceListArr:
                invoiceListArrCopy.length > 0 ? invoiceListArrCopy : [],
              selectedRange1: [null, null],
            });
          }}
          onChange={(val) => {
            const oldArray =
              invoiceListArrCopy && invoiceListArrCopy.length > 0
                ? invoiceListArrCopy
                : [];

            const dateFrom =
              val && val[0]
                ? moment(val[0]).format("MMM D YYYY")
                : moment(new Date()).format("MMM D YYYY");

            const dateTo =
              val && val[1]
                ? moment(val[1]).format("MMM D YYYY")
                : moment(new Date()).format("MMM D YYYY");
            const newArr = oldArray.filter((singleObj) => {
              const createdAt = moment(singleObj.invoice_date).format(
                "MMM D YYYY"
              );

              return (
                createdAt === dateFrom ||
                createdAt === dateTo ||
                (new Date(createdAt) > new Date(dateFrom) &&
                  new Date(dateTo) > new Date(createdAt))
              );
            });
            this.setState({
              invoiceListArr: newArr.length > 0 ? newArr : [],
              selectedRange1: val,
            });
          }}
        />
      );
    };
    var labelField = "name";
    const setData = (singleDispatch) => {
      const unit =
        singleDispatch.purchase_order_details &&
        singleDispatch.purchase_order_details.unit &&
        singleDispatch.purchase_order_details.unit;
      singleDispatch["PO#"] = singleDispatch.purchase_order_details
        ? singleDispatch.purchase_order_details.po_number
        : "-";
      singleDispatch["Dispatch date"] = moment(
        singleDispatch.dispatch_date
      ).format("ddd, MMM Do YYYY");

      singleDispatch["From"] = singleDispatch.dispatched_from_details
        ? singleDispatch.dispatched_from_details.name
        : "-";
      singleDispatch["To"] = singleDispatch.dispatched_to_details
        ? singleDispatch.dispatched_to_details.name
        : "-";
      singleDispatch["Bags qty"] =
        singleDispatch.subList &&
        singleDispatch.subList.length > 0 &&
        singleDispatch.subList.reduce(
          (previousValue, currentValue) =>
            Number(previousValue) +
            Number(
              currentValue.dispatch_packaging &&
                currentValue.dispatch_packaging.length > 0
                ? currentValue.dispatch_packaging.reduce(
                    (previousValue1, currentValue1) =>
                      Number(previousValue1) +
                      (currentValue1.total_bags_dispatched
                        ? Number(currentValue1.total_bags_dispatched)
                        : 0),
                    0
                  )
                : 0
            ),
          0
        );

      singleDispatch["Gross wt"] = singleDispatch.gross_weight
        ? `${Number(
            singleDispatch.gross_weight ? singleDispatch.gross_weight : 0
          ).toFixed(3)} ${unit}`
        : "-";
      singleDispatch["Dispatch qty"] = singleDispatch.dispatch_weight
        ? `${Number(
            singleDispatch.dispatch_weight ? singleDispatch.dispatch_weight : 0
          ).toFixed(3)} ${unit}`
        : "-";
      singleDispatch["Dispatch Status"] = singleDispatch.dispatch_status
        ? singleDispatch.dispatch_status
        : "-";
      singleDispatch["totalamount"] = 0;
      singleDispatch["subList"] = singleDispatch.subList
        ? singleDispatch.subList.map((singleSub) => {
            singleSub["Brand"] = filterData(
              BrandList,
              "id",
              singleSub.brand,
              "onlyOne"
            )
              ? filterData(BrandList, "id", singleSub.brand, "onlyOne")
                  .brand_name
              : "";

            singleSub["Item Category"] = filterData(
              ItemCateoryList,
              "id",
              singleSub.item_category,
              "onlyOne"
            )
              ? filterData(
                  ItemCateoryList,
                  "id",
                  singleSub.item_category,
                  "onlyOne"
                ).name
              : "";
            singleSub["Item Type"] = filterData(
              ItemList,
              "id",
              singleSub.item_type,
              "onlyOne"
            )
              ? `${Number(
                  filterData(ItemList, "id", singleSub.item_type, "onlyOne")
                    .broken_percentage
                ).toFixed(2)} %`
              : "";
            singleSub["Packaging"] =
              singleSub.dispatch_packaging &&
              singleSub.dispatch_packaging.length > 0
                ? `  ${
                    singleSub.dispatch_packaging.map((sP) => {
                      const singlePack = sP.packaging_details;

                      return singlePack
                        ? `${singlePack.size}${singlePack.unit}${" - "}${
                            singlePack.bag_type
                          }`
                        : "";
                    })[0]
                  } Total (...s)`
                : "-";
            singleSub["PackagingUI"] = (
              <ShowPackaging
                noTotalbags={true}
                details={singleSub.dispatch_packaging.map((sP) => {
                  const singlePack = sP.packaging_details;
                  singlePack.used_bags = sP.total_bags_dispatched;

                  return singlePack;
                })}
              />
            );
            singleSub["PackagingUIPdf"] =
              singleSub.dispatch_packaging &&
              singleSub.dispatch_packaging.length > 0
                ? singleSub.dispatch_packaging
                    .map((sP) => {
                      const singlePack = sP.packaging_details;

                      return singlePack
                        ? `${singlePack.size}${singlePack.unit}${" - "}${
                            singlePack.bag_type
                          }`
                        : "";
                    })
                    .join(", ")
                : "-";
            return singleSub;
          })
        : [];
      return singleDispatch;
    };
    return (
      <Layout
        loading={loading[EndPoints["Brokers"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Brokers`,
          labelField: labelField,
        }}
        fabClickHandler={(value) => {
          this.setState({
            openDialog: true,
            loadingFun: false,
            editList: false,
          });
        }}
        listClickHandler={this.listClickHandler}
        serchClick={this.listClickHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
      >
        {openDialog === true ? (
          <CreateDialog
          noAddAnother={true}
            textFieldWidth="calc(50% - 20px)"
            heightNew="500px"
            maxHeight="calc(100vh - 40px)"
            widthNew="900px"
            onEditUpdate={(res) => {
              this.onEditUpdate(res);
            }}
            // addFieldsToAdd={[{ name: "is_active", value: true }]}
            module={"Brokers"}
            selectedList={editList ? editList : false}
            editList={editList ? editList : false}
            handleClose={() => {
              this.setState({
                openDialog: false,
                loadingFun: false,
                editList: true,
              });
            }}
            postApi="masters/broker/list"
            editApi="masters/broker"
            inputArray={[
        
              {
                name: "Type",
          type: "radio",
          array: ["PO", "SO"],
          // update: true,
          api: "broker_for",
          disabled: editList && editList.broker_for ? false : true,
          defaultValue: {
            value:
              editList && editList.broker_for
                ? editList.broker_for
                :  "PO",
          },
          // onChange: (data) => {
          //   setType_ci(data["Type"]);
          //   return data;
          // },
              },
              {
                name: "Active",
                type: "toggle",
                defaultValue: {
                  value: editList ? editList.is_active : true,
                },
                api: "is_active",
              },
      
              {
                name: "name",
                type: "textField",
                dataType: "text",
                api: "name",
                defaultValue: {
                  value: editList && editList.name,
                },
              },
              {
                name: "phone number",
                type: "textField",
                dataType: "number",
                onInput: "phone_number",
                not_required: true,
                only_number: true,
                api: "phone",
                defaultValue: {
                  value: editList && editList.phone,
                },
              },
              {
                name: "address",
                type: "addressTextField",
                dataType: "text",
                api: "address",
                defaultValue: {
                  value: editList && editList.address,
                },
              },
              {
                name: "GST IN",
                type: "textField",
                dataType: "text",
                not_required: true,
                onKeyPress: "alphaNum",
                // only_number: true,
                onInput: "gst_in",
                api: "gstin",
                defaultValue: {
                  value: editList && editList.gstin,
                },
                helperText: "ex: 22AAAAA0000A1Z5",
              },

              {
                name: "contact person",
                type: "textField",
                dataType: "text",
                api: "contact_person",
                not_required:true,
                defaultValue: {
                  value: editList && editList.contact_person,
                },
              },

              {
                name: "state",
                type: "select",
                array: stateArray,
                value: "name",
                api: "state",
                defaultValue: {
                  value: editList && editList.state,
                },
              },
              {
                name: "city",
                type: "textField",
                dataType: "text",
                api: "city",
                onKeyPress: "textOnly",
                defaultValue: {
                  value: editList && editList.city,
                },
              },

              {
                name: "pincode",
                type: "textField",
                onInput: "pincode",
                dataType: "number",
                api: "pincode",
                only_number: true,
                defaultValue: {
                  value: editList && editList.pincode,
                },
              },
            ]}
            postData={this.props.BrokersFunction}
            fetchAgain={() => {
              this.props.BrokersFunction("get", EndPoints["Brokers"]);
            }}
          />
        ) : (
          ""
        )}

        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.Brokers === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <TabComponent
              onChange={(newVal) => {
                console.log(dispatchList, "dispatch list ");
                const selctedTab =
                  newVal === 0
                    ? "unpaid"
                    : newVal === 1
                    ? "pending"
                    : newVal === 2
                    ? "paid"
                    : newVal === 3 && "brokerDetail";

                this.setState({ selctedTab }, () => {
                  // localStorage.setItem("selectedTabSSIPL", selctedTab);
                  if (newVal === 0) {
                    this.fetchDispatchList("UNPAID");
                    tabb = "UNPAID";
                  }
                  if (newVal === 1) {
                    this.fetchDispatchList("PENDING");
                    tabb = "PENDING";
                  }
                  if (newVal === 2) {
                    this.fetchDispatchList("paid");
                    tabb = "PAID";
                  }
                });
              }}
              list={[
                {
                  name: "Unpaid",
                  component: (
                    <TableComponent
                      loader={loader ? loader : false}
                      onCheckSubmit={async (selectedListArray) => {
                        return this.setState({
                          openStatusDialog: true,
                          broken_amount: "",
                          selectedListArray: selectedListArray,
                        });
                      }}
                      bottomBar={true}
                      subMenu={true}
                      filterChild={() => {
                        return filterChild("unpaid");
                      }}
                      checkbox={true}
                      noAddButton={true}
                      module={"Dispatch"}
                      {...this.state}
                      header={[
                        "Sr.No.",
                        "PO#",
                        "Dispatch date",
                        "From",
                        "To",
                        "Bags qty",
                        "Gross wt",
                        "Dispatch qty",
                        "Dispatch Status",
                      ]}
                      subMenuheader={[
                        "Sr.No.",
                        "Brand",
                        "Item Category",
                        "Item Type",
                        "Packaging",
                      ]}
                      actions={[]}
                      {...this.props}
                      tableList={
                        dispatchList && dispatchList.length
                          ? filterData(
                              dispatchList,
                              "broker",
                              this.state.singlelistView.id
                            ) &&
                            filterData(
                              filterData(
                                dispatchList,
                                "broker",
                                this.state.singlelistView.id
                              ),
                              "broker_payment",
                              "UNPAID"
                            ) &&
                            filterData(
                              filterData(
                                dispatchList,
                                "broker",
                                this.state.singlelistView.id
                              ),
                              "broker_payment",
                              "UNPAID"
                            )
                              .sort((a, b) => {
                                var dateB = moment(b.dispatch_date).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                var dateA = moment(a.dispatch_date).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                return new Date(dateB) - new Date(dateA);
                              })
                              .map((singleDispatch) => {
                                return setData(singleDispatch);
                              })
                          : []
                      }
                      postData={this.props.BrokersFunction}
                    />
                  ),
                },
                {
                  name: "Pending",
                  component: (
                    <TableComponent
                      loader={loader ? loader : false}
                      onCheckSubmit={async (selectedListArray) => {
                        return this.setState({
                          openStatusDialog: true,
                          broken_amount: "",
                          selectedListArray: selectedListArray,
                        });
                      }}
                      bottomBar={true}
                      subMenu={true}
                      filterChild={() => {
                        return filterChild("pending");
                      }}
                      checkbox={true}
                      noAddButton={true}
                      module={"Dispatch"}
                      {...this.state}
                      header={[
                        "Sr.No.",
                        "PO#",
                        "Dispatch date",
                        "From",
                        "To",
                        "Bags qty",
                        "Gross wt",
                        "Dispatch qty",
                        "Dispatch Status",
                      ]}
                      subMenuheader={[
                        "Sr.No.",
                        "Brand",
                        "Item Category",
                        "Item Type",
                        "Packaging",
                      ]}
                      actions={[]}
                      {...this.props}
                      tableList={
                        dispatchList && dispatchList.length
                          ? filterData(
                              dispatchList,
                              "broker",
                              this.state.singlelistView.id
                            ) &&
                            filterData(
                              filterData(
                                dispatchList,
                                "broker",
                                this.state.singlelistView.id
                              ),
                              "broker_payment",
                              "PENDING"
                            ) &&
                            filterData(
                              filterData(
                                dispatchList,
                                "broker",
                                this.state.singlelistView.id
                              ),
                              "broker_payment",
                              "PENDING"
                            )
                              .sort((a, b) => {
                                var dateB = moment(b.dispatch_date).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                var dateA = moment(a.dispatch_date).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                return new Date(dateB) - new Date(dateA);
                              })
                              .map((singleDispatch) => {
                                return setData(singleDispatch);
                              })
                          : []
                      }
                      postData={this.props.BrokersFunction}
                    />
                  ),
                },

                {
                  name: "Paid",
                  component: (
                    <InvoiceCardUi
                      singlelistView={singlelistView}
                      module="broker"
                      setData={setData}
                      filterChild={() => {
                        return filterChild2();
                      }}
                      listArrCopy={
                        invoiceListArrCopy && invoiceListArrCopy.length
                          ? invoiceListArrCopy
                          : []
                      }
                      header={[
                        "Sr.No.",
                        "PO#",
                        "Dispatch date",
                        "From",
                        "To",
                        "Bags qty",
                        "Gross wt",
                        "Dispatch qty",
                        "Dispatch Status",
                      ]}
                      pdfHeader={[
                        "Sr.No.",
                        "PO#",
                        "Dispatch date",
                        "From",
                        "To",
                        "Bags qty",
                        "Gross wt",
                        "Dispatch qty",
                      ]}
                      loader={loader ? loader : false}
                      fetchFunction={this.props.BrokersFunction}
                      listArr={
                        invoiceListArr && invoiceListArr.length
                          ? invoiceListArr
                          : []
                      }
                    />
                  ),
                },
                {
                  name: "Broker Details",
                  component: (
                    <Paper
                      className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <div className="width-100 d-flex justifyFE">
                        <Tooltip title="Edit">
                          <EditIcon
                            onClick={() => {
                              this.setState({
                                openDialog: true,
                                editList: singlelistView,
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      </div>

                      <div
                        className="overflowYA scrollBluePrimary"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          padding: "0 50px",
                          height: "calc(100% - 31.7px)",
                        }}
                      >
                         <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            Broker For:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",

                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}
                          >
                            {singlelistView.broker_for}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            Name:{" "}
                          </p>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}
                          >
                            {singlelistView ? singlelistView.name : ""}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            Phone Number:
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}
                          >
                            {singlelistView.phone}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            Address:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",

                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}
                          >
                            {singlelistView.address}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            GST IN:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",

                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}
                          >
                            {singlelistView.gstin}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            Contact Person:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",

                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}
                          >
                            {singlelistView.contact_person}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            City:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",

                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}
                          >
                            {singlelistView.city}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            State:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",

                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}
                          >
                            {singlelistView.state}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            Pincode:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",

                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}
                          >
                            {singlelistView.pincode}
                          </p>
                        </div>
                      </div>
                    </Paper>
                  ),
                },
              ]}
            />
          )}
          {openStatusDialog === true ? (
            <Dialog
              ref={(e) => {
                try {
                  const target =
                    ReactDOM.findDOMNode(e).children[2].children[0];
                  target.style.borderRadius = "0px";
                  target.style.maxHeight = "calc(100% - 62px)";
                  target.style.minHeight = showInvoice
                    ? "calc(100% - 63px)"
                    : "300px";

                  target.style.height = showInvoice
                    ? "calc(100% - 63px)"
                    : "300px";
                  target.style.minWidth = showInvoice
                    ? "calc(100% - 150px)"
                    : "40%";
                  target.style.maxWidth = showInvoice
                    ? "calc(100% - 150px)"
                    : "calc(100% - 150px)";
                  target.style.margin = "32px 75px";
                } catch (e) {}
              }}
              open={true}
              onClose={() => {
                this.setState({
                  openStatusDialog: false,
                  broken_amount: "",
                  editDialog: false,
                  added: false,
                  selectedListArray: false,
                  showInvoice: false,
                });
              }}
              TransitionComponent={Transition}
            >
              <DialogActions
                className="d-flex justifySB"
                style={{
                  background: primary,
                  color: "white",
                  padding: "10px 24px",
                }}
                onClick={() => {
                  this.setState({
                    openStatusDialog: false,
                    broken_amount: "",
                    editDialog: false,
                    added: false,
                    selectedListArray: false,
                    showInvoice: false,
                  });
                }}
              >
                {" "}
                <h3 style={styles.heading}>
                  {showInvoice !== false
                    ? "Invoice"
                    : this.state.selectedListArray[0] &&
                      this.state.selectedListArray[0].broker_payment ===
                        "PENDING"
                    ? "Approve Payment"
                    : "Mark as Paid"}
                </h3>
                <Tooltip title="close" arrow placement="top">
                  <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
                    <CrossIcon strokeWidth="2" />
                  </IconButton>
                </Tooltip>
              </DialogActions>{" "}
              <DialogContent
                className="dashedDividerBottom d-flex d-flex-column justifyC alignC"
                style={{ height: "100%" }}
              >
                <div style={{ height: "0", width: "0", overflow: "hidden" }}>
                  {downloadInvoicePdf === true ? (
                    <InvoiceCardUi
                      onlyShow={false}
                      setLoader={() => {
                        this.setState({ loader: false });
                      }}
                      onDownload={() => {
                        this.setState({
                          openStatusDialog: false,
                          editDialog: false,
                          added: false,
                          selectedListArray: false,
                          showInvoice: false,
                          loader: false,
                          downloadInvoicePdf: false,
                          broken_amount: "",
                        });
                      }}
                      onlyDownload={showInvoice}
                      singlelistView={singlelistView}
                      module="broker"
                      setData={setData}
                      filterChild={() => {
                        return filterChild2();
                      }}
                      listArrCopy={
                        invoiceListArrCopy && invoiceListArrCopy.length
                          ? invoiceListArrCopy
                          : []
                      }
                      header={[
                        "Sr.No.",
                        "PO#",
                        "Dispatch date",
                        "From",
                        "To",
                        "Bags qty",
                        "Gross wt",
                        "Dispatch qty",
                        "Dispatch Status",
                      ]}
                      pdfHeader={[
                        "Sr.No.",
                        "PO#",
                        "Dispatch date",
                        "From",
                        "To",
                        "Bags qty",
                        "Gross wt",
                        "Dispatch qty",
                      ]}
                      loader={loader ? loader : false}
                      fetchFunction={this.props.BrokersFunction}
                      listArr={
                        invoiceListArr && invoiceListArr.length
                          ? invoiceListArr
                          : []
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>

                {loader === true ? (
                  <StyledCircularProgress />
                ) : showInvoice !== false ? (
                  <InvoiceCardUi
                    onlyShow={true}
                    setLoader={() => {
                      this.setState({ loader: false });
                    }}
                    onDownload={() => {
                      this.setState({
                        openStatusDialog: false,
                        broken_amount: "",
                        editDialog: false,
                        added: false,
                        selectedListArray: false,
                        showInvoice: false,
                        loader: false,
                        broken_amount: "",
                        downloadInvoicePdf: false,
                      });
                    }}
                    onlyDownload={showInvoice}
                    singlelistView={singlelistView}
                    module="broker"
                    setData={setData}
                    filterChild={() => {
                      return filterChild2();
                    }}
                    listArrCopy={
                      invoiceListArrCopy && invoiceListArrCopy.length
                        ? invoiceListArrCopy
                        : []
                    }
                    header={[
                      "Sr.No.",
                      "PO#",
                      "Dispatch date",
                      "From",
                      "To",
                      "Bags qty",
                      "Gross wt",
                      "Dispatch qty",
                      "Dispatch Status",
                    ]}
                    pdfHeader={[
                      "Sr.No.",
                      "PO#",
                      "Dispatch date",
                      "From",
                      "To",
                      "Bags qty",
                      "Gross wt",
                      "Dispatch qty",
                    ]}
                    loader={loader ? loader : false}
                    fetchFunction={this.props.BrokersFunction}
                    listArr={
                      invoiceListArr && invoiceListArr.length
                        ? invoiceListArr
                        : []
                    }
                  />
                ) : (
                  <>
                    <div
                      style={{
                        fontSize: "0.9rem",
                        justifyContent: "center",
                        marginTop: "20px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Are you Sure ?{" "}
                    </div>
                    {this.state.selectedListArray[0] &&
                    this.state.selectedListArray[0].broker_payment ===
                      "PENDING" ? (
                      "Are you sure you want to approve and  mark these dispatches as paid ?"
                    ) : (
                      <CssTextField
                        required={false}
                        type="number"
                        onFocus={StopScroll}
                        autoComplete="off"
                        style={{ margin: "40px 0" }}
                        id="outlined-basic"
                        label="Brokerage Amount"
                        variant="outlined"
                        name="broken_amount"
                        value={broken_amount ? broken_amount : ""}
                        onChange={(e) => {
                          this.setState({ broken_amount: e.target.value });
                        }}
                      />
                    )}
                  </>
                )}
              </DialogContent>
              <div
                style={{
                  justifyContent: "end",
                  display: "flex",
                  padding: "10px",
                }}
              >
                {showInvoice ? (
                  <>
                    <StyledButton
                      name="Download pdf"
                      width="150px"
                      padding="2px 5px"
                      onClick={() => {
                        this.setState({
                          downloadInvoicePdf: true,
                          loader: true,
                        });
                      }}
                    />
                    {/* <StyledButton withBg={true} name="Download CSV" width="150px" padding="2px 5px" /> */}
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          openStatusDialog: false,
                          editDialog: false,
                          added: false,
                          selectedListArray: false,
                          broken_amount: "",
                        });
                        console.log(this.state.selectedListArray, "api data");
                        console.log(tabb, "selected tab");
                      }}
                      style={{
                        color: "rgb(241 154 48)",
                        margin: "0 10px 0 0",
                        padding: "2px 5px",
                      }}
                    >
                      No
                    </Button>
                    <Button
                      onClick={() => {
                        if (
                          this.state.selectedListArray &&
                          this.state.selectedListArray.length > 0
                        ) {
                          this.setState({ loader: true });
                          const data = this.state.selectedListArray;
                          const newInvoice = {
                            invoice_date: moment(new Date()).format(
                              "YYYY-MM-DD"
                            ),
                            // invoice_number: `${data[0].id.toString()}${data.length.toString()}`,
                            invoice_for: "BROKER",
                            amount: this.state.broken_amount
                              ? this.state.broken_amount
                              : 0,
                            is_active: true,
                            broker: singlelistView.id,
                          };
                          const apiData = [
                            { name: "id" },
                            { name: "dispatch_date" },
                            { name: "total_bags" },
                            { name: "dispatch_weight" },
                            { name: "miller_bill_amount" },
                            { name: "miller_tcs" },
                            { name: "miller_advance" },
                            { name: "miller_total_amount" },
                            { name: "bilty_number" },
                            { name: "bilty_date" },
                            { name: "truck_number" },
                            { name: "gross_weight" },
                            { name: "net_weight" },
                            { name: "tare_weight" },
                            { name: "freight_rate" },
                            { name: "total_freight" },
                            { name: "transporter_advance" },
                            { name: "transporter_balance" },
                            { name: "last_survey" },
                            { name: "miller_payment" },
                            { name: "transporter_payment" },
                            { name: "broker_payment" },
                            { name: "dispatch_status" },
                            { name: "miller_bill_number" },
                            { name: "is_active" },
                            { name: "unit" },
                            { name: "dispatched_from" },
                            { name: "dispatched_to" },
                            { name: "purchase_order" },
                            { name: "sales_order" },
                            { name: "transporter" },
                            { name: "surveyor" },
                          ];

                          if (
                            this.state.selectedListArray[0].broker_payment ===
                            "PENDING"
                          ) {
                            console.log("qqqqqqqqq");
                            this.props
                              .BrokersFunction(
                                "post",
                                "dispatch/invoice/list",
                                null,
                                "invoiceUpdate",
                                newInvoice
                              )
                              .then((invoiceId) => {
                                if (invoiceId && invoiceId.id) {
                                  const newArr = [];
                                  data.map((sData) => {
                                    const newObj = {};
                                    // const pay = "";
                                    apiData.map((s) => {
                                      // pay =
                                      //   s.name === "broker_payment"
                                      //     ? s.value
                                      //     : "";
                                      // console.log(pay, "payment");
                                      return (newObj[s.name] = s.value
                                        ? sData[s.value]
                                        : sData[s.name]
                                        ? sData[s.name]
                                        : s.name === "transporter"
                                        ? ""
                                        : s.name === "transporter_payment" ||
                                          s.name === "broker_payment"
                                        ? false
                                        : null);
                                    });
                                    newObj.invoice = invoiceId.id;
                                    newObj.invoice_number = 0;
                                    newObj.broker_payment = "PAID";

                                    newObj.invoice_date = moment(
                                      new Date()
                                    ).format("YYYY-MM-DD");
                                    return newArr.push(newObj);
                                  });

                                  this.props
                                    .BrokersFunction(
                                      "put",
                                      "dispatch/bulk-update",
                                      null,
                                      "dispatchListBulk",
                                      newArr
                                    )
                                    .then((dispatchListBulk) => {
                                      console.log(newArr, "array pending api");
                                      this.fetchDispatchList("PENDING");
                                      this.setState({
                                        loader: false,
                                        showInvoice: invoiceId,
                                      });
                                    });
                                }
                              });
                          } else if (
                            this.state.selectedListArray[0].broker_payment ===
                            "UNPAID"
                          ) {
                            if (userRole === "admin") {
                              this.props
                                .BrokersFunction(
                                  "post",
                                  "dispatch/invoice/list",
                                  null,
                                  "invoiceUpdate",
                                  newInvoice
                                )
                                .then((invoiceId) => {
                                  if (invoiceId && invoiceId.id) {
                                    const newArr = [];
                                    data.map((sData) => {
                                      const newObj = {};
                                      const pay = "";
                                      apiData.map((s) => {
                                        // pay =
                                        //   s.name === "broker_payment"
                                        //     ? s.value
                                        //     : "";
                                        // console.log(pay, "payment");
                                        return (newObj[s.name] = s.value
                                          ? sData[s.value]
                                          : sData[s.name]
                                          ? sData[s.name]
                                          : s.name === "transporter"
                                          ? ""
                                          : s.name === "transporter_payment" ||
                                            s.name === "broker_payment"
                                          ? false
                                          : null);
                                      });
                                      newObj.invoice = invoiceId.id;
                                      newObj.invoice_number = 0;
                                      newObj.broker_payment = "PAID";

                                      newObj.invoice_date = moment(
                                        new Date()
                                      ).format("YYYY-MM-DD");
                                      return newArr.push(newObj);
                                    });

                                    this.props
                                      .BrokersFunction(
                                        "put",
                                        "dispatch/bulk-update",
                                        null,
                                        "dispatchListBulk",
                                        newArr
                                      )
                                      .then((dispatchListBulk) => {
                                        this.fetchDispatchList("UNPAID");
                                        this.setState({
                                          loader: false,
                                          showInvoice: invoiceId,
                                        });
                                      });
                                  }
                                });
                            } else {
                              // this.props
                              //   .BrokersFunction("post", "dispatch/invoice/list", null, "invoiceUpdate", newInvoice)
                              //   .then((invoiceId) => {
                              //     if (invoiceId && invoiceId.id) {
                              //       const newArr = [];
                              //       data.map((sData) => {
                              //         const newObj = {};
                              //         const pay = "";
                              //         apiData.map((s) => {
                              //           // pay =
                              //           //   s.name === "broker_payment"
                              //           //     ? s.value
                              //           //     : "";
                              //           // console.log(pay, "payment");
                              //           return (newObj[s.name] = s.value
                              //             ? sData[s.value]
                              //             : sData[s.name]
                              //             ? sData[s.name]
                              //             : s.name === "transporter"
                              //             ? ""
                              //             : s.name === "transporter_payment" || s.name === "broker_payment"
                              //             ? false
                              //             : null);
                              //         });
                              //         newObj.invoice = invoiceId.id;
                              //         newObj.invoice_number = 0;
                              //         newObj.broker_payment = "PENDING";

                              //         newObj.invoice_date = moment(new Date()).format("YYYY-MM-DD");
                              //         return newArr.push(newObj);
                              //       });

                              //       this.props
                              //         .BrokersFunction("put", "dispatch/bulk-update", null, "dispatchListBulk", newArr)
                              //         .then((dispatchListBulk) => {
                              //           this.fetchDispatchList("UNPAID");
                              //           this.setState({
                              //             loader: false,
                              //             showInvoice: invoiceId,
                              //           });
                              //         });
                              //     }
                              //   });
                              const newArr = [];
                              data.map((sData) => {
                                const newObj = {};
                                const pay = "";
                                apiData.map((s) => {
                                  // pay =
                                  //   s.name === "broker_payment"
                                  //     ? s.value
                                  //     : "";
                                  // console.log(pay, "payment");
                                  return (newObj[s.name] = s.value
                                    ? sData[s.value]
                                    : sData[s.name]
                                    ? sData[s.name]
                                    : s.name === "transporter"
                                    ? ""
                                    : s.name === "transporter_payment" ||
                                      s.name === "broker_payment"
                                    ? false
                                    : null);
                                });
                                //  newObj.invoice = invoiceId.id;
                                //  newObj.invoice_number = 0;
                                newObj.broker_payment = "PENDING";

                                //  newObj.invoice_date = moment(
                                //    new Date()
                                //  ).format("YYYY-MM-DD");
                                return newArr.push(newObj);
                              });

                              this.props
                                .BrokersFunction(
                                  "put",
                                  "dispatch/bulk-update",
                                  null,
                                  "dispatchListBulk",
                                  newArr
                                )
                                .then((dispatchListBulk) => {
                                  this.fetchDispatchList("UNPAID");
                                  this.setState({
                                    loader: false,
                                    //  showInvoice: invoiceId,
                                    openStatusDialog: false,
                                  });
                                });
                            }
                          }
                        }
                      }}
                      style={{
                        background: "rgb(241 154 48)",
                        color: "white",
                        margin: "0 0 0 10px",
                        padding: "2px 5px",
                        padding: "2px 5px",
                      }}
                    >
                      Yes
                    </Button>
                  </>
                )}
              </div>
            </Dialog>
          ) : (
            ""
          )}
        </Paper>
      </Layout>
    );
  }
}

export default BrokersComponent;
