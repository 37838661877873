import {
  Button,
  CircularProgress,
  withStyles,
  Snackbar,
  SnackbarContent,
  Divider,
  TextField,
  Avatar,
  InputBase,
  Paper,
  Dialog,
  DialogActions,
  Tooltip,
  IconButton,
  DialogContent,
  Backdrop,
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepContent,
} from "@material-ui/core";
import ReactDOM from "react-dom";
import "./styles.css";
import { CheckCircle } from "@mui/icons-material";

import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import {
  primary,
  green,
  white,
  mostLightGrey,
  disableColor,
  lightestGrey,
  darkGrey,
  primaryOpacity019,
  orange,
  lightblue,
} from "./colors";
import { CancelIcon, CrossIcon, SearchIcon } from "./Icons";
import Rhombus from "./Rhombus.gif";
import { parseISOWithOptions } from "date-fns/fp";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { CSVLink } from "react-csv";
import { mediumScreen } from "../Regex";
const CssButton = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "RGBA(0,134,126,0.1)",
      color: primary,
    },
  },
})(Button);
const CssLoadButton = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "RGBA(0,134,126,0.1)",
      color: primary,
    },
  },
})(CssButton);
export const StyledLoadingButton = (props) => (
  <Tooltip title={props.tooltip ? props.tooltip : ""} placement="right-start">
    <CssLoadButton
      id={props.id ? props.id : ""}
      // loading={props.loading ? props.loading : false}
      onClick={props.onClick}
      className={
        props.error
          ? "buttonAnimation"
          : props.loading === true || props.disabled
          ? ""
          : props.background && "boxShadow "
      }
      style={{
        padding: props.padding && props.padding,
        background:
          props.loading === true
            ? "rgba(0, 0, 0, 0.12)"
            : props.background
            ? props.background
            : props.loading === true || props.disabled
            ? disableColor
            : props.withBg
            ? primary
            : props.color
            ? props.color
            : "transparent",
        color: props.onlyTextColor
          ? props.onlyTextColor
          : props.loading === true || props.disabled
          ? lightestGrey
          : props.withBg
          ? props.mostLightGrey
            ? darkGrey
            : white
          : props.color
          ? props.color
          : primary,
        fontFamily: props.fontFamily && props.fontFamily,
        fontSize: mediumScreen() ? "0.8rem" : props.fontSize && props.fontSize,
        textTransform: props.textTransform ? props.textTransform : "capitalize",
        border: props.border ? props.border : "",
        borderRadius: props.borderRadius ? props.borderRadius : props.withBg ? "6px" : "",
        margin: props.margin ? props.margin : "",
        width: props.width ? props.width : "fit-content",
        minWidth: props.minWidth ? props.minWidth : "",
        maxWidth: props.maxWidth ? props.maxWidth : "",
        height: props.height ? props.height : "",
        maxHeight: props.maxHeight ? props.maxHeight : "",
      }}
      disabled={props.loading === true ? true : props.disabled && props.disabled}
    >
      {props.loading === true ? (
        <StyledCircularProgress height="24.5px" width="24.5px" margin="0" color="rgba(0, 0, 0, 0.26)" />
      ) : (
        <>
          {props.name}
          {props.icon && <div style={{ minWidth: "3px" }} />}
          {props.icon}
        </>
      )}
    </CssLoadButton>
  </Tooltip>
);
export const CssTextField95 = withStyles({
  // "& .MuiMenu-paper": {
  //   margin: "42px 0 0 0",
  // },
  // "& .MuiFormHelperText-root": {
  //   color: lightblue,
  // },
  // "& .MuiFormHelperText-root.Mui-error": {
  //   color: "#f44336",
  // },
  root: {
    "& .MuiFormHelperText-root": {
      color: lightblue,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
    },
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
    },
    "& .MuiOutlinedInput-input": {
      padding: mediumScreen() ? "6px 9.5px" : "9.5px",
      fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(9.5px , 9.5px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: mediumScreen() ? "translate(18px, -4px) scale(0.75)"  : "translate(14px, -6px) scale(0.75)",
      fontSize: mediumScreen() ? "0.875rem" : "0.9rem",
    },
    "& label.Mui-focused": {
      color: primary,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: mostLightGrey,
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },

      "& .MuiOutlinedInput-input": {
        padding: "9.5px",
      },
      "&.MuiInputLabel-outlined": {
        transform: "translate(9.5px , 17px) scale(1)",
      },
    },
  },
})(TextField);
export const CssTextField85 = withStyles({
  root: {
    "& .MuiFormHelperText-root": {
      color: lightblue,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
    },
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
    },
    "& .MuiOutlinedInput-input": {
      padding: "7.5px",
      fontSize: mediumScreen() ? "0.8rem" : "0.9rem", 
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(7.5px , 7.5px) scale(1)",
      borderColor: primary,
      fontSize: mediumScreen() ? "0.8rem" : "0.9rem", 
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      borderColor: primary,
    },
    "& label.Mui-focused": {
      color: primary,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: mostLightGrey,
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },

      "& .MuiOutlinedInput-input": {
        padding: "7.5px",
        fontSize: mediumScreen() ? "0.8rem" : "0.9rem", 
      },
      "& .MuiInputLabel-outlined": {
        transform: "translate(9.5px , 9.5px) scale(1)",
        borderColor: primary,
        fontSize: mediumScreen() ? "0.8rem" : "0.9rem", 
      },
    },
  },
})(TextField);
export const CssTextField = withStyles({
  // "& .MuiMenu-paper": {
  //   margin: "42px 0 0 0",
  // },
  // "& .MuiFormHelperText-root": {
  //   color: lightblue,
  // },
  // "& .MuiFormHelperText-root.Mui-error": {
  //   color: "#f44336",
  // },
  root: {
    "& .MuiInputBase-root": {
      fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
    },
    "& .MuiInputLabel-shrink": {
      whiteSpace: 'nowrap',
      fontSize: mediumScreen() ? "0.9rem" : "1rem",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": { 
      textTransform: "capitalize",
      fontSize: mediumScreen() ? "0.8rem" : "0.9rem", 
    },
    "& .MuiMenu-list": { textTransform: "capitalize" },
    "& .MuiFormHelperText-root": {
      color: lightblue,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
    },
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
      maxHeight: 180,
    },
    "& .MuiOutlinedInput-input": {
      padding: mediumScreen() ? "10px" : "20px",
      fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(15px , 15px) scale(1)",
      fontSize: mediumScreen() ? "0.85rem" : "0.95rem",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      width: 'auto',
      whiteSpace: 'nowrap',
    },
    "& label.Mui-focused": {
      color: primary,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: mostLightGrey,
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },

      "& .MuiOutlinedInput-input": {
        padding: "15px",
        fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
      },
      "&.MuiInputLabel-outlined": {
        transform: "translate(20px , 17px) scale(1)",
        fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
      },
      "&.MuiOutlinedInput-multiline": {
        padding: 0,
      }
    },
  },
})(TextField);
export const CssTextFieldMobile = withStyles({
  // "& .MuiMenu-paper": {
  //   margin: "42px 0 0 0",
  // },

  // "& .MuiFormHelperText-root": {
  //   color: lightblue,
  // },
  // "& .MuiFormHelperText-root.Mui-error": {
  //   color: "#f44336",
  // },
  root: {
    "& .MuiFormHelperText-root": {
      color: lightblue,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
    },
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(10px , 10px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label.Mui-focused": {
      color: white,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primary,
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiSelect-icon": {
        color: white,
      },
      background: primary,
      color: white,
      borderRadius: "13px",
      "& fieldset": {
        borderColor: mostLightGrey,
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },
    },
  },
})(TextField);
export const CssTextFieldOrange = withStyles({
  "& .MuiMenu-paper": {
    margin: "42px 0 0 0",
  },
  root: {
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
    },
    "& .MuiInputBase-inputMultiline": {
      color: darkGrey,
    },
    "& .MuiOutlinedInput-input": {
      padding: "20px",

      color: darkGrey,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(20px , 20px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label.Mui-focused": {
      color: orange,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: orange,
    },
    "& .MuiOutlinedInput-multiline": {
      display: "flex",
      alignItems: "flex-end",
      color: darkGrey,
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: orange,
      },
      "&:hover fieldset": {
        borderColor: orange,
      },
      "&.Mui-focused fieldset": {
        borderColor: orange,
      },
    },
  },
})(TextField);
const CssInputBase = withStyles({
  root: {
    "& .MuiInputBase-input": { color: darkGrey },
    "& :hover": {
      // backgroundColor: "RGBA(0,134,126,0.1)",
      // color: primary,
      border: "none",
    },
  },
})(InputBase);

export const CSVButton = (props) => (
  <Tooltip title={'Download CSV'} placement="right-start">
    <CSVLink
      data={props.csvData || []}
      headers={props.csvHeader || []}
      filename={`${props.fileName}.csv`}
      style={{ height: 20 }}
    >
      <CloudDownloadOutlinedIcon style={{ color: "#4b87c5" }} />
    </CSVLink>
  </Tooltip>
)
export const StyledButton = (props) => (
  <Tooltip title={props.tooltip ? props.tooltip : ""} placement="right-start">
    <CssButton
      onClick={props.onClick}
      className={props.disabled ? "" : props.background && "boxShadow"}
      style={{
        maxHeight: props.maxHeight ? props.maxHeight : "",
        background: props.background
          ? "rgb(241 154 48)"
          : props.noColorWhenDisable
          ? ""
          : props.disabled
          ? disableColor
          : props.withBg
          ? primary
          : props.color
          ? props.color
          : "transparent",
        color: props.onlyTextColor
          ? props.onlyTextColor
          : props.disabled
          ? lightestGrey
          : props.withBg
          ? props.mostLightGrey
            ? darkGrey
            : white
          : props.color
          ? props.color
          : primary,
        textTransform: props.textTransform ? props.textTransform : "capitalize",
        border: props.border ? props.border : "",
        borderRadius: props.borderRadius ? props.borderRadius : props.withBg ? "6px" : "",
        margin: props.margin ? props.margin : "",
        width: props.width ? props.width : "fit-content",
        minWidth: props.minWidth ? props.minWidth : "",
        height: props.height ? props.height : "",
        transform: props.transform ? props.transform : "",
        padding: props.padding ? props.padding : "",
        fontSize: props.fontSize ? props.fontSize : "inherit",
      }}
      disabled={props.disabled && props.disabled}
    >
      {props.icon} <div style={{ minWidth: "3px" }} /> {props.name}
    </CssButton>
  </Tooltip>
);
export const StyledCircularProgress = (props) => (
  <div className="d-flex justifyC alignC width-100 height-100">
    <CircularProgress
      style={{
        height: props.height ? props.height : "65px",
        width: props.width ? props.width : "65px",
        color: props.color ? props.color : primary,
        margin: "0",
      }}
    />
  </div>
);
export const StyledLoader = (props) => (
  <div className="d-flex justifyC alignC width-100 height-100">
    <img src={Rhombus} alt="loading..." />
  </div>
);
export const StyledSnackbar = (props) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: props.center ? "center" : "bottom",
        horizontal: props.center ? "center" : props.success || props.error ? "right" : "center",
      }}
      open={true}
      autoHideDuration={2000}
      onClose={() => props.handleCloseSnackbar()}
    >
      <SnackbarContent
        style={{
          backgroundColor: props.success ? green : props.error ? "#DB0011" : props.adminRequestPage ? "grey" : primary,
        }}
        message={
          <span id="message-id" style={{ color: "white" }}>
            {props.message ? props.message : "updated successfully"}
          </span>
        }
      />
    </Snackbar>
  );
};
export const StyledDivider = (props) => {
  return (
    <Divider
      style={{
        color: props.color ? props.color : mostLightGrey,
        opacity: props.opacity ? props.opacity : "0.8",
        margin: props.margin ? props.margin : "0",
        width: props.width ? props.width : "100%",
      }}
    />
  );
};
export const StyledSearchBar = (props) => {
  const [searchBarValue, setSearchBarValue] = React.useState("");
  return (
    <Paper
      className="d-flex width-100 alignC"
      style={{
        maxWidth: mediumScreen() ? "180px" : "300px",
        width: mediumScreen() ? "180px" : "300px",
        borderRadius: "7px",
        height: mediumScreen() ? "28px" : "38px",
        fontSize: "0.9rem",
        background: props.bgC ? props.bgC : white, // "rgba(158, 158, 158, 0.18)",
      }}
      elevation={props.bgC ? 0 : 3}
      component="form"
    >
      <div
        className="d-flex align-c justify-c"
        style={{
          padding: props.padding ? props.padding : "12px 12px",
          transform: mediumScreen() ? "scale(0.8)" : "scale(1)",
        }}
      >
        <SearchIcon color={primary} />
      </div>
      <CssInputBase
        disabled={props.copyData && props.copyData.length === 0}
        style={{ width: "100%", border: "none", fontSize: mediumScreen() ? "0.7rem" : "inherit" }}
        placeholder={
          //props.placeholder ? props.placeholder :
          "Search !!"
        }
        inputProps={{ "aria-label": "search google maps", fontSize: mediumScreen() ? "0.7rem" : "inherit" }}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
          }
        }}
        onChange={(event) => {
          setSearchBarValue(event.target.value);
          const filterData = [];
          if (props.copyData && props.fieldName) {
            props.copyData.map((data) => {
              return props.fieldName.map((singleField) => {
                if (
                  data[singleField] &&
                  data[singleField].toString().toLowerCase().includes(event.target.value.toString().toLowerCase())
                ) {
                  if (!filterData.includes(data)) {
                    return filterData.push(data);
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              });
            });
            props.filteredData(filterData);
          } else {
            props.onChange(event);
          }
        }}
        value={searchBarValue}
      />
      {searchBarValue && (
        <div
          className="d-flex alignC justifyC"
          style={{
            padding: "0 9.5px",
          }}
        >
          <Avatar
            onClick={(event) => {
              setSearchBarValue("");
              props.onClear();
            }}
            style={{
              color: white,
              backgroundColor: "#1E3558", // primaryOpacity019,
              borderRadius: "50%",
              width: "18px",
              height: "18px",
              fontSize: "0.7rem",
              textAlign: "center",
              justifyContent: "center",
              transform: mediumScreen() ? "scale(0.8)" : "scale(0.9)",
            }}
          >
            <CancelIcon color={white} background={"#1E3558"} />
          </Avatar>
        </div>
      )}
    </Paper>
  );
};
export const CssFilledSelectTextField = withStyles({
  // "& .MuiMenu-paper": {
  //   margin: "42px 0 0 0",
  // },

  // "& .MuiFormHelperText-root.Mui-error": {
  //   color: "#f44336",
  // },
  root: {
    "& .MuiSelect-select": {
      padding: "5px 5px 5px 10px",
      minHeight: "26.5px",
      border: "none",
      display: "flex",
      alignItems: "center",
      textTransform: "capitalize",
      borderRadius: "7px",
      background: "rgba(158, 158, 158, 0.18)",
    },
    "& .MuiFilledInput-underline:after": { border: "none" },
    "& .MuiFilledInput-underline:before": { border: "none" },
    "& .MuiFormHelperText-root": {
      color: lightblue,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
    },
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
    },
    "& .MuiOutlinedInput-input": {
      padding: "20px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(20px , 20px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label.Mui-focused": {
      color: primary,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: mostLightGrey,
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },
    },
  },
})(TextField);
export const CssKeyboardDatePicker = withStyles({
  root: {
    "&:hover": {
      border: "none",
    },
    // '& .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    //   padding: '0px 4px 7.5px 6px',
    // },
    // '&.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root': {
    //   padding: '5px',
    // },
    "& .MuiOutlinedInput-adornedEnd": {
      padding: "0",
    },
    "& .MuiInputBase-root.Mui-disabled": { color: "black" },
    "& .MuiFormControl-root": {},

    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input ": {
        caretColor: "transparent",
        background: "white",
        padding: "15px",
      },
      "& .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root": {
        padding: "5px",
      },
    },
  },
})(KeyboardDatePicker);
export const CardDetail = (props) => (
  <div
    key={props.keyId ? props.keyId : "key0"}
    onClick={props.onClick ? props.onClick : () => {}}
    style={{
      height: props.height ? props.height : "100%",
      width: props.width ? props.width : "100%",
      minWidth: props.minWidth ? props.minWidth : "inherit",
      margin: props.margin ? props.margin : "",
      background: props.background ? props.background : "",
      cursor: props.pointer ? "pointer" : "",
      padding: props.padding ? props.padding : "10px",
    }}
    className={`${
      props.noBoxShadow ? "" : "boxShadow"
    } borderRadius7 fontWeight500  textC fontSize08 d-flex alignC justifyC d-flex-column`}
  >
    {props.children ? props.children() : ""}
  </div>
);
export const CssDialog = (props) => {
  const {
    error,
    success,
    height,
    onClose,
    snackbarClose,
    header,
    noappbar,
    children,
    loadingDialog,
    added,
    handleClearFields,
    reset,
    edit,
    onSubmit,
    noActions,
    fullScreen,
    addButton,
    noReset,
    addButtonWidth,
    bottomBarContent,
    width,
    headerPrimary,
    resetText,
    maxWidth,
    padding,
  } = props;
  return (
    <Dialog
      ref={(e) => {
        try {
          const target = ReactDOM.findDOMNode(e).children[2].children[0];
          target.style.borderRadius = fullScreen ? "0" : "10px";
          target.style.maxHeight = fullScreen ? "100%" : height ? height : "calc(100vh - 120px)";
          target.style.height = fullScreen ? "100%" : "650px";
          target.style.minWidth = width ? width : fullScreen ? "100%" : "90%";
          target.style.maxWidth = maxWidth ? maxWidth : "calc(100% - 150px)";
          target.style.margin = fullScreen ? "0" : "55px 75px 65px";
        } catch (e) {}
      }}
      open={true}
      onClose={onClose ? onClose : () => {}}
      // TransitionComponent={Transition}
    >
      {props.onlyCross ? (
        <DialogActions className="d-flex justifyFE textCW backgroundWhite textColorprimary padding10 boxShadow margin8">
          {header && <h3 className="margin0 textColorprimary width-100-32-81 textOEllipse">{header}</h3>}
          <Tooltip title="close" arrow placement="top">
            <IconButton className="padding5 borderRadius3" onClick={onClose ? onClose : () => {}}>
              <CrossIcon strokeWidth="2" color={primary} />
            </IconButton>
          </Tooltip>
        </DialogActions>
      ) : noappbar ? (
        ""
      ) : (
        <DialogActions
          className={`d-flex justifySB textColorprimary padding10 boxShadow margin8 ${
            headerPrimary ? "backgroundColorPrimary" : "backgroundWhite"
          }`}
        >
          <h3 className={`margin0  width-100-32-81 textOEllipse ${headerPrimary ? "textCW" : " textColorprimary"}`}>
            {header}
          </h3>

          <Tooltip title="close" arrow placement="top">
            <IconButton className="padding5 borderRadius3" onClick={onClose ? onClose : () => {}}>
              <CrossIcon strokeWidth="2" color={headerPrimary ? white : primary} />
            </IconButton>
          </Tooltip>
        </DialogActions>
      )}
      <DialogContent className="dashedDividerBottom height-100" style={{ padding: padding ? padding : "20px" }}>
        {error || success ? (
          <StyledSnackbar
            error={error}
            success={success}
            handleCloseSnackbar={snackbarClose ? snackbarClose : () => {}}
          />
        ) : (
          ""
        )}
        {loadingDialog === true ? (
          <Backdrop className="zIndex1 textCW" open={true}>
            <StyledCircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}
        {added ? (
          <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
            <CheckCircle style={{ width: "100px", height: "100px", color: green }} />
            <span className="heading" style={{ color: green, margin: "10px 0 0 0" }}>
              {added}
            </span>
          </div>
        ) : (
          <div className="height-100 width-100 d-flex  d-flex-column overflowYA scrollBluePrimary">
            {children ? children() : ""}
          </div>
        )}
      </DialogContent>
      {noActions ? (
        ""
      ) : (
        <DialogActions
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            minHeight: "52.5px",
          }}
        >
          {bottomBarContent ? (
            bottomBarContent()
          ) : added ? (
            <StyledButton
              onClick={
                handleClearFields
                  ? () => {
                      handleClearFields("no");
                    }
                  : () => {}
              }
              name={"Add Another"}
              withBg={true}
              background={green}
              width="120px"
              margin="0 10px 0 0"
            />
          ) : (
            <>
              {noReset ? (
                ""
              ) : (
                <StyledButton
                  onClick={
                    reset
                      ? () => {
                          reset();
                        }
                      : () => {}
                  }
                  name={resetText ? resetText : "Reset"}
                  width="60px"
                  margin="0 10px 0 0"
                  onlyTextColor={primary}
                />
              )}
              <StyledButton
                onClick={onSubmit ? onSubmit : () => {}}
                name={addButton ? addButton : edit ? "Submit" : "Add"}
                withBg={true}
                background={"rgb(241 154 48)"}
                width={addButtonWidth ? addButtonWidth : "60px"}
              />
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
export const CustomStepper = (props) => {
  const { steps, defaultStep, orientation, onlyLabel } = props;

  const activeStep = defaultStep ? defaultStep : 0;
  return (
    <Stepper
      activeStep={activeStep}
      orientation={orientation ? orientation : "vertical"}
      style={{ padding: "0", width: "100%" }}
    >
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StepLabel
          // optional={
          //   index === steps.length - 1 ? onlyLabel ? "" : <Typography variant="caption">Last step</Typography> : null
          // }
          >
            {step.label}
          </StepLabel>
          {onlyLabel ? "" : <StepContent>{step.content()}</StepContent>}
        </Step>
      ))}
    </Stepper>
  );
};
export const HeadingLine = (props) => (
  <div
    style={{
      width: props.width ? props.width : "100%",
      minWidth: props.minWidth ? props.minWidth : "",
      maxWidth: props.maxWidth ? props.maxWidth : "",
      padding: props.padding ? props.padding : props.withBg ? "7px 15px" : "",
      margin: props.margin ? props.margin : "0 0 10px 0",
      justifyContent: props.children2 && "space-between",
      color: props.color ? props.color : "",
      background: props.withBg ? "rgb(30 53 89 / 10%)" : "",
      // borderRadius: props.withBg ? "5px" : "",
      justifyContent: props.center ? "center" : props.withBg ? "center" : "",
    }}
    className={`${
      props.subHeading ? "textColorGrey " : props.result ? "textCOlorBlack " : "textColorLIghtBlack " //textDecorUSpac textDecorU "
    } d-flex alignC fontWeight500 letterS1 textC ${props.subHeading ? "fontSize09" : "fontSize1"}  `}
  >
    {props.children}
    <div>{props.children2 && props.children2}</div>
  </div>
);
export const ShowDetails = (props) => (
  <div
    style={{
      height: props.height ? props.height : "100%",
      width: props.width ? props.width : "100%",
      padding: props.padding ? props.padding : "10px 20px",
      margin: props.margin ? props.margin : "",
      fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
    }}
    className={`${
      props.noboxShadow ? "" : "boxShadow"
    }  borderRadius7 fontWeight500  letterS1 textC fontSize08 d-flex  textCOlorBlack flexWrap ${
      props.isMobile ? "" : "alignC"
    }`}
  >
    {props.lists &&
      props.lists.length > 0 &&
      props.lists.map((single, i) => (
        <div
          style={{
            height: props.noFixHeight ? "" : "auto",
            padding: "",
            margin: props.lists.length - 1 === i ? "0" : "0 10px 0 0",
            width: "fit-content",
          }}
          className={`${
            props.lists.length - 1 === i && props.lists2 && props.lists2.filter((s) => s !== false).length > 0
              ? "dashedDividerBottom "
              : ""
          } d-flex ${props.noFixHeight ? "alignC" : "alignC"} textEllipses`}
          key={i}
        >
          <div
            style={{ 
              minWidth: props.minWidthOfTitle ? props.minWidthOfTitle : "fit-content",
              fontSize: mediumScreen() ? "0.8rem" : "0.9rem", 
            }}
            className="fontWeightBold justifySB d-flex alignC"
          >
            {single.ui ? single.name : `${single.name} `}
            <div style={{ margin: "0 10px 0 10px" }}>{`:`}</div>
          </div>

          <div
            style={{
              margin: "0",
              height: "100%",
              width: props.minWidthOfTitle
                ? `calc(100% - ${props.minWidthOfTitle ? props.minWidthOfTitle : "100px"} )`
                : "fit-content",
              color: single.name === "Status" ? orange : "",
              fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
            }}
            className="textEllipses"
          >
            {" "}
            {single.value}
          </div>
        </div>
      ))}
    {props.lists2
      ? props.lists2.map(
          (single, i) =>
            single && (
              <div
                style={{
                  margin: i === 0 ? "0" : i === 1 ? "10px 0" : i === 2 ? "0" : props.margin ? props.margin : "0",
                  color: "black",
                  minHeight: single.edit ? "69px" : "",
                }}
                className={`width-100 d-flex alignC  ${single.after ? "" : ""}`}
                key={i}
              >
                <div
                  style={{ 
                    minWidth: props.minWidthOfTitle ? props.minWidthOfTitle : "100px",
                    fontSize: mediumScreen() ? "0.8rem" : "0.9rem", 
                    }}
                  className="fontWeightBold justifySB d-flex"
                >
                  {single.ui ? single.name : `${single.name} `}
                  <div style={{ margin: "0 10px 0 0" }}>{`:`}</div>
                </div>

                <div className="marginR5"></div>

                <div
                  style={{
                    margin: "0 0 0 5px",
                    height: "100%",
                    width: `calc(100% - ${props.minWidthOfTitle ? props.minWidthOfTitle : "100px"} )`,
                    // justifyContent: single.edit ? "space-between" : "",
                    fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
                  }}
                  className="textEllipses  d-flex alignC"
                >
                  {" "}
                  {single.value} {single.edit ? single.edit : ""}
                </div>
              </div>
            )
        )
      : ""}
    {props.ui ? props.ui() : ""}
  </div>
);
