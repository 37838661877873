import React, { useEffect } from "react";
import { connect } from "react-redux";
import RiceBagSellersComponent from "./RiceBagSellersComponent";
import { BrandsManagementsFunction } from "../../redux/index";
import { useParams } from "react-router";
import { EndPoints } from "../../utils/EndPoints";

function RiceBagSellersContainer(props) {
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    if (props.user.uid) {
      props.BrandsManagementsFunction("get", EndPoints["RiceBagSellers"]);
    }
  };

  const { tab, id } = useParams();
  const params = { tab: tab, id: id };
  return <RiceBagSellersComponent {...props} params={params} />;
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  [EndPoints["RiceBagSellers"]]: state?.modules[EndPoints["RiceBagSellers"]] && Object?.values(state?.modules[EndPoints["RiceBagSellers"]]).filter(Array.isArray).flat(),
  loading: state.modules.loading,
});
const mapDispatchToProps = {
  BrandsManagementsFunction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiceBagSellersContainer);
