import React, { useRef } from "react";

import "../PurchaseOrders/pdf.css";
import { PDFExport } from "@progress/kendo-react-pdf";
import DownloadIcon from "@mui/icons-material/Download";
import { filterData } from "../../Regex";
import parse from "html-react-parser";
import { CssButton } from "../PurchaseOrders/PurchaseOrdersComponent";
import moment from "moment";
import { boldCss, HDivider } from "../PurchaseOrders/PurchaseOrderPdf";
import { CompanyFullName } from "../../Defaults";
import SvgPreview from "../PurchaseOrders/SvgPreview";

const CustomDiv = ({
  title,
  content = "",
  contentUI = "",
  displayCondition = true,
}) => {
  return displayCondition ? (
    <div style={{ width: "100%", display: "flex" }}>
      <p style={{ fontSize: "0.6rem", margin: "0px", minWidth: "150px" }}>
        {title}{" "}
      </p>
      {contentUI !== "" 
        ? contentUI() 
        : <p style={{ margin: "0px 0 10px 10px" }}>
          {': ' + content || ""}
        </p>
      }
    </div>
  ) : (
    ""
  );
};

function SalesAddendumPdf(props) {
  const {
    singlelistView,
    ClientList,
    CurrencyList,
    PortsList,
    SoPaymentList,
    preview,
    specifiationTypes,
    masters,
    newAddendumData,
    newAddendumChanges,
    handleDeliveryTermsText,
  } = props;

  const Article1 = [
    "item_category",
    "so_specifications",
    "packaging",
    "bag_weight",
    "buyers_marking",
  ];
  const Article2a = ["total_quantity", "quantity_tolerance", "contract_rate", "extras"];
  const Article2b = ["currency", "quantity_tolerance", "unit", "inco_term", "discharge_port", "shipping_term"];
  const Article3 = [
    "shipment_date",
    "load_ports",
    "discharge_port",
    "shipment_by",
  ];
  const Article4 = ["so_payment_term", "duty_payment_term"];

  const handleRateQuantity = (field, id, data) => {
    if(field === 'contract_rate'){
      return data ? data : newAddendumData.so_items?.find(obj => obj.id === id).contract_rate 
    }
    else if(field === 'total_quantity'){
      return data ? data : newAddendumData.so_items?.find(obj => obj.id === id).total_quantity 
    }
  }

  console.log("SALES", newAddendumData, props, props.deliveryTerm);
  const contentArea = useRef(null);
  const getSpecificationName = (sp) => {
    switch (sp) {
      case "equal_to":
        return "only";

      case "not_equal_to":
        return "not";

      case "greater_than":
        return "above";

      case "greater_than_or_equal_to":
        return "min";

      case "less_than":
        return "below";

      case "less_than_or_equal_to":
        return "max";
    }
  };
  var converter = require("number-to-words");

  const handlePriceValueData = (data, field) => {

    if(field === 'item_category'){
      return filterData(masters['ItemCateoryList'], "id", Number(data), "onlyOne")?.name || ""
    }
    if(field === 'currency'){
      console.log("CURRENCY", data, field, filterData(CurrencyList, "id", Number(data), "onlyOne")?.name, filterData(CurrencyList, "id", Number(data), "onlyOne")?.name || "")
      return filterData(CurrencyList, "id", Number(data), "onlyOne")?.name || ""
    }
    if(field === 'inco_term'){
      let Inco = filterData(masters["Incoterms"], "id", data, "onlyOne")?.name || ""
      return Inco
    }
    if(field === 'shipping_term'){
      return filterData(masters["ShippingTerms"], "id", Number(data), "onlyOne")?.value || ""
    }
    if(field === 'packaging'){
      const formattedPackaging = data
      .map(({ size, unit, bag_type }) => `${size} ${unit} ${bag_type}`)
      .join(", ");

      return formattedPackaging
    }
    else
     return data[field]
  }

  const handleExportWithFunction = (event) => {
    if (contentArea.current) {
      contentArea.current.save();
    }
  };

  const buyerDetails = filterData(
    ClientList,
    "id",
    newAddendumData.buyer,
    "onlyOne"
  )
    ? filterData(ClientList, "id", newAddendumData.buyer, "onlyOne")
    : "";
  const file = {
    name: "Sales Order No.",
    value: newAddendumData && newAddendumData.so_number,
    date: newAddendumData.contract_date,
    buyer: buyerDetails.client_name,
    buyerAddress: buyerDetails.address,
    buyerCity: buyerDetails.city,
    buyerState: buyerDetails.state,
    email: buyerDetails.email,
    totalQuantity: newAddendumData.total_quantity,
    unit: newAddendumData.unit,
    terms: singlelistView.terms,
  };
  const PageTemplate = (props) => {
    return (
      <>
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "15px",
            right: "15px",
            height: "90px",
            width: "calc(100% - 30px)",
            fontFamily: "Arial",
          }}
        >
          <div
            style={{
              width: "100%",
              borderBottom: "1.5px solid rgba(0, 0, 0, 2.25)",
            }}
          >
            <div
              style={{
                padding: "5px 0",
                width: "calc(100% - 40px)",
                display: "flex",
                textTransform: "initial !important",
                margin: "0 10px",
              }}
            >
              <SvgPreview
                svg={"/SmileBots-log.png"}
                sx={{
                  height: "40px",
                  width: "60px",
                }}
              />
              <div
                style={{
                  width: "calc(100% - 120px)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginRIGHT: "60px",
                  textAlign: "justify",
                }}
              >
                <h2
                  style={{
                    ...boldCss,
                    textTransform: "uppercase",
                    margin: "0 2px 0 0",
                    fontSize: "0.9rem",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "justify",
                  }}
                >
                  {CompanyFullName}
                </h2>
                <div
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "600",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "justify",
                  }}
                >
                  CIN No: U14200CT2007PTC020290
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "calc(100% - 25px)",
              display: "flex",
              justifyContent: "flex-start",
              fontWeight: "bold",
              textDecoration: "underline",
              fontSize: "0.6rem",
              marginLeft: "25px",
            }}
          >
            {`Contract no: ${file.value}`} <br />
            {`Dated: ${
              file.date ? moment(file.date).format("DD.MM.YYYY") : ""
            }`}
          </div>
          <br />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "15px",
            right: "15px",
            height: "40px",
            width: "calc(100% - 30px)",
            fontFamily: "Arial",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontSize: "0.6rem",
              justifyContent: "flex-end",
            }}
          >
            {" "}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                fontSize: "0.5rem",
              }}
            >{`Page:${props.pageNum}/${props.totalPages}`}</div>
            {HDivider}
            <div style={{ width: "100%", fontWeight: "bold" }}>
              Registered Off : Behind Samrat Talkies, Civil Station Road, Raipur
              -492001,(C.G.) Ph:+91 771 2887453, 2524245
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                Fax: +91 771 4032240, Email : sainath@srisainath.com Web:
                <span style={{ ...boldCss }}>www.srisainath.com</span>
              </div>
              <div>This is computer generated</div>
            </div>
            {HDivider}
          </div>
        </div>
      </>
    );
  };
  const fls = {
    name: "Sales Order No.",
    value: newAddendumData && newAddendumData.so_number,
    date: newAddendumData.contract_date,
    buyer: buyerDetails.client_name,
    buyerAddress: buyerDetails.address,
    buyerCity: buyerDetails.city,
    buyerState: buyerDetails.state,
    buyerPhone: buyerDetails.phone,
    email: buyerDetails.email,
    country: buyerDetails.country,
    totalQuantity: newAddendumData.total_quantity,
    unit: newAddendumData.unit,
    terms: singlelistView.terms,
  };

  return (
    <>
      <div
        style={
          preview
            ? { width: "100%", height: "100%" }
            : { height: "0", width: "0", overflow: "hidden" }
        }
      >
        <PDFExport
          ref={contentArea}
          keepTogether="div"
          paperSize="A4"
          // margin="2cm"
          // forcePageBreak=".avoid-break"
          fileName={`${newAddendumData && newAddendumData.so_number}.pdf`}
          allPages={true}
          margin={{
            bottom: 50,
            left: 25,
            right: 25,
            top: 100,
          }}
          pageTemplate={PageTemplate}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              fontFamily: "Arial",
              color: "rgba(0, 0, 0, 0.84)",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                padding: "4px",
                fontSize: "0.6rem",
                textTransform: "initial !important",
              }}
            >
              <div
                style={{
                  height: "fit-content",
                  // minHeight: "692px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      fontSize: "0.6rem",
                      fontWeight: 700,
                      margin: "10px 0 10px 20px",
                      textAlign: "center",
                      textDecoration: "underline",
                    }}
                  >
                    {`ADDENDUM - 1 FORMING INTEGRAL PART TO SALES CONTRACT NO: ${
                      newAddendumData.so_number
                    }  Dated: ${moment(
                      newAddendumChanges?.sales_order?.addendum_date ||
                        new Date()
                    ).format("DD.MM.YYYY")}`}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      fontSize: "0.6rem",
                      // textDecoration: "underline",
                    }}
                  >
                    {`This Addendum is made on ${moment(
                      newAddendumChanges?.sales_order?.addendum_date ||
                        new Date()
                    ).format("DD.MM.YYYY")} between:`}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      fontSize: "0.6rem",
                    }}
                  >
                    <div>
                      <strong>{`${fls?.buyer?.toUpperCase()}`}</strong> <br />
                      <strong>
                        {`${fls.buyerAddress}, ${fls.buyerCity}, ${
                          fls.buyerState ? `${fls.buyerState}, ` : ""
                        }${fls.country ? `${fls.country}, ` : ""} `}
                      </strong>{" "}
                      <br />
                      {fls.email && (
                        <strong>{`Email: ${
                          fls.email ? fls.email.toLowerCase() : ""
                        }`}</strong>
                      )}
                      <br />
                      (Herein after called the BUYER)
                    </div>
                    AND
                    <div>
                      <strong>
                        SRI SAINATH INDUSTRY PRIVATE LIMITED <br />
                        BEHIND SAMRAT TALKIES, STATION ROAD,
                        RAIPUR-492001,CHHATTISGARH (INDIA)
                      </strong>
                      <br />
                      (Herein after called the SELLER)
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex",
                      fontSize: "0.6rem",
                      marginTop: 10,
                    }}
                  >
                    {`
                    This is agreed upon between buyer and seller on SALES CONTRACT NO. ${
                      newAddendumData.so_number
                    } Dated: ${moment(
                      newAddendumChanges?.sales_order?.addendum_date ||
                        new Date()
                    ).format("DD.MM.YYYY")} for the following:
                    `}
                  </div>

                  <br />
                </div>
                {(newAddendumChanges?.so_items?.length > 0 &&
                  newAddendumChanges?.so_items.some(
                    (obj) =>
                      Article1.some((key) => obj.hasOwnProperty(key)) ||
                      Article2a.some((key) => obj.hasOwnProperty(key))
                  )) ||
                (newAddendumChanges?.sales_order &&
                  Object.keys(newAddendumChanges?.sales_order)?.length > 0 &&
                  Article2b.some(
                    (key) => key in newAddendumChanges?.sales_order
                  )) ? (
                  <>
                    <div
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "bolder",
                        marginTop: "10px 0",
                      }}
                    >
                      Article 1: COMMODITY AND SPECIFICATION
                    </div>

                    {newAddendumChanges?.so_items && newAddendumChanges?.so_items.length > 0 ? (
                      newAddendumChanges?.so_items.map((single, index) => (
                        <>
                          {console.log(
                            "singlePDF1",
                            single,
                          )}
                          <CustomDiv
                            title={"PRODUCT"}
                            content={single?.item_category 
                              ? handlePriceValueData(single?.item_category, 'item_category')
                              : newAddendumData?.so_items && newAddendumData?.so_items?.find(d => d.id === single.id)?.item_category?.name || ""
                            }
                            displayCondition={Boolean(newAddendumChanges?.so_items?.length > 0 &&
                              single?.item_category
                            )}
                          />
                          <CustomDiv
                            title={"SPECIFICATIONS"}
                            contentUI={() => (
                              <div
                                style={{
                                  margin: "0px 0 10px 10px",
                                  display: "flex",
                                  gap: "3px",
                                }}
                              > :
                                <div>
                                  {single["so_specifications"] &&
                                    single["so_specifications"].length > 0 &&
                                    single["so_specifications"].map((item) => {
                                      const ss = item.nameId ?? item.name;
                                      const filterS = specifiationTypes?.find(
                                        (st) => st.id === ss
                                      );
                                      return (
                                        <>
                                          <div style={{display: "grid",gridTemplateColumns: "4fr 4fr",}}>
                                            <div>
                                            {filterS ? filterS.text : item.name}
                                            </div>{" "}
                                            <div>
                                            {`: ${
                                              filterS
                                                ? `${item.value ?? ""} ${
                                                    filterS.unit
                                                      ? filterS.unit.toUpperCase() ?? ""
                                                      : ""
                                                  }`
                                                : item.value
                                            } ${getSpecificationName(item.condition) ?? "" }`}</div>
                                          </div>
                                        </>
                                      );
                                    })}
                                </div>
                              </div>
                            )}
                            displayCondition={Boolean( newAddendumChanges?.so_items?.length > 0 &&
                             single?.so_specifications?.length
                            )}
                          />
                          <CustomDiv
                            title={"ORIGIN"}
                            content={"INDIA"}
                            displayCondition={Boolean(
                              newAddendumChanges?.so_items?.length
                            )}
                          />
                          <CustomDiv
                            title={"PACKING"}
                            contentUI={() => (
                              <p style={{ margin: "0px 0 10px 10px" }}> 
                              :{" "}
                              {single[`packaging`] &&
                              single[`packaging`].length > 0
                                ? single[`packaging`].map((p, i) => {
                                    const item = p?.size ? p : masters["PackagingList"]?.find(pkg => pkg.id === p)
                                    return (
                                      <>
                                        {`In ${item.size} ${item.unit} Net. new ${item.bag_type} bags${
                                          single[`packaging`].length - 1 === i
                                            ? "."
                                            : ", "
                                        } ${
                                          single.bag_weight ||
                                          single.bag_weight === "NA" ||
                                          single.bag_weight === "na"
                                            ? `${single.bag_weight}.`
                                            : ""
                                        }`}
                                      </>
                                    )
                                  })
                                : ""
                              }                              </p>
                            )}
                            displayCondition={Boolean( newAddendumChanges?.so_items?.length > 0 &&
                              single?.packaging?.length
                            )}
                          />
                          <CustomDiv
                            title={"MARKING"}
                            content={single?.buyers_marking}
                            displayCondition={Boolean(
                              (newAddendumChanges?.so_items?.length > 0 &&
                                newAddendumChanges?.so_items.some(
                                  (obj) =>
                                    Article1.some((key) =>
                                      obj.hasOwnProperty(key)
                                    ) ||
                                    Article2a.some((key) =>
                                      obj.hasOwnProperty(key)
                                    )
                                )) ||
                                (newAddendumChanges?.sales_order &&
                                  Object.keys(newAddendumChanges?.sales_order)
                                    ?.length > 0 &&
                                  Article2b.some(
                                    (key) =>
                                      key in newAddendumChanges?.sales_order
                                  ))
                            )}
                          />
                        </>
                      ))
                    ) : (
                      <div>NO PRODUCT</div>
                    )}
                  </>
                ) : (
                  ""
                )}
                {(newAddendumChanges?.so_items?.length > 0 &&
                  newAddendumChanges?.so_items.some((obj) =>
                    Article2a.some((key) => obj.hasOwnProperty(key))
                  )) ||
                (newAddendumChanges?.sales_order &&
                  Object.keys(newAddendumChanges?.sales_order)?.length > 0 &&
                  Article2b.some(
                    (key) => key in newAddendumChanges?.sales_order
                  )) ? (
                  <>
                    <div
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "bolder",
                        marginTop: "10px",
                      }}
                    >
                      Article 2: QUANTITY / PRICE/ TOTAL AMOUNT
                    </div>
                    {newAddendumChanges?.so_items && newAddendumChanges?.so_items.length > 0 ? (
                      newAddendumChanges?.so_items.map((single, index) => (
                        <>
                          <CustomDiv
                            title={"QUANTITY"}
                            content={`${
                              single["total_quantity"]
                                ? single["total_quantity"] + (newAddendumChanges?.sales_order?.unit || newAddendumData?.unit || "")
                                : ""
                            } ${single.quantity_tolerance ?? ""} ${
                              newAddendumData.total_no_of_containers
                                ? ` in ${newAddendumData.total_no_of_containers} FCL`
                                : ""
                            }`}
                          />
                          <CustomDiv
                            title={"PRICE"}
                            content={
                              //  [{obj.currency}, {contract_rate_list}, {obj.unit}, {obj.inco_term}, {obj.discharge_port}, {obj.shipping_term}, {packaging_list}, {extras_list}]
                                handlePriceValueData(newAddendumChanges?.sales_order?.currency || newAddendumData?.currency, 'currency') +
                                " " +
                                handleRateQuantity('contract_rate', single.id, single["contract_rate"]) +
                                " " +
                                newAddendumChanges?.sales_order?.unit || newAddendumData?.unit +
                                " " +
                                handlePriceValueData(newAddendumChanges?.sales_order?.inco_term || newAddendumData?.inco_term, 'inco_term') +
                                " " +
                                handleDeliveryTermsText(
                                  handlePriceValueData(newAddendumChanges?.sales_order?.inco_term || newAddendumData?.inco_term, 'inco_term'),
                                  newAddendumChanges?.sales_order.load_ports || newAddendumData?.load_ports,
                                  newAddendumChanges?.sales_order?.discharge_port || newAddendumData?.discharge_port
                                ) +
                                " " +
                                handlePriceValueData(newAddendumChanges?.sales_order?.shipping_term  || newAddendumData?.shipping_term, 'shipping_term') +
                                " " +
                                handlePriceValueData(newAddendumChanges?.so_items?.[0]?.packaging || newAddendumData?.so_items?.[0]?.packaging, 'packaging') +
                                " " +
                                newAddendumChanges?.so_items?.[0]?.extras || newAddendumData?.so_items?.[0]?.extras
                                
                                
                            }
                          />
                          <CustomDiv
                            title={"VALUE"}
                            content={
                              ` ${handlePriceValueData(newAddendumChanges?.sales_order?.currency || newAddendumData?.currency, 'currency')}
                                ${Number( handleRateQuantity('contract_rate', single.id, single["contract_rate"])  ) * Number( handleRateQuantity('total_quantity', single.id, single["total_quantity"]) ) + '/- (' }
                                ${handlePriceValueData(newAddendumChanges?.sales_order?.currency || newAddendumData?.currency, 'currency')}
                                ${converter.toWords(
                                Number( handleRateQuantity('contract_rate', single.id, single["contract_rate"]) ) * Number( handleRateQuantity('total_quantity', single.id, single["total_quantity"])  )
                                || 0) + " only.)"}
                              `
                            }
                          />
                        </>
                      ))
                    ) : (
                      <div>NO PRODUCT</div>
                    )}
                  </>
                ) : (
                  ""
                )}
                {newAddendumChanges?.sales_order &&
                Object.keys(newAddendumChanges?.sales_order).length > 0 &&
                Article3.some(
                  (key) => key in newAddendumChanges?.sales_order
                ) ? (
                  <>
                    <div
                      style={{
                        fontSize: "0.8rem",
                        fontWeight: "bolder",
                        marginTop: "10px",
                      }}
                    >
                      Article 3: DELIVERY/ LOADPORT/ DISCHARGE PORT/ SHIPPING
                      TERMS:
                    </div>
                    <CustomDiv
                      title={"Shipment"}
                      content={
                        newAddendumChanges?.sales_order?.shipment_date
                          ? moment(
                              newAddendumChanges?.sales_order?.shipment_date
                            ).format("DD/MM/YYYY")
                          : ""
                      }
                      displayCondition={Boolean(
                        "shipment_date" in newAddendumChanges?.sales_order
                      )}
                    />
                    <CustomDiv
                      title={"Load Port"}
                      content={
                        newAddendumChanges?.sales_order?.load_ports?.length >
                          0 &&
                        newAddendumChanges.sales_order.load_ports
                          ?.filter((sp) => sp !== "Any Indian Port")
                          .map(
                            (sp, index, arr) =>
                              filterData(PortsList, "id", Number(sp), "onlyOne")
                                .name + (index !== arr.length - 1 ? ", " : "")
                          )
                          .join("") +
                          ", INDIA" +
                          (newAddendumChanges.sales_order.load_ports.includes(
                            "Any Indian Port"
                          )
                            ? " Any Indian Port"
                            : "")
                      }
                      displayCondition={Boolean(
                        "Load Port" in newAddendumChanges?.sales_order
                      )}
                    />
                    <CustomDiv
                      title={"Discharge Port"}
                      content={
                        newAddendumChanges?.sales_order?.discharge_port
                          ? newAddendumChanges?.sales_order?.discharge_port
                          : ""
                      }
                      displayCondition={Boolean(
                        "discharge_port" in newAddendumChanges?.sales_order
                      )}
                    />
                    <CustomDiv
                      title={"Shipment by"}
                      content={
                        newAddendumChanges?.sales_order?.shipment_by
                          ? newAddendumChanges?.sales_order?.shipment_by
                          : ""
                      }
                      displayCondition={Boolean(
                        "shipment_by" in newAddendumChanges?.sales_order
                      )}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
              {console.log(
                "PAYMENt",
                newAddendumChanges?.so_items,
                newAddendumData
              )}
              {newAddendumChanges?.sales_order &&
              Object.keys(newAddendumChanges?.sales_order).length > 0 &&
              Article4.some((key) => key in newAddendumChanges?.sales_order) ? (
                <>
                  <div
                    // className="page-break"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "bolder",
                      marginTop: "15px",
                    }}
                  >
                    Article 4: PAYMENT{" "}
                  </div>
                  <CustomDiv
                    title={"Payment"}
                    content={`${
                      newAddendumData.so_payment_term &&
                      filterData(
                        SoPaymentList,
                        "id",
                        Number(newAddendumData.so_payment_term),
                        "onlyOne"
                      )
                        ? filterData(
                            SoPaymentList,
                            "id",
                            Number(newAddendumData.so_payment_term),
                            "onlyOne"
                          ).term
                        : ""
                    } ${newAddendumData.duty_payment_term ?? ""}`}
                    displayCondition={Boolean(
                      "so_payment_term" in newAddendumChanges?.sales_order ||
                        "duty_payment_term" in newAddendumChanges?.sales_order
                    )}
                  />
                  <CustomDiv
                    title={"Seller’s Bank Details"}
                    contentUI={() => (
                      <p style={{ margin: "0px 0 10px 10px" }}> 
                        <div>: BANK OF BARODA, MAIN BRANCH</div>
                        <div style={{ margin: "0px 0 0px 5px" }}>
                          GAUSHALA COMPLEX,K.K.ROAD,RAIPUR-Chhattisgarh-INDIA${" "}
                        </div>
                        <div style={{ margin: "0px 0 0px 5px" }}>Swift Code: BARBINBBRAI</div>
                        <div style={{ margin: "0px 0 0px 5px" }}>Account No. 05100200001242</div>
                      </p>
                    )}
                    displayCondition={Boolean(
                      "so_payment_term" in newAddendumChanges?.sales_order ||
                        "duty_payment_term" in newAddendumChanges?.sales_order
                    )}
                  />
                </>
              ) : (
                ""
              )}

              {
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginLeft: "0px",
                    }}
                  >
                    <p
                      style={{
                        margin: "10px 0 10px 0px",
                      }}
                    >
                      {parse(newAddendumChanges?.sales_order?.policy_of_so_policy || "")}
                    </p>
                  </div>
                </>
              }
              <div style={{
                pageBreakInside: 'avoid'
              }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    fontSize: "0.7rem",
                    // textDecoration: "underline",
                  }}
                >
                  All other terms and conditions in this sales contract will
                  remain unchanged.
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    marginTop: "40px",
                    fontSize: "0.6rem",
                  }}
                >
                  <div
                    style={{
                      width: "fit-content",
                      minWidth: "50%",
                      fontWeight: "bold",
                    }}
                  >
                    SELLER
                    <br />
                    SRI SAINATH INDUSTRY PVT LIMITED
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    (Authorised Signatory)
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    BUYER
                    <br />
                    {buyerDetails?.client_name?.toUpperCase()}
                    <br />
                    <br />
                    <br />
                    <br />
                    Name and designation of the person <br />
                    (Authorised Signatory)
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PDFExport>
      </div>
      {preview ? (
        ""
      ) : (
        <CssButton
          onClick={() => {
            handleExportWithFunction();
          }}
          style={{
            background: "rgb(241 154 48)",
            color: "white",
            marginRight: "10px",
            textTransform: "initial !important",
          }}
        >
          Download <DownloadIcon fontSize="small" />
        </CssButton>
      )}
    </>
  );
}

export default SalesAddendumPdf;
