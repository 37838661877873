import React, { useEffect, useState } from "react";
import { useSnackbar } from "../../snackbarContext";
import SalesAddendumPdf from "./SalesAddendumPdf";
import { CssButton } from "../PurchaseOrders/PurchaseOrdersComponent";

import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import { filterData, StopScroll } from "../../Regex";
import moment from "moment";
import { lightGrey, primary, red, white } from "../../styles/colors";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Paper,
  Slide,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
} from "@mui/material";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import ShowSpecifications from "../../layoutComponents/ShowSpecifications";
import {
  CssKeyboardDatePicker,
  CssTextField,
  CssTextField95,
  StyledButton,
} from "../../styles/StyledComponents";
import { CrossIcon, EditIcon } from "../../styles/Icons";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import ReactDOM from "react-dom";
import {
  CustomAutocomplete,
  CustomMultiSelectAutocomplete,
} from "../../layoutComponents/CustomAutocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { Add, Update } from "@material-ui/icons";
import ReactQuill from "react-quill";
import { EndPoints } from "../../utils/EndPoints";
var converter = require("number-to-words");

const styles = {
  editButton: {
    background: "rgb(241 154 48)",
    color: "white",
    marginRight: "10px",
    textTransform: "capitalize",
  },
  container: {
    height: "calc(100% - 10px)",
    width: "fit-content",
    minWidth: "100%",
    overflowX: "auto",
    margin: "45px 0 0 0",
  },
  innerContainer: {
    backgroundColor: "#8080801a",
    padding: "1em 10px",
    borderRadius: "10px",
    width: "100%",
    minWidth: "100%",
  },
  deliveryTermsContainer: {
    backgroundColor: "#8080801a",
    marginTop: 2,
    padding: "1px 10px 10px",
    borderRadius: "10px",
    width: "100%",
    minWidth: "100%",
  },
  tableContainer: (width, edit) => ({
    margin: "0px 0 20px 0",
    height: "auto",
    width: width ? width : edit ? "100%" : "calc(100% - 30px)",
    minWidth: width && "100%",
    fontSize: "0.9rem",
  }),
  tablePaper: {
    height: "auto",
    width: "100%",
    borderRadius: "7px",
  },
  tableInnerContainer: {
    backgroundColor: "#8080801a",
    color: primary,
    borderColor: primary,
    width: "fit-content",
    minWidth: "100%",
    tableLayout: "fixed",
    display: "flex",
    alignItems: "center",
    height: "30px",
    minHeight: "30px",
    overflow: "hidden",
    borderRadius: "7px 7px 0 0",
  },
  tableHeader: (name, widthList, row) => ({
    margin: "12px 10px",
    width:
      widthList && widthList[row]
        ? name === "Specifications"
          ? "180px"
          : name === "Packaging"
          ? "135.49px"
          : widthList[row]
        : "fit-content",
    display: "flex",
    alignItems: "center",
    color: "#100f0f",
    textTransform: "capitalize",
  }),
  editColumn: (width) => ({
    margin: "12px 10px",
    width: width ? width : "65px",
    display: "flex",
    alignItems: "center",
    color: "#100f0f",
    textTransform: "capitalize",
  }),
  tableBody: {
    height: "fit-content",
    overflowY: "auto",
    width: "fit-content",
    minWidth: "100%",
    position: "relative",
    background: "rgb(128 128 128 / 10%)",
  },
  tableInnerBody: (name, widthList) => ({
    margin: "12px 10px",
    width:
      widthList && widthList[name]
        ? name === "Specifications"
          ? "180px"
          : name === "Packaging"
          ? "135.49px"
          : widthList[name]
        : "fit-content",
    minWidth: widthList && widthList[name] ? widthList[name] : "fit-content",
    textTransform: "capitalize",
  }),
  tableBodyEditColumn: {
    margin: "12px 10px",
    width: "65px",
    minWidth: "50px",
    textTransform: "capitalize",
  },
  dialogTitleAction: {
    background: primary,
    color: "white",
    padding: "10px 24px",
  },
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  dialogFieldDiv: {
    display: "flex",
    width: "100%",
    fontSize: "1rem",
    color: "grey",
    background: "white",
    minHeight: "71.91px",
  },
  dialogItemContainer: {
    fontSize: "1rem",
    margin: "20px 0",
    fontWeight: "500",
    justifyContent: "center",
    display: "flex",
  },
  specificationContainer: {
    height: "fit-content",
    width: "100%",
    minWidth: "100%",
    position: "relative",
    borderRadius: "0 0 7px 7px",
    borderRight: "1px solid rgba(128, 128, 128, 0.64)",
    borderLeft: "1px solid rgba(128, 128, 128, 0.64)",
    borderBottom: "1px solid rgba(128, 128, 128, 0.64)",
    padding: "5px 0",
  },
};

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 200, // Set the maximum height for the dropdown
    },
  },
  MenuListProps: {
    style: {
      overflowY: "auto", // Enable vertical scrolling
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const colorArr = [white, lightGrey];

const specificationHeader = [
  {
    name: "Type",
    api: "name",
    value: "name",
  },
  {
    name: "Condition",
    api: "condition",
    value: "name",
  },
  {
    name: "Value",
    api: "value",
  },
];

const MaxMin = [
  { name: "Greater Than", id: "greater_than" },
  { name: "Greater Than Or Equal To", id: "greater_than_or_equal_to" },
  { name: "Less Than", id: "less_than" },
  { name: "Not Equal To", id: "not_equal_to" },
  { name: "Equal To", id: "equal_to" },
  { name: "Less Than Or Equal To", id: "less_than_or_equal_to" },
];

const SOAddendumItemTable = ({
  edit,
  array,
  showTotal,
  width,
  editFunct = "",
  Unit = "",
  soItemheader,
  widthList,
  specifiationTypes,
}) => {
  return (
    <div style={styles.tableContainer(width, edit)}>
      {console.log("array", array)}
      <Paper
        className="d-flex d-flex-column overflowXA positionR scrollBluePrimary"
        style={styles.tablePaper}
      >
        <div style={styles.tableInnerContainer}>
          <div
            className="d-flex alignC justifySE width-100"
            style={{ background: "rgb(128 128 128 / 64%)" }}
          >
            {soItemheader &&
              soItemheader.map((name, i) => (
                <div
                  key={name}
                  className="justifyC d-flex alignC"
                  style={styles.tableHeader(name, widthList, soItemheader[i])}
                >
                  {name}
                </div>
              ))}
            {edit && (
              <div
                className="justifyC d-flex alignC"
                style={styles.editColumn()}
              >
                Edit
              </div>
            )}
          </div>
        </div>
        {array && array.length > 0 ? (
          <>
            <div className="scrollBluePrimary" style={styles.tableBody}>
              {array.map(
                (row, i) =>
                  row && (
                    <div
                      key={`${i}row`}
                      className="d-flex width-100 d-flex-column"
                    >
                      <div className="d-flex width-100 d-flex-column justifySE">
                        <div
                          className="d-flex width-100 justifySE dividerBorder"
                          style={{
                            backgroundColor:
                              colorArr && colorArr[i % colorArr.length],
                            height: "35px",
                          }}
                        >
                          {soItemheader &&
                            soItemheader.map((name) => (
                              <div
                                key={name + "body" + i}
                                className="d-flex alignC justifyC minWidthFC"
                                style={styles.tableInnerBody(name, widthList)}
                              >
                                {name === "Packaging" && row[name] ? (
                                  <ShowPackaging
                                    noTotalbags={true}
                                    returned_bags={false}
                                    noBags={true}
                                    details={
                                      row[`Packaging`] &&
                                      row[`Packaging`].length > 0
                                        ? row[`Packaging`]
                                        : []
                                    }
                                  />
                                ) : name === "Specifications" && row[name] ? (
                                  <ShowSpecifications
                                    details={
                                      row[name] && row[name].length > 0
                                        ? row[name].map((sP) => {
                                            const ss = sP.name;
                                            const filterS =
                                              specifiationTypes.find(
                                                (st) => st.id === ss
                                              );
                                            return {
                                              ...sP,
                                              name: filterS
                                                ? `${filterS.text.toLowerCase()}`
                                                : "",
                                              value: filterS
                                                ? `${
                                                    sP.value
                                                  } ${filterS.unit.toLowerCase()}`
                                                : sP.value,
                                            };
                                          })
                                        : []
                                    }
                                  />
                                ) : row[name] ? (
                                  row[`${name}Unit`] ? (
                                    row[`${name}Unit`]
                                  ) : name === "Item Type" ? (
                                    `${row[name]} %`
                                  ) : (
                                    row[name]
                                  )
                                ) : (
                                  "-"
                                )}
                              </div>
                            ))}
                          {edit && (
                            <div
                              className="d-flex alignC justifyC minWidthFC"
                              style={styles.tableBodyEditColumn}
                            >
                              <StyledButton
                                tooltip="Edit"
                                name={<EditIcon />}
                                width="60px"
                                onClick={() => {
                                  if (editFunct) {
                                    editFunct(row);
                                  }
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
            {showTotal && (
              <div className="d-flex alignC width-100">
                {soItemheader &&
                  soItemheader.map((name, i) => (
                    <div
                      key={name}
                      className="justifyC d-flex alignC"
                      style={styles.tableInnerBody(name, widthList)}
                    >
                      {i === 0 ? (
                        <span style={{ fontWeight: "500" }}>{"Total"}</span>
                      ) : i === 5 ? (
                        `${array.reduce(
                          (previousValue1, currentValue1) =>
                            Number(previousValue1) +
                            (currentValue1["Total Item Quantity"]
                              ? Number(currentValue1["Total Item Quantity"])
                              : 0),
                          0
                        )} ${Unit} `
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                {edit && (
                  <div
                    className="justifyC d-flex alignC"
                    style={styles.editColumn()}
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
            <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>
              No SO Item added !
            </span>
          </div>
        )}
      </Paper>
    </div>
  );
};

const SpecificationTable = ({
  specificationHeader,
  specificationArr,
  specifiationTypes,
  handleResetItemRow,
  handleSpecificationAddition,
}) => {
  const [editSpecificationRow, setEditSpecificationRow] = useState({
    id: "",
    name: null,
    value: "",
    condition: null,
    so_items: null,
  });

  const [editSpecRowId, setEditSpecRowId] = useState(null);
  const [editSpecRowError, setEditSpecRowError] = useState({
    name: "",
    value: "",
    condition: "",
  });

  return (
    <div className="d-flex width-100 flexWrap justifyC">
      <div className="width-100 " style={{ margin: "20px 10px" }}>
        <div style={styles.tableInnerContainer}>
          <div
            className="d-flex alignC justifySE width-100"
            style={{ background: "rgb(128 128 128 / 64%)" }}
          >
            {specificationHeader.map((sC) => (
              <div
                key={sC.name}
                className="justifyC d-flex alignC"
                style={styles.editColumn("calc(25% - 54px)")}
              >
                {sC.name}
              </div>
            ))}
            <div
              className="justifyC d-flex alignC"
              style={styles.editColumn(82)}
            >
              Action
            </div>
          </div>
        </div>
        {console.log("specificationArr", specificationArr)}
        <div style={styles.specificationContainer}>
          {specificationArr &&
            specificationArr.map((sA, i) => (
              <div
                className={`dividerBorder d-flex width-100 justifySE `}
                style={{
                  backgroundColor: colorArr && colorArr[i % colorArr.length],
                  height: "44px",
                }}
              >
                {console.log("sA", sA)}
                {specificationHeader.map((sC) => (
                  <div
                    key={sC.name}
                    className="justifyC d-flex alignC"
                    style={styles.editColumn("calc(25% - 54px)")}
                  >
                    {editSpecRowId !== sA?.id
                      ? sC.name === "Type"
                        ? filterData(
                            specifiationTypes,
                            "id",
                            sA.name,
                            "onlyOne"
                          )?.text || ""
                        : sC.name === "Condition"
                        ? MaxMin.find((d) => d.id === sA.condition)?.name || "-"
                        : sA.value
                      : null}
                  </div>
                ))}
                <div
                  className="justifyC d-flex alignC"
                  style={styles.editColumn(82)}
                  onClick={() => {
                    setEditSpecRowId(sA.id);
                    setEditSpecificationRow({
                      id:
                        sA.id || `${sA.name}-${sA.condition || 0}-${sA.value}`,
                      name: filterData(
                        specifiationTypes,
                        "id",
                        sA.name,
                        "onlyOne"
                      ),
                      value: sA["value"],
                      condition: sA["condition"]
                        ? filterData(MaxMin, "id", sA.condition, "onlyOne")
                        : null,
                      so_items: sA.so_items,
                      operation: "update",
                    });
                  }}
                >
                  <StyledButton
                    name={<EditIcon />}
                    toolTip="Edit"
                    maxHeight="24px"
                    padding="0"
                    height="24px"
                    width="40px"
                    minWidth="0"
                  />
                </div>
              </div>
            ))}
          <div
            className="d-flex width-100 justifySE"
            style={{
              backgroundColor:
                colorArr && colorArr[specificationArr.length % colorArr.length],
              minHeight: "60px",
            }}
          >
            <CustomAutocomplete
              id="Type"
              required={true}
              autoComplete="off"
              options={specifiationTypes || []}
              getOptionLabel={(option) => option.text || ""}
              value={editSpecificationRow?.name || null}
              error={editSpecRowError?.name ? true : false}
              onChange={(e, newValue) => {
                setEditSpecificationRow({
                  ...editSpecificationRow,
                  name: newValue,
                });
              }}
              style={
                { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
              }
              label="Type"
            />
            <CustomAutocomplete
              id="Condition"
              required={
                editSpecificationRow?.name?.value_type === "number"
                  ? true
                  : false
              }
              autoComplete="off"
              disabled={editSpecificationRow?.name?.value_type !== "number"}
              options={
                editSpecificationRow?.name?.value_type === "number"
                  ? MaxMin
                  : []
              }
              getOptionLabel={(option) => option.name || ""}
              value={editSpecificationRow?.condition || null}
              error={editSpecRowError?.condition ? true : false}
              onChange={(e, newValue) => {
                setEditSpecificationRow({
                  ...editSpecificationRow,
                  condition: newValue,
                });
              }}
              style={
                { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
              }
              label={
                editSpecificationRow?.name?.value_type !== "number"
                  ? "No Condition"
                  : "Condition"
              }
            />
            <CssTextField
              required={true}
              type="Value"
              autoComplete="off"
              id="Value"
              label="Value"
              style={
                { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
              }
              variant="outlined"
              name="ShipmentBy"
              value={editSpecificationRow?.value || ""}
              onChange={(e) => {
                setEditSpecificationRow({
                  ...editSpecificationRow,
                  value: e.target.value,
                });
              }}
              error={editSpecRowError?.value ? true : false}
            />
            <div
              className="justifySA d-flex"
              style={{
                margin: "10px",
                width: "82px",
                display: "flex",
                color: "#100f0f",
                textTransform: "capitalize",
              }}
            >
              <StyledButton
                name={<Add />}
                toolTip="Add"
                maxHeight="37.99px"
                padding="0"
                height="37.99px"
                width="40px"
                minWidth="0"
                onClick={() => {
                  if (
                    Object.values(editSpecificationRow).every(
                      (v) => v === "" || v === null || v === undefined
                    )
                  ) {
                    setEditSpecRowError({
                      name: true,
                      condition: true,
                      value: true,
                    });
                    return;
                  }
                  let hasError = false;
                  if (editSpecificationRow?.name) {
                    if (
                      editSpecificationRow?.name?.value_type === "number" &&
                      !editSpecificationRow?.condition
                    ) {
                      setEditSpecRowError((prev) => ({
                        ...prev,
                        condition: true,
                      }));
                      hasError = true;
                    }
                    if (!editSpecificationRow?.value) {
                      setEditSpecRowError((prev) => ({ ...prev, value: true }));
                      hasError = true;
                    }
                  } else if (
                    editSpecificationRow?.condition ||
                    editSpecificationRow?.value
                  ) {
                    setEditSpecRowError((prev) => ({ ...prev, name: true }));
                    hasError = true;
                  }
                  if (
                    editSpecificationRow?.name ||
                    editSpecificationRow?.condition
                  ) {
                    if (!editSpecificationRow?.value) {
                      setEditSpecRowError((prev) => ({ ...prev, value: true }));
                      hasError = true;
                    }
                  }
                  if (!hasError) {
                    let d = { ...editSpecificationRow };
                    setEditSpecificationRow({
                      id: "",
                      name: null,
                      value: "",
                      condition: null,
                      so_items: "",
                    });
                    setEditSpecRowId(null);
                    handleSpecificationAddition(
                      {
                        id:
                          d?.id ||
                          `${d.name?.id}-${d.condition?.id || 0}-${d.value}`,
                        name: d?.name?.id,
                        value: d?.value,
                        condition: d?.condition?.id,
                        so_items: d?.so_items,
                      },
                      "single",
                      d?.id ||
                        `${d.name?.id}-${d.condition?.id || 0}-${d.value}`,
                      d?.operation
                    );
                  }
                }}
              />
              <StyledButton
                name={<CrossIcon color={primary} strokeWidth="2" />}
                toolTip="Clear"
                maxHeight="37.99px"
                onClick={() => {
                  setEditSpecRowId(null);
                  setEditSpecificationRow({
                    id: "",
                    name: null,
                    value: "",
                    condition: null,
                  });
                  setEditSpecRowError({
                    name: false,
                    condition: false,
                    value: false,
                  });
                }}
                padding="0"
                margin="0 0 0 2px"
                height="37.99px"
                width="40px"
                minWidth="0"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        id="update-button"
        className="d-flex alignC justifyFE width-100"
        style={{
          margin: "10px",
        }}
      >
        <StyledButton
          name="clear"
          onClick={() => {
            setEditSpecRowId(null);
            handleResetItemRow();
            setEditSpecRowError({
              name: false,
              condition: false,
              value: false,
            });
            setEditSpecificationRow({
              id: "",
              name: null,
              value: "",
              condition: null,
            });
          }}
        />
        <StyledButton
          onClick={() => {
            setEditSpecRowError({
              name: false,
              condition: false,
              value: false,
            });
            setEditSpecificationRow({
              id: "",
              name: null,
              value: "",
              condition: null,
            });
            setEditSpecRowId(null);
            handleResetItemRow();
            handleSpecificationAddition(specificationArr, "all");
          }}
          name={`Update Item`}
          minWidth="30px"
          width="150px"
          margin="0 10px"
          withBg={true}
        />
      </div>
    </div>
  );
};

const AddendumDetails = ({ label, keyValue, label2, keyValue2 }) => {
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <div style={{ width: "500px" }} className="d-flex">
        <div
          style={{ width: "151.1px" }}
          className="d-flex justifySB fontSize1"
        >
          {label} <div>:</div>
        </div>
        <div
          style={{
            width: "calc(100% - 171.1px)",
            fontSize: "1rem",
            margin: "0 0 0 20px",
            color: "#808080",
          }}
        >
          {keyValue}
        </div>
      </div>
      {label2 && (
        <div style={{ width: "clac(100% - 500px)" }} className="d-flex">
          <div
            style={{ width: "151.1px" }}
            className="d-flex justifySB fontSize1"
          >
            {label2} <div>:</div>
          </div>
          <div
            style={{
              width: "calc(100% - 171.1px)",
              fontSize: "1rem",
              margin: "0 0 0 20px",
              color: label2 === "Status" ? "#f39c12" : "#808080",
            }}
          >
            {keyValue2}
          </div>
        </div>
      )}
    </div>
  );
};

const SalesAddendum = (props) => {
  const showSnackbar = useSnackbar();

  const [addendumData, setAddendumData] = useState({});
  const [editData, setEditData] = useState({});
  const [errorMsg, setErrorMsg] = useState({});
  const [addendumDialog, setAddendumDialog] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [editItemRow, setEditItemRow] = useState({});
  const [itemStep, setItemStep] = useState(0);
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [addendumPayload, setAddendumPayload] = useState({});

  const resetState = () => {
    setAddendumData({});
    setEditData({});
    setErrorMsg({});
    setAddendumDialog(false);
    setPreviewPdf(false);
    setEditItemRow({});
    setItemStep(0);
    setCalenderOpen(false);
  };

  const fetchAddendum = () => {
    props
      .SalesOrdersFunction(
        "get",
        "dispatch/so-addendum",
        props.singlelistView.id,
        null,
        null,
        null,
        true
      )
      .then((resp) => {
        if (resp.error) {
          showSnackbar(resp.error.data, "error");
        } else {
          let data = resp;
          const delivery_terms = [];
          delivery_terms[0] =
            filterData(
              props.masters["CurrencyList"],
              "id",
              Number(data?.currency),
              "onlyOne"
            )?.name || "";
          delivery_terms[1] = [];
          delivery_terms[2] = data?.unit || "";
          delivery_terms[3] =
            filterData(
              props.masters["Incoterms"],
              "id",
              Number(data?.inco_term),
              "onlyOne"
            )?.name || "";
          delivery_terms[4] = data?.discharge_port || "";
          delivery_terms[5] =
            filterData(
              props.masters["ShippingTerms"],
              "id",
              Number(data.shipping_term),
              "onlyOne"
            )?.value || "";
          delivery_terms[6] = [];
          delivery_terms[7] = [];

          data?.so_items.map((so_item) => {
            so_item.packaging.map((pkg) =>
              delivery_terms[6].push(`${pkg.size} ${pkg.unit} ${pkg.bag_type}`)
            );
            delivery_terms[1].push(Number(so_item.contract_rate));
            delivery_terms[7].push(so_item?.extras);
          });

          data.so_delivery_terms = delivery_terms;
          setAddendumData(data);
        }
      });
  };

  const UpdateAddendum = (payload) => {
    if (payload) {
      props
        .SalesOrdersFunction(
          "put",
          "dispatch/so-addendum/update",
          addendumData.id,
          null,
          payload,
          null,
          true
        )
        .then((res) => {
          if (res.error) {
            showSnackbar(res.data, "error");
          } else {
            showSnackbar("So addendum updated successfully", "success");
            resetState();
            fetchAddendum();
          }
        });
    }
  };

  const arrangeItemTableData = (data) => {
    console.log("arrange", data);
    const newData = [];
    let itemsData = data.so_items;
    if (itemsData) {
      itemsData.map((item) => {
        const newItem = {};
        newItem["id"] = item.id;
        newItem["Brand"] = item.brand?.name || "";
        newItem["BrandId"] = item.brand?.id || "";
        newItem["Item Category"] = item?.item_category?.name || "";
        newItem["Item CategoryId"] = item?.item_category?.id || "";
        newItem["Item Type"] =
          Number(item?.item_type?.broken_percentage).toFixed(2) || "";
        newItem["Item TypeId"] = item?.item_type?.id || "";
        newItem["Packaging"] =
          item?.packaging?.length > 0
            ? item.packaging?.map((p) => {
                return {
                  id: p?.id,
                  size: p?.size,
                  unit: p?.unit,
                  bag_type: p?.bag_type,
                };
              })
            : [];
        newItem["packagageArrUsed"] = item?.packaging || "";
        newItem["HSN Code"] = item?.hsn_code?.hsn_code || "";
        newItem["HSN CodeId"] = item?.hsn_code?.id || "";
        newItem["Total Item Quantity"] = item?.total_quantity || "";
        newItem["Total Item QuantityUnit"] =
          (item?.total_quantity || "") + " " + (data?.unit || "");
        newItem["Contract Rate"] = item?.contract_rate || "";
        newItem["Contract RateUnit"] =
          (item?.contract_rate || "") + "/" + (data?.unit || "");
        newItem["Buyer Marking"] = item?.buyers_marking || "";
        newItem["Specifications"] =
          item?.so_specifications?.length > 0 ? item.so_specifications : [];
        newItem["Quantity Tolerance"] = item?.quantity_tolerance || "";
        newItem["Bag Weight"] = item?.bag_weight || "";

        newData.push(newItem);
      });
    }
    return newData;
  };

  const handleAddendumEditData = (data) => {
    if (data) {
      fetchPolicy(data.inco_term, data.shipping_term);
      let dialogData = {
        // ...data,
        id: data?.id,
        Unit: filterData(props.unit, "name", data.unit, "onlyOne"),
        Incoterm: filterData(
          props.masters["Incoterms"],
          "id",
          Number(data.inco_term),
          "onlyOne"
        ),
        ShippingTerms: filterData(
          props.masters["ShippingTerms"],
          "id",
          Number(data.shipping_term),
          "onlyOne"
        ),
        portArry: data.load_ports.length
          ? data.load_ports.map((l) =>
              l !== "Any Indian Port"
                ? filterData(portsList, "id", Number(l), "onlyOne")
                : { nameCity: "Any Indian Port", id: "Any Indian Port" }
            )
          : [],
        DischargePort: data?.discharge_port,
        Currency: filterData(
          props["CurrencyList"],
          "id",
          Number(data.currency),
          "onlyOne"
        ),
        ShipmentDate: data.shipment_date,
        ShipmentBy: data.shipment_by,
        extras: data.so_items[0]?.extras || "",
        duty_payment_term: data.duty_payment_term,
        policy_of_so_policy: data.policy_of_so_policy,
        so_policy: data.so_policy,
        so_items: data.so_items,
      };
      setEditData(dialogData);
    }
  };

  const handleDialogItem = (field, data) => {
    setEditItemRow((prev) => {
      return {
        ...prev,
        [field]: data,
      };
    });
  };

  const handleResetItemRow = () => {
    setEditItemRow({});
    setItemStep(0);
  };

  const handleSpecificationAddition = (data, type, id, updatingRow = "") => {
    console.log(
      "handleSpecificationAddition",
      data,
      type,
      id,
      updatingRow,
      editItemRow.so_specifications
    );
    if (type === "single") {
      let so_specifications = [...editItemRow.so_specifications];
      if (id) {
        const existingIndex = so_specifications.findIndex(
          (spec) => spec.id === data.id
        );
        console.log("data", data, type, id, existingIndex);
        if (existingIndex !== -1) {
          so_specifications[existingIndex] = data;
        } else {
          so_specifications.push(data);
        }
      } else {
        if (updatingRow === "update") {
          const index = so_specifications.findIndex(
            (spec) =>
              spec.name === data.name ||
              spec.id === data.id ||
              spec.value === data.value
          );

          if (index !== -1) {
            so_specifications[index] = data;
          } else {
            so_specifications.push(data);
          }
        } else {
          so_specifications.push(data);
        }
      }

      setEditItemRow({
        ...editItemRow,
        so_specifications,
      });
    } else {
      console.log("UPDATE ITEM", editItemRow, editData, data);
      let EditDialogData = {
        so_items: editData.so_items.map((item) => {
          if (item.id === editItemRow.id) {
            return {
              ...editItemRow,
              brand: {
                id: editItemRow.brand_data?.id,
                name: editItemRow.brand_data?.brand_name,
              },
              item_category: editItemRow.item_category_data,
              item_type: editItemRow.item_type_data,
              packaging: editItemRow.packagingArr,
              hsn_code: editItemRow.hsn_code_data,
            };
          }
          return item;
        }),
        ...Object.keys(editData).reduce((acc, key) => {
          if (key !== "so_items") {
            acc[key] = editData[key];
          }
          return acc;
        }, {}),
      };
      console.log("EditDialogData", EditDialogData);
      setEditData(EditDialogData);
      setEditItemRow({});
    }
  };

  const checkItemRowField = () => {
    return true;
  };

  const handleDialogUpdateItem = (data) => {
    console.log(data);
    setEditItemRow({
      id: data.id,
      brand_data: filterData(props["BrandList"], "id", data.BrandId, "onlyOne"),
      item_category_data: filterData(
        props.masters["ItemCateoryList"],
        "id",
        data["Item CategoryId"],
        "onlyOne"
      ),
      item_type_data: filterData(
        props["ItemList"],
        "id",
        data["Item TypeId"],
        "onlyOne"
      ),
      packagingArr: data["Packaging"]?.length
        ? data["Packaging"].map((p) => {
            return { ...p, sizeUnit: `${p.size}${p.unit} - ${p.bag_type}` };
          })
        : [],
      hsn_code_data: filterData(
        props.masters["HSNlist"],
        "id",
        data["HSN CodeId"],
        "onlyOne"
      ),
      total_quantity: data["Total Item Quantity"],
      contract_rate: data["Contract Rate"],
      buyers_marking: data["Buyer Marking"],
      quantity_tolerance: data["Quantity Tolerance"],
      bag_weight: data["Bag Weight"],
      so_specifications: data.Specifications,
    });
  };

  const handleAddendumChange = (field, value) => {
    console.log(field, value);
    if (field === "load_ports") {
      const loadPortIds = value.map((port) => port.id);
      setEditData((prev) => ({
        ...prev,
        [field]: loadPortIds,
      }));
    } else {
      setEditData((prev) => ({
        ...prev,
        [field]: value,
      }));
    }

    if (field === "Incoterm") {
      fetchPolicy(value.id, editData["ShippingTerms"]?.id);
    } else if (field === "ShippingTerms") {
      fetchPolicy(editData["Incoterm"]?.id, value.id);
    }
  };

  const scrollDiv = (id) => {
    console.log(id, "lllllllllllllllllllllllllll");
    const chat = document.getElementById(id);
    chat.scrollIntoView();
  };

  const fetchPolicy = (iC, sC) => {
    console.log(iC, sC, "fetchPolicy");
    if (iC && sC) {
      props
        .SalesOrdersFunction(
          "get",
          EndPoints["Policys"],
          null,
          "policy",
          null,
          {
            inco_term: iC,
            shipping_term: sC,
          }
        )
        .then((soPolicy) => {
          let soPolicyData = soPolicy.find(
            (s) => s.inco_term == iC && s.shipping_term == sC
          );
          console.log(soPolicy, soPolicyData, editData, iC, sC, "soPolicy");
          if (soPolicyData) {
            handleAddendumChange("so_policy", soPolicyData?.id);
            handleAddendumChange("policy_of_so_policy", soPolicyData?.policy);
          }
        });
    }
  };

  const handleDeliveryTermsText = (incoTerms, loadPortArr, dischargePort) => {
    console.log(editData, incoTerms, loadPortArr, dischargePort);
    if (incoTerms === "FOB") {
      let data = loadPortArr
        ?.map((port) => {
          const portData = filterData(
            props.portsList,
            "id",
            Number(port),
            "onlyOne"
          );
          return portData ? portData?.name : portData;
        })
        .join(", ");
      let suffixData = loadPortArr?.some((port) => {
        return port === "Any Indian Port";
      });
      return data + (suffixData ? "" : ", INDIA");
    } else {
      return dischargePort;
    }
  };

  const compareArraysById = (arrayA, arrayB) => {
    // Normalize both arrays to strings for consistent comparison
    const normalizedA = arrayA?.map(String);
    const normalizedB = arrayB?.map(String);

    console.log(arrayA, arrayB, normalizedA, normalizedB);
    // Check if both arrays contain the same elements (order doesn't matter)
    const areEqual =
      normalizedA.length === normalizedB.length &&
      normalizedA.every((value) => normalizedB.includes(value));

    return areEqual;
  };

  const handleSalesOrderChangeCheck = (edit, old) => {
    let Keys = [
      { editKey: "Unit", editKey2: "name", oldKey: "unit" },
      { editKey: "Incoterm", editKey2: "id", oldKey: "inco_term" },
      { editKey: "ShippingTerms", editKey2: "id", oldKey: "shipping_term" },
      { editKey: "DischargePort", oldKey: "discharge_port" },
      { editKey: "Currency", editKey2: "id", oldKey: "currency" },
      { editKey: "ShipmentDate", oldKey: "shipment_date" },
      { editKey: "ShipmentBy", oldKey: "shipment_by" },
      { editKey: "duty_payment_term", oldKey: "duty_payment_term" },
      { editKey: "so_policy", oldKey: "so_policy" },
      { editKey: "policy_of_so_policy", oldKey: "policy_of_so_policy" },
    ];

    let changedData = {};
    Keys.forEach((key) => {
      let editValue = key.editKey2
        ? edit[key.editKey]?.[key.editKey2]
        : edit[key.editKey];
      if (String(editValue) !== String(old[key.oldKey])) {
        console.log(
          "sales_order_change",
          key.oldKey,
          editValue,
          old[key.oldKey]
        );
        changedData[key.oldKey] = editValue;
      }
    });

    let load_ports = compareArraysById(
      editData.portArry.map((p) => p.id),
      old.load_ports
    );
    if (!load_ports) {
      changedData["load_ports"] = editData.portArry.map((p) => p.id);
    }

    const oldChanges = old.so_addendum?.sales_order || {};
    const mergedData = { ...oldChanges, ...changedData };

    return mergedData;
  };

  const handleSoItemsChangesCheck = (editData, oldData) => {
    let Keys = [
      { key1: "brand", key2: "id" },
      { key1: "item_category", key2: "id" },
      { key1: "item_type", key2: "id" },
      { key1: "hsn_code", key2: "id" },
      { key1: "total_quantity" },
      { key1: "contract_rate" },
      { key1: "buyers_marking" },
      { key1: "quantity_tolerance" },
      { key1: "bag_weight" },
    ];

    let changedSoData = [];

    editData.forEach((edit) => {
      const old = oldData.find((o) => o.id === edit.id);

      let changedData = {};
      Keys.forEach((key) => {
        let editValue = key.key2 ? edit[key.key1]?.[key.key2] : edit[key.key1];
        let oldValue = key.key2 ? old[key.key1]?.[key.key2] : old[key.key1];
        if (String(editValue) !== String(oldValue)) {
          changedData[key.key1] = editValue;
        }
      });

      let packaging = compareArraysById(
        edit.packaging && edit.packaging?.map((p) => p.id),
        old.packaging && old.packaging?.map((d) => d.id)
      );

      console.log(
        changedData,
        "changedData",
        edit,
        old,
        edit.packaging,
        old.packaging,
        packaging,
        edit.packaging?.map((p) => p.id)
      );
      if (!packaging) {
        changedData["packaging"] = edit.packaging?.map((p) => p.id);
      }

      let so_specifications = [];
      if (edit.so_specifications && old.so_specifications) {
        edit.so_specifications.forEach((spec) => {
          const oldSpec = old.so_specifications.find((o) => o.id === spec.id);
          if (oldSpec) {
            if (
              String(spec.name) !== String(oldSpec.name) ||
              String(spec.value) !== String(oldSpec.value) ||
              String(spec.condition) !== String(oldSpec.condition)
            ) {
              so_specifications.push({
                id: spec.id,
                name: spec.name,
                value: spec.value,
                condition: spec.condition,
                so_items: edit.id,
              });
            }
          } else {
            so_specifications.push({
              id: spec.id.includes("-") ? null : spec.id,
              name: spec.name,
              value: spec.value,
              condition: spec.condition,
              so_items: edit.id,
            });
          }
        });
      }
      if (so_specifications.length > 0) {
        changedData["so_specifications"] = so_specifications;
      }

      if (Object.keys(changedData).length > 0) {
        const oldChanges = old.so_addendum?.so_items || [];
        const existingChange =
          oldChanges.find((item) => item.id === edit.id) || {};
        const mergedChanges = { ...existingChange, ...changedData };
        changedSoData.push({ id: edit.id, ...mergedChanges });
      }
    });

    return changedSoData;
  };

  const handleProceedClick = () => {
    let Payload = {};
    let sales_order = handleSalesOrderChangeCheck(editData, addendumData);
    let so_items = handleSoItemsChangesCheck(
      editData.so_items,
      addendumData.so_items
    );

    Payload["sales_order"] = sales_order;
    Payload["so_items"] = so_items;

    console.log(editData, addendumData, Payload, "handleProceedClick");

    if (Object.keys(Payload).length > 0) {
      Payload["addendum_date"] = moment(new Date()).format("YYYY-MM-DD");
    }
    {
      setAddendumPayload(Payload);
    }
  };

  useEffect(() => {
    setPreviewPdf(true);
  }, [addendumPayload]);

  useEffect(() => {
    fetchAddendum();
  }, []);

  const {
    singlelistView,
    specifiationTypes,
    soItemsArrayTOPreview,
    masters,
    portsList,
    soItemheader,
    widthList,
    unit,
    BrandList,
    ItemCateoryList,
    ItemList,
  } = props;

  console.log(props);

  return (
    <div
      className="width-100 d-flex d-flex-column"
      style={{ padding: "15px 0" }}
    >
      <div className="positionAB width-100 d-flex justifySB alignC">
        <div className="textTU fontWeightBold" style={{ margin: "0 10px 0 0" }}>
          {singlelistView && singlelistView.so_type
            ? `SO Type : ${singlelistView.so_type}`
            : ""}
        </div>

        <div className="d-flex">
          {singlelistView.is_editable === true ? (
            <>
              <CssButton
                style={styles.editButton}
                onClick={() => {
                  handleAddendumEditData(addendumData);
                  setAddendumDialog("edit");
                }}
              >
                Edit
                <ModeOutlinedIcon
                  fontSize="small"
                  style={{ height: "17px", margin: "0 0 3px 0" }}
                />
              </CssButton>
            </>
          ) : null}

          <SalesAddendumPdf
            {...props}
            specifiationTypes={specifiationTypes}
            tableList={soItemsArrayTOPreview}
            newAddendumData={addendumData}
            newAddendumChanges={addendumData?.so_addendum}
            handleDeliveryTermsText={handleDeliveryTermsText}
          />
        </div>
      </div>
      <div style={styles.container} className="scrollBluePrimary">
        <div style={styles.innerContainer}>
          <AddendumDetails
            label="Unit"
            keyValue={addendumData?.unit || ""}
            label2="Total Quantity"
            keyValue2={`${addendumData?.total_quantity || ""} ${
              addendumData?.unit || ""
            }`}
          />
          <AddendumDetails
            label="Discharge Port"
            keyValue={addendumData?.discharge_port || ""}
            label2="Load Port"
            keyValue2={
              addendumData?.load_ports
                ?.map((sP) =>
                  sP !== "Any Indian Port"
                    ? filterData(portsList, "id", Number(sP), "onlyOne")
                        ?.nameCity || ""
                    : "Any Indian Port"
                )
                .join(", ") || ""
            }
          />
          <AddendumDetails
            label="Incoterm"
            keyValue={
              filterData(
                masters["Incoterms"],
                "id",
                Number(addendumData?.inco_term),
                "onlyOne"
              )?.name || ""
            }
            label2="Shipping Terms"
            keyValue2={
              filterData(
                masters["ShippingTerms"],
                "id",
                Number(addendumData?.shipping_term),
                "onlyOne"
              )?.value || ""
            }
          />
          <AddendumDetails
            label="Shipment Date"
            keyValue={
              addendumData?.shipment_date
                ? moment(addendumData.shipment_date).format("DD MMM YYYY")
                : ""
            }
            label2="Shipment By"
            keyValue2={addendumData?.shipment_by || ""}
          />
        </div>

        <div
          style={{
            margin: "10px 0 0 0",
            width: "100%",
            minWidth: "100%",
          }}
        >
          <SOAddendumItemTable
            edit={false}
            array={arrangeItemTableData(addendumData)}
            showTotal={false}
            width="fit-content"
            soItemheader={soItemheader}
            widthList={widthList}
            specifiationTypes={specifiationTypes}
          />
        </div>

        <div style={styles.deliveryTermsContainer}>
          <div style={{ width: "100%", display: "flex", marginTop: 10 }}>
            <div style={{ width: "100%" }} className="d-flex">
              <div
                className="d-flex justifySB fontSize09"
                style={{ width: 120 }}
              >
                Delivery Terms <div>:</div>
              </div>
              <div style={{ width: "calc(100% - 130px)", marginLeft: 30 }}>
                {addendumData.so_delivery_terms &&
                addendumData.so_delivery_terms.length > 0
                  ? addendumData.so_delivery_terms[1] &&
                    addendumData.so_delivery_terms[1].length > 0
                    ? addendumData.so_delivery_terms[1]?.map(
                        (singleItem, i) => (
                          <div
                            style={{
                              color: "black",
                              textAlign: "left",
                              width: "100%",
                            }}
                          >
                            {`${
                              addendumData.so_delivery_terms[0]
                            } ${singleItem}/${
                              addendumData.so_delivery_terms[2]
                            } ${
                              addendumData.so_delivery_terms[3]
                            }, ${handleDeliveryTermsText(
                              addendumData.so_delivery_terms[3],
                              addendumData.load_ports,
                              addendumData.so_delivery_terms[4]
                            )} 
                                        ${addendumData?.so_delivery_terms[5]}
                                        ${
                                          addendumData.so_delivery_terms[6][i]
                                            ? ` ${addendumData.so_delivery_terms[6][i]} `
                                            : "."
                                        }
                                        ${
                                          addendumData.so_delivery_terms[7][i]
                                            ? ` ${addendumData.so_delivery_terms[7][i]} `
                                            : "."
                                        }`}
                          </div>
                        )
                      )
                    : ""
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      {addendumDialog ? (
        <Dialog
          ref={(e) => {
            try {
              const target = ReactDOM.findDOMNode(e).children[2].children[0];
              target.style.borderRadius = "0px";
              target.style.maxHeight = "calc(100vh - 62px)";
              target.style.minHeight = "calc(100vh - 62px)";
              target.style.height = "calc(100vh - 62px)";
              target.style.minWidth = "calc(100% - 62px)";
              target.style.maxWidth = "calc(100% - 62px)";
              target.style.margin = "32px";
            } catch (e) {}
          }}
          open={true}
          onClose={() => {
            setEditData({});
            setErrorMsg({});
            setAddendumDialog(false);
            setPreviewPdf(false);
            setEditItemRow({});
            setItemStep(0);
            setCalenderOpen(false);
          }}
          TransitionComponent={Transition}
        >
          <DialogActions
            className="d-flex justifySB"
            style={styles.dialogTitleAction}
          >
            <h3 style={styles.heading}>
              <DiscountOutlinedIcon
                style={{ color: "white", margin: "0 5px 0 0" }}
              />
              Edit SO Addendum
            </h3>
            <Tooltip title="close" arrow placement="top">
              <IconButton
                style={{ padding: "5px", borderRadius: "5px" }}
                onClick={() => {
                  setEditData({});
                  setErrorMsg({});
                  setAddendumDialog(false);
                  setPreviewPdf(false);
                  setEditItemRow({});
                  setItemStep(0);
                  setCalenderOpen(false);
                }}
              >
                <CrossIcon strokeWidth="2" />
              </IconButton>
            </Tooltip>
          </DialogActions>
          <DialogContent
            className="dashedDividerBottom"
            style={{ height: "100%" }}
          >
            {console.log(editData, "editData")}
            {previewPdf ? (
              <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
                <SalesAddendumPdf
                  preview={true}
                  specifiationTypes={specifiationTypes}
                  tableList={editData.so_items}
                  {...props}
                  newAddendumData={addendumData}
                  newAddendumChanges={addendumPayload}
                  onClose={() => {}}
                  widthList={["20px", "100px", "50px", "70px", "70px", "200px"]}
                  handleDeliveryTermsText={handleDeliveryTermsText}
                />
              </div>
            ) : (
              <>
                <div style={styles.dialogFieldDiv}>
                  <CustomAutocomplete
                    id="unit"
                    required={true}
                    autoComplete="off"
                    options={unit}
                    getOptionLabel={(option) => option.name}
                    value={editData?.Unit ? editData?.Unit : null}
                    error={errorMsg.Unit ? true : false}
                    helperText={errorMsg.Unit ? errorMsg.Unit : ""}
                    onChange={(e, newValue) => {
                      handleAddendumChange("Unit", newValue);
                    }}
                    style={
                      { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    }
                    label="Unit"
                  />
                  <CustomAutocomplete
                    id="Incoterm"
                    required={true}
                    autoComplete="off"
                    options={masters["Incoterms"] || []}
                    getOptionLabel={(option) => option.name}
                    value={editData?.Incoterm ? editData?.Incoterm : null}
                    error={errorMsg.Incoterm ? true : false}
                    helperText={errorMsg.Incoterm ? errorMsg.Incoterm : ""}
                    onChange={(e, newValue) => {
                      handleAddendumChange("Incoterm", newValue);
                    }}
                    style={
                      { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    }
                    label="Incoterm"
                  />
                  <CustomAutocomplete
                    id="ShippingTerms"
                    required={true}
                    autoComplete="off"
                    options={masters["ShippingTerms"] || []}
                    getOptionLabel={(option) => option.value}
                    value={
                      editData?.ShippingTerms ? editData?.ShippingTerms : null
                    }
                    error={errorMsg.ShippingTerms ? true : false}
                    helperText={
                      errorMsg.ShippingTerms ? errorMsg.ShippingTerms : ""
                    }
                    onChange={(e, newValue) => {
                      handleAddendumChange("ShippingTerms", newValue);
                    }}
                    style={
                      { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    }
                    label="ShippingTerms"
                  />
                  <CustomMultiSelectAutocomplete
                    id="portArry"
                    required={true}
                    autoComplete="off"
                    multiple
                    options={portsList || []}
                    getOptionLabel={(option) =>
                      option?.nameCity ? option?.nameCity : ""
                    }
                    value={editData?.portArry ? editData?.portArry : null}
                    error={errorMsg?.portArry ? true : false}
                    helperText={errorMsg?.portArry ? errorMsg?.portArry : ""}
                    onChange={(e, newValue) => {
                      const filteredVal = newValue.filter((port) => {
                        if (newValue.some((p) => p.id === "Any Indian Port")) {
                          return port.id === "Any Indian Port";
                        }
                        return true;
                      });
                      handleAddendumChange("portArry", filteredVal);
                    }}
                    style={{ width: "calc(25% - 20px)", margin: "10px" }}
                    optionKey="nameCity"
                    label="Load Port"
                  />
                </div>
                <div style={styles.dialogFieldDiv}>
                  <CssTextField
                    autoComplete="off"
                    style={
                      { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    }
                    error={errorMsg?.DischargePort ? true : false}
                    required={true}
                    helperText={
                      errorMsg?.DischargePort
                        ? "Please add Discharge Port!"
                        : false
                    }
                    InputProps={{}}
                    id="DischargePort"
                    label="Discharge Port"
                    variant="outlined"
                    name="DischargePort"
                    value={
                      editData?.DischargePort ? editData?.DischargePort : ""
                    }
                    onChange={(e) => {
                      handleAddendumChange("DischargePort", e.target.value);
                    }}
                  />
                  <CustomAutocomplete
                    id="Currency"
                    required={true}
                    autoComplete="off"
                    options={masters["CurrencyList"] || []}
                    getOptionLabel={(option) =>
                      option.symbol
                        ? `${option.name}  (${option.symbol})`
                        : option.name
                    }
                    value={editData?.Currency ? editData?.Currency : null}
                    error={errorMsg.Currency ? true : false}
                    helperText={errorMsg.Currency ? errorMsg.Currency : ""}
                    onChange={(e, newValue) => {
                      handleAddendumChange("Currency", newValue);
                    }}
                    style={
                      { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    }
                    label="Currency"
                  />
                  <MuiPickersUtilsProvider
                    libInstance={moment()}
                    utils={DateFnsUtils}
                  >
                    <CssKeyboardDatePicker
                      label={"Shipment Date"}
                      variant="inline"
                      onOpen={() => {
                        setCalenderOpen(true);
                      }}
                      onClose={() => {
                        setCalenderOpen(false);
                      }}
                      error={false}
                      invalidLabel=""
                      onKeyDown={(evt) => {
                        evt.keyCode === 8 && evt.preventDefault();
                      }}
                      onKeyPress={(ev) => {
                        ev.preventDefault();
                      }}
                      allowKeyboardControl={false}
                      keyboardIcon={<InsertInvitationIcon />}
                      autoOk
                      inputVariant="outlined"
                      style={
                        { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                      }
                      format="dd MMM yyyy"
                      id="ShipmentDate"
                      value={
                        editData?.ShipmentDate
                          ? editData?.ShipmentDate
                          : new Date()
                      }
                      InputAdornmentProps={{ position: "end" }}
                      onChange={(newValue) => {
                        handleAddendumChange(
                          "ShipmentDate",
                          moment(newValue).format("YYYY-MM-DD")
                        );
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <CssTextField
                    required={true}
                    type="text"
                    autoComplete="off"
                    error={errorMsg.ShipmentBy ? true : false}
                    helperText={
                      errorMsg.ShipmentBy ? errorMsg.ShipmentBy : false
                    }
                    id="ShipmentBy"
                    label="Shipment By"
                    style={
                      { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                    }
                    variant="outlined"
                    name="ShipmentBy"
                    value={editData?.ShipmentBy ? editData?.ShipmentBy : ""}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 20);
                    }}
                    onChange={(e) => {
                      handleAddendumChange("ShipmentBy", e.target.value);
                    }}
                  />
                </div>
                <div style={styles.dialogItemContainer}>
                  Add Multiple SO Items
                </div>
                <div
                  style={{ border: "1px solid rgba(128, 128, 128, 0.64)" }}
                  className="borderRadius7"
                  id="item"
                >
                  <SOAddendumItemTable
                    edit={true}
                    array={arrangeItemTableData(editData)}
                    showTotal={true}
                    width={null}
                    soItemheader={soItemheader}
                    widthList={widthList}
                    specifiationTypes={specifiationTypes}
                    editFunct={handleDialogUpdateItem}
                    Unit={editData?.Unit?.name}
                  />
                  {console.log("EDITROW:", editItemRow)}
                  {editItemRow?.id ? (
                    <div style={{ margin: "20px", padding: "10px" }}>
                      {itemStep === 0 ? (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              fontSize: "1rem",
                              color: "grey",
                              background: "white",
                              width: "100%",
                              minHeight: "71.91px",
                            }}
                          >
                            <CustomAutocomplete
                              id="Brand"
                              required={true}
                              autoComplete="off"
                              options={
                                (BrandList &&
                                  BrandList?.sort(
                                    (a, b) =>
                                      (Boolean(a.item_category) ||
                                        Boolean(a.item_type)) -
                                      (Boolean(b.item_category) ||
                                        Boolean(b.item_type))
                                  )) ||
                                []
                              }
                              getOptionLabel={(option) =>
                                option?.item_category && option?.item_type
                                  ? `${option?.brand_name}-${
                                      filterData(
                                        ItemCateoryList,
                                        "id",
                                        option.item_category,
                                        "onlyName"
                                      ).name || ""
                                    }-${
                                      filterData(
                                        ItemList,
                                        "id",
                                        option.item_type,
                                        "onlyName"
                                      ).broken_percentage || ""
                                    } %`
                                  : `${option.brand_name}`
                              }
                              value={
                                editItemRow?.brand_data
                                  ? editItemRow?.brand_data
                                  : null
                              }
                              error={errorMsg.brand_data ? true : false}
                              helperText={
                                errorMsg.brand ? errorMsg.brand_data : ""
                              }
                              onChange={(e, newValue) => {
                                handleDialogItem("brand_data", newValue);
                              }}
                              style={
                                { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                              }
                              label="Brand"
                            />
                            <CustomAutocomplete
                              id="ItemCategory"
                              required={true}
                              autoComplete="off"
                              options={ItemCateoryList || []}
                              getOptionLabel={(option) => option?.name || ""}
                              value={
                                editItemRow?.item_category_data
                                  ? editItemRow?.item_category_data
                                  : null
                              }
                              error={errorMsg.item_category_data ? true : false}
                              helperText={
                                errorMsg.item_category_data
                                  ? errorMsg.item_category_data
                                  : ""
                              }
                              onChange={(e, newValue) => {
                                handleDialogItem(
                                  "item_category_data",
                                  newValue
                                );
                              }}
                              style={
                                { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                              }
                              label="ItemCategory"
                            />
                            <CustomAutocomplete
                              id="ItemType"
                              required={true}
                              autoComplete="off"
                              options={ItemList || []}
                              getOptionLabel={(option) =>
                                option?.broken_percentage + " %" || ""
                              }
                              value={
                                editItemRow?.item_type_data
                                  ? editItemRow?.item_type_data
                                  : null
                              }
                              error={errorMsg.item_type_data ? true : false}
                              helperText={
                                errorMsg.item_type_data
                                  ? errorMsg.item_type_data
                                  : ""
                              }
                              onChange={(e, newValue) => {
                                handleDialogItem("item_type_data", newValue);
                              }}
                              style={
                                { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                              }
                              label="ItemType"
                            />
                            <CustomMultiSelectAutocomplete
                              id="packagingArr"
                              required={true}
                              autoComplete="off"
                              multiple
                              options={
                                masters["PackagingList"] &&
                                masters["PackagingList"].length > 0
                                  ? masters["PackagingList"].map((sP) => {
                                      sP.sizeUnit = `${sP.size}${sP.unit} - ${sP.bag_type}`;
                                      return sP;
                                    })
                                  : []
                              }
                              getOptionLabel={(option) =>
                                option?.sizeUnit ? option?.sizeUnit : ""
                              }
                              value={
                                editItemRow?.packagingArr
                                  ? editItemRow?.packagingArr
                                  : null
                              }
                              error={errorMsg?.packagingArr ? true : false}
                              helperText={
                                errorMsg?.packagingArr
                                  ? errorMsg?.packagingArr
                                  : ""
                              }
                              onChange={(e, newValue) => {
                                handleDialogItem("packagingArr", newValue);
                              }}
                              style={{
                                width: "calc(25% - 20px)",
                                margin: "10px",
                              }}
                              optionKey="sizeUnit"
                              label="Packaging"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              fontSize: "1rem",
                              color: "grey",
                              background: "white",
                              width: "100%",
                              minHeight: "71.91px",
                            }}
                          >
                            <CustomAutocomplete
                              id="HSN"
                              required={true}
                              autoComplete="off"
                              options={masters["HSNlist"] || []}
                              getOptionLabel={(option) =>
                                option?.hsn_code || ""
                              }
                              value={
                                editItemRow?.hsn_code_data
                                  ? editItemRow?.hsn_code_data
                                  : null
                              }
                              error={errorMsg.hsn_code_data ? true : false}
                              helperText={
                                errorMsg.hsn_code_data
                                  ? errorMsg.hsn_code_data
                                  : ""
                              }
                              onChange={(e, newValue) => {
                                handleDialogItem("hsn_code_data", newValue);
                              }}
                              style={
                                { width: "calc(25% - 20px)", margin: "10px" } //disabled={tp}
                              }
                              label="HSN"
                            />
                            <CssTextField
                              required={true}
                              type="number"
                              onFocus={StopScroll}
                              autoComplete="off"
                              error={errorMsg.total_quantity ? true : false}
                              helperText={
                                errorMsg.total_quantity
                                  ? errorMsg.total_quantity
                                  : false
                              }
                              id="outlined-basic"
                              label="Total Item Quantity"
                              onKeyDown={(evt) => {
                                evt.key === "e" && evt.preventDefault();

                                evt.key === "E" && evt.preventDefault();
                                evt.key === "-" && evt.preventDefault();
                                evt.keyCode === 38 && evt.preventDefault();
                                evt.keyCode === 40 && evt.preventDefault();
                              }}
                              style={{
                                width: "calc(25% - 20px)",
                                margin: "10px",
                              }}
                              InputProps={{
                                endAdornment: editData?.Unit?.name || "",
                              }}
                              variant="outlined"
                              name="total_quantity"
                              value={
                                editItemRow?.total_quantity
                                  ? editItemRow?.total_quantity
                                  : ""
                              }
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .toString()
                                  .slice(0, 20);
                              }}
                              onChange={(e) => {
                                handleDialogItem(
                                  "total_quantity",
                                  e.target.value
                                );
                              }}
                            />
                            <CssTextField
                              required={true}
                              type="number"
                              onFocus={StopScroll}
                              autoComplete="off"
                              style={
                                {
                                  width: "calc(25% - 20px)",
                                  margin: "10px",
                                } //disabled={tp}
                              }
                              onKeyDown={(evt) => {
                                evt.key === "e" && evt.preventDefault();

                                evt.key === "E" && evt.preventDefault();
                                evt.key === "-" && evt.preventDefault();
                                evt.keyCode === 38 && evt.preventDefault();
                                evt.keyCode === 40 && evt.preventDefault();
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    /{editData?.Unit?.name || ""}
                                  </InputAdornment>
                                ),
                              }}
                              error={errorMsg.contract_rate ? true : false}
                              helperText={
                                errorMsg.contract_rate
                                  ? "Please Add Rate!"
                                  : false
                              }
                              id="outlined-basic"
                              label="Contract Rate"
                              variant="outlined"
                              value={editItemRow?.contract_rate || ""}
                              onChange={(e) => {
                                handleDialogItem(
                                  "contract_rate",
                                  e.target.value
                                );
                              }}
                            />
                            <CssTextField
                              required={true}
                              autoComplete="off"
                              value={
                                editItemRow?.buyers_marking
                                  ? editItemRow?.buyers_marking
                                  : "" //
                              }
                              error={errorMsg.buyers_marking ? true : false}
                              helperText={
                                errorMsg.buyers_marking
                                  ? "Please add Buyer Marking"
                                  : false
                              }
                              onChange={(e) => {
                                handleDialogItem(
                                  "buyers_marking",
                                  e.target.value
                                );
                              }}
                              style={
                                {
                                  width: "calc(25% - 20px)",
                                  margin: "10px",
                                } //disabled={tp}
                              }
                              id="outlined-select-currency-native"
                              label="Buyer’s marking"
                              variant="outlined"
                              InputLabelProps={{}}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              fontSize: "1rem",
                              color: "grey",
                              background: "white",
                              width: "100%",
                              minHeight: "71.91px",
                            }}
                          >
                            <CssTextField
                              required={false}
                              autoComplete="off"
                              value={
                                editItemRow?.quantity_tolerance
                                  ? editItemRow?.quantity_tolerance
                                  : "" //
                              }
                              error={
                                errorMsg?.quantity_tolerance ? true : false
                              }
                              helperText={
                                errorMsg?.quantity_tolerance
                                  ? "Please add Bag Weight "
                                  : false
                              }
                              onChange={(e) => {
                                handleDialogItem(
                                  "quantity_tolerance",
                                  e.target.value
                                );
                              }}
                              style={{
                                width: "calc(25% - 20px)",
                                margin: "10px",
                              }}
                              id="outlined-select-currency-native"
                              label="Quantity Tolerance"
                              variant="outlined"
                              InputLabelProps={{}}
                            />
                            <CssTextField
                              required={false}
                              autoComplete="off"
                              value={
                                editItemRow?.bag_weight
                                  ? editItemRow?.bag_weight
                                  : "" //
                              }
                              error={errorMsg?.bag_weight ? true : false}
                              helperText={
                                errorMsg?.bag_weight
                                  ? "Please add Bag Weight "
                                  : false
                              }
                              onChange={(e) => {
                                handleDialogItem("bag_weight", e.target.value);
                              }}
                              style={{
                                width: "calc(25% - 20px)",
                                margin: "10px",
                              }}
                              id="outlined-select-currency-native"
                              label="Bag Weight"
                              variant="outlined"
                              InputLabelProps={{}}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              fontSize: "1rem",
                              color: "grey",
                              background: "white",
                              width: "100%",
                              minHeight: "71.91px",
                            }}
                          >
                            {itemStep === 1 ? (
                              ""
                            ) : (
                              <div
                                style={{
                                  width: "calc(100% - 20px)",
                                  margin: "10px",
                                }}
                                className="d-flex justifyFE"
                                id="update-button"
                              >
                                <StyledButton
                                  name="Next"
                                  background={primary}
                                  margin="10px 0"
                                  withBg={true}
                                  onClick={() => {
                                    if (checkItemRowField()) {
                                      setItemStep(itemStep + 1);
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <SpecificationTable
                          specificationHeader={specificationHeader}
                          specificationArr={editItemRow.so_specifications}
                          soItemId={editItemRow.id}
                          specifiationTypes={specifiationTypes}
                          handleResetItemRow={handleResetItemRow}
                          handleSpecificationAddition={
                            handleSpecificationAddition
                          }
                        />
                      )}
                      <Stepper activeStep={itemStep}>
                        <Step
                          onClick={() => {
                            if (itemStep === 1) {
                              setItemStep(itemStep - 1);
                            }
                          }}
                          style={{
                            cursor: itemStep ? "pointer" : "",
                            "& .MuiStepIcon-root": {
                              color: itemStep === 0 ? primary : "#ccc",
                            },
                          }}
                        >
                          <StepLabel
                            style={{
                              marginLeft: "200px",
                              fontFamily: "Poppins",
                            }}
                            StepIconProps={{
                              style: {
                                color: primary,
                                fontFamily: "Poppins",
                              },
                            }}
                          >
                            Add Item Details
                          </StepLabel>
                        </Step>
                        <Step>
                          <StepLabel
                            style={{
                              marginRight: "200px",
                              fontFamily: "Poppins",
                            }}
                            StepIconProps={{
                              style: {
                                color: itemStep === 1 ? primary : "#ccc",
                                fontFamily: "Poppins",
                              },
                            }}
                          >
                            Add Multiple Specifications
                          </StepLabel>
                        </Step>
                      </Stepper>
                    </div>
                  ) : null}
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      border: "1px solid #80808042",
                      padding: "10px",
                      marginBottom: "20px",
                      borderRadius: "5px",
                      marginTop: "20px",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        color: "black",
                        width: "120px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Delivery Terms :{" "}
                    </div>
                    <div style={{ width: "calc(100% - 130px)" }}>
                      {console.log(
                        "DE",
                        editItemRow,
                        editData?.so_items?.slice(0, 1)
                      )}
                      {editData?.so_items &&
                        editData?.so_items?.slice(0, 1).map((singleItem, i) => (
                          <div
                            className="d-flex alignC"
                            style={{
                              fontSize: "1rem",
                              color: "grey",
                              background: "white",
                              width: "100%",
                              margin: "10px 0",
                            }}
                          >
                            {console.log(
                              "Delivery Terms",
                              singleItem,
                              editData
                            )}
                            {`
                              ${editData?.Currency?.name || ""}  
                              ${singleItem["contract_rate"]} 
                              /${editData?.Unit?.name || ""} ${
                              editData?.Incoterm?.name || ""
                            } in
                              ${handleDeliveryTermsText(
                                editData?.Incoterm?.name,
                                editData.portArry.map((p) => p.id),
                                editData?.DischargePort
                              )}
                              ${editData?.ShippingTerms?.value}
                              ${singleItem["packaging"]?.[0].size || ""}
                              ${singleItem["packaging"]?.[0].unit || ""}
                              ${singleItem["packaging"]?.[0].bag_type || ""}
                            `}
                            <CssTextField95
                              autoComplete="off"
                              value={editData?.extras || ""}
                              error={errorMsg?.extras}
                              helperText={
                                errorMsg.extras ? "Please add Varaible" : false
                              }
                              onChange={(e) => {
                                handleAddendumChange("extras", e.target.value);
                              }}
                              style={{ margin: "0 5px" }}
                              id="Variable"
                              label="Write Text"
                              variant="outlined"
                              InputLabelProps={{}}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <CssTextField95
                    required={false}
                    autoComplete="off"
                    value={
                      editData?.duty_payment_term
                        ? editData?.duty_payment_term
                        : "" //
                    }
                    error={errorMsg.duty_payment_term ? true : false}
                    helperText={
                      errorMsg.duty_payment_term ? "Please add Varaible" : false
                    }
                    onChange={(e) => {
                      handleAddendumChange("duty_payment_term", e.target.value);
                    }}
                    style={
                      { margin: "10px 0px", width: "100%" } //disabled={tp}
                    }
                    id="duty_payment_term"
                    label="Duty Payment Term"
                    variant="outlined"
                    InputLabelProps={{}}
                  />
                </div>
                {editData?.Incoterm?.id && editData?.ShippingTerms?.id ? (
                  <div style={{ margin: "10px 0" }}>
                    <span id="policy">SO Policy :</span>
                    <ReactQuill
                      theme="snow"
                      id="outlined-basic"
                      autoComplete="new-password"
                      style={{
                        width: "100%",
                      }}
                      // id="policy"
                      label={"So Policy"}
                      value={
                        editData?.policy_of_so_policy
                          ? editData?.policy_of_so_policy
                          : ""
                      }
                      onChange={(newContent) => {
                        if (editData?.soPolicy) {
                          handleAddendumChange(
                            "so_policy",
                            editData?.soPolicy.id
                          );
                          handleAddendumChange(
                            "policy_of_so_policy",
                            newContent
                          );
                        } else {
                          handleAddendumChange(
                            "policy_of_so_policy",
                            newContent
                          );
                        }
                      }}
                      variant="outlined"
                    />
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </DialogContent>
          <DialogActions
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              minHeight: "52.5px",
            }}
          >
            <>
              <StyledButton
                onClick={() => {
                  if (previewPdf) {
                    setPreviewPdf(false);
                  } else {
                    handleAddendumEditData(addendumData);
                  }
                }}
                name={previewPdf ? "Back" : "Reset to default"}
                margin="0 10px 0 0"
              />
              <StyledButton
                name={previewPdf ? "Update" : "Proceed"}
                withBg={true}
                background={"#1a9520"}
                width="120px"
                onClick={() => {
                  if (previewPdf) {
                    UpdateAddendum(addendumPayload);
                  } else {
                    handleProceedClick();
                  }
                }}
              />
            </>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
    </div>
  );
};

export default SalesAddendum;
