import React, { useEffect } from "react";
import { useState } from "react";
import CreateDialog from "../../layoutComponents/CreateDialog";
import {
  calculateGstAmount,
  calculatePercentage,
  filterData,
  StopScroll,
} from "../../Regex";
import {
  CssTextField95,
  HeadingLine,
  ShowDetails,
  StyledButton,
  StyledLoadingButton,
} from "../../styles/StyledComponents";
import { sum, multiply, divide } from "lodash";
import { primary, red } from "../../styles/colors";
import { lightGrey, white } from "../../styles/colors";
import { Divider } from "@material-ui/core";
function ItemDetailCommercial(props) {
  const {
    postData,
    selected,
    selectedSo,
    selectedCi,
    alldisabled,
    onlyView,
    masters,
    errorMsg,
    setErrorMsg,
    setData,
    editData,
    ci_type,
    rateOfGst,
    amountDataInOther
  } = props;
  console.log(amountDataInOther)
  const [editDetail, seteditDetail] = useState({});
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);

  const [dialogOpen, setDialogOpen] = useState({ open: false, details: false });
  const unit = selectedSo && selectedSo.unit ? selectedSo.unit : "";
  const [submittedData, setSubmittedData] = useState({});
  useEffect(() => {
    setSubmittedData(editData);
    console.log(editData, "editDatasubmittsubmittedDataedData1");
    seteditDetail(editData);
  }, [editData]);
  const [inputArr, setInputArr] = useState([]);
  const inputArrCopy = [
    {
      disabled: true,
      name: "Net Weight",
      type: "textField",
      dataType: "number",
      api: "Net Weight",
      defaultValue: {
        value:
          editDetail && editDetail[`Net Weight`]
            ? editDetail[`Net Weight`]
            : "",
      },
      endAdornment: unit,
    },
    {
      name: "Gross Weight",
      type: "textField",
      dataType: "number",
      endAdornment: unit,
      defaultValue: {
        value:
          editDetail && editDetail["Gross Weight"]
            ? editDetail["Gross Weight"]
            : "",
      },
      api: "Gross Weight",
      disabled: alldisabled,
      onChange: async (sub) => {
        setSubmittedData((prev) => {
          return {
            ...prev,
            "Gross Weight": sub["Gross Weight"],
            // amount_in_other: amountDataInOther,
          };
        });
        return sub;
      },
    },
    // {
    //   disabled: alldisabled,
    //   name: `Amount in ${
    //     selected && selected["Currency"] ? `${selected["Currency"]}` : ""
    //   }`,
    //   type: "textField",
    //   dataType: "number",
    //   api: "amount_in_other",
    //   defaultValue: {
    //     value:
    //       editDetail && editDetail[`amount_in_other`]
    //         ? editDetail[`amount_in_other`]
    //         : "",
    //   },
    //   endAdornment:
    //     selected && selected["Currency"] ? `${selected["Currency"]}` : "",
    //   onChange: async (sub) => {
    //     var data = {};
    //     if (ci_type === "GST") {
    //       sub["GST Amount"] = calculatePercentage(
    //         sub[
    //           `Amount in ${
    //             selected && selected["Currency"]
    //               ? `${selected["Currency"]}`
    //               : ""
    //           }`
    //         ]
    //           ? sub[
    //               `Amount in ${
    //                 selected && selected["Currency"]
    //                   ? `${selected["Currency"]}`
    //                   : ""
    //               }`
    //             ]
    //           : 0,
    //         rateOfGst
    //       );
    //       sub["Total Amount IGST"] = calculateGstAmount(
    //         sub[
    //           `Amount in ${
    //             selected && selected["Currency"]
    //               ? `${selected["Currency"]}`
    //               : ""
    //           }`
    //         ]
    //           ? sub[
    //               `Amount in ${
    //                 selected && selected["Currency"]
    //                   ? `${selected["Currency"]}`
    //                   : ""
    //               }`
    //             ]
    //           : 0,
    //         rateOfGst
    //       );
    //       data = {
    //         "GST Amount": calculatePercentage(
    //           sub[
    //             `Amount in ${
    //               selected && selected["Currency"]
    //                 ? `${selected["Currency"]}`
    //                 : ""
    //             }`
    //           ]
    //             ? sub[
    //                 `Amount in ${
    //                   selected && selected["Currency"]
    //                     ? `${selected["Currency"]}`
    //                     : ""
    //                 }`
    //               ]
    //             : 0,
    //           rateOfGst
    //         ),
    //         "Total Amount IGST": calculateGstAmount(
    //           sub[
    //             `Amount in ${
    //               selected && selected["Currency"]
    //                 ? `${selected["Currency"]}`
    //                 : ""
    //             }`
    //           ]
    //             ? sub[
    //                 `Amount in ${
    //                   selected && selected["Currency"]
    //                     ? `${selected["Currency"]}`
    //                     : ""
    //                 }`
    //               ]
    //             : 0,
    //           rateOfGst
    //         ),
    //       };
    //     }
    //     setSubmittedData((prev) => {
    //       return {
    //         ...prev,
    //         amount_in_other:
    //           sub[
    //             `Amount in ${
    //               selected && selected["Currency"]
    //                 ? `${selected["Currency"]}`
    //                 : ""
    //             }`
    //           ],
    //         ...data,
    //       };
    //     });
    //     return sub;
    //   },
    // },
    {
      name: "Container Numbers",
      title: "Container Numbers",
      type: "multiInput",
      dataType: "text",
      api: "container_number",
      freeSolo: true,
      endAdornment: unit,
      defaultValue: {
        value:
          editDetail && editDetail["container_number"]
            ? editDetail["container_number"]
            : [],
      },
      disabled: alldisabled,
      onChange: async (sub) => {
        console.log(sub["Container Numbers"], "weert45");
        setSubmittedData((prev) => {
          return {
            ...prev,
            container_number: sub["Container Numbers"],
          };
        });
        return sub;
      },
    },
  ];
  const changeTo = (to, weigths, opposite) => {
    if (opposite) {
      if (to === "MT") {
        return multiply(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return multiply(weigths, 100);
      }
    } else {
      if (to === "MT") {
        return divide(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return divide(weigths, 100);
      }
    }
  };
  const changeUnit = (from, to, weigths, opposite) => {
    //default in kg

    if (opposite) {
      if (from === "MT") {
        return changeTo(to, divide(weigths, 1000), opposite);
      } else if (from === "Kgs") {
        return changeTo(to, weigths);
      } else if (from === "Qtl") {
        return changeTo(to, divide(weigths, 100), opposite);
      }
    } else {
      if (from === "MT") {
        return changeTo(to, multiply(weigths, 1000));
      } else if (from === "Kgs") {
        return changeTo(to, weigths);
      } else if (from === "Qtl") {
        return changeTo(to, multiply(weigths, 100));
      }
    }
  };
  const calculateNetWeight = (submiited) => {
    const allWeights =
      selected.packaging &&
      selected.packaging.length > 0 &&
      selected.packaging.map((sP) => {
      
        const pVal = sP;
        return changeUnit(
          pVal.unit,
          unit,
          multiply(
            Number(
              submiited[
                `Actual ${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`
              ]
                ? submiited[
                    `Actual ${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`
                  ]
                : 0
            ),
            Number(pVal.size)
          ).toFixed(3)
        );
      });
    return allWeights.reduce(
      (previousValue, currentValue) =>
        Number(previousValue) + Number(currentValue ? currentValue : 0),
      0
    );
  };
  useEffect(() => {
    if (selected && dialogOpen.open === true) {
      const newInput = inputArrCopy.map((s, i) => {
        return {
          ...s,
          defaultValue: {
            value: editDetail && editDetail[s.api] ? editDetail[s.api] : "",
          },
        };
      });
      const ids = [];
      selected.packaging &&
        selected.packaging.length > 0 &&
        selected.packaging.map((sP) => {
          const pVal = sP;
          console.log(ids, sP)
          // filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
          //   ? filterData(masters.PackagingList, "id", Number(sP), "onlyOne")
          //   : "";
          if (!ids.includes(sP)) {
            console.log(editDetail, sP, "editDetail")
            ids.push(sP);
            newInput.push(  {
              name:  `Empty ${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`,
              type: "textField",
              dataType: "number",
              only_number: true,
              api: `${sP.id}-empty-bags`, 
                endAdornment: `bags`,
              disabled: alldisabled,
              onChange: async (submiited) => {
                setSubmittedData((prev) => {
                  return {
                    ...prev,
                    [`${sP.id}-empty-bags`]:
                      submiited[
                        `Empty ${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`
                      ],
                  };
                });
                return submiited;
              },
              defaultValue: {
                value: editDetail && editDetail[`${sP.id}-empty-bags`]
                ? editDetail[`${sP.id}-empty-bags`]
                :""
                // :Divider( multiply((editDetail && editDetail[`${sP.id}-bags`]?Number( editDetail[`${sP.id}-bags`]):0),100),2)
                
              }, 
              helperText:`Default 2 % bags of Actual ${pVal.size} ${pVal.unit}`,
              shrink: true,
            },
              {
                disabled: true,
                name: `Actual ${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`,
                type: "textField",
                dataType: "number",
                only_number: true,
                
                api: `${sP.id}-bags`,
                defaultValue: {
                  value:
                    editDetail && editDetail[`${sP.id}-bags`]
                      ? editDetail[`${sP.id}-bags`]
                      : "",
                },
                endAdornment: `bags`,
                helperText: `${
                  submittedData[`${sP.id}-bags`]
                    ? `Weight : ${multiply(
                        Number(submittedData[`${sP.id}-bags`]),
                        Number(pVal.size)
                      ).toFixed(3)}${pVal.unit}`
                    : ""
                }`,
                shrink: true,
                max: sP.total_bags ? sP.total_bags : 0,
                onChange: async (submiited) => {
                  submiited["Net Weight"] = calculateNetWeight(submiited);
                  setSubmittedData((prev) => {
                    return {
                      ...prev,
                      [`${sP.id}-bags`]:
                        submiited[
                          `Actual ${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`
                        ],
                      "Net Weight": calculateNetWeight(submiited),
                    };
                  });
                  return submiited;
                },
              },
              
     
              {
                disabled: true,
                name: `Custom Inv ${pVal.size} ${pVal.unit} ${pVal.bag_type} Bags Count`, //
                type: "textField",
                dataType: "number",
                only_number: true,
                api: `${sP.id}-bags`,
                defaultValue: {
                  value: sP.total_bags ? sP.total_bags : "",
                  // value: editDetail && editDetail[`${sP.id}-bags`] ? editDetail[`${sP.id}-bags`] : "",
                },
                endAdornment: `bags`,
                helperText: `${
                  submittedData[`${sP.id}-bags`]
                    ? `Weight : ${multiply(
                        Number(submittedData[`${sP.id}-bags`]),
                        Number(pVal.size)
                      ).toFixed(3)}${pVal.unit}`
                    : ""
                }`,
                shrink: true,
              },
            
              
            );
          }
          return sP;
        });
      if (ci_type === "GST") {
        newInput.push(
          {
            name: "GST Amount",
            type: "textField",
            disabled: true,
            dataType: "number",
            endAdornment: "INR",
            // percentage: "100",
            defaultValue: {
              value: submittedData["GST Amount"]
                ? submittedData["GST Amount"]
                : calculatePercentage(
                    submittedData.amount_in_other
                      ? submittedData.amount_in_other
                      : 0,
                    rateOfGst
                  ),
            },
          },
          {
            name: "Total Amount IGST",
            type: "textField",
            disabled: true,
            dataType: "number",
            endAdornment: "INR",
            // percentage: "100",
            defaultValue: {
              value: submittedData["Total Amount IGST"]
                ? submittedData["Total Amount IGST"]
                : calculateGstAmount(
                    submittedData.amount_in_other
                      ? submittedData.amount_in_other
                      : 0,
                    rateOfGst
                  ),
            },
            api: "total_fob_value_of_shipped_cargo",
          }
        );
      }
      console.log(
        sum([
          ci_type === "GST" ? 5 : 3,
          multiply(3, selected.packaging.length),
        ]) ,
              newInput,"xcfghjio")

      if (
        sum([
          ci_type === "GST" ? 5 : 3,
          multiply(3, selected.packaging.length),
        ]) === newInput.length
      ) {
        setInputArr(newInput.reverse());
        setLoader(false);
      }
    }
  }, [dialogOpen.open, submittedData, editDetail, ci_type, rateOfGst]);

  return (
    <>
    {console.log(inputArr)}
      {dialogOpen && dialogOpen.open && loader === false && (
        <CreateDialog
          onlyView={onlyView ? true : false}
          noTitle={true}
          module={`Custom Invoice : ${
            selectedCi && selectedCi.invoice_number
              ? selectedCi.invoice_number
              : ""
          }`}
          closeOnSave={true}
          selectedList={dialogOpen.details ? dialogOpen.details : false}
          editList={dialogOpen.details ? dialogOpen.details : false}
          handleClose={() => {
            setDialogOpen({ open: false, details: false });
          }}
          detailUI={() => {
            return (
              <ShowDetails
                isMobile={true}
                noFixHeight={true}
                width="100%"
                lists={[
                  {
                    name: "Brand",
                    value: `${selected["Brand"]}-${selected["Item Category"]}-${selected["Item Type"]}%`,
                  },
                  {
                    name: "HSN Code",
                    value:
                      selected && selected["HSN Code"]
                        ? `${selected["HSN Code"]}`
                        : "",
                  },
                  {
                    name: "Contract Rate",
                    value:
                      selected && selected["Contract Rate"]
                        ? `${selected["Contract Rate"]}/${unit}`
                        : "",
                  },
                  {
                    name: "Currency",
                    value:
                      selected && selected["Currency"]
                        ? `${selected["Currency"]}`
                        : "",
                  },
                  {
                    name: "Cus. In. Gross Weight",
                    value:
                      selected && selected["gross_weight"]
                        ? `${selected["gross_weight"]}${unit}`
                        : "",
                  },
                  {
                    name: "Cus. In. Net Weight",
                    value:
                      selected && selected["net_weight"]
                        ? `${selected["net_weight"]}${unit}`
                        : "",
                  },
                ]}
                height={"fit-content"}
                margin="10px"
              />
            );
          }}
          textFieldWidth="calc(50% - 20px)"
          textFieldStyle={{ width: "calc(50% - 20px)", margin: "10px" }}
          heightNew="fit-content"
          maxHeight="calc(100vh - 60px)"
          widthNew="800px"
          inputArray={inputArr}
          checkAllFields={() => {
            // const checkData = (name) => {
            //   if (submittedData[name]) {
            //     return true;
            //   } else {
            //     setError((prev) => {
            //       return { ...prev, [name]: true };
            //     });
            //     return false;
            //   }
            // };

            // if (checkData(`Fob Value ${rowHeader[2]}`) === true) {
            return true;
            // } else {
            //   return false;
            // }
          }}
          postDataToState={(data) => {
            if (setData) {
              console.log(submittedData, "editDatasubmittsubmittedDataedData");
              setData(submittedData);
            }
            setErrorMsg();
            setDialogOpen({ open: false, details: false });
          }}
        />
      )}
      {console.log(editDetail, "editDetail")}
      {(editDetail && editDetail[`Net Weight`]
        ? Number(editDetail[`Net Weight`])
        : 0) > 0 ? (
        <StyledLoadingButton
          id={props.id ? props.id : "addbutoon"}
          error={
            errorMsg["addDetail"] || errorMsg[`${selected.id}addDetail`]
              ? true
              : false
          }
          width="120px"
          loading={loader}
          withBg={true}
          background={
            errorMsg["addDetail"] || errorMsg[`${selected.id}addDetail`]
              ? red
              : false
          }
          name={
            onlyView
              ? "View Details"
              : editData
              ? "Update details"
              : "add details"
          }
          onClick={() => {
            setLoader(true);
            if (!editData) {
              setSubmittedData({});
            }
            setError({});
            setDialogOpen({ open: true, details: false });
          }}
        />
      ) : (
        "-"
      )}
    </>
  );
}

export default ItemDetailCommercial;