import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";

import { color3, primary } from "../styles/colors";
import { Popover, Typography } from "@mui/material";
import { Button, Divider, Tooltip } from "@material-ui/core";
import { mediumScreen } from "../Regex";

const CustomDesign = (props) => {
  const { rows, keys } = props;
  return (
    <Typography sx={{ fontFamily: "Poppins" }}>
    {console.log("r",rows, keys)}
          <div style={{ width: "100%", minWidth: 260, padding: "1px 4px" }}>
            {rows.length > 0 &&
              rows?.map((item, i) => (
                <>
                <Typography
                    className="d-flex justifySB"
                    style={{ fontFamily: "Poppins", whiteSpace: "nowrap" }}
                  >
                    <div
                      style={{
                        color: "rgb(88,88,88)",
                        fontWeight: "bold",
                        width: "80%",
                        fontSize:  mediumScreen() ? "0.7rem" : "0.8rem",
                        marginRight: "5px",
                        width: "46%",
                        whiteSpace: "nowrap",
                      }}
                    >
                    {console.log("item", item, item[keys])}
                      {item[keys] || ""}
                    </div>
                  </Typography>
                  {i !== rows?.length - 1 && (
                    <Divider variant="middle" style={{ margin: "2% 0" }} />
                  )}
                </>
              ))}
          </div>
        </Typography>
  )
}

const SimplePopover = (props) => {
  const { content, popOverIcon } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <span 
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
      {popOverIcon
        ? popOverIcon
        : <InfoOutlinedIcon
            style={{
              color: open ? primary : "inherit",
              transform: "scale(0.9)",
              paddingTop: 5,
              cursor: "pointer",
            }}
          />
      }
      </span>
      <Popover
        id={id}
        sx={{ pointerEvents: "none", fontFamily: "Poppins" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
      >
        <Typography 
        sx={{ fontFamily: "Poppins", p: 1 }}>
        {
          content?.type === 'custom'
          ? <CustomDesign rows={content?.rows} keys={content?.keys} />
          : content || ""
        }
        </Typography>
      </Popover>
    </div>
  );
};

export default SimplePopover;
