import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";

import ProgressBar from "./ProgressBar";
import { mediumScreen } from "../Regex";

function ListItemElement(props) {
  const [selectedIndex, setIndex] = useState(props.selectedIndex);
  const {
    avatar,
    index,
    listClickHandler,
    primaryTitle,
    primary,
    secondary,
    dispatchList,
    selectedStatus,
    progressBar,
    is_finalised,
    primarySecondValue,
    primaryFieldThirdFallback,
    salesList,
    color,
    unit,
    primaryField,
    secondaryField,
    itemData,
  } = props;
  if (selectedIndex === -1 && props.params && props.params.id) {
    setIndex(props.params.id);
  }
  useEffect(() => {
    setIndex(props.selectedIndex);
  }, [props.selectedIndex]);
  return (
    <div key={primary ? primary.toString() : "keys"}>
      <ListItem
        key={primary ? primary.toString() : "keys"}
        className="cursorP"
        alignItems="flex-start"
        style={{
          // padding: "20px 4px",
          position: "relative",
          // overflow: "hidden",
          backgroundColor: selectedIndex === index ? "rgb(30 53 89 / 9%)" : "",
          borderBottom: "1px solid rgb(241 154 48)",
          textTransform: "capitalize",
          padding: mediumScreen() ? "6px 6px" : "16px",
        }}
        onClick={(e) => {
          e.preventDefault();
          listClickHandler(index,itemData);
        }}
      >
        {salesList ? (
          ""
        ) : dispatchList ? (
          is_finalised ? (
            <Avatar
              style={{
                color: "white",
                backgroundColor: primary,
                borderRadius: "20px",
                width: "25px",
                height: "25px",
                fontSize: "0.7rem",
                textAlign: "center",
                justifyContent: "center",
                background: "#00800075",
                position: "absolute",
                right: "16px",
                top: "5px",
              }}
            >
              <CheckCircle />{" "}
            </Avatar>
          ) : primaryFieldThirdFallback &&
            primaryFieldThirdFallback.total_pending_trips &&
            primaryFieldThirdFallback.total_pending_trips > 0 ? (
            <Avatar
              style={{
                color: "white",
                backgroundColor: primary,
                borderRadius: "20px",
                width: "25px",
                height: "25px",
                fontSize: "0.7rem",
                textAlign: "center",
                justifyContent: "center",
                background: "#00800075",
                position: "absolute",
                right: "16px",
                top: "5px",
              }}
            >
              {primaryFieldThirdFallback.total_pending_trips}
            </Avatar>
          ) : (
            ""
          )
        ) : (
          ""
          // <ListItemAvatar>
          //   <Avatar
          //     alt={primary}
          //     src={avatar}
          //     style={{
          //       background: dispatchList ? "rgb(241 154 48)" : "",
          //       boxShadow:
          //         "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
          //     }}
          //   >
          //     {dispatchList
          //       ? primary && primary.substring(8, 10)
          //       : primary && primary.toString().substring(0, 1)}
          //   </Avatar>
          // </ListItemAvatar>
        )}
        <div
          style={{
            margin: "6px 0",
            width: '100%',
            // width: salesList ? "100%" : "calc(100% - 56px)",
            fontSize: mediumScreen() ? "0.75rem" : "inherit",
          }}
        >
          <div style={{ width: "100%" }}>
            {dispatchList ? (
              <>
              <div
                style={{
                  display: "flex",
                  justifyContent: dispatchList ? "center" : "space-between",
                }}
                className={dispatchList ? "d-flex width-100" : ""}
              >
                {primaryTitle && 
                  <Typography
                  component={"span"}
                  style={
                    {
                      display: "inline-flex",
                      width: "auto",
                      // maxWidth: "calc(100% - 58px)",
                      maxWidth: "calc(100% - 8px)",
                      // maxHeight: "38px",
                      height: "auto",
                      fontSize: mediumScreen() ? "0.65rem" : "0.7rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: 'center',
                      color: selectedIndex === index ? "rgb(92 92 92)" : color?"#999": "",
                      fontWeight: selectedIndex === index ? "800" : "500",
                      textTransform:
                        primaryField === "email" ? "lowercase" : "",
                      whiteSpace: mediumScreen() ? "normal" : "normal",
                    }
                    // textTransform: "capitalize",
                  }
                  >
                    {primaryTitle}
                  </Typography>
                }
                </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: dispatchList ? "center" : "space-between",
                }}
                className={dispatchList ? "d-flex width-100" : ""}
              >
                <Typography
                  component={"span"}
                  style={
                    {
                      display: "inline-flex",
                      width: "auto",
                      // maxWidth: "calc(100% - 58px)",
                      maxWidth: "calc(100% - 8px)",
                      // maxHeight: "38px",
                      height: "auto",
                      fontSize: mediumScreen() ? "0.7rem" : "0.9rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: selectedIndex === index ? "rgb(241 154 48)" :color?"black": "",
                      fontWeight: selectedIndex === index ? "800" : "500",
                      textTransform:
                        primaryField === "email" ? "lowercase" : "",
                      whiteSpace: mediumScreen() ? "normal" : "normal",
                    }
                    // textTransform: "capitalize",
                  }
                >
                  {primary}
                </Typography>{" "}
                {/* <Typography
                  style={{
                    // fontSize: "0.8rem",
                    flexDirection: "column",
                    display: "flex",
                    height: "auto",
                    alignItems: "center",
                    fontSize: "0.7rem",
                    borderRadius: "5px",
                    padding: "0 2Px",
                    justifyContent: "center",
                    width: "100%", textAlign: "center"
                  }}
                  component={"span"}
                >
                  {primary === moment(new Date()).format("YYYY-MM-DD")
                    ? "Today"
                    : primary ===
                      moment(new Date()) //firebase.firestore.Timestamp.now()
                        .subtract(1, "days")
                        .format("YYYY-MM-DD")
                      ? "Yesterday"
                      : ""}
                </Typography>{" "} */}
              </div>
              {props.secondLineUI && <div className="d-flex width-100 justifyC">
                {props.secondLineUI(props?.itemData)}
              </div>
              }
              </>
            ) : (
              <div
                style={{
                  color: selectedIndex === index ? "rgb(30 53 89)" : "black",
                  // fontSize: "0.82rem",
                  fontWeight: selectedIndex === index ? "800" : "500",
                  whiteSpace: mediumScreen() ? "normal" : "normal",
                  height: mediumScreen()
                  ? '100%'
                  : primary
                    ? primary.length > 20
                      ? "40px"
                      : "20px"
                    : "20px",
                  // width: "100%",
                  overflow: "hidden",
                  // textOverflow: "ellipsis",
                  flexWrap: "wrap",
                  wordBreak: "break-word",
                }}
              >
                {primary}
              </div>
            )}
          </div>
          <div style={{ width: "100%" }}>
            {dispatchList && !Boolean(selectedStatus === 'DRAFT') ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: dispatchList ? "center" : "space-between",
                }}
                className={dispatchList ? "d-flex width-100" : ""}
              >
                <div
                  style={{
                    marginTop: "0",
                    maxHeight: mediumScreen() ? "auto" : "48px",
                    fontSize: "0.7rem",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    fontWeight: selectedIndex === index ? "800" : "500",
                  }}
                >
                  {progressBar ? (
                    primaryFieldThirdFallback === 0 ? (
                      "No Dispatches Yet!"
                    ) : (
                      <ProgressBar
                        selectedIndex={
                          selectedIndex === index ? "true" : "false"
                        }
                        completedChapters={
                          primarySecondValue
                            ? Number(primarySecondValue).toFixed(3)
                            : 0
                        }
                        totalChapters={
                          primaryFieldThirdFallback
                            ? Number(primaryFieldThirdFallback).toFixed(3)
                            : 0
                        }
                        unit={unit ? unit : ""}
                        title1={"Dispatched"}
                        title2={`Total`}
                      />
                    )
                  ) : salesList ? (
                    <>
                      {` Remaining ${
                        primarySecondValue
                          ? Number(primarySecondValue).toFixed(0)
                          : ""
                      } MT | Total
                      ${
                        primaryFieldThirdFallback
                          ? Number(primaryFieldThirdFallback).toFixed(0)
                          : ""
                      }MT`}
                    </>
                  ) : (
                    <>
                      {" "}
                      {primaryFieldThirdFallback &&
                      primaryFieldThirdFallback.total_trips
                        ? primaryFieldThirdFallback.total_trips
                        : ""}{" "}
                      trips |{" "}
                      {primaryFieldThirdFallback &&
                      primaryFieldThirdFallback.total_net_weight
                        ? primaryFieldThirdFallback.total_net_weight
                        : ""}{" "}
                      MT
                    </>
                  )}
                </div>
              </div>
            ) : !Boolean(selectedStatus === 'DRAFT') ? (
              <div
                style={{
                  color: color ? color : "grey",
                  fontWeight: selectedIndex === index ? "600" : "500",
                  textTransform: secondaryField === "email" ? "lowercase" : "",
                  whiteSpace: "normal",
                  flexWrap: "wrap",
                  wordBreak: "break-word",
                }}
              >
                {" "}
                {secondary}
              </div>
            ) : null}
          </div>
        </div>
      </ListItem>
      <Divider />
    </div>
  );
}

export default ListItemElement;
