import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import TabComponent from "../../layoutComponents/TabComponent";
import EditIcon from "@mui/icons-material/Edit";
import TableComponent from "../../layoutComponents/TableComponent";
import Tooltip from "@material-ui/core/Tooltip";
import NoData from "../../styles/Illustrations/NoData";
import { Paper } from "@material-ui/core";
import CreateDialogForPort from "../../layoutComponents/CreateDialogForPort";
import { EndPoints } from "../../utils/EndPoints";
import { CrossIcon, TopArrow, BottomArrow } from "../../styles/Icons";
import ReactDOM from "react-dom";
import {
  primary,
  white,
  lightGrey,
  red,
  lightGreen,
} from "../../styles/colors";
import { filterData, mediumScreen, StopScroll } from "../../Regex";
import ShowPackagingPort from "../../layoutComponents/ShowPackagingPort";
import {
  CssTextField,
  CssTextField95,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import CommercialiInvoiceStepper from "./CommercialiInvoiceStepper";
import MenuItem from "@mui/material/MenuItem";
import { subtract, multiply, divide, sum } from "lodash";
import {
  Divider,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  Button,
  DialogContent,
} from "@material-ui/core";
import moment from "moment";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DeleteIcon from "@mui/icons-material/Delete";
import { withStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import DialogTitle from "@material-ui/core/DialogTitle";
import { stateArray } from "../../Defaults";
import CustomInvoice from "./CustomInvoice";
import Inventory from "./Inventory";
import PackingList from "./PackingList";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Warehouse } from "@mui/icons-material";
import SailingIcon from "@mui/icons-material/Sailing";
import EventIcon from "@mui/icons-material/Event";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { SnackbarContext } from "../../snackbarContext";
import SimplePopover from "../../layoutComponents/SimplePopover";

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: 200, // Set the maximum height for the dropdown
    },
  },
  MenuListProps: {
    style: {
      overflowY: 'auto', // Enable vertical scrolling
    },
  },
};

export const CssKeyboardDatePicker = withStyles({
  root: {
    "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-input ": {
      padding: "14px 14px",
    },
    "& .MuiIconButton-root ": {
      padding: "0px",
    },
  },
})(KeyboardDatePicker);

export const CssKeyboardDatePickerSmall = withStyles({
  root: {
    "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-input ": {
      padding: "9.5px",
    },
    "& .MuiIconButton-root ": {
      padding: "0px",
    },
  },
})(KeyboardDatePicker);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  withDivider50: { width: "calc(40% - 5px)" },
  mainDiv: {
    width: "100%",
    height: "100%",
  },
  innerDiv: {
    height: "50%",
    background: "white",
    borderRadius: "10px",
    marginBottom: "20px",
    pointerEvents: "none",
    width: "100%",
  },
  innerDiv1: {
    height: "65%",
    background: "#8080801a",
    borderRadius: "10px",
    padding: "10px 10px 0 10px",
    width: "100%",
    pointerEvents: "none",
  },
  innerMainDiv: {
    width: "100%",
    display: "flex",
  },
};
export class PortsComponent extends Component {
  static contextType = SnackbarContext;
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Ports"],
        tooltip: `Click to approve pending ${EndPoints["Ports"]}`,
        data: [],
        route: `/Ports/${EndPoints["Ports"].split("/")[0]}`,
        goBackRoute: "/Ports",
        primaryField: "name",
        primarySecondValue: "total_net_weight",
        primaryFieldFallback: "total_trips", // Optional
        secondaryField: "city",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",

        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#1a9520", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.state = {
      tabs: templateTabs,
      loadingDispatch: true,
      arrival_date: new Date(),
      summaryData: [],
      Ports: [],
      singlelistView: null,
      listViewArray: [],
      loading: false,
      New: [""],
      UnloadAgentArr: [""],
      returnButtonError: false,
      portDialog: false,
      editList: false,
      PortDispatchedList: {},
      selctedTab: "port Details",
      selctedTab2: "Intransit",
      PortDispatchedItemList: [],
      ArrivalDialog: false,
      widthList: {},
      colorArr: [white, lightGrey],
      statusArrival: ["Continue to Shipping", "Move to Godown"],
      loadingDialog: false,
      error: false,
      success: false,
      editArrival: false,
      openAddBagDialog: false,
      calenderOpen: false,
      shippingDateOpen: false,
      shippingDate: "",
      deleteDialog: false,
      deleteUnloadingAgentDialog: false,
      gDown: [],
      salesOrderList: [],
      masters: {},
      selectGodown: 0,
      ShippingList: [],
      commercialList: [],
      customList: [],
      confirmApp: false,
      portUnreachedheader: [
        "Brand",
        "Item Category",
        "Item Type",
        "Packaging",
        // "HSN Code",
        "Total Bags",
        // "Contract Rate",

        "Remark",
        "Status",
        "Godown",
        "Shipping Date",
        "Unloading Agent",
        "MR/LEO No.",
        "Sales Order",
        "Custom Invoice",
        "Commercial Invoice",
      ],
      errorMsg: { Godown: false, unloadingAgent: false },
      bagerror: "",
      bagsuccess: "",
      tabNumber: 0,
      isSpinning: {},
      paginationData: {},
      resetPage: false,
      displayPending: false,
    };
  }

  componentDidMount() {
    this.showSnackbar = this.context;
  }

  handleIconClick = (indexId, type) => {
    this.setState(
      {
        isSpinning: {
          ...Object.keys(this.state.isSpinning).reduce(
            (acc, key) => ({ ...acc, [key]: false }),
            {}
          ),
          [indexId]: type,
        },
      },
      () => {
        console.log("After:", this.state.isSpinning);
      }
    );
  };
  fetchDEtails = () => {
    this.props
      .PortsFunction("get", "masters/notify/list", null, "notifyList", null, {
        is_active: "True",
      })
      .then((notifyList) => {
        const masters = this.state.masters;
        masters["notifyList"] =
          notifyList && notifyList.length > 0 ? notifyList : [];
        this.setState({ masters });
      });

    this.props
      .PortsFunction("get", EndPoints["Shippers"], null, "shippersList", null, {
        is_active: "True",
      })
      .then((ShippersList) => {
        const masters = this.state.masters;
        masters["shippersList"] =
          ShippersList && ShippersList.length > 0 ? ShippersList : [];
        this.setState({ masters });
      });

    this.props
      .PortsFunction(
        "get",
        EndPoints["Precarriers"],
        null,
        "PreCarrierList",
        null,
        {
          is_active: "True",
        }
      )
      .then((PrecarriersList) => {
        console.log(PrecarriersList, "itemDatasssssssssss");
        const masters = this.state.masters;
        masters["PreCarrierList"] =
          PrecarriersList && PrecarriersList.length > 0 ? PrecarriersList : [];
        this.setState({ masters });
      });
    // this.setState({ loadingGodown: true });
    // this.props.PortsFunction("get", "masters/godown/list", null, "GodownList").then((GodownList) => {
    //   if (GodownList) {
    //     this.setState({
    //       loadingGodown: false,
    //       GodownList: filterData(GodownList, "port", this.state.singlelistView.id)
    //         ? filterData(GodownList, "port", this.state.singlelistView.id)
    //         : [],
    //     });
    //   } else {
    //     this.setState({ loadingGodown: false });
    //   }
    // });
    this.props
      .PortsFunction(
        "get",
        "masters/benificiary/list",
        null,
        "benificiarysList"
      )
      .then((benificiarysList) => {
        if (benificiarysList) {
          const masters = this.state.masters;
          masters["benificiarysList"] =
            benificiarysList && benificiarysList.length > 0
              ? benificiarysList
              : [];
          this.setState({ masters });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/so-payment-term/list",
        null,
        "soPaymentTermsList",
        null,
        { is_active: "True" }
      )
      .then((soPaymentTermsList) => {
        if (soPaymentTermsList) {
          const masters = this.state.masters;
          masters["soPaymentTermsList"] =
            soPaymentTermsList && soPaymentTermsList.length > 0
              ? soPaymentTermsList
              : [];
          this.setState({ masters });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/packaging/list",
        null,
        "packagingList",
        null,
        { is_active: "True" }
      )
      .then((packagingList) => {
        if (packagingList) {
          const masters = this.state.masters;
          masters["packagingList"] =
            packagingList && packagingList.length > 0 ? packagingList : [];
          this.setState({ masters });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/payment-terms/list",
        null,
        //state.singlelistView.id,
        "PaymentTermList",
        null,
        { is_active: "True" }
      )
      .then((PaymentTermList) => {
        if (PaymentTermList) {
          const masters = this.state.masters;
          masters["paymentTermList"] =
            PaymentTermList && PaymentTermList.length > 0
              ? PaymentTermList
              : [];
          this.setState({ masters });
        } else {
          const masters = this.state.masters;
          masters["paymentTermList"] = [];
          this.setState({ masters });
        }
      });
    this.props
      .PortsFunction("get", "masters/clients/list", null, "ClientList")
      .then((buyersList) => {
        if (buyersList) {
          const masters = this.state.masters;
          masters["buyersList"] =
            buyersList && buyersList.length > 0 ? buyersList : [];
          this.setState({ masters });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/brand/list",
        null,
        // this.state.singlelistView.id,
        "BrandList",
        null,
        { is_active: "True" }
      )
      .then((BrandList) => {
        if (BrandList) {
          const masters = this.state.masters;
          masters["BrandList"] =
            BrandList && BrandList.length > 0 ? BrandList : [];
          this.setState({ masters, BrandList });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/item-category/list",
        null,
        // this.state.singlelistView.id,
        "ItemCategoryList",
        null,
        { is_active: "True" }
      )
      .then((ItemCateoryList) => {
        if (ItemCateoryList) {
          const masters = this.state.masters;
          masters["ItemCateoryList"] =
            ItemCateoryList && ItemCateoryList.length > 0
              ? ItemCateoryList
              : [];
          this.setState({ masters, ItemCateoryList });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/item-type/list",
        null,
        // this.state.singlelistView.id,
        "ItemTypeList",
        null,
        { is_active: "True" }
      )
      .then((ItemList) => {
        if (ItemList) {
          const masters = this.state.masters;
          masters["ItemList"] = ItemList && ItemList.length > 0 ? ItemList : [];
          this.setState({ masters, ItemList });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/hsn/list",
        null,
        // this.state.singlelistView.id,
        "HSNlist",
        null,
        { is_active: "True" }
      )
      .then((HSNlist) => {
        const masters = this.state.masters;
        masters["HSNlist"] = HSNlist && HSNlist.length > 0 ? HSNlist : [];
        this.setState({ masters });
        if (HSNlist) {
          const masters = this.state.masters;
          masters["HSNlist"] = HSNlist && HSNlist.length > 0 ? HSNlist : [];
          this.setState({ masters, HSNlist });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/contract-type/list",
        null,
        // this.state.singlelistView.id,
        "ContractTypeList",
        null,
        { is_active: "True" }
      )
      .then((ContractTypeList) => {
        if (ContractTypeList) {
          this.setState({ ContractTypeList });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/survey-company/list",
        null,
        // this.state.singlelistView.id,
        "SurveyCompanyList",
        null,
        { is_active: "True" }
      )
      .then((SurveyCompanyList) => {
        if (SurveyCompanyList) {
          this.setState({ SurveyCompanyList });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "arrival/list/",
        null,
        // this.state.singlelistView.id,
        "PackagingList",
        null,
        { is_active: "True" }
      )
      .then((PackagingList) => {
        if (PackagingList) {
          this.setState({ PackagingList });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/packaging/list",
        null,
        // this.state.singlelistView.id,
        "PackagingList",
        null,
        { is_active: "True" }
      )
      .then((PackagingList) => {
        console.log(PackagingList, "PackagingList");
        if (PackagingList) {
          const masters = this.state.masters;
          masters["PackagingList"] =
            PackagingList && PackagingList.length > 0 ? PackagingList : [];
          this.setState({ masters });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/inco-term/list",
        null,
        //state.singlelistView.id,
        "IncotermsList",
        null,
        { is_active: "True" }
      )
      .then((incoTermList) => {
        if (incoTermList) {
          const masters = this.state.masters;
          masters["incoTermList"] =
            incoTermList && incoTermList.length > 0 ? incoTermList : [];
          this.setState({ masters });
        }
      });

    this.props
      .PortsFunction(
        "get",
        "masters/currency/list",
        null,
        //state.singlelistView.id,
        "CurrencyList"
      )
      .then((CurrencyList) => {
        if (CurrencyList) {
          const masters = this.state.masters;
          masters["CurrencyList"] =
            CurrencyList && CurrencyList.length > 0 ? CurrencyList : [];
          this.setState({ masters });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/port/list",
        null,
        //state.singlelistView.id,
        "PortList"
      )
      .then((PortList) => {
        if (PortList) {
          const masters = this.state.masters;
          masters["PortList"] =
          PortList && PortList.length > 0 ? PortList : [];
          this.setState({ masters });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/surveyor/list",
        null,
        // this.state.singlelistView.id,
        "allsurveyorList",
        null,
        { is_active: "True" }
      )
      .then((allsurveyorList) => {
        if (allsurveyorList) {
          this.setState({ allsurveyorList });
        }
      });
    this.props
      .PortsFunction(
        "get",
        "masters/unloading-agent/list",
        null,
        // this.state.singlelistView.id,
        "unloadingAgentList"
      )
      .then((unloadingAgentList) => {
        if (unloadingAgentList) {
          const masters = this.state.masters;
          masters["UnloadingAgentList"] =
            unloadingAgentList && unloadingAgentList.length > 0
              ? unloadingAgentList
              : [];
          this.setState({ masters });
        }
      });
  };
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  UNSAFE_componentWillMount() {
    this.fetchDEtails();
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Ports"] && next.params.id) {
        this.setState(
          {
            singlelistView: this.state.tabs[0].data[next.params.id],
          },
          () => {
            if (this.state.singlelistView && this.state.singlelistView.id) {
              this.props.PortsFunction(
                "get",
                this.props.user.uid,
                EndPoints["Ports"],
                this.state.singlelistView.id,
                "Ports"
              );
            }
          }
        );
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }

  listClickHandler = (value) => {
    this.setState(
      {
        singlelistView: value,
      },
      () => {
        if (
          this.state.selctedTab !== "port Details" &&
          (this.state.tabNumber === 2 ||
            this.state.tabNumber === 3 ||
            this.state.tabNumber === 4)
        ) {
          this.fetchDispatchList(
            this.state.selctedTab === "Arrival"
              ? this.state.selctedTab2
              : this.state.selctedTab
          );
        }
        this.props
          .PortsFunction(
            "get",
            "dispatch/sales-order/list",
            null,
            "salesOrderList",
            null,
            {
              status: "OPEN",
              port_id: value.id,
            }
          )
          .then((salesOrderList) => {
            const masters = this.state.masters;
            masters["salesOrderList"] =
              salesOrderList && salesOrderList.length > 0 ? salesOrderList : [];
            this.setState({ masters });
          });
      }
    );
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  fetchGodownHistory = (godown, urlText=false, type=false) => {
    this.props
          .PortsFunction(
            "get",
             type === 'pagination' ? urlText : "arrival/godown-history-lite/list",
            null,
            "historyList",
            null,
            type === 'pagination' 
            ? null
            : {
                godown_id: godown,
              },
            type === 'pagination' ? true  : false,
            )
          .then((historyDataList) => {
            const historyList = historyDataList?.results ? historyDataList.results : historyDataList;
            if (historyList && historyList.length > 0) {
              console.log("sethandleGodownChangefgos loadingDispatch");
              this.setState({
                historyList: historyList,
                loadingDispatch: false,
              });
              if(historyDataList?.results){
                this.setState({
                  paginationData: {
                    count: historyDataList?.count_on_page || 0,
                    total_count: historyDataList?.total_count || 0,
                    next: historyDataList?.next || null,
                    previous: historyDataList?.previous || null,
                  }
                })
              } else{
                this.setState({ paginationData: {} })
              }
            } else {
              console.log("sethandleGodownChangefgos loadingDispatch");
              this.setState({ historyList: [], loadingDispatch: false, paginationData: {} });
            }
          });
  }
  handleGodownChange = (godown,) => {
    console.log("sethandleGodownChangefgos");
    this.props
      .PortsFunction("get", "arrival/inventory/list", null, "InvList", null, {
        godown_id: godown,
      })
      .then((InvList) => {
        if (InvList && InvList.length > 0) {
          this.setState({ InvList: InvList });
        } else {
          this.setState({ InvList: [] });
        }
        this.fetchGodownHistory(godown)
      });
    this.setState({
      selectGodown: godown,
    });
  };
  fetchDispatchList = (tab, urlText=false, type=false) => {
    this.setState({ isSpinning: {} });
    console.log(tab, "this.state.selctedTab tab fetch Intransit", urlText, type);
    if (
      (this.state.singlelistView && tab === "Arrival") ||
      (this.state.singlelistView && tab === "Intransit")
    ) {
      console.log("fetch Intransit");
      this.setState({ loadingDispatch: true, PortDispatchedList: {} });
      this.props
        .PortsFunction(
          "get",
          type === 'pagination' ? urlText : "arrival/list",
          null,
          //this.state.singlelistView.id,
          "PortDispatchedList",
          null,
          type === 'pagination' 
          ? null
          :{
              port_id: this.state.singlelistView.id,
              arrival_status: "in_transit",
            },
          type === 'pagination' ? true  : false,
        )
        .then((PortList) => {
          console.log("called",PortList)
          const PortDispatchedList = PortList?.results ? PortList.results : PortList;
          if (PortDispatchedList && PortDispatchedList.length > 0) {
            this.setState({ loadingDispatch: true });
            PortDispatchedList.map((sr) => {
              let arr = [];
              sr.arrival_item_details.map((item) => {
                arr.push({
                  ...item.dispatch_items_details,
                  arrival_item_status: item.arrival_item_status,
                });
                // sr.subList= item.dispatch_items_details;
                return item;
              });
              sr.subList = arr;
              sr.portId = this.state.singlelistView.id;
              return sr;
              // sr.subList = sr.arrival_item_details.dispatch_items_details;
            });
            this.setState({ loadingDispatch: false });
          }
          if (PortDispatchedList) {
            this.setState({ PortDispatchedList, loadingDispatch: false });
          } else {
            this.setState({ loadingDispatch: false });
          }
          if(PortList?.results){
            this.setState({
              paginationData: {
                count: PortList?.count_on_page || 0,
                total_count: PortList?.total_count || 0,
                next: PortList?.next || null,
                previous: PortList?.previous || null,
              }
            })
          }else{
            this.setState({ paginationData: {} })
          }
        });
    }

    if (this.state.singlelistView && tab === "Reached") {
      //|| (this.state.singlelistView && tab === "Inventory")
      this.setState({ loadingDispatch: true, PortDispatchedList: {} });
      this.props
        .PortsFunction(
          "get",
          type === 'pagination' ? urlText : "arrival/list",
          null,
          //this.state.singlelistView.id,
          "PortDispatchedList",
          null,
          type === 'pagination' 
          ? null
          : this.state?.displayPending
            ? {
                port_id: this.state.singlelistView.id,
                arrival_status: "reached",
                pending: "True",
              }
          : { port_id: this.state.singlelistView.id, arrival_status: "reached" },
          type === 'pagination' ? true  : false,
        )
        .then((PortList) => {
          console.log(PortList)
          const PortDispatchedList = PortList ;
          if (PortDispatchedList && PortDispatchedList?.results.length > 0) {
            console.log("PortDispatchedList", PortDispatchedList);
            this.setState({ loadingDispatch: true });
            PortDispatchedList?.results?.map((sr) => {
              let arr = [];
              let dispatchPack = [];
              let arrivalPack = [];
              sr.arrival_item_details.map((item) => {
                dispatchPack = item.dispatch_items_details.dispatch_packaging;
                arrivalPack = item.arrival_packagings;
                dispatchPack.map((sp, i) => {
                  let ret =
                    arrivalPack[i] && arrivalPack[i].returned_bags
                      ? arrivalPack[i].returned_bags
                      : 0;
                  sp.returned_bags = ret ? ret : "";
                  return sp;
                });

                item.dispatch_items_details.newPack = dispatchPack;
                arr.push({
                  ...item.dispatch_items_details,
                  arrival_item_status: item.arrival_item_status,
                  ...item,
                });
                return item;
              });
              sr.subList = arr;
              sr.portId = this.state.singlelistView.id;
              return sr;
              // sr.subList = sr.arrival_item_details.dispatch_items_details;
            });
            this.setState({ loadingDispatch: false });
          } else {
            this.setState({ loadingDispatch: false });
          }
          if (PortDispatchedList) {
            this.setState({ PortDispatchedList, loadingDispatch: false });
          } else {
            this.setState({ loadingDispatch: false });
          }
          if(PortList?.results){
            this.setState({
              paginationData: {
                count: PortList?.count_on_page || 0,
                total_count: PortList?.total_count || 0,
                next: PortList?.next || null,
                previous: PortList?.previous || null,
              }
            })
          }else{
            this.setState({ paginationData: {} })
          }
        });
    }
    console.log(tab, "tabtabtab");
    if (this.state.singlelistView && tab === "Shipping") {
      this.setState({ loadingDispatch: true, ShippingList: [] }, () => {
        this.props
          .PortsFunction(
            "get",
            type === 'pagination' ? urlText : "arrival/shipping-table/list",
            null,
            //this.state.singlelistView.id,
            "ShippingList",
            null,
            type === 'pagination' 
            ? null
            : { port_id: this.state.singlelistView.id },
            type === 'pagination' ? true  : false,
          )
          .then((ShipList) => {
            const ShippingList = ShipList?.results ? ShipList.results : ShipList;
            if (ShippingList && ShippingList.length > 0) {
              this.setState({ loadingDispatch: false, ShippingList });
            }
            console.log(
              ShippingList,
              this.props.masters,
              "ShippingListf2ewtrhy"
            );
            if(ShipList?.results){
              this.setState({
                paginationData: {
                  count: ShipList?.count_on_page || 0,
                  total_count: ShipList?.total_count || 0,
                  next: ShipList?.next || null,
                  previous: ShipList?.previous || null,
                }
              })
            }
            else{
              this.setState({ paginationData: {} })
            }
          });
        this.props
          .PortsFunction(
            "get",
            "arrival/commercial-invoice/list",
            null,
            "PortCIs",
            null,
            {
              // sales_order_id: e.currentTarget.id,
              // // shipment_status: "EXPENSES CALCULATED",
              // // is_active: "True",
              // // port_id: singlelistView && singlelistView.id && singlelistView.id,
              // status: "DRAFT",
            }
          )
          .then((commercialList) => {
            if (commercialList && commercialList.length > 0) {
              this.setState({ loadingDispatch: false, commercialList });
            }
            console.log(commercialList, "commercialListf2ewtrhy");
          });
        this.props
          .PortsFunction(
            "get",
            "arrival/custom-invoice/list",
            null,
            "PortCIs",
            null,
            {
              // sales_order_id: e.currentTarget.id,
              // // shipment_status: "EXPENSES CALCULATED",
              // // is_active: "True",
              // // port_id: singlelistView && singlelistView.id && singlelistView.id,
              // status: "DRAFT",
            }
          )
          .then((customList) => {
            if (customList && customList.length > 0) {
              this.setState({ loadingDispatch: false, customList });
            }
            console.log(customList, "customListf2ewtrhy");
          });
      });
    }
    if (this.state.singlelistView && tab === "Inventory") {
      const { singlelistView } = this.state;
      this.setState({ loadingDispatch: true, GodownList: [] });
      if (
        singlelistView &&
        singlelistView.godown_details &&
        singlelistView.godown_details.length > 0 &&
        singlelistView.godown_details
      ) {
        const GodownList =
          singlelistView &&
          singlelistView.godown_details &&
          singlelistView.godown_details.length > 0 &&
          singlelistView.godown_details;
        this.setState(
          {
            GodownList: GodownList.sort(function (a, b) {
              return b.created_at - a.created_at;
            }),
          },
          () => {
            if(type === 'pagination'){
              this.fetchGodownHistory(
                GodownList.sort((a, b) => {
                  return b.created_at - a.created_at;
                })[0].id,
                urlText,
                type
              );
            }
            else{
              this.handleGodownChange(
                GodownList.sort((a, b) => {
                  return b.created_at - a.created_at;
                })[0].id
              );
            }
          }
        );
      } else {
        this.setState({
          InvList: [],
          historyList: [],
          GodownList: [],
          loadingDispatch: false,
        });
      }
    }
  };

  pageChangeAPI = (url) => {
    console.log(this.state?.tabNumber, this.state?.selctedTab, this.state?.selctedTab2, url, 'pagination')
    this.fetchDispatchList(
      this.state?.selctedTab === 'Arrival'
      ? this.state?.selctedTab2
      : this.state?.selctedTab, 
      url, 
      'pagination'
    );
  } 

  resetPaginationPage = (field) => {
    this.setState({ resetPage: field })
  }

  handleToggle = (data) => {
    this.setState({ displayPending: data }, () => {
      this.resetPaginationPage("resetPage");
      this.fetchDispatchList(this.state?.selctedTab2);
    });
  };

  changeTo = (to, weigths, opposite) => {
    if (opposite) {
      if (to === "MT") {
        return multiply(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return multiply(weigths, 100);
      }
    } else {
      if (to === "MT") {
        return divide(weigths, 1000);
      } else if (to === "Kgs") {
        return weigths;
      } else if (to === "Qtl") {
        return divide(weigths, 100);
      }
    }
  };
  changeUnit = (from, to, weigths, opposite) => {
    //default in kg

    if (opposite) {
      if (from === "MT") {
        return this.changeTo(to, divide(weigths, 1000), opposite);
      } else if (from === "Kgs") {
        return this.changeTo(to, weigths);
      } else if (from === "Qtl") {
        return this.changeTo(to, divide(weigths, 100), opposite);
      }
    } else {
      if (from === "MT") {
        return this.changeTo(to, multiply(weigths, 1000));
      } else if (from === "Kgs") {
        return this.changeTo(to, weigths);
      } else if (from === "Qtl") {
        return this.changeTo(to, multiply(weigths, 100));
      }
    }
  };
  handleConfirmApp = () => {
    const moveShippingArr = [];
    const { masters, singlelistView, approve, reject } = this.state;
    let singleDispatchData = approve || reject
    console.log(approve, singlelistView, this.state, "appreeeeeeeeove");
      let shippingAvailable = singleDispatchData.subList.find(
        (it) => it.arrival_item_status === "shipped"
      );
      let itemBulkArr = [];
      let packagingBulkArr = [];
      singleDispatchData.subList.map((d) => {
        const packaging = d.arrival_packagings[0];
        const dispatchdetail = filterData(
          d.dispatch_packaging,
          "id",
          packaging.dispatch_packaging,
          "onlyOne"
        );
        const returned_bags_of_packgings = {};
        returned_bags_of_packgings[dispatchdetail.packaging] =
          packaging.returned_bags || 0;

        packagingBulkArr.push({
          dispatch_packaging: dispatchdetail.id || "",
          arrival_item: packaging.arrival_item || "",
          packaging: packaging.packaging || "",
          returned_bags: packaging?.returned_bags || 0,
          godown: d.godown || "",
          id: packaging.id || "",
        });

        itemBulkArr.push({
          dispatch_items: d.dispatch_items || "",
          arrival: d.arrival || "",
          remark: d.remark || "",
          godown: d.godown || "",
          returned_bags_of_packgings: returned_bags_of_packgings,
          returned_bags: d.returned_bags || 0,
          id: d.id || "",
          port: singleDispatchData.portId || "",
          arrival_status: "reached",
          sales_order: d.sales_order || "",
          custom_invoice: d.custom_invoice || "",
          shipping_date: d.shipping_date || "",
          arrival_item_status: d.arrival_item_status || "",
          return_status: d.return_status || "",
          unloading_agent: d.unloading_agent?.id || "",
          mr_or_leo_no: d.mr_or_leo_no || "",
        });

        console.log(
          "itemBulkArr",
          itemBulkArr,
          "packagingBulkArr",
          packagingBulkArr
        );
      });
    if (approve && shippingAvailable) {
      approve.arrival_item_details.map((item) => {
        const packaging_count = {};
        const used_weight = {};

        const unit =
          masters && masters.salesOrderList && masters.salesOrderList.length > 0
            ? masters.salesOrderList?.find(
                (it) =>
                  it.so_number === approve.arrival_table_details.sales_order
              )?.unit ?? "kgs"
            : "kgs";

        item.dispatch_items_details.dispatch_packaging.map((packaging) => {
          const arrival_pac = item.arrival_packagings.find(
            (it) => it.packaging === packaging.packaging
          );
          const bag_count = subtract(
            packaging.total_bags_dispatched,
            arrival_pac?.returned_bags ?? 0
          );
          packaging_count[packaging.packaging] = bag_count;

          const calculatedValue = this.changeUnit(
            packaging.packaging_details.unit,
            unit,
            packaging.packaging_details.size
          );
          used_weight[packaging.packaging] = multiply(
            Number(calculatedValue ? calculatedValue : 0),
            bag_count
          );
          return packaging;
        });
        moveShippingArr.push({
          used_weight: used_weight,
          packaging_count: packaging_count,
          is_active: true,
          custom_invoice: item.custom_invoice,
          commercial_invoice: item.commercial_invoice,
          coming_from: "DIRECT",
          brand: item.dispatch_items_details.brand,
          godown: null,
          port: singlelistView.id,
          shipping_date: item.shipping_date || "",
          arrival_item: item.arrival_packagings[0].arrival_item || "",
        });
        return item;
      });
      console.log(moveShippingArr, "moveShippingArr");

      this.props
        .PortsFunction(
          "post",
          "arrival/commercial-invoice/packing-list/bulk-create",
          null,
          "post",
          moveShippingArr
        )
        .then((res4) => {
          if (res4.error) {
            this.setState({
              error: res4.data,
              loadingDialog: false,
              confirmApp: false,
              approve: false,
            });
          } else {
            this.handleClear();
            // this.fetchDispatchList(
            //   editArrival
            //     ? "Reached"
            //     : "Arrival"
            // );
            this.setState({
              success: "User Role Other Arrival should be Approved",
              loadingDialog: false,
              confirmApp: false,
              approve: false,
            });
          }
        });
    }
    this.props
      .PortsFunction(
        "patch",
        "arrival",
        this.state.approve ? this.state.approve.id : this.state.reject.id,
        null,
        { approval_status: this.state.approve ? "ACCEPTED" : "REJECTED" },
        null,
        null,
        "noeditparam"
      )
      .then((res) => {
        console.log(res, "resssss1");
        this.showSnackbar(this.state.approve ? "Approved Successfully" : "Rejected Successfully", "success")
        this.fetchDispatchList("Reached");
        if (approve) {
          this.props
            .PortsFunction(
              "put",
              "arrival/items/bulk-update",
              null,
              "post",
              itemBulkArr
            )
            .then((res2) => {
              console.log(res2, "resssss2");
              this.props
                .PortsFunction(
                  "put",
                  "arrival/packaging/bulk-update",
                  null,
                  "post",
                  packagingBulkArr
                )
                .then((res3) => {
                  console.log(res3, "resssss3");
                });
            });
        }
        if (res === "Arrival has been approved") {
          this.setState({
            confirmApp: false,
            approve: false,
            reject: false,
            snackDel: "approved",
          });
        }
        if (res.toString()[0] === "E") {
          this.setState({
            confirmApp: false,

            approve: false,
            reject: false,
          });
        }
      });
    this.setState({
      confirmApp: false,
      approve: false,
      reject: false,
    });
  };
  setInitialValues(props) {
    if (props[EndPoints["Ports"]] && props[EndPoints["Ports"]].length > 0) {
      props[EndPoints["Ports"]].sort((a, b) => b.id - a.id);
    }
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data =
        props[EndPoints["Ports"]] && props[EndPoints["Ports"]].length > 0
          ? props[EndPoints["Ports"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [];
      return tab;
    });
    this.setState({
      listViewArray:
        props[EndPoints["Ports"]] && props[EndPoints["Ports"]].length > 0
          ? props[EndPoints["Ports"]]
          : [],
      Ports: props.Ports,
      tabs,
      loading: props.loading,
    });
  }
  handleClear = (rowData="") => {
    const { PortDispatchedItemList } = this.state;
    if (PortDispatchedItemList.length > 0) {
      PortDispatchedItemList.map((sItem) => {
        const details = sItem.dispatch_items_details;
        const packaging = sItem.arrival_packagings; //details.dispatch_packaging//
        packaging.map((sPack) => {
          return this.setState({
            [`${sPack.dispatch_packaging}Returned`]: false,
          });
        });
        this.setState({
          [`${details.po_item}Status`]: false,
          [`${details.po_item}Godown`]: false,
          [`${details.po_item}Remark`]: false,
          [`${details.po_item}Sales`]: false,
          [`${details.po_item}ShippingDate`]: "",
          [`${details.po_item}Unloading Agent`]: "",
          [`${details.po_item}MR/LEO No.`]: "",
          [`${details.po_item}Cus`]: false,
          [`${details.po_item}customInvoices`]: [],
          [`${details.po_item}commercialInvoice`]: [],
        });
        return sItem;
      });
    }

    this.setState({
      arrival_date: new Date(),
      gross_weight: Number(rowData?.arrival_table_details?.dispatch_net_weight || 0).toFixed(3),
      net_weight: Number(rowData?.arrival_table_details?.dispatch_net_weight || 0).toFixed(3),
      tare_weight: "",
      surveyorCompany: this.state?.SurveyCompanyList.find(d => d.id === rowData?.arrival_table_details?.dispatch_survey_company)?.name || "",
      surveyorCompanyId: rowData?.arrival_table_details?.dispatch_survey_company || "",
      surveyor: "",
    },() => {
      this.setState({ loadingDialog: true });
                  this.props
                    .PortsFunction(
                      "get",
                      "masters/surveyor/list",
                      null,
                      "",
                      null,
                      {
                        is_active: "True",
                        company_id: this.state.surveyorCompanyId,
                      }
                    )
                    .then((res) => {
                      if (res) {
                        this.setState({
                          surveyorListForSurvay: res,
                          surveyor: res.find(d => d.id === rowData?.arrival_table_details?.dispatch_surveyor)?.name,
                          surveyorId: rowData?.arrival_table_details?.dispatch_surveyor,
                          loadingDialog: false,
                        });
                      } else {
                        this.setState({ loadingDialog: false });
                      }
                    });
    });
  };

  setWidth = (list, header) => {
    const { poItemheader } = this.state;

    if (list && list.length > 0) {
      if (list[0]) {
        const widthList = {};
        const array = header
          ? header
          : poItemheader
          ? poItemheader
          : Object.keys(list[0]);

        if (array && array.length > 0) {
          array.map((single, i) => {
            if (single) {
              return (widthList[array[i]] = `${
                Math.floor(
                  10 *
                    (single.toString().length > this.longest(single, list)
                      ? single.toString().length
                      : this.longest(single, list)
                      ? this.longest(single, list)
                      : 1)
                ) < 110
                  ? 110
                  : Math.floor(
                      10 *
                        (single.toString().length > this.longest(single, list)
                          ? single.toString().length
                          : this.longest(single, list)
                          ? this.longest(single, list)
                          : 1)
                    )
              }px`);
            }
            return single;
          });
        }
        this.setState({ widthList });
      }
    }
  };
  longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key].toString().length;
        } else {
          return 1;
        }
      })
    );
  };
  deleteItem = (godownNameToDelete) => {
    let { New } = this.state;

    let filteredGodown = New.filter(
      (godownName) => godownName !== godownNameToDelete
    );

    this.setState({
      New: filteredGodown,
    });
  };
  deleteUnloadingAgentItem = (uAToDelete) => {
    let { UnloadAgentArr } = this.state;

    let filtereduA = UnloadAgentArr.filter((uA) => uA !== uAToDelete);

    this.setState({
      UnloadAgentArr: filtereduA,
    });
  };

  addClick = () => {
    if (!this.state.New.includes("")) {
      this.setState((prevState) => ({ New: [...prevState.New, ""] }));
    } else {
      this.setState({ error: "add" });
    }
  };

  addUnloadingAgentClick = () => {
    if (!this.state.UnloadAgentArr.includes("")) {
      this.setState((prevState) => ({
        UnloadAgentArr: [...prevState.UnloadAgentArr, ""],
      }));
    } else {
      this.setState({ error: "add" });
    }
  };
  handleChange1 = (i, event) => {
    if (event.target.name === "UnloadingAgent") {
      let UnloadAgentArr = [...this.state.UnloadAgentArr];

      if (UnloadAgentArr[i] && UnloadAgentArr[i].name) {
        UnloadAgentArr[i].name = event.target.value;
      } else {
        UnloadAgentArr[i] = { name: event.target.value };
      }
      this.setState({
        UnloadAgentArr,
        errorMsg: { ...this.state.errorMsg, unloadingAgent: false },
      });
    } else {
      let New = [...this.state.New];

      if (New[i] && New[i].name) {
        New[i].name = event.target.value;
      } else {
        New[i] = { name: event.target.value };
      }
      this.setState({
        New,
        errorMsg: { ...this.state.errorMsg, Godown: false },
      });
    }
  };
  handleChange = (event) => {
    const errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;
    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };
  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  handleUnloadingAgentClose = () => {
    this.setState({ deleteUnloadingAgentDialog: false });
  };

  render() {
    const {
      loadingDialog,
      arrival_date,
      error,
      success,
      tabs,
      listViewArray,
      singlelistView,
      loading,
      portDialog,
      BrandList,
      GodownList,
      selectGodown,
      editList,
      ArrivalDialog,
      portUnreachedheader,
      widthList,
      colorArr,
      New,
      UnloadAgentArr,
      errorMsg,
      PortDispatchedList,
      PortDispatchedItemList,
      ItemList,
      ItemCateoryList,
      statusArrival,
      SurveyCompanyList,
      allsurveyorList,
      openAddBagDialog,
      loadingDispatch,
      editArrival,
      calenderOpen,
      deleteDialog,
      deleteUnloadingAgentDialog,
      gross_weight,
      tare_weight,
      surveyorListForSurvay,
      surveyorCompanyId,
      surveyorCompany,
      surveyor,
      returnButtonError,
      masters,
      tabNumber,
      ShippingList,
      commercialList,
      customList,
      packagingList,
      salesOrderList,
      confirmApp,
      paginationData,
      resetPage,
    } = this.state;
    // const{commercialList}=this.props;
    console.log(ShippingList, "wwwwwwwwwww");
    
    
   

   
    var labelField = "name";

    const setData = (singleSub, rowData) => {
      console.log("singleSub", singleSub, rowData);
      const details = singleSub.dispatch_items_details;
      singleSub["Brand"] = filterData(BrandList, "id", details.brand, "onlyOne")
        ? filterData(BrandList, "id", details.brand, "onlyOne").brand_name
        : "";

      singleSub["Item Category"] = filterData(
        ItemCateoryList,
        "id",
        details.item_category,
        "onlyOne"
      )
        ? filterData(ItemCateoryList, "id", details.item_category, "onlyOne")
            .name
        : "";
      singleSub["Item Type"] = filterData(
        ItemList,
        "id",
        details.item_type,
        "onlyOne"
      )
        ? `${Number(
            filterData(ItemList, "id", details.item_type, "onlyOne")
              .broken_percentage
          ).toFixed(2)} `
        : "";
      singleSub["Packaging"] =
        details.dispatch_packaging && details.dispatch_packaging.length > 0
          ? `  ${
              details.dispatch_packaging.map((sP) => {
                const singlePack = sP.packaging_details;

                return singlePack
                  ? `${singlePack.size}${singlePack.unit}${" - "}${
                      singlePack.bag_type
                    }`
                  : "";
              })[0]
            } Total (...s)`
          : "-";
      singleSub["PackagingUI"] = (
        <ShowPackagingPort
          noTotalbags={true}
          returned_bags={true}
          details={details.dispatch_packaging.map((sP) => {
            const singlePack = sP.packaging_details;
            singlePack.used_bags = sP.total_bags_dispatched;

            singlePack.returned_bags = this.state[`${sP.id}Returned`]
              ? this.state[`${sP.id}Returned`]
              : "";
            // singlePack.new_remaining_bags = subtract(
            // this.state
            //   .editingDispatch !==
            //   false
            //   ? sum([
            //       sP.remaining_bags,
            //       sP.used_bags_fordiapatch,
            //     ])
            //   :
            // sP.remaining_bags,
            // this.state[`${singlePackaging.dispatch_packaging}Returned`]
            //   ? this.state[`${singlePackaging.dispatch_packaging}Returned`]
            //   :
            //   this.state[`${sP.id}Returned`] ? this.state[`${sP.id}Returned`] : ""
            // );

            return singlePack;
          })}
        />
      );
      // singleSub["HSN Code"] = filterData(HSNlist, "id", details.hsn_code, "onlyOne")
      //   ? filterData(HSNlist, "id", details.hsn_code, "onlyOne").hsn_code
      //   : "";
      singleSub["Total Bags"] = details.total_bags ? details.total_bags : "";
      // singleSub["Contract Rate"] = details.contract_rate ? details.contract_rate : "";

      singleSub["Remark"] = "WEFWFFFjhpikjhioihFty";
      singleSub["RemarkUI"] = (
        <CssTextField95
          // required={true}
          type="text"
          autoComplete="off"
          style={
            { width: "100%", maxWidth: "100%" } //disabled={tp}
          }
          // error={errorMsg[`${details.po_item}Remark`] ? true : false}
          // helperText={errorMsg[`${details.po_item}Remark`] ? errorMsg[`${details.po_item}Remark`] : ""}

          id="outlined-basic"
          label="Remark"
          variant="outlined"
          name={`${details.po_item}Remark`}
          value={
            this.state[`${details.po_item}Remark`]
              ? this.state[`${details.po_item}Remark`]
              : ""
          }
          onChange={(event) => {
            const errorMsg = this.state.errorMsg;
            errorMsg[`${details.po_item}Remark`] = false;

            this.setState({
              [event.target.name]: event.target.value,
            });
          }}
        />
      );
      singleSub["Status"] = "WEFWFFFjhpikjhioihFty";
      singleSub["StatusUI"] = (
        <CssTextField95
          required={true}
          autoComplete="off"
          value={
            this.state[`${details.po_item}Status`]
              ? this.state[`${details.po_item}Status`]
              : "" //
          }
          onChange={(e) => {
            const errorMsg = this.state.errorMsg;
            errorMsg[`${details.po_item}Status`] = false;

            this.setState({
              [`${details.po_item}StatusId`]: e.currentTarget.id,
              [`${details.po_item}Status`]: e.target.value,
            });
          }}
          style={
            { width: "100%" } //disabled={tp}
          }
          id="outlined-select-currency-native"
          select
          label="Status"
          variant="outlined"
          error={errorMsg[`${details.po_item}Status`] ? true : false}
          // disabled={true}
          InputLabelProps={{}}
          SelectProps={{ MenuProps: MenuProps }}
        >
          {statusArrival &&
            statusArrival.length > 0 &&
            statusArrival.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
        </CssTextField95>
      );
      singleSub["Godown"] = "WEFWFFFjhpikjhioihFty";
      singleSub["GodownUI"] =
        this.state[`${details.po_item}Status`] &&
        this.state[`${details.po_item}Status`] === "Move to Godown" ? (
          <CssTextField95
            required={true}
            value={
              this.state[`${details.po_item}Godown`]
                ? this.state[`${details.po_item}Godown`]
                : "" //
            }
            error={errorMsg[`${details.po_item}Godown`] ? true : false}
            // error={errorMsg.ItemCategory ? true : false}
            // helperText={errorMsg.ItemCategory ? errorMsg.ItemCategory : ""}
            onChange={(e) => {
              const errorMsg = this.state.errorMsg;
              errorMsg[`${details.po_item}Godown`] = false;
              this.setState({
                [`${details.po_item}GodownId`]: e.currentTarget.id,
                [`${details.po_item}Godown`]: e.target.value,
              });
            }}
            style={
              { width: "100%" } //disabled={tp}
            }
            id="outlined-select-currency-native"
            select
            label="Godown"
            variant="outlined"
            // disabled={true}
            InputLabelProps={{}}
          >
            {singleSub.godowns_list &&
              singleSub.godowns_list.length > 0 &&
              singleSub.godowns_list.map((option) => (
                <MenuItem key={option.name} value={option.name} id={option.id}>
                  {option.name}
                </MenuItem>
              ))}
          </CssTextField95>
        ) : (
          "-"
        );
      singleSub["Shipping Date"] = "ewewewewewewe";
      singleSub["Shipping DateUI"] =
        this.state[`${details.po_item}Status`] &&
        this.state[`${details.po_item}Status`] === "Continue to Shipping" ? (
          <MuiPickersUtilsProvider libInstance={moment()} utils={DateFnsUtils}>
            <CssKeyboardDatePickerSmall
              label={"Shipping Date"}
              variant="inline"
              onKeyUp={(ev) => {
                ev.preventDefault();
              }}
              onKeyDown={(ev) => {
                ev.preventDefault();
              }}
              onOpen={() => {
                this.setState({ shippingDateOpen: true });
              }}
              onClose={() => {
                this.setState({ shippingDateOpen: false });
              }}
              // minDate={"2022-01-22"}
              // error={errorMsg.date ? true : false}
              // helperText={errorMsg.date ? errorMsg.date : ""}
              invalidLabel=""
              onKeyPress={(ev) => {
                ev.preventDefault();
              }}
              allowKeyboardControl={false}
              keyboardIcon={
                this.state.shippingDateOpen ? <EventIcon /> : <EventIcon />
              }
              autoOk
              inputVariant="outlined"
              style={{ width: "100%" }}
              format="dd MMM, yyyy"
              size="small"
              value={
                this.state[`${details.po_item}ShippingDate`]
                  ? this.state[`${details.po_item}ShippingDate`]
                  : new Date()
              }
              //views={['year', 'month']}
              InputAdornmentProps={{ position: "end" }}
              onChange={(newValue) => {
                this.setState({
                  [`${details.po_item}ShippingDate`]:
                    moment(newValue).format("YYYY-MM-DD"),
                });
              }}
            />
          </MuiPickersUtilsProvider>
        ) : (
          "-"
        );
      singleSub["Unloading Agent"] = "ewewewewewewe";
      singleSub["Unloading AgentUI"] =
        this.state[`${details.po_item}Status`] &&
        this.state[`${details.po_item}Status`] === "Continue to Shipping" ? (
          <CssTextField95
            autoComplete="off"
            value={
              this.state[`${details.po_item}Unloading Agent`]
                ? this.state[`${details.po_item}Unloading Agent`]
                : "" //
            }
            error={errorMsg[`${details.po_item}Unloading Agent`] ? true : false}
            helperText={
              errorMsg[`${details.po_item}Unloading Agent`]
                ? errorMsg[`${details.po_item}Unloading Agent`]
                : ""
            }
            onChange={(e) => {
              const errorMsg = this.state.errorMsg;
              errorMsg[`${details.po_item}Unloading Agent`] = false;
              this.setState({
                [`${details.po_item}Unloading AgentId`]: e.currentTarget.id,
                [`${details.po_item}Unloading Agent`]: e.target.value,
              });
              console.log("Unloading Agent", this.state, details);
            }}
            style={{ width: "100%" }}
            id="outlined-select-currency-native"
            select
            label="Unloading Agent"
            variant="outlined"
            InputLabelProps={{}}
            SelectProps={{ MenuProps: MenuProps }}
          >
            {console.log(masters.UnloadingAgentList, "sdfcgvhbjn")}
            {masters &&
            masters.UnloadingAgentList &&
            masters.UnloadingAgentList.length > 0 &&
            masters.UnloadingAgentList.length > 0 ? (
              masters.UnloadingAgentList.sort((a, b) => b.id - a.id).map(
                (option) => (
                  <MenuItem
                    key={option.name}
                    value={option.name}
                    id={option.id}
                  >
                    {option.name}
                  </MenuItem>
                )
              )
            ) : (
              <MenuItem
                disabled={true}
                key={"No data!"}
                value={"No data!"}
                id={"No data!"}
              >
                No data!
              </MenuItem>
            )}
          </CssTextField95>
        ) : (
          "-"
        );
      singleSub["MR/LEO No."] = "WEFWFFFjhpikjhioihFty";
      singleSub["MR/LEO No.UI"] =
        this.state[`${details.po_item}Status`] === "Continue to Shipping" ? (
          <CssTextField95
            // required={true}
            type="text"
            autoComplete="off"
            style={{ width: "100%", maxWidth: "100%" }}
            id="outlined-basic"
            label="MR/LEO No."
            variant="outlined"
            name={`${details.po_item}MR/LEO No.`}
            value={
              this.state[`${details.po_item}MR/LEO No.`]
                ? this.state[`${details.po_item}MR/LEO No.`]
                : ""
            }
            onChange={(event) => {
              const errorMsg = this.state.errorMsg;
              errorMsg[`${details.po_item}MR/LEO No.`] = false;

              this.setState({
                [event.target.name]: event.target.value,
              });
            }}
          />
        ) : (
          "-"
        );
      singleSub["Sales Order"] = "WEFWFFFjhpikjhioihFty";
      singleSub["Commercial Invoice"] = "WEFWFFFjhpikjhioihFty";
      singleSub["Custom Invoice"] = "WEFWFFFjhpikjhioihFty";
      singleSub["Sales OrderUI"] =
        this.state[`${details.po_item}Status`] &&
        this.state[`${details.po_item}Status`] === "Continue to Shipping" ? (
          <CssTextField95
            required={true}
            autoComplete="off"
            value={
              this.state[`${details.po_item}Sales`]
                ? this.state[`${details.po_item}Sales`]
                : "" //
            }
            error={errorMsg[`${details.po_item}Sales`] ? true : false}
            helperText={
              errorMsg[`${details.po_item}Sales`]
                ? errorMsg[`${details.po_item}Sales`]
                : ""
            }
            onChange={(e) => {
              const errorMsg = this.state.errorMsg;
              errorMsg[`${details.po_item}Sales`] = false;
              this.setState({
                [`${details.po_item}SalesId`]: e.currentTarget.id,
                [`${details.po_item}Sales`]: e.target.value,
              });
              console.log("SALES", this.state, details);
              this.props
                .PortsFunction(
                  "get",
                  "arrival/custom-invoice/list",
                  null,
                  "PortCIs",
                  null,
                  {
                    sales_order: e.currentTarget.id,
                    // shipment_status: "EXPENSES CALCULATED",
                    is_active: "True",
                    // port_id: singlelistView && singlelistView.id && singlelistView.id,
                    brand: details.brand,
                    status: "DRAFT,OPEN",
                  }
                )
                .then((customInvoices) => {
                  if (customInvoices) {
                    const portID =
                      this.state?.singlelistView &&
                      this.state?.singlelistView?.id;
                    const filterCustomInvoiceData = customInvoices.filter(
                      (c) => c.loading_port === portID
                    );
                    console.log(
                      "FILTER SALES CUSTOM",
                      filterCustomInvoiceData,
                      customInvoices
                    );
                    this.setState({
                      [`${details.po_item}customInvoices`]:
                        filterCustomInvoiceData,
                    });
                  } else {
                    this.setState({
                      [`${details.po_item}customInvoices`]: [],
                    });
                  }
                });
              // this.props
              //   .PortsFunction(
              //     "get",
              //     "arrival/commercial-invoice/list",
              //     null,
              //     "PortCIs",
              //     null,
              //     {
              //       sales_order_id: e.currentTarget.id,
              //       status: "DRAFT,OPEN",
              //     }
              //   )
              //   .then((commercialInvoice) => {
              //     if (commercialInvoice) {
              //       this.setState({
              //         [`${details.po_item}commercialInvoice`]:
              //           commercialInvoice,
              //         [`${details.po_item}ComId`]: false,
              //         [`${details.po_item}Com`]: false,
              //         [`${details.po_item}CusId`]: false,
              //         [`${details.po_item}Cus`]: false,

              //         [`${details.po_item}customInvoices`]: [],
              //       });
              //     } else {
              //       this.setState({
              //         [`${details.po_item}commercialInvoice`]: [],
              //         [`${details.po_item}ComId`]: false,
              //         [`${details.po_item}Com`]: false,
              //         [`${details.po_item}CusId`]: false,
              //         [`${details.po_item}Cus`]: false,

              //         [`${details.po_item}customInvoices`]: [],
              //       });
              //     }
              //   });
            }}
            style={
              { width: "100%" } //disabled={tp}
            }
            id="outlined-select-currency-native"
            select
            label="Sales Order"
            variant="outlined"
            InputLabelProps={{}}
            SelectProps={{ MenuProps: MenuProps }}
          >
            {console.log(
              details,
              masters.salesOrderList.filter((s) =>
                s.so_items_brands.includes(details.brand)
              ),
              "sdfcgvhbjn"
            )}
            {masters &&
            masters.salesOrderList &&
            masters.salesOrderList.length > 0 &&
            masters.salesOrderList.filter((s) =>
              s.so_items_brands.includes(details.brand)
            ) ? (
              masters.salesOrderList
                .filter((s) => s.so_items_brands.includes(details.brand))
                .filter((s) =>
                  s.so_packaging_brands[details.brand].includes(
                    details.dispatch_packaging[0].packaging
                  )
                )
                .sort((a, b) => b.id - a.id)
                .map((option) => (
                  <MenuItem
                    key={option.so_number}
                    value={option.so_number}
                    id={option.id}
                  >
                  <div className="d-flex d-flex-column ">
                    <span>
                    {option.so_number}
                    </span>
                    <span style={{fontSize: 12, color: '#888',}}>
                      Remaining : {
                        option?.remaining_quantity || ""
                      } {option?.unit || ""}
                    </span>
                    </div>
                  </MenuItem>
                ))
            ) : (
              <MenuItem
                disabled={true}
                key={"No data!"}
                value={"No data!"}
                id={"No data!"}
              >
                No data!
              </MenuItem>
            )}
          </CssTextField95>
        ) : (
          "-"
        );
      singleSub["Custom InvoiceUI"] =
        this.state[`${details.po_item}Status`] &&
        this.state[`${details.po_item}Status`] === "Continue to Shipping" ? (
          this.state[`${details.po_item}Sales`] ? (
            <CssTextField95
              required={true}
              autoComplete="off"
              value={
                this.state[`${details.po_item}Cus`]
                  ? this.state[`${details.po_item}Cus`]
                  : "" //
              }
              error={errorMsg[`${details.po_item}Cus`] ? true : false}
              helperText={
                errorMsg[`${details.po_item}Cus`]
                  ? errorMsg[`${details.po_item}Cus`]
                  : ""
              }
              onChange={(e) => {
                const errorMsg = this.state.errorMsg;
                errorMsg[`${details.po_item}Cus`] = false;
                const selectedCiDetail = filterData(
                  this.state[`${details.po_item}customInvoices`],
                  "id",
                  Number(e.currentTarget.id),
                  "onlyone"
                )
                  ? filterData(
                      this.state[`${details.po_item}customInvoices`],
                      "id",
                      Number(e.currentTarget.id),
                      "onlyone"
                    )
                  : "";
                const checkCustomInvoice = (cI, packaging, noBags) => {
                  // pkg_counter
                  const array = [];
                  const requires = [];
                  cI.custom_invoice_item_details.map((item) => {
                    console.log(
                      item,
                      Number(item.pkg_counter[packaging.packaging]),
                      packaging,
                      Number(noBags)
                    );
                    if (
                      item.pkg_counter[packaging.packaging] &&
                      (Number(item.pkg_counter[packaging.packaging]) >
                        Number(noBags) ||
                        Number(item.pkg_counter[packaging.packaging]) ===
                          Number(noBags))
                    ) {
                      array.push("success");
                    }
                    requires.push(item.pkg_counter);
                    return item;
                  });
                  if (array.length > 0) {
                    return "success";
                  } else {
                    return {
                      packagings: packaging.packaging_details,
                      bags: noBags,
                      ciPackagings: requires,
                    };
                  }
                };
                const weight = subtract(
                  Number(
                    selectedCiDetail.net_weight
                      ? selectedCiDetail.net_weight
                      : 0
                  ),
                  Number(
                    selectedCiDetail.used_weight
                      ? selectedCiDetail.used_weight
                      : 0
                  )
                );

                const unit = selectedCiDetail.unit;
                const id1 = [];
                const total_value = [];
                const arrpfShip = [];
                const errors = [];
                console.log(details);
                details.dispatch_packaging.map((singlePack) => {
                  const calculatedValue = this.changeUnit(
                    singlePack.packaging_details.unit,
                    unit,
                    singlePack.packaging_details.size
                  );
                  if (
                    !id1.includes(singlePack.packaging) &&
                    checkCustomInvoice(
                      selectedCiDetail,
                      singlePack,
                      subtract(
                        singlePack.total_bags_dispatched,
                        this.state[`${singlePack.id}Returned`]
                          ? this.state[`${singlePack.id}Returned`]
                          : 0
                      )
                    ) === "success"
                  ) {
                    total_value.push(
                      multiply(
                        Number(calculatedValue ? calculatedValue : 0),
                        subtract(
                          singlePack.total_bags_dispatched,
                          this.state[`${singlePack.id}Returned`]
                            ? this.state[`${singlePack.id}Returned`]
                            : 0
                        )
                      )
                    );
                    id1.push(singlePack.packaging);
                  } else {
                    errors.push(
                      checkCustomInvoice(
                        selectedCiDetail,
                        singlePack,
                        subtract(
                          singlePack.total_bags_dispatched,
                          this.state[`${singlePack.id}Returned`]
                            ? this.state[`${singlePack.id}Returned`]
                            : 0
                        )
                      )
                    );
                  }
                  arrpfShip.push({
                    // used_weight: sum([
                    //   Number(selectedCiDetail && selectedCiDetail.used_weight ? selectedCiDetail.used_weight : 0),
                    //   multiply(
                    //     Number(calculatedValue ? calculatedValue : 0),
                    //     subtract(
                    //       singlePack.total_bags_dispatched,
                    //       this.state[`${singlePack.id}Returned`] ? this.state[`${singlePack.id}Returned`] : 0
                    //     )
                    //   ),
                    // ]),
                    // bl_number:
                    //   selectedCiDetail && selectedCiDetail.bl_number
                    //     ? selectedCiDetail.bl_number
                    //     : "",
                    // bag_number: subtract(
                    //   singlePack.total_bags_dispatched,
                    //   this.state[`${singlePack.id}Returned`] ? this.state[`${singlePack.id}Returned`] : 0
                    // ),
                    used_weight: {
                      [singlePack.packaging]:
                        // sum([
                        //   Number(selectedCiDetail && selectedCiDetail.used_weight ? selectedCiDetail.used_weight : 0),
                        multiply(
                          Number(calculatedValue ? calculatedValue : 0),
                          subtract(
                            singlePack.total_bags_dispatched,
                            this.state[`${singlePack.id}Returned`]
                              ? this.state[`${singlePack.id}Returned`]
                              : 0
                          )
                        ),
                      // ]),
                    },
                    packaging_count: {
                      [singlePack.packaging]: subtract(
                        singlePack.total_bags_dispatched,
                        this.state[`${singlePack.id}Returned`]
                          ? this.state[`${singlePack.id}Returned`]
                          : 0
                      ),
                    },
                    is_active: true,
                    custom_invoice: e.currentTarget.id, // selctedCi.id

                    commercial_invoice: this.state[`${details.po_item}ComId`], // selctedCi.id
                    coming_from: "DIRECT",
                    // packaging: singlePack.packaging,

                    brand: details.brand,
                    godown: this.state[`${details.po_item}GodownId`]
                      ? this.state[`${details.po_item}GodownId`]
                      : null,
                    port: singlelistView.id,
                  });
                  return singlePack;
                });
                if (
                  total_value &&
                  total_value.length > 0 &&
                  total_value.reduce(
                    (previousValue1, currentValue1) =>
                      Number(previousValue1) +
                      Number(currentValue1 ? currentValue1 : 0)
                  ) <= weight
                ) {
                  this.setState({
                    [`${details.po_item}CusId`]: e.currentTarget.id,
                    [`${details.po_item}arrpfShip`]: arrpfShip,
                    [`${details.po_item}Cus`]: e.target.value,
                  });
                } else {
                  console.log(errors, "errorserrors");
                  this.setState({
                    error:
                      errors.length > 0
                        ? `${errors.map((s) => {
                            return `${s.bags} bags of ${s.packagings.size}${s.packagings.unit}`;
                          })} is not available in this custom invoice!`
                        : "Less Quantity is available in selected Custom Invoice!",
                  });
                }
                selectedCiDetail?.commercial_invoice &&
                  this.props
                    .PortsFunction(
                      "get",
                      `arrival/commercial-invoice/${selectedCiDetail?.commercial_invoice}`,
                      null,
                      "PortCIs",
                      null,
                      {}
                    )
                    .then((commercialInvoice) => {
                      if (commercialInvoice) {
                        this.setState({
                          [`${details.po_item}commercialInvoice`]:
                            commercialInvoice,
                          [`${details.po_item}ComId`]: false,
                          [`${details.po_item}Com`]: false,
                        });
                      } else {
                        this.setState({
                          [`${details.po_item}commercialInvoice`]: [],
                          [`${details.po_item}ComId`]: false,
                          [`${details.po_item}Com`]: false,
                        });
                      }
                    });
              }}
              style={
                { width: "100%" } //disabled={tp}
              }
              id="outlined-select-currency-native"
              select
              label="Custom Invoice"
              variant="outlined"
              InputLabelProps={{}}
              SelectProps={{ MenuProps: MenuProps }}
            >
              {this.state[`${details.po_item}customInvoices`] &&
              this.state[`${details.po_item}customInvoices`].length > 0 ? (
                this.state[`${details.po_item}customInvoices`]
                  .sort((a, b) => b.id - a.id)
                  .map((option) => (
                    <MenuItem
                      key={option.invoice_number}
                      value={option.invoice_number}
                      id={option.id}
                    >
                      {option.invoice_number}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem
                  disabled={true}
                  key={"No data!"}
                  value={"No data!"}
                  id={"No data!"}
                >
                  No data!
                </MenuItem>
              )}
            </CssTextField95>
          ) : (
            "-"
          )
        ) : (
          "-"
        );

      singleSub["Commercial InvoiceUI"] =
        this.state[`${details.po_item}Status`] &&
        this.state[`${details.po_item}Status`] === "Continue to Shipping" ? (
          this.state[`${details.po_item}Sales`] ? (
            <CssTextField95
              // required={true}
              autoComplete="off"
              value={
                this.state[`${details.po_item}Com`]
                  ? this.state[`${details.po_item}Com`]
                  : "" //
              }
              error={errorMsg[`${details.po_item}Com`] ? true : false}
              helperText={
                errorMsg[`${details.po_item}Com`]
                  ? errorMsg[`${details.po_item}Com`]
                  : ""
              }
              onChange={(e) => {
                const errorMsg = this.state.errorMsg;
                errorMsg[`${details.po_item}Com`] = false;

                this.setState({
                  [`${details.po_item}ComId`]: e.currentTarget.id,
                  [`${details.po_item}Com`]: e.target.value,
                });
              }}
              style={
                { width: "100%" } //disabled={tp}
              }
              id="outlined-select-currency-native"
              select
              label="Commercial Invoice"
              variant="outlined"
              InputLabelProps={{}}
              SelectProps={{ MenuProps: MenuProps }}
            >
              {this.state[`${details.po_item}commercialInvoice`] &&
              this.state[`${details.po_item}commercialInvoice`] ? (
                <MenuItem
                  key={
                    this.state[`${details.po_item}commercialInvoice`]
                      .invoice_number
                  }
                  value={
                    this.state[`${details.po_item}commercialInvoice`]
                      .invoice_number
                  }
                  id={this.state[`${details.po_item}commercialInvoice`].id}
                >
                  {
                    this.state[`${details.po_item}commercialInvoice`]
                      .invoice_number
                  }
                </MenuItem>
              ) : (
                <MenuItem
                  disabled={true}
                  key={"No data!"}
                  value={"No data!"}
                  id={"No data!"}
                >
                  No data!
                </MenuItem>
              )}
            </CssTextField95>
          ) : (
            "-"
          )
        ) : (
          "-"
        );
      return singleSub;
    };

    const filterChild = () => {
      var godwn = [];
      if (GodownList && GodownList.length > 0) {
        GodownList.map((item) => {
          godwn.push({
            value: item.id,
            label: item.name,
          });
          return item;
        });
      }
      return (
        <CssTextField95
          style={{ 
            width: mediumScreen() ? "160px" : "200px", 
            margin: "0px",
            fontSize: mediumScreen() ? "0.8rem" : "0.9rem" ,
            fontFamily: 'Poppins'
          }}
          variant="outlined"
          select
          label="Godown"
          value={selectGodown}
          onChange={(e) => {
            this.handleGodownChange(e.target.value);
          }}
        >
          {godwn.map((option) => (
            <MenuItem 
              key={option.value} 
              value={option.value} 
              style={{ 
                fontSize: mediumScreen() ? "0.8rem" : "0.9rem",
                fontFamily: 'Poppins' 
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </CssTextField95>

        // <CssTextField
        //   required={true}
        //   autoComplete="off"
        //   style={
        //     { width: "200px", margin: "0px" } //disabled={tp}
        //   }
        //   id="outlined-select-currency-native"
        //   select
        //   label="Godown"
        //   variant="outlined"
        //   // disabled={true}
        //   InputLabelProps={{}}
        // >
        //   {/* {ItemCateoryList &&
        //   ItemCateoryList.length > 0 &&
        //   ItemCateoryList.map((option) => (
        //     <MenuItem key={option.name} value={option.name} id={option.id}>
        //       {option.name}
        //     </MenuItem>
        //   ))}*/}
        // </CssTextField>
      );
    };

    const portUnreachedTable = (
      edit,
      array,
      addBag,
      noTotalQuantity,
      showTotal,
      width,
      submittedData
    ) => {
      return (
        <div
          style={{
            margin: "20px 0 20px 0",
            height: "calc(100% - 40px)",
            width: width ? width : edit ? "100%" : "calc(100% - 30px)",
            minWidth: width && "100%",
            fontSize:  mediumScreen() ? "0.75rem" : "0.9rem",
          }}
        >
        {console.log("PORT",array, )}
          <div style={{ display: "flex", marginBottom: "20px" }}>
            <MuiPickersUtilsProvider
              libInstance={moment()}
              utils={DateFnsUtils}
            >
              <CssKeyboardDatePicker
                label={"Arrival Date"}
                variant="inline"
                onKeyUp={(ev) => {
                  ev.preventDefault();
                }}
                onKeyDown={(ev) => {
                  ev.preventDefault();
                }}
                onOpen={() => {
                  this.setState({ calenderOpen: true });
                }}
                onClose={() => {
                  this.setState({ calenderOpen: false });
                }}
                // minDate={"2022-01-22"}
                error={errorMsg.arrival_date ? true : false}
                helperText={errorMsg.arrival_date ? errorMsg.arrival_date : ""}
                invalidLabel=""
                onKeyPress={(ev) => {
                  ev.preventDefault();
                }}
                allowKeyboardControl={false}
                keyboardIcon={calenderOpen ? <EventIcon /> : <EventIcon />}
                autoOk
                inputVariant="outlined"
                style={
                  { width: "100%", maxWidth: "100%", marginRight: "20px" } //disabled={tp}
                }
                format="dd MMM, yyyy"
                value={arrival_date ? arrival_date : new Date()}
                //views={['year', 'month']}
                InputAdornmentProps={{ position: "end" }}
                onChange={(newValue) => {
                  this.setState({
                    arrival_date: newValue,
                    errorMsg,
                  });
                }}
              />
            </MuiPickersUtilsProvider>
            <CssTextField
              required={true}
              type="number"
              onFocus={StopScroll}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              onKeyDown={(evt) => {
                // if (singleInput.only_number === true) {
                //   evt.key === "." && evt.preventDefault();
                // }
                evt.key === "e" && evt.preventDefault();

                evt.key === "E" && evt.preventDefault();
                evt.key === "-" && evt.preventDefault();
                evt.keyCode === 38 && evt.preventDefault();
                evt.keyCode === 40 && evt.preventDefault();
              }}
              autoComplete="off"
              style={
                { width: "100%", maxWidth: "100%", marginRight: "20px" } //disabled={tp}
              }
              error={errorMsg.gross_weight ? true : false}
              helperText={errorMsg.gross_weight ? errorMsg.gross_weight : ""}
              id="outlined-basic"
              label="Port Gross Weight"
              variant="outlined"
              name={"gross_weight"}
              value={this.state.gross_weight ? this.state.gross_weight : ""}
              onChange={(event) => {
                const errorMsg = this.state.errorMsg;
                errorMsg.gross_weight = false;

                errorMsg.net_weight = false;
                if (
                  (event.target.value ? Number(event.target.value) : 0) <=
                  (tare_weight ? tare_weight : 0)
                ) {
                  this.setState({
                    tare_weight: 0,
                    error:
                      "Tare weight can't be greater or equal to Gross weight!",
                  });
                } else {
                  this.setState({
                    net_weight: Number(
                      (event.target.value ? Number(event.target.value) : 0) -
                        (tare_weight ? tare_weight : 0)
                    ).toFixed(3),
                  });
                }
                this.setState({
                  [event.target.name]: event.target.value,
                  errorMsg,
                });

                return event;
              }}
            />
            <CssTextField
              required={true}
              type="number"
              onFocus={StopScroll}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              onKeyDown={(evt) => {
                // if (singleInput.only_number === true) {
                //   evt.key === "." && evt.preventDefault();
                // }
                evt.key === "e" && evt.preventDefault();

                evt.key === "E" && evt.preventDefault();
                evt.key === "-" && evt.preventDefault();
                evt.keyCode === 38 && evt.preventDefault();
                evt.keyCode === 40 && evt.preventDefault();
              }}
              autoComplete="off"
              style={
                { width: "100%", maxWidth: "100%", marginRight: "20px" } //disabled={tp}
              }
              error={errorMsg.tare_weight ? true : false}
              helperText={errorMsg.tare_weight ? errorMsg.tare_weight : ""}
              id="outlined-basic"
              label="Port Tare Weight"
              variant="outlined"
              name={"tare_weight"}
              value={this.state.tare_weight ? this.state.tare_weight : ""}
              onChange={(event) => {
                const errorMsg = this.state.errorMsg;
                errorMsg.tare_weight = false;
                errorMsg.net_weight = false;

                if (
                  (gross_weight ? Number(gross_weight) : 0) <=
                  (event.target.value ? event.target.value : 0)
                ) {
                  this.setState({
                    tare_weight: 0,
                    error: "Tare weight can't be equal to Gross weight !",
                  });
                } else {
                  this.setState({
                    [event.target.name]: event.target.value,

                    net_weight: Number(
                      (gross_weight ? Number(gross_weight) : 0) -
                        (event.target.value ? event.target.value : 0)
                    ).toFixed(3),
                  });
                }
                this.setState({
                  errorMsg,
                });

                return event;
              }}
            />
            <CssTextField
              required={true}
              type="number"
              onFocus={StopScroll}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              onKeyDown={(evt) => {
                // if (singleInput.only_number === true) {
                //   evt.key === "." && evt.preventDefault();
                // }
                evt.key === "e" && evt.preventDefault();

                evt.key === "E" && evt.preventDefault();
                evt.key === "-" && evt.preventDefault();
                evt.keyCode === 38 && evt.preventDefault();
                evt.keyCode === 40 && evt.preventDefault();
              }}
              disabled={true}
              autoComplete="off"
              style={
                { width: "100%", maxWidth: "100%", marginRight: "20px" } //disabled={tp}
              }
              error={errorMsg.net_weight ? true : false}
              helperText={errorMsg.net_weight ? errorMsg.net_weight : ""}
              id="outlined-basic"
              label="Port Net weight"
              variant="outlined"
              name={"net_weight"}
              value={this.state.net_weight ? this.state.net_weight : ""}
              // onChange={(event) => {
              //   const errorMsg = this.state.errorMsg;
              //   errorMsg.net_weight = false;

              //   this.setState({
              //     [event.target.name]: event.target.value,
              //     errorMsg,
              //   });
              // }}
            />
          </div>

          <div style={{ display: "flex", marginBottom: "20px" }}>
            <CssTextField
              required={true}
              autoComplete="off"
              value={
                surveyorCompany ? surveyorCompany : "" //
              }
              error={errorMsg.surveyorCompany ? true : false}
              helperText={
                errorMsg.surveyorCompany ? errorMsg.surveyorCompany : ""
              }
              onChange={(e) => {
                const errorMsg = this.state.errorMsg;
                errorMsg.surveyorCompany = false;
                if (SurveyCompanyList && SurveyCompanyList.length > 0) {
                  this.setState({ loadingDialog: true });
                  this.props
                    .PortsFunction(
                      "get",
                      "masters/surveyor/list",
                      null,
                      "",
                      null,
                      {
                        is_active: "True",
                        company_id: e.currentTarget.id,
                      }
                    )
                    .then((res) => {
                      if (res) {
                        this.setState({
                          surveyorListForSurvay: res,
                          loadingDialog: false,
                        });
                      } else {
                        this.setState({ loadingDialog: false });
                      }
                    });
                }
                this.setState(
                  {
                    errorMsg,
                    surveyorCompany: e.target.value,
                    surveyorCompanyId: e.currentTarget.id,
                  },
                  () => {}
                );
              }}
              style={
                { width: "calc(25% - 20px)", marginRight: "20px" } //disabled={tp}
              }
              id="outlined-select-currency-native"
              select
              label="Survey Company"
              variant="outlined"
              InputLabelProps={{}}
            >
              {SurveyCompanyList &&
                SurveyCompanyList.length > 0 &&
                SurveyCompanyList.map((option) => (
                  <MenuItem
                    key={option.name}
                    value={option.name}
                    id={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
            </CssTextField>

            {surveyorCompanyId && (
              <CssTextField
                required={false}
                autoComplete="off"
                value={
                  surveyor ? surveyor : "" //
                }
                error={errorMsg.surveyor ? true : false}
                helperText={errorMsg.surveyor ? errorMsg.surveyor : ""}
                onChange={(e) => {
                  const errorMsg = this.state.errorMsg;
                  errorMsg.surveyor = false;
                  this.setState({
                    errorMsg,
                    surveyor: e.target.value,
                    surveyorId: e.currentTarget.id,
                  });
                }}
                style={
                  { width: "calc(25% - 20px)", marginRight: "20px" } //disabled={tp}
                }
                id="outlined-select-currency-native"
                select
                label="Surveyor"
                variant="outlined"
                InputLabelProps={{}}
              >
                {surveyorListForSurvay &&
                  surveyorListForSurvay.length > 0 &&
                  surveyorListForSurvay.map((option) => (
                    <MenuItem
                      key={option.name}
                      value={option.name}
                      id={option.id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
              </CssTextField>
            )}
            {/* <CssTextField
              required={true}
              type="text"
              disabled={true}
              autoComplete="off"
              style={
                { width: "calc(25% - 20px)" } //disabled={tp}
              }
              error={errorMsg.unloading_agent ? true : false}
              helperText={
                errorMsg.unloading_agent ? errorMsg.unloading_agent : ""
              }
              id="outlined-basic"
              label="Unloading Agent"
              variant="outlined"
              name={"unloading_agent"}
              value={
                singlelistView.unloading_agent
                  ? singlelistView.unloading_agent
                  : ""
              }
              // onChange={(event) => {
              //   const errorMsg = this.state.errorMsg;
              //   errorMsg.net_weight = false;

              //   this.setState({
              //     [event.target.name]: event.target.value,
              //     errorMsg,
              //   });
              // }}
            /> */}
          </div>
          <Paper
            className="d-flex d-flex-column  overflowXA positionR scrollBluePrimary "
            style={{
              height: "75%",
              width: "100%",
              borderRadius: "7px",
            }}
          >
            <div
              style={{
                backgroundColor: "#8080801a",
                color: primary,
                borderColor: primary,
                width: "fit-content",
                minWidth: "100%",
                tableLayout: "fixed",
                display: "flex",
                alignItems: "center",
                height: "30px",
                minHeight: "30px",
                overflow: "hidden",
                borderRadius: "7px 7px 0 0",
              }}
            >
              <div
                className="d-flex alignC justifySE width-100"
                style={{ background: "rgb(128 128 128 / 64%)" }}
              >
                {portUnreachedheader &&
                  portUnreachedheader.map((name, i) =>
                    noTotalQuantity && name === "Total Quantity" ? (
                      ""
                    ) : (
                      <div
                        className="justifyC d-flex alignC"
                        style={{
                          margin: "12px 10px",
                          width: [
                            "Unloading Agent",
                            "Shipping Date",
                            "MR/LEO No.",
                          ].includes(name)
                            ? 210
                            : widthList && widthList[portUnreachedheader[i]]
                            ? widthList[portUnreachedheader[i]]
                            : "fit-content",
                          minWidth: "fit-content",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                          textTransform: "capitalize",
                        }}
                      >
                        {name}
                      </div>
                    )
                  )}
                {edit && (
                  <div
                    className="justifyC d-flex alignC"
                    style={{
                      margin: "12px 10px",
                      width: "65px",
                      display: "flex",
                      alignItems: "center",
                      color: "#100f0f",
                      textTransform: "capitalize",
                    }}
                  >
                    Edit
                  </div>
                )}
              </div>
            </div>
            {array && array.length > 0 ? (
              <>
                <div
                  className="scrollBluePrimary"
                  style={{
                    height: "fit-content",
                    overflowY: "auto",
                    width: "fit-content",
                    minWidth: "100%",
                    position: "relative",
                    background: "rgb(128 128 128 / 10%)",
                  }}
                >
                  {array.map(
                    (singleDate, i) =>
                      singleDate && (
                        <>
                          <div className="d-flex width-100 d-flex-column justifySE">
                            <div
                              className="d-flex width-100 justifySE"
                              style={{
                                // margin: "1px 0 0 0",
                                backgroundColor:
                                  colorArr && colorArr[i % colorArr.length],
                                height: edit === "checkBox" ? "44px" : "auto",
                              }}
                            >
                              {portUnreachedheader &&
                                portUnreachedheader.map((name) =>
                                  noTotalQuantity &&
                                  name === "Total Quantity" ? (
                                    ""
                                  ) : (
                                    <div
                                      className="d-flex alignC justifyC "
                                      style={{
                                        margin: "12px 10px",
                                        width: [
                                          "Unloading Agent",
                                          "Shipping Date",
                                          "MR/LEO No.",
                                        ].includes(name)
                                          ? 210
                                          : widthList && widthList[name]
                                          ? widthList[name]
                                          : "fit-content",
                                        minWidth: [
                                          "Unloading Agent",
                                          "Shipping Date",
                                          "MR/LEO No.",
                                        ].includes(name)
                                          ? 210
                                          : widthList && widthList[name]
                                          ? widthList[name]
                                          : "fit-content",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {singleDate[name]
                                        ? singleDate[`${name}UI`]
                                          ? singleDate[`${name}UI`]
                                          : singleDate[`${name}Unit`]
                                          ? singleDate[`${name}Unit`]
                                          : name === "Item Type"
                                          ? `${singleDate[name]} %`
                                          : singleDate[name]
                                        : "-"}
                                      {name === "Packaging" && addBag ? (
                                        <StyledButton
                                          color={
                                            returnButtonError === true
                                              ? "#FF3D00"
                                              : ""
                                          }
                                          onlyTextColor={
                                            returnButtonError === true &&
                                            "white"
                                          }
                                          onClick={() => {
                                            this.setState({
                                              openAddBagDialog: singleDate,
                                            });
                                          }}
                                          // onlyTextColor={errorMsg[`${singlePackaging.id}Returned`]&&"#FF3D00"}
                                          name={
                                            addBag === "showbagOnly"
                                              ? "Edit"
                                              : "Return"
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )
                                )}
                              {edit && (
                                <div
                                  className="d-flex alignC justifyC minWidthFC"
                                  style={{
                                    margin: "12px 10px",
                                    width: "65px",
                                    minWidth: "50px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {" "}
                                  <Tooltip
                                    title="Edit"
                                    placement="top"
                                    disableFocusListener
                                    disableTouchListener
                                    arrow
                                  >
                                    <StyledButton
                                      name={<EditIcon />}
                                      width="60px"
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </div>

                            <Divider className="width-100" />
                          </div>

                          <Divider />
                        </>
                      )
                  )}
                </div>
                {showTotal && (
                  <div className="d-flex alignC width-100">
                    {portUnreachedheader &&
                      portUnreachedheader.map((name, i) => (
                        <div
                          className=" d-flex justifyC alignC"
                          style={{
                            margin: "12px 10px",
                            width:
                              widthList && widthList[portUnreachedheader[i]]
                                ? widthList[portUnreachedheader[i]]
                                : "fit-content",
                            minWidth:
                              widthList && widthList[portUnreachedheader[i]]
                                ? widthList[portUnreachedheader[i]]
                                : "fit-content",
                            color: "#100f0f",
                            textTransform: "capitalize",
                          }}
                        >
                        {console.log("TOTAL,", array, 
                        Number(array.reduce((p, c) => Number(p) + Number(c[name] ? c[name] : 0), 0)), 
                          Number(array.reduce((prev, curr) => Number(prev) +
                                curr.arrival_packagings.reduce(
                                  (p1, c1) => Number(p1) + Number(this.state[`${c1.dispatch_packaging}Returned`] || 0),
                                  0
                                ),
                              0
                            ))
                        )}
                          {i === 0 ? (
                            <div style={{ fontWeight: "500" }}>Total</div>
                          ) : i === 1 ||
                            i === 2 ||
                            i === portUnreachedheader.length - 1 ||
                            i === portUnreachedheader.length - 2 ? (
                            " "
                          ) : i === 3 ? (
                            array.reduce(
                              (previousValue, currentValue) =>
                                Number(previousValue) +
                                currentValue.arrival_packagings.reduce(
                                  (previousValue1, currentValue1) =>
                                    Number(previousValue1) +
                                    Number(
                                      this.state[
                                        `${currentValue1.dispatch_packaging}Returned`
                                      ]
                                        ? this.state[
                                            `${currentValue1.dispatch_packaging}Returned`
                                          ]
                                        : 0
                                    ),
                                  0
                                ),
                              0
                            )
                          ) : (
                            i === 4 &&
                            `${
                              Number(array.reduce((p, c) => Number(p) + Number(c[name] ? c[name] : 0), 0 )) - 
                              Number(array.reduce((prev, curr) => Number(prev) +
                                curr.arrival_packagings.reduce(
                                  (p1, c1) => Number(p1) + Number(this.state[`${c1.dispatch_packaging}Returned`] || 0),
                                  0
                                ), 0
                              ))
                            }`
                          )}
                        </div>
                      ))}
                    {edit && (
                      <div
                        className="d-flex alignC justifyC minWidthFC"
                        style={{
                          margin: "12px 10px",
                          width: "65px",
                          minWidth: "50px",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                      </div>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>
                  No Data!
                </span>
              </div>
            )}
          </Paper>
        </div>
      );
    };

    return (
      <Layout
        loading={loading[EndPoints["Ports"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Ports`,
          labelField: labelField,
        }}
        fabClickHandler={(value) => {
          this.setState(
            {
              portDialog: true,
              loadingFun: false,
              editList: false,
            },
            () => {}
          );
        }}
        listClickHandler={this.listClickHandler}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
      >
        {ArrivalDialog === true ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "0px";
                target.style.maxHeight = "calc(100% - 62px)";
                target.style.minHeight = "620px";

                target.style.height = "620px";
                target.style.minWidth = "90%";
                target.style.maxWidth = "calc(100% - 150px)";
                target.style.margin = "32px 75px";
              } catch (e) {}
            }}
            open={true}
            onClose={() => {
              this.setState(
                {
                  ArrivalDialog: false,
                  editDialog: false,
                  added: false,
                },
                () => {
                  // this.reset("no snac");
                }
              );
            }}
            TransitionComponent={Transition}
          >
            {openAddBagDialog !== false ? (
              <Dialog
                ref={(e) => {
                  try {
                    const target =
                      ReactDOM.findDOMNode(e).children[2].children[0];
                    target.style.borderRadius = "0px";
                    target.style.maxHeight = "calc(100% - 40px)";
                    target.style.minHeight = "300px";

                    target.style.height = "fit-content";
                    target.style.minWidth = "300px";
                    target.style.maxWidth = "300px";
                    target.style.margin = "0";
                  } catch (e) {}
                }}
                open={true}
                onClose={() => {
                  this.setState({
                    openAddBagDialog: false,
                  });
                }}
                TransitionComponent={Transition}
              >
                {" "}
                <DialogActions
                  className="d-flex justifySB"
                  style={{
                    background: primary,
                    color: "white",
                    padding: "10px 24px",
                  }}
                  onClick={() => {
                    this.setState({
                      openAddBagDialog: false,
                    });
                  }}
                >
                  <h3 style={styles.heading}>Return Bags</h3>
                  <Tooltip title="close" arrow placement="top">
                    <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
                      <CrossIcon strokeWidth="2" />
                    </IconButton>
                  </Tooltip>
                </DialogActions>
                <DialogContent
                  className="dashedDividerBottom  width-100 d-flex alignC justifyC d-flex-column"
                  style={{ height: "100%" }}
                >
                  {this.state.bagerror || this.state.bagsuccess ? (
                    <SnackbarComponent
                      autoHideDuration={error ? 5000 : 1500}
                      error={this.state.bagerror}
                      success={this.state.bagsuccess}
                      snackbarClose={() => {
                        this.setState({
                          bagerror: "",
                          bagsuccess: "",
                        });
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {openAddBagDialog &&
                    openAddBagDialog["arrival_packagings"] &&
                    openAddBagDialog["arrival_packagings"].map(
                      (singlePackaging) => {
                        const dispatchdetail = filterData(
                          openAddBagDialog["dispatch_items_details"][
                            "dispatch_packaging"
                          ],
                          "id",
                          singlePackaging.dispatch_packaging,
                          "onluOne"
                        )
                          ? filterData(
                              openAddBagDialog["dispatch_items_details"][
                                "dispatch_packaging"
                              ],
                              "id",
                              singlePackaging.dispatch_packaging,
                              "onluOne"
                            )
                          : "";

                        const total_bags_dispatched =
                          dispatchdetail.total_bags_dispatched
                            ? dispatchdetail.total_bags_dispatched
                            : "";

                        return (
                          <div
                            className="d-flex-column"
                            style={{
                              display: "flex",
                              width: "100%",
                              fontSize: "1rem",
                              color: "rgba(0, 0, 0, 0.87)",
                            }}
                          >
                            {dispatchdetail
                              ? `${dispatchdetail.packaging_details.size} ${
                                  dispatchdetail.packaging_details.unit
                                } Total Bags ${
                                  this.state[
                                    `${singlePackaging.dispatch_packaging}Returned`
                                  ]
                                    ? subtract(
                                        total_bags_dispatched,
                                        this.state[
                                          `${singlePackaging.dispatch_packaging}Returned`
                                        ]
                                      )
                                    : total_bags_dispatched
                                }`
                              : ""}

                            <CssTextField95
                              required={true}
                              onInput={(e) => {
                                if (
                                  e.target.value > total_bags_dispatched
                                    ? Number(total_bags_dispatched)
                                    : 0
                                ) {
                                  this.setState({
                                    bagerror:
                                      "Can't be greater than total bags",
                                  });
                                }
                                e.target.value = e.target.value
                                  ? e.target.value > total_bags_dispatched
                                    ? Number(total_bags_dispatched)
                                    : 0
                                    ? Number(total_bags_dispatched)
                                    : e.target.value
                                  : "";
                              }}
                              error={
                                errorMsg[
                                  `${singlePackaging.dispatch_packaging}Returned`
                                ]
                                  ? true
                                  : false
                              }
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  max: total_bags_dispatched
                                    ? Number(total_bags_dispatched)
                                    : 0,
                                },
                              }}
                              type="number"
                              onFocus={StopScroll}
                              autoComplete="off"
                              id="outlined-basic"
                              label="Return Bags"
                              style={{ width: "100%", margin: "10px 0" }}
                              variant="outlined"
                              value={
                                this.state[
                                  `${singlePackaging.dispatch_packaging}Returned`
                                ]
                                  ? this.state[
                                      `${singlePackaging.dispatch_packaging}Returned`
                                    ]
                                  : ""
                              }
                              onChange={(e) => {
                                var errorMsg = this.state.errorMsg;
                                errorMsg[
                                  `${singlePackaging.dispatch_packaging}Returned`
                                ] = false;
                                this.setState({
                                  [`${singlePackaging.dispatch_packaging}Returned`]:
                                    e.target.value,

                                  // showBags:
                                  //   total_bags_dispatched - e.target.value,
                                  errorMsg,
                                  returnButtonError: false,
                                });

                                // if (e.target.value >= total_bags_dispatched) {
                                //   this.setState({ showBags: "zero" });
                                // }
                              }}
                            />
                          </div>
                        );
                      }
                    )}
                </DialogContent>
                <DialogActions
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    minHeight: "52.5px",
                  }}
                >
                  <StyledButton
                    onClick={() => {
                      // this.handleClear();
                      // const details=openAddBagDialog.dispatch_items_details;
                      // const packaging=details.arrival_packagings//details.dispatch_packaging//sItem.arrival_packagings;

                      // newArr.map((sObj) => {
                      //   if (sObj.po_item_id === openAddBagDialog.po_item_id) {
                      //     sObj["packagageArrUsed"] = submittedDataOfUsedBags[openAddBagDialog.po_item_id];
                      //   } else {
                      //   }
                      //   return sObj;
                      // });
                      this.setState({
                        // poItemsArrayTOPreviewCopy2: newArr,
                        openAddBagDialog: false,
                      });
                    }}
                    name={"Done"}
                    withBg={true}
                    background={primary}
                    width="120px"
                    margin="0 10px 0 0"
                  />
                </DialogActions>
              </Dialog>
            ) : (
              ""
            )}
            <DialogActions
              className="d-flex justifySB"
              style={{
                background: primary,
                color: "white",
                padding: "10px 24px",
              }}
              // onClick={() => {
              //   this.setState({
              //     ArrivalDialog: false,
              //     editDialog: false,
              //     added: false,
              //   });
              // }}
            >
              {" "}
              <h3 style={styles.heading}>
                {" "}
                {/* <PurchaseIcon style={{ color: "white", margin: "0 5px 0 0" }} /> */}
                Mark as Reached (Selected Dispatched Item Details)
              </h3>
              <Tooltip title="close" arrow placement="top">
                <IconButton
                  onClick={() => {
                    this.setState({
                      ArrivalDialog: false,
                      editDialog: false,
                      added: false,
                    });
                  }}
                  style={{ padding: "5px", borderRadius: "5px" }}
                >
                  <CrossIcon strokeWidth="2" />
                </IconButton>
              </Tooltip>
            </DialogActions>
            <DialogContent
              className="dashedDividerBottom"
              style={{ height: "100%" }}
            >
              {error || success ? (
                <SnackbarComponent
                  error={error}
                  autoHideDuration={error ? 5000 : 1500}
                  success={success}
                  snackbarClose={() => {
                    this.setState(
                      {
                        error: "",
                      },
                      () => {
                        if (success === "All items are updated!") {
                          this.setState({
                            success: "",
                            loadingDialog: false,
                            editArrival: false,
                            openAddBagDialog: false,
                            ArrivalDialog: false,
                            editDialog: false,
                            added: false,
                          });
                        } else {
                          this.setState({
                            success: "",
                          });
                        }
                      }
                    );
                  }}
                />
              ) : (
                ""
              )}
              {loadingDialog === true ? (
                <div className="alignC justiyC d-flex width-100 height-100">
                  <StyledCircularProgress color="rgb(241 154 48)" />
                </div>
              ) : (
                portUnreachedTable(
                  false,
                  PortDispatchedItemList.map((singleDispatch) => {
                    return setData(singleDispatch, PortDispatchedList?.length > 0 && PortDispatchedList?.find(d => d.id === singleDispatch.arrival));
                  }),
                  "addBag",
                  false,
                  "showTotal"
                )
              )}
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                minHeight: "52.5px",
              }}
            >
              {" "}
              <div
                //className="d-flex alignC justifyFE"
                style={{
                  margin: "10px",
                  //width: "calc(25% - 20px)",
                  minWidth: "calc(25% - 20px)",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <StyledButton
                  name={editArrival ? "Reset to Default" : "Reset"}
                  onClick={() => {
                    this.handleClear();
                  }}
                />
                <StyledButton
                  name={editArrival ? "Update" : "Proceed"}
                  minWidth="30px"
                  width="150px"
                  margin="0 10px"
                  withBg={true}
                  onClick={() => {
                    if (PortDispatchedItemList.length > 0) {
                      if (this.state.arrival_date) {
                        if (this.state.gross_weight) {
                          if (this.state.tare_weight) {
                            if (this.state.net_weight) {
                              if (this.state.surveyorCompany) {
                                const newArr = [];
                                const newPackagingArr = [];
                                const moveShippingArr = [];
                                this.setState({ loadingDialog: true });
                                PortDispatchedItemList.map((sItem) => {
                                  const singlePack = [];
                                  const details = sItem.dispatch_items_details;
                                  const packaging = sItem.arrival_packagings; //details.dispatch_packaging//
                                  const returned_bags_of_packgings = {};
                                  if (this.state[`${details.po_item}Status`]) {
                                    if (
                                      (this.state[
                                        `${details.po_item}Status`
                                      ] !== "Move to Godown" &&
                                        this.state[
                                          `${details.po_item}Sales`
                                        ]) ||
                                      this.state[`${details.po_item}Status`] ===
                                        "Move to Godown"
                                    ) {
                                      if (
                                        (this.state[
                                          `${details.po_item}Status`
                                        ] !== "Move to Godown" &&
                                          this.state[
                                            `${details.po_item}Cus`
                                          ]) ||
                                        this.state[
                                          `${details.po_item}Status`
                                        ] === "Move to Godown"
                                      ) {
                                        if (
                                          (this.state[
                                            `${details.po_item}Status`
                                          ] !== "Move to Godown" &&
                                            this.state[
                                              `${details.po_item}Cus`
                                            ]) ||
                                          this.state[
                                            `${details.po_item}Status`
                                          ] === "Move to Godown"
                                        ) {
                                          if (
                                            this.state[
                                              `${details.po_item}Status`
                                            ] === "Continue to Shipping"
                                          ) {
                                            // moveShippingArr.concat(this.state[`${details.po_item}arrpfShip`]);
                                            this.state[
                                              `${details.po_item}arrpfShip`
                                            ].map((s) => {
                                              moveShippingArr.push(s);
                                              return s;
                                            });
                                          }
                                          packaging.map((sPack) => {
                                            const dispatchdetail = filterData(
                                              details["dispatch_packaging"],
                                              "id",
                                              sPack.dispatch_packaging,
                                              "onlyOne"
                                            )
                                              ? filterData(
                                                  details["dispatch_packaging"],
                                                  "id",
                                                  sPack.dispatch_packaging,
                                                  "onlyOne"
                                                )
                                              : "";

                                            returned_bags_of_packgings[
                                              dispatchdetail.packaging
                                            ] = this.state[
                                              `${sPack.dispatch_packaging}Returned`
                                            ]
                                              ? this.state[
                                                  `${sPack.dispatch_packaging}Returned`
                                                ]
                                              : 0;
                                            const newObjForPack = {};
                                            newObjForPack[
                                              "dispatch_packaging"
                                            ] = dispatchdetail.id;

                                            newObjForPack["arrival_item"] =
                                              sPack.arrival_item;
                                            newObjForPack.packaging =
                                              dispatchdetail.packaging;

                                            newObjForPack["returned_bags"] =
                                              this.state[
                                                `${sPack.dispatch_packaging}Returned`
                                              ]
                                                ? this.state[
                                                    `${sPack.dispatch_packaging}Returned`
                                                  ]
                                                : 0;
                                            newObjForPack["godown"] =
                                              this.state[
                                                `${details.po_item}Godown`
                                              ] &&
                                              this.state[
                                                `${details.po_item}Status`
                                              ] === "Move to Godown"
                                                ? this.state[
                                                    `${details.po_item}GodownId`
                                                  ]
                                                : null;
                                            newObjForPack.id = sPack.id;
                                            singlePack.push(newObjForPack);
                                            newPackagingArr.push(newObjForPack);

                                            return sPack;
                                          });
                                          if (
                                            singlePack.length ===
                                            packaging.length
                                          ) {
                                            // if (this.state[`${details.po_item}Remark`]) {

                                            if (
                                              this.state[
                                                `${details.po_item}Status`
                                              ] !== "Move to Godown" ||
                                              this.state[
                                                `${details.po_item}Godown`
                                              ]
                                            ) {
                                              const newObj = {};

                                              newObj["dispatch_items"] =
                                                sItem.dispatch_items;
                                              newObj["arrival"] = Number(
                                                sItem.arrival
                                              );

                                              newObj["remark"] =
                                                this.state[
                                                  `${details.po_item}Remark`
                                                ] || "";
                                              // if (
                                              //   this.state[`${details.po_item}Godown`] &&
                                              //   this.state[`${details.po_item}Status`] === "Move to Godown"
                                              // ) {
                                              newObj["godown"] =
                                                this.state[
                                                  `${details.po_item}Godown`
                                                ] &&
                                                this.state[
                                                  `${details.po_item}Status`
                                                ] === "Move to Godown"
                                                  ? this.state[
                                                      `${details.po_item}GodownId`
                                                    ]
                                                  : null;
                                              // }
                                              newObj[
                                                "returned_bags_of_packgings"
                                              ] = returned_bags_of_packgings;
                                              newObj["returned_bags"] =
                                                singlePack.reduce(
                                                  (
                                                    previousValue,
                                                    currentValue
                                                  ) =>
                                                    Number(previousValue) +
                                                    Number(
                                                      currentValue.returned_bags
                                                        ? currentValue.returned_bags
                                                        : 0
                                                    ),
                                                  0
                                                );
                                              newObj.id = sItem.id;
                                              newObj.port = singlelistView.id;
                                              newObj.arrival_status = "reached";
                                              console.log(
                                                moveShippingArr,
                                                "ewfefefeferf"
                                              );

                                              if (moveShippingArr.length > 0) {
                                                newObj.sales_order = filterData(
                                                  this.state[
                                                    `${details.po_item}customInvoices`
                                                  ],
                                                  "id",
                                                  Number(
                                                    moveShippingArr[0]
                                                      .custom_invoice
                                                  ),
                                                  "onlyOne"
                                                )
                                                  ? filterData(
                                                      this.state[
                                                        `${details.po_item}customInvoices`
                                                      ],
                                                      "id",
                                                      Number(
                                                        moveShippingArr[0]
                                                          .custom_invoice
                                                      ),
                                                      "onlyOne"
                                                    ).sales_order
                                                  : "";
                                                newObj.custom_invoice = Number(
                                                  moveShippingArr[0]
                                                    .custom_invoice
                                                );
                                                newObj.shipping_date =
                                                  this.state[
                                                    `${details.po_item}ShippingDate`
                                                  ] ||
                                                  moment(new Date()).format(
                                                    "YYYY-MM-DD"
                                                  );
                                                newObj.unloading_agent =
                                                  this.state[
                                                    `${details.po_item}Unloading AgentId`
                                                  ];
                                                newObj.mr_or_leo_no =
                                                  this.state[
                                                    `${details.po_item}MR/LEO No.`
                                                  ];
                                                if (
                                                  moveShippingArr[0]
                                                    .commercial_invoice
                                                ) {
                                                  newObj.commercial_invoice =
                                                    Number(
                                                      moveShippingArr[0]
                                                        .commercial_invoice
                                                    );
                                                }
                                                moveShippingArr[0].custom_invoice =
                                                  Number(
                                                    moveShippingArr[0]
                                                      .custom_invoice
                                                  );
                                                moveShippingArr[0].shipping_date =
                                                  this.state[
                                                    `${details.po_item}ShippingDate`
                                                  ] ||
                                                  moment(new Date()).format(
                                                    "YYYY-MM-DD"
                                                  );
                                                moveShippingArr[0].unloading_agent =
                                                  this.state[
                                                    `${details.po_item}Unloading AgentId`
                                                  ] || "";
                                                moveShippingArr[0].mr_or_leo_no =
                                                  this.state[
                                                    `${details.po_item}MR/LEO No.`
                                                  ] || "";
                                                moveShippingArr[0].arrival_item =
                                                  sItem.id;
                                                if (
                                                  moveShippingArr[0]
                                                    .commercial_invoice
                                                ) {
                                                  moveShippingArr[0].commercial_invoice =
                                                    Number(
                                                      moveShippingArr[0]
                                                        .commercial_invoice
                                                    ) || null;
                                                  moveShippingArr[0].bl_number =
                                                    this.state[
                                                      `${details.po_item}customInvoices`
                                                    ][0].bl_number || "";
                                                }
                                                moveShippingArr[0].commercial_invoice = 
                                                moveShippingArr[0]?.commercial_invoice || null;
                                              }

                                              newObj["arrival_item_status"] =
                                                this.state[
                                                  `${details.po_item}Status`
                                                ] === "Move to Godown"
                                                  ? "godown"
                                                  : "shipped";
                                              newObj["return_status"] = newObj[
                                                "returned_bags"
                                              ]
                                                ? newObj["returned_bags"] ===
                                                  Number(details.total_bags)
                                                  ? "full_return"
                                                  : "semi_returned"
                                                : "semi_returned";
                                              newArr.push(newObj);
                                            } else {
                                              const errorMsg =
                                                this.state.errorMsg;
                                              errorMsg[
                                                `${details.po_item}Godown`
                                              ] = `Please add Godown !`;
                                              return this.setState({
                                                errorMsg,
                                              });
                                            }

                                            // } else {
                                            //   const errorMsg = this.state.errorMsg;
                                            //   errorMsg[`${details.po_item}Remark`] = true;

                                            //   return this.setState({ errorMsg });
                                            // }
                                          }
                                        } else {
                                          this.setState({
                                            errorMsg: {
                                              ...errorMsg,
                                              [`${details.po_item}Cus`]: true,
                                            },
                                          });
                                        }
                                      } else {
                                        // this.setState({
                                        //   errorMsg: {
                                        //     ...errorMsg,
                                        //     [`${details.po_item}Com`]: true,
                                        //   },
                                        // });
                                      }
                                    } else {
                                      this.setState({
                                        errorMsg: {
                                          ...errorMsg,
                                          [`${details.po_item}Sales`]: true,
                                        },
                                      });
                                    }
                                  } else {
                                    const errorMsg = this.state.errorMsg;
                                    errorMsg[`${details.po_item}Status`] = true;
                                    return this.setState({ errorMsg });
                                  }
                                  console.log(
                                    this.state[
                                      `${details.po_item}customInvoices`
                                    ],
                                    "qwdwefwefwefwefewf"
                                  );
                                  return sItem;
                                });

                                if (
                                  newArr.length ===
                                  PortDispatchedItemList.length
                                ) {
                                  console.log(
                                    "NEWARR234",
                                    newArr,
                                    this.props,
                                    this.state,
                                    newPackagingArr,
                                    newArr[0].arrival,
                                    moveShippingArr
                                  );
                                  this.props
                                    .PortsFunction(
                                      "patch",
                                      "arrival",
                                      newArr[0].arrival,
                                      "post",
                                      {
                                        arrival_status: "reached",
                                        approval_status:
                                          this.props?.user?.userData?.roles
                                            ?.toString()
                                            .toLowerCase() === "admin"
                                            ? "ACCEPTED"
                                            : "PENDING",

                                        arrival_date: moment(
                                          this.state.arrival_date
                                        ).format("YYYY-MM-DD"),
                                        gross_weight: this.state.gross_weight,
                                        net_weight: this.state.net_weight,
                                        tare_weight: this.state.tare_weight,
                                        returned_bags: newArr.reduce(
                                          (previousValue, currentValue) =>
                                            Number(previousValue) +
                                            currentValue.returned_bags
                                              ? Number(
                                                  currentValue.returned_bags
                                                )
                                              : 0,
                                          0
                                        ),
                                        total_bags: 100,
                                        survey_company:
                                          this.state.surveyorCompanyId,
                                        surveyor: this.state.surveyorId
                                          ? this.state.surveyorId
                                          : null,
                                        // unloading_agent:
                                        // singlelistView.unloading_agent,
                                      }
                                    )
                                    .then((res3) => {
                                      if (res3.error) {
                                        console.log(res3, "res3res3");
                                        this.setState({
                                          error:
                                            res3.data[0]?.message ?? res3.data,
                                          loadingDialog: false,
                                        });
                                      } else {
                                        this.props
                                          .PortsFunction(
                                            "put",
                                            "arrival/items/bulk-update",
                                            null,
                                            "post",
                                            newArr
                                          )
                                          .then((res) => {
                                            if (res.error) {
                                              this.setState({
                                                error:
                                                  typeof res.data === "string"
                                                    ? res.data
                                                    : res.data.length > 0
                                                    ? res.data
                                                        .map((s) => {
                                                          return `${
                                                            s.field
                                                              ? `${s.field} :`
                                                              : ""
                                                          } ${
                                                            typeof s.message ===
                                                            "string"
                                                              ? s.message
                                                              : s.message
                                                                  .length > 0 &&
                                                                s.message[0] &&
                                                                s.message[0]
                                                          }`;
                                                        })
                                                        .join(" ,")
                                                    : res.data,
                                                loadingDialog: false,
                                              });
                                            } else {
                                              this.props
                                                .PortsFunction(
                                                  "put",
                                                  "arrival/packaging/bulk-update",
                                                  null,
                                                  "post",
                                                  newPackagingArr
                                                )
                                                .then((res2) => {
                                                  if (res2.error) {
                                                    this.setState({
                                                      error:
                                                        typeof res2.data ===
                                                        "string"
                                                          ? res2.data
                                                          : res2.data.length > 0
                                                          ? res2.data
                                                              .map((s) => {
                                                                return `${
                                                                  s.field
                                                                    ? `${s.field} :`
                                                                    : ""
                                                                } ${
                                                                  typeof s.message ===
                                                                  "string"
                                                                    ? s.message
                                                                    : s.message
                                                                        .length >
                                                                        0 &&
                                                                      s
                                                                        .message[0] &&
                                                                      s
                                                                        .message[0]
                                                                }`;
                                                              })
                                                              .join(" ,")
                                                          : res2.data,
                                                      loadingDialog: false,
                                                    });
                                                  } else {
                                                    console.log(
                                                      "ROLE",
                                                      this.props?.user?.userData?.roles
                                                        ?.toString()
                                                        .toLowerCase() ===
                                                        "admin"
                                                    );
                                                    if (
                                                      moveShippingArr.length >
                                                        0 &&
                                                      this.props?.user?.userData?.roles
                                                        ?.toString()
                                                        .toLowerCase() ===
                                                        "admin"
                                                    ) {
                                                      this.props
                                                        .PortsFunction(
                                                          "post",
                                                          "arrival/commercial-invoice/packing-list/bulk-create",
                                                          null,
                                                          "post",
                                                          moveShippingArr
                                                        )
                                                        .then((res4) => {
                                                          if (res4.error) {
                                                            this.setState({
                                                              error: res4.data,
                                                              loadingDialog: false,
                                                            });
                                                          } else {
                                                            this.handleClear();
                                                            this.fetchDispatchList(
                                                              editArrival
                                                                ? "Reached"
                                                                : "Arrival"
                                                            );
                                                            this.setState({
                                                              success:
                                                                "All items are updated!",
                                                              loadingDialog: false,
                                                            });
                                                          }
                                                        });
                                                    } else {
                                                      this.handleClear();

                                                      this.fetchDispatchList(
                                                        editArrival
                                                          ? "Reached"
                                                          : "Arrival"
                                                      );
                                                      this.setState({
                                                        success:
                                                          "All items are updated!",
                                                        loadingDialog: false,
                                                      });
                                                    }
                                                  }
                                                });
                                            }
                                          });
                                      }
                                    });
                                } else {
                                  this.setState({ loadingDialog: false });
                                }
                              } else {
                                const errorMsg = this.state.errorMsg;
                                errorMsg.surveyorCompany = true;

                                return this.setState({ errorMsg });
                              }
                            } else {
                              const errorMsg = this.state.errorMsg;
                              errorMsg.net_weight = true;

                              return this.setState({ errorMsg });
                            }
                          } else {
                            const errorMsg = this.state.errorMsg;
                            errorMsg.tare_weight = true;

                            return this.setState({ errorMsg });
                          }
                        } else {
                          const errorMsg = this.state.errorMsg;
                          errorMsg.gross_weight = true;

                          return this.setState({ errorMsg });
                        }
                      } else {
                        const errorMsg = this.state.errorMsg;
                        errorMsg.arrival_date = true;

                        return this.setState({ errorMsg });
                      }
                    }
                  }}
                />
              </div>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        {confirmApp === true ? (
          <Dialog
            open={true}
            onClose={() =>
              this.setState({
                confirmApp: false,
                approve: false,
                reject: false,
              })
            }
            aria-labelledby="confirm-dialog"
          >
            <DialogTitle id="confirm-dialog">Make Changes ?</DialogTitle>
            <DialogContent>
              Are you sure you want to{" "}
              {this.state.approve && this.state.approve !== false
                ? "approve"
                : "reject"}{" "}
              this Arrival ?
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  this.setState({
                    confirmApp: false,
                    approve: false,
                    reject: false,
                  });
                }}
                style={{
                  backgroundColor: "rgb(241 154 48)",
                  color: "white",
                  padding: "2.5px",
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                onClick={this.handleConfirmApp}
                style={{
                  backgroundColor: "rgb(241 154 48)",
                  color: "white",
                  padding: "2.5px",
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        {portDialog === true || editList !== false ? (
          <CreateDialogForPort
            tableData={() => {
              return (
                <>
                  <div>
                    <div style={{ margin: "10px 0" }}>
                      <span style={{ fontSize: "1rem", fontWeight: "500" }}>
                        Add Godown{" "}
                      </span>
                    </div>
                    <div style={{ width: "100%" }}>
                      {New.map((godownName, i) => {
                        return (
                          <>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CssTextField
                                required={true}
                                type="text"
                                autoComplete="off"
                                style={
                                  {
                                    // width: "calc(50% - 20px)",
                                    marginTop: "15px",
                                    // display: "flex",
                                  } //disabled={tp}
                                }
                                error={errorMsg.Godown ? true : false}
                                helperText={
                                  errorMsg.Godown ? errorMsg.Godown : ""
                                }
                                id="outlined-basic"
                                label="Godown Name"
                                variant="outlined"
                                name="Godown"
                                value={godownName ? godownName.name : ""}
                                onChange={(e) => {
                                  this.handleChange1(i, e);
                                }}
                              />
                              <div style={{ display: "flex" }}>
                                <DeleteIcon
                                  color="error"
                                  style={{
                                    marginLeft: "15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (!godownName.id)
                                      this.deleteItem(godownName);
                                    else
                                      this.setState({
                                        deleteDialog: godownName,
                                      });
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}

                      {editList && deleteDialog !== false ? (
                        <Dialog
                          open={true}
                          onClose={() => this.handleClose(false)}
                          aria-labelledby="confirm-dialog"
                        >
                          <DialogTitle id="confirm-dialog">
                            Make Changes ?
                          </DialogTitle>
                          <DialogContent>
                            Are you sure you want to delete this Godown ?
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="contained"
                              onClick={this.handleClose}
                              style={{
                                backgroundColor: "rgb(241 154 48)",
                                color: "white",
                              }}
                            >
                              No
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => {
                                this.props
                                  .PortsFunction(
                                    "delete",
                                    "masters/godown",
                                    deleteDialog && deleteDialog.id,
                                    "postdata",
                                    null
                                  )
                                  .then((res3) => {
                                    if (res3.error) {
                                      this.setState({
                                        error: res3.data,
                                        loading: false,
                                      });
                                    } else {
                                      this.setState(
                                        {
                                          success: editList
                                            ? `${module} Edited`
                                            : "",
                                          deleteDialog: false,
                                        },
                                        () => {
                                          this.props
                                            .PortsFunction(
                                              "get",
                                              EndPoints["Ports"]
                                            )
                                            .then((res) => {
                                              if (
                                                res &&
                                                res.length > 0 &&
                                                singlelistView &&
                                                singlelistView.id
                                              ) {
                                                res.map((s) => {
                                                  if (
                                                    s.id === singlelistView.id
                                                  ) {
                                                    this.setState({
                                                      singlelistView: s,
                                                      New:
                                                        s.godown_details &&
                                                        s.godown_details
                                                          .length > 0
                                                          ? s.godown_details
                                                          : [""],
                                                    });
                                                  }
                                                  return s;
                                                });
                                              }
                                            });
                                          // if (editList) {
                                          //   this.props.fetchAgain();
                                          // }
                                        }
                                      );
                                    }
                                  });
                              }}
                              style={{
                                backgroundColor: "rgb(241 154 48)",
                                color: "white",
                              }}
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        ""
                      )}
                    </div>

                    <Button
                      style={{
                        justifyContent: "left",
                        border: "1px solid rgb(30 53 89)",
                        padding: "3px 10px",
                        margin: "15px 0",
                      }}
                      onClick={this.addClick}
                    >
                      add Godown
                    </Button>
                  </div>
                </>
              );
            }}
            rightSideTable={() => {
              return (
                <>
                  <div>
                    <div style={{ margin: "10px 0" }}>
                      <span style={{ fontSize: "1rem", fontWeight: "500" }}>
                        Add Unloading Agent{" "}
                      </span>
                    </div>
                    <div style={{ width: "100%" }}>
                      {UnloadAgentArr.map((uA, i) => {
                        return (
                          <>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CssTextField
                                required={true}
                                type="text"
                                autoComplete="off"
                                style={{
                                  marginTop: "15px",
                                }}
                                error={errorMsg.unloadingAgent ? true : false}
                                helperText={
                                  errorMsg.unloadingAgent
                                    ? errorMsg.unloadingAgent
                                    : ""
                                }
                                id="outlined-basic"
                                label="Unloading Agent"
                                variant="outlined"
                                name="UnloadingAgent"
                                value={uA ? uA.name : ""}
                                onChange={(e) => {
                                  this.handleChange1(i, e);
                                }}
                              />
                              <div style={{ display: "flex" }}>
                                <DeleteIcon
                                  color="error"
                                  style={{
                                    marginLeft: "15px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (!uA.id)
                                      this.deleteUnloadingAgentItem(uA);
                                    else
                                      this.setState({
                                        deleteUnloadingAgentDialog: uA,
                                      });
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}

                      {editList && deleteUnloadingAgentDialog !== false ? (
                        <Dialog
                          open={true}
                          onClose={() => this.handleUnloadingAgentClose(false)}
                          aria-labelledby="confirm-dialog1"
                        >
                          <DialogTitle id="confirm-dialog1">
                            Make Changes ?
                          </DialogTitle>
                          <DialogContent>
                            Are you sure you want to delete this Unloading Agent
                            ?
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="contained"
                              onClick={this.handleUnloadingAgentClose}
                              style={{
                                backgroundColor: "rgb(241 154 48)",
                                color: "white",
                              }}
                            >
                              No
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => {
                                this.props
                                  .PortsFunction(
                                    "delete",
                                    "masters/unloading-agent/",
                                    deleteUnloadingAgentDialog &&
                                      deleteUnloadingAgentDialog.id,
                                    "postdata",
                                    null
                                  )
                                  .then((res3) => {
                                    if (res3.error) {
                                      this.setState({
                                        error: res3.data,
                                        loading: false,
                                      });
                                    } else {
                                      this.setState(
                                        {
                                          success: editList
                                            ? `${module} Edited`
                                            : "",
                                          deleteUnloadingAgentDialog: false,
                                        },
                                        () => {
                                          this.props
                                            .PortsFunction(
                                              "get",
                                              EndPoints["Ports"]
                                            )
                                            .then((res) => {
                                              if (
                                                res &&
                                                res.length > 0 &&
                                                singlelistView &&
                                                singlelistView.id
                                              ) {
                                                res.map((s) => {
                                                  if (
                                                    s.id === singlelistView.id
                                                  ) {
                                                    this.setState({
                                                      singlelistView: s,
                                                      UnloadAgentArr:
                                                        s.unloading_agent_details &&
                                                        s
                                                          .unloading_agent_details
                                                          .length > 0
                                                          ? s.unloading_agent_details
                                                          : [""],
                                                    });
                                                  }
                                                  return s;
                                                });
                                              }
                                            });
                                        }
                                      );
                                    }
                                  });
                              }}
                              style={{
                                backgroundColor: "rgb(241 154 48)",
                                color: "white",
                              }}
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      ) : (
                        ""
                      )}
                    </div>

                    <Button
                      style={{
                        justifyContent: "left",
                        border: "1px solid rgb(30 53 89)",
                        padding: "3px 10px",
                        margin: "15px 0",
                      }}
                      onClick={this.addUnloadingAgentClick}
                    >
                      Add Unloading Agent
                    </Button>
                  </div>
                </>
              );
            }}
            textFieldWidth="calc(50% - 20px)"
            heightNew="500px"
            maxHeight="calc(100vh - 40px)"
            widthNew="900px"
            // onEditUpdate={(res) => {
            // if(res) { this.setState({ singlelistView: res,})}
            // }}
            // addFieldsToAdd={[{ name: "is_active", value: true }]}=
            module={"Port"}
            selectedList={false}
            editList={editList ? editList : false}
            reset={() => {
              this.setState({
                loadingFun: false,
                editList: false,
                New: [""],
              });
            }}
            handleClose={() => {
              this.setState({
                portDialog: false,
                loadingFun: false,
                editList: false,
                New: [""],
                UnloadAgentArr: [""],
              });
            }}
            settError={() => {
              this.setState({
                errorMsg: { ...errorMsg, Godown: "PLease add godown" },
              });
            }}
            postApi="masters/port/list"
            tableValueDetailArray={New}
            rightSideTableData={UnloadAgentArr}
            editApi="masters/port"
            inputArray={[
              {
                name: "Active",
                type: "toggle",
                api: "is_active",
                defaultValue: {
                  value: editList?.is_active || true,
                },
              },
              {
                name: "name",
                type: "textField",
                dataType: "text",
                api: "name",
                defaultValue: {
                  value: editList && editList.name,
                },
              },
              {
                name: "address",
                type: "addressTextField",
                dataType: "text",
                defaultValue: {
                  value: editList && editList.address,
                },
                api: "address",
              },
              // {
              //   name: "unloading Agent",
              //   type: "textField",
              //   dataType: "text",
              //   defaultValue: {
              //     value: editList && editList.unloading_agent,
              //   },
              //   api: "unloading_agent",
              // },

              {
                name: "state",
                type: "select",
                array: stateArray,
                value: "name",
                api: "state",
                defaultValue: {
                  value: editList && editList.state,
                },
              },
              {
                name: "city",
                type: "textField",
                dataType: "text",
                api: "city",
                defaultValue: {
                  value: editList && editList.city,
                },
              },
              {
                name: "pincode",
                type: "textField",
                onInput: "pincode",
                dataType: "number",
                api: "pincode",
                only_number: true,
                defaultValue: {
                  value: editList && editList.pincode,
                },
              },
            ]}
            postData={this.props.PortsFunction}
            fetchAgain={(msg) => {
              this.setState(
                {
                  portDialog: false,
                  loadingFun: false,
                  editList: false,
                  New: [""],
                  UnloadAgentArr: [""],
                },
                () => {
                  console.log(msg, "mwefrgthyujiklosg");
                  this.setState({ success: msg });
                }
              );
              this.props
                .PortsFunction("get", EndPoints["Ports"])
                .then((res) => {
                  if (
                    res &&
                    res.length > 0 &&
                    singlelistView &&
                    singlelistView.id
                  ) {
                    res.map((s) => {
                      if (s.id === singlelistView.id) {
                        this.setState({ singlelistView: s });
                      }
                      return s;
                    });
                  }
                });
            }}
          />
        ) : (
          ""
        )}
        {error || success ? (
          <SnackbarComponent
            error={error}
            autoHideDuration={error ? 5000 : 1500}
            success={success}
            snackbarClose={() => {
              this.setState({
                error: "",

                success: "",
              });
            }}
          />
        ) : (
          ""
        )}
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.Ports === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="rgb(241 154 48)" />
            </div>
          ) : (
            <TabComponent
              selectedValue={tabNumber}
              onChange={(newVal) => {
                const selctedTab =
                  newVal === 4
                    ? "Shipping"
                    : newVal === 2
                    ? "Arrival"
                    : newVal === 3
                    ? "Inventory"
                    : newVal === 4 && "port Details";
                this.setState({ selctedTab, paginationData: {}, tabNumber: newVal }, () => {
                  if (newVal === 2 || newVal === 3 || newVal === 4) {
                    this.fetchDispatchList(
                      newVal === 2
                        ? "Arrival"
                        : newVal === 4
                        ? "Shipping"
                        : newVal === 3 && "Inventory"
                    );
                  }
                });
              }}
              list={[
                {
                  name: "Custom Invoices",
                  component: (
                    <CustomInvoice
                      {...this.state}
                      {...this.props}
                      setAllCustom={(list) => {
                        this.setState({ custom_invoice_list: list });
                      }}
                      PortsFunction={this.props.PortsFunction}
                      changeTab={(tab) => {
                        {
                          console.log("TAB", tab);
                        }
                        this.setState({ tabNumber: tab }, () => {
                          if (tab === 2 || tab === 3) {
                            this.fetchDispatchList(
                              tab === 2 ? "Arrival" : tab === 3 && "Inventory"
                            );
                          }
                        });
                      }}
                    />
                  ),
                },
                {
                  name: "Commercial Invoices",
                  component: (
                    <CommercialiInvoiceStepper
                      {...this.state}
                      {...this.props}
                      PortsFunction={this.props.PortsFunction}
                      changeTab={(tab) => {
                        this.setState({ tabNumber: tab }, () => {
                          if (tab === 2 || tab === 3) {
                            this.fetchDispatchList(
                              tab === 2 ? "Arrival" : tab === 3 && "Inventory"
                            );
                          }
                        });
                      }}
                    />
                  ),
                },

                {
                  name: "Arrival",
                  component: (
                    <TabComponent
                      onChange={(newVal) => {
                        const selctedTab2 =
                          newVal === 0
                            ? "Intransit"
                            : newVal === 1 && "Reached";
                        this.setState({ selctedTab2, paginationData: {} }, () => {
                          if (newVal === 1 || newVal === 0) {
                            this.fetchDispatchList(
                              newVal === 0
                                ? "Intransit"
                                : newVal === 1 && "Reached"
                            );
                          }
                        });
                      }}
                      list={[
                        {
                          name: "Intransit",
                          component: (
                            <TableComponent
                              module={"Intransit"}
                              actions={[]}
                              {...this.state}
                              noAddButton={true}
                              loader={loadingDispatch === true ? true : false}
                              // bottomBar={true}
                              subMenu={true}
                              subMenuheader={[
                                "Sr.No.",
                                "Brand",
                                "Item Category",
                                "Item Type",
                                "Packaging",
                              ]}
                              header={[
                                "Sr.No.",
                                "Action",
                                "Dispatch Date",
                                "Miller",
                                "Purchase Order",
                                "Sales Order",
                                // "Miller Bill Number",
                                // "Miller City",
                                "Truck Number",
                              ]}
                              secondLineHeaderData={{
                                Miller: {
                                  secondLiner: "Miller Bill Number",
                                  secondLinerHover: ["Miller City"],
                                },
                              }}
                              tablePagination={true}
                              paginationData={paginationData}
                              pageChangeAPI={this.pageChangeAPI}
                              resetPage={resetPage}
                              tableList={
                                PortDispatchedList &&
                                PortDispatchedList?.length
                                  ? PortDispatchedList
                                      ?.map((singleDispatch) => {
                                        singleDispatch["Dispatch Date"] =
                                          moment(
                                            singleDispatch.arrival_table_details
                                              .dispatch_date
                                          ).format('DD MMM YYYY');
                                        singleDispatch["Miller Bill Number"] =
                                          singleDispatch.arrival_table_details
                                            .miller_bill_number ?? "wed";
                                        singleDispatch["Action"] = () => {
                                          return (
                                            <Button
                                              // fontSize="large"
                                              style={{
                                                cursor: "pointer",
                                                backgroundColor:
                                                  "rgb(241 154 48)",
                                                color: "white",
                                                fontSize: mediumScreen() ? "0.7rem" : "0.8rem",
                                              }}
                                              size="small"
                                              withBg={true}
                                              // name="Mark as reached"
                                              onClick={() => {
                                                this.props
                                                  .PortsFunction(
                                                    "get",
                                                    "arrival/items/list",
                                                    null,
                                                    //this.state.singlelistView.id,
                                                    "PortDispatchedList",
                                                    null,
                                                    {
                                                      arrival_id:
                                                        singleDispatch.id,
                                                    }
                                                  )
                                                  .then(
                                                    (
                                                      PortDispatchedItemList
                                                    ) => {
                                                      if (
                                                        PortDispatchedItemList
                                                      ) {
                                                        this.setState(
                                                          {
                                                            PortDispatchedItemList,
                                                            loadingDialog: false,
                                                          },
                                                          () => {
                                                            this.setWidth(
                                                              PortDispatchedItemList,
                                                              portUnreachedheader
                                                            );
                                                          }
                                                        );
                                                      } else {
                                                        this.setState({
                                                          PortDispatchedItemList:
                                                            [],
                                                          loadingDialog: false,
                                                        });
                                                      }
                                                    }
                                                  );
                                                this.setState({
                                                  ArrivalDialog: true,
                                                  loadingDialog: true,
                                                });
                                                this.handleClear(singleDispatch);
                                              }}
                                            >
                                              Mark Reached
                                            </Button>
                                            // <Tooltip title="Mark As Reached">
                                            //   <PublishedWithChangesIcon
                                            //     fontSize="large"
                                            //     style={{ cursor: "pointer" }}
                                            //     withBg={true}
                                            //     // name="Mark as reached"
                                            //     onClick={() => {
                                            //       this.props
                                            //         .PortsFunction(
                                            //           "get",
                                            //           "arrival/items/list",
                                            //           null,
                                            //           //this.state.singlelistView.id,
                                            //           "PortDispatchedList",
                                            //           null,
                                            //           {
                                            //             arrival_id: singleDispatch.id,
                                            //           }
                                            //         )
                                            //         .then((PortDispatchedItemList) => {
                                            //           if (PortDispatchedItemList) {
                                            //             this.setState(
                                            //               {
                                            //                 PortDispatchedItemList,
                                            //               },
                                            //               () => {
                                            //                 this.setWidth(PortDispatchedItemList, portUnreachedheader);
                                            //               }
                                            //             );
                                            //           }
                                            //         });
                                            //       this.setState({
                                            //         ArrivalDialog: true,
                                            //       });
                                            //       this.handleClear();
                                            //     }}
                                            //   />
                                            // </Tooltip>
                                          );
                                        };

                                        singleDispatch["Purchase Order"] =
                                          singleDispatch.arrival_table_details.purchase_order;
                                        singleDispatch["Sales Order"] =
                                          singleDispatch.arrival_table_details.sales_order;

                                        singleDispatch["Truck Number"] =
                                          singleDispatch.arrival_table_details.truck_number;

                                        singleDispatch["Miller"] =
                                          singleDispatch.arrival_table_details.miller;
                                        singleDispatch["Miller City"] =
                                          singleDispatch.arrival_table_details.city;

                                        singleDispatch["subList"] =
                                          singleDispatch.subList
                                            ? singleDispatch.subList.map(
                                                (singleSub) => {
                                                  singleSub["Brand"] =
                                                    filterData(
                                                      BrandList,
                                                      "id",
                                                      singleSub.brand,
                                                      "onlyOne"
                                                    )
                                                      ? filterData(
                                                          BrandList,
                                                          "id",
                                                          singleSub.brand,
                                                          "onlyOne"
                                                        ).brand_name
                                                      : "";

                                                  singleSub["Item Category"] =
                                                    filterData(
                                                      ItemCateoryList,
                                                      "id",
                                                      singleSub.item_category,
                                                      "onlyOne"
                                                    )
                                                      ? filterData(
                                                          ItemCateoryList,
                                                          "id",
                                                          singleSub.item_category,
                                                          "onlyOne"
                                                        ).name
                                                      : "";
                                                  singleSub["Item Type"] =
                                                    filterData(
                                                      ItemList,
                                                      "id",
                                                      singleSub.item_type,
                                                      "onlyOne"
                                                    )
                                                      ? `${Number(
                                                          filterData(
                                                            ItemList,
                                                            "id",
                                                            singleSub.item_type,
                                                            "onlyOne"
                                                          ).broken_percentage
                                                        ).toFixed(2)} %`
                                                      : "";

                                                  singleSub["PackagingUI"] = (
                                                    <ShowPackagingPort
                                                      noTotalbags={true}
                                                      details={singleSub.dispatch_packaging.map(
                                                        (sP) => {
                                                          const singlePack =
                                                            sP.packaging_details;
                                                          singlePack.used_bags =
                                                            sP.total_bags_dispatched;

                                                          return singlePack;
                                                        }
                                                      )}
                                                    />
                                                  );
                                                  singleSub["PackagingUIPdf"] =
                                                    singleSub.dispatch_packaging &&
                                                    singleSub.dispatch_packaging
                                                      .length > 0
                                                      ? singleSub.dispatch_packaging
                                                          .map((sP) => {
                                                            const singlePack =
                                                              sP.packaging_details;

                                                            return singlePack
                                                              ? `${
                                                                  singlePack.size
                                                                }${
                                                                  singlePack.unit
                                                                }${" - "}${
                                                                  singlePack.bag_type
                                                                }`
                                                              : "";
                                                          })
                                                          .join(", ")
                                                      : "-";
                                                  singleSub["Packaging"] =
                                                    singleSub.dispatch_packaging &&
                                                    singleSub.dispatch_packaging
                                                      .length > 0
                                                      ? singleSub.dispatch_packaging
                                                          .map((sP) => {
                                                            const singlePack =
                                                              sP.packaging_details;

                                                            return singlePack
                                                              ? `${
                                                                  singlePack.size
                                                                }${
                                                                  singlePack.unit
                                                                }${" - "}${
                                                                  singlePack.bag_type
                                                                }`
                                                              : "";
                                                          })
                                                          .join(", ")
                                                      : "-";
                                                  return singleSub;
                                                }
                                              )
                                            : [];
                                        // singleDispatch["Dispatch DateMonth"] = singleDispatch.dispatch_date;

                                        // singleDispatch["Gross Weight"] = singleDispatch.gross_weight;
                                        // ? singleDispatch
                                        //     .gross_weight.name
                                        // : "-";

                                        // singleDispatch["Tare Weight"] = singleDispatch.net_weight;

                                        // singleDispatch["Survey Company"] =
                                        //   singleDispatch.survey_company &&
                                        //   filterData(SurveyCompanyList, "id", singleDispatch.survey_company, "onlyOne")
                                        //     ? filterData(
                                        //         SurveyCompanyList,
                                        //         "id",
                                        //         singleDispatch.survey_company,
                                        //         "onlyOne"
                                        //       ).name
                                        //     : "";

                                        // singleDispatch["Surveyor"] =
                                        //   singleDispatch.surveyor &&
                                        //   filterData(allsurveyorList, "id", singleDispatch.surveyor, "onlyOne")
                                        //     ? filterData(allsurveyorList, "id", singleDispatch.surveyor, "onlyOne").name
                                        //     : "";
                                        return singleDispatch;
                                      })
                                      .sort((a, b) => {
                                        var dateB = moment(b.created_at).format(
                                          "MMM D YYYY h:mm:ss A"
                                        );
                                        var dateA = moment(a.created_at).format(
                                          "MMM D YYYY h:mm:ss A"
                                        );
                                        // console.log(
                                        //   dateA,
                                        //   dateB,
                                        //   "SSSSSSSSSSSSSSSSSSSSS"
                                        // );
                                        return (
                                          new Date(dateB) - new Date(dateA)
                                        );
                                      })
                                      .filter(
                                        (s) =>
                                          s.arrival_table_details.port ===
                                          singlelistView.name
                                      )
                                      .filter(
                                        (s) => s.arrival_status === "in_transit"
                                      )
                                  : []
                              }
                              {...this.props}
                            />
                          ),
                        },
                        {
                          name: "Reached",
                          component: (
                            <TableComponent
                              module={"Reached"}
                              noAddButton={true}
                              subMenu={true}
                              subMenuheader={[
                                "Sr.No.",
                                // "Arrival Status",
                                "Brand",
                                "Item Category",
                                // "Item Type",
                                "Packaging",
                                "Godown",
                                "Custom Invoice",
                                "Commercial Invoice",
                                "Unloading Agent",
                                "MR/LEO No.",
                              ]}
                              {...this.state}
                              loader={loadingDispatch === true ? true : false}
                              actions={[]}
                              footer={true}
                              footerList={
                                PortDispatchedList && {
                                  Approval: `Total: ${
                                    PortDispatchedList?.total_no_of_reached_items
                                  } ${
                                    PortDispatchedList?.total_no_of_reached_items >
                                    1
                                      ? "Items"
                                      : "Item"
                                  }`,
                                  "Net Weight":
                                    PortDispatchedList?.total_net_weight,
                                  "Total Bags":
                                    PortDispatchedList?.total_num_bags,
                                  "Returned Bags":
                                    PortDispatchedList?.total_num_returned_bags,
                                }
                              }
                              header={[
                                "Sr.No.",
                                "Arrival Date",
                                "Dispatch Date",
                                "Approval",
                                //"Edit",
                                "Contract Rate",
                                "Miller",
                                "Miller Bill Amount",
                                "Dispatch Miller Total Amount",
                                // "Gross Weight",
                                // "Tare Weight",
                                "Truck Number",
                                "Net Weight",
                                "Total Bags",
                                // "Returned Bags",
                                // "Survey Company",
                                // "Surveyor",
                              ]}
                              secondLineHeaderData={{
                                "Arrival Date": {
                                  secondLiner: "Status",
                                  secondLinerHover: []
                                },
                                "Miller": {
                                  secondLiner: "Miller Bill Number",
                                  secondLinerHover: []
                                },
                                "Miller Bill Amount": {
                                  secondLiner: "",
                                  secondLinerHover: [
                                    "Dispatch Other Amount",
                                    "Dispatch Other Deduction",
                                    "Remark"
                                  ]
                                },
                                "Net Weight": {
                                  secondLiner: "",
                                  secondLinerHover: [
                                    "Gross Weight",
                                    "Tare Weight",
                                  ]
                                },
                                "Total Bags": {
                                  secondLiner: "",
                                  secondLinerHover: [
                                    "Returned Bags",
                                  ]
                                },
                                "Contract Rate": {
                                  secondLiner: "Contract Date",
                                  secondLinerHover: []
                                },
                              }}
                              toggleComponent={{
                                display: true,
                                label: "Display Pending Only",
                                onChange: this.handleToggle,
                              }}
                              tablePagination={true}
                              paginationData={paginationData}
                              pageChangeAPI={this.pageChangeAPI}
                              resetPage={resetPage}
                              csvDownloadButton={{
                                visible: true,
                                apiCall: {
                                  method: 'GET',
                                  url: "reports/arrival-report/list",
                                  params: this.state?.displayPending
                                    ? {
                                        port_id: this.state.singlelistView.id,
                                        arrival_status: "reached",
                                        pending: "True",
                                      }
                                    : { 
                                      port_id: this.state.singlelistView.id, 
                                      arrival_status: "reached" 
                                      },
                                },
                                header: [
                                  { label: "Arrival Date", key: "arrival_datetime" },
                                  { label: "Dispatch Date", key: "dispatch_date" },
                                  { label: "Approval", key: "approval_status" },
                                  { label: "Contract Rate", key: "po_contract_rate", key2: "po_unit", key2Prefix: "/" },
                                  { label: "Contract Date", key: "po_contract_date",},
                                  { label: "Miller", key: "miller" },
                                  { label: "Miller Bill Number", key: "miller_bill_number" },
                                  { label: "Miller Bill Amount", key: "miller_bill_amount" },
                                  { label: "Dispatch Other Amount", key: "dispatch_other_amount" },
                                  { label: "Dispatch Other Deduction", key: "dispatch_other_deduction" },
                                  { label: "Dispatch Miller Total Amount", key: "dispatch_miller_total_amount" },
                                  { label: "Truck Number", key: "truck_number" },
                                  { label: "Gross Weight", key: "gross_weight" },
                                  { label: "Tare Weight", key: "tare_weight" },
                                  { label: "Net Weight", key: "net_weight" },
                                  { label: "Total Bags", key: "total_bags" },
                                  { label: "Returned Bags", key: "returned_bags" },
                                  { label: "Remark", key: "remark" }
                                ],
                                itemHeader: [
                                  { label: "Item - Status", key: "arrival_item_details.arrival_item_status" },
                                  { label: "Item - Brand", key: "arrival_item_details.brand" },
                                  { label: "Item - Item Category", key: "arrival_item_details.item_category"},
                                  { label: "Item - Godown", key: "arrival_item_details.godown"},
                                  { label: "Item - Sales Order", key: "arrival_item_details.sales_order_number"},
                                  { label: "Item - Custom Invoice", key: "arrival_item_details.custom_invoice_number"},
                                  { label: "Item - Commercial Invoice", key: "arrival_item_details.commercial_invoice_number"},
                                  { label: "Item - Unloading Agent", key: "arrival_item_details.unloading_agent"},
                                  { label: "Item - MR/Leo No.", key: "arrival_item_details.mr_or_leo_no"},
                                  {
                                    label: "Item - Packaging - Size Unit",
                                    key: "arrival_item_details.arrival_packagings.packaging.size",
                                    key2Prefix: "",
                                    key2: "arrival_item_details.arrival_packagings.packaging.unit",
                                  },
                                  {
                                    label: "Item - Packaging - Bag Type",
                                    key: "arrival_item_details.arrival_packagings.packaging.bag_type",
                                  },
                                  {
                                    label: "Item - Packaging - Remaining Bags",
                                    key: "arrival_item_details.arrival_packagings.total_bags_dispatched",
                                  },
                                  {
                                    label: "Item - Packaging - Returned Bags",
                                    key: "arrival_item_details.arrival_packagings.returned_bags",
                                  },
                                ],
                                mappingArr: ['arrival_item_details', 'arrival_packagings'],
                                fileName: "PortReached",
                              }}
                              tableList={
                                PortDispatchedList &&
                                PortDispatchedList?.results?.length
                                  ? PortDispatchedList?.results
                                      .map((singleDispatch) => {
                                        console.log(
                                          singleDispatch,
                                          "singleDispatch"
                                        );

                                        singleDispatch["Miller"] =
                                          singleDispatch.arrival_table_details.miller;
                                        singleDispatch["Miller Bill Number"] =
                                          singleDispatch.arrival_table_details
                                            .miller_bill_number ?? "wed";
                                            singleDispatch["Truck Number"] =
                                          singleDispatch.arrival_table_details.truck_number;
                                          singleDispatch["Dispatch Date"] =
                                    moment(singleDispatch.arrival_table_details.dispatch_date).format('DD MMM YYYY')
                                        singleDispatch["Status"] = () => {
                                          return (
                                            <>
                                              <div
                                                style={{
                                                  width: 18,
                                                  height: 18,
                                                  borderRadius: "50%",
                                                  padding: "2%",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  color: primary,
                                                  fontWeight: 600,
                                                }}
                                              >
                                                {singleDispatch["subList"].find(
                                                  (d) =>
                                                    d.arrival_item_status.toUpperCase() ===
                                                    "GODOWN"
                                                ) &&
                                                singleDispatch["subList"].find(
                                                  (d) =>
                                                    d.arrival_item_status.toUpperCase() ===
                                                    "SHIPPED"
                                                ) ? (
                                                  <span className="d-flex alignC">
                                                    <Warehouse
                                                      style={{
                                                        transform: "scale(0.8)",
                                                        color: "#4b2e2e",
                                                      }}
                                                    />{" "}
                                                    /{" "}
                                                    <SailingIcon
                                                      style={{
                                                        transform: "scale(0.8)",
                                                        color: primary,
                                                      }}
                                                    />
                                                  </span>
                                                ) : singleDispatch[
                                                    "subList"
                                                  ].find(
                                                    (d) =>
                                                      d.arrival_item_status.toUpperCase() ===
                                                      "GODOWN"
                                                  ) ? (
                                                    <SimplePopover
                                                popOverIcon={<Warehouse
                                                  style={{
                                                    transform: "scale(0.8)",
                                                    color: "#4b2e2e",
                                                  }}
                                                />}
                                                content={{
                                                  type: 'custom',
                                                  rows: singleDispatch.arrival_item_details.map((d) => {
                                                    return {
                                                      godown: d.godowns_list?.find(g => g.id === d.godown)?.name,
                                                    } }
                                                ),
                                                  keys: 'godown'
                                                }}
                                              />
                                                ) : (
                                                  (
                                                    <SailingIcon
                                                      style={{
                                                        transform: "scale(0.8)",
                                                        color: primary,
                                                      }}
                                                    />
                                                  ) || ""
                                                )}
                                              </div>
                                            </>
                                          );
                                        };

                                        singleDispatch["Approval"] = () => {
                                          return singleDispatch.approval_status ===
                                            "PENDING" &&
                                            this.props?.user?.userData?.roles
                                              ?.toString()
                                              .toLowerCase() === "admin" ? (
                                            <>
                                              <Tooltip
                                                title="Approve this Arrival"
                                                arrow
                                                placement="top"
                                              >
                                                <Button
                                                  onClick={() => {
                                                    this.setState({
                                                      confirmApp: true,
                                                      approve: singleDispatch,
                                                    });
                                                  }}
                                                  style={{
                                                    fontSize:  mediumScreen() ? "0.7rem" : "0.8rem",
                                                    boxShadow:
                                                      "rgba(128, 128, 128, 0.53) 1px 1px 7px",
                                                    marginRight: "10px",
                                                    color: "green",
                                                    fontWeight: 500,
                                                  }}
                                                >
                                                  Approve
                                                  {/* <CheckCircleOutlineIcon color="success" /> */}
                                                </Button>
                                              </Tooltip>

                                              <Tooltip
                                                title="Reject this Arrival"
                                                arrow
                                                placement="top"
                                              >
                                                <Button
                                                  onClick={() => {
                                                    this.setState({
                                                      confirmApp: true,
                                                      reject: singleDispatch,
                                                    });
                                                  }}
                                                  style={{
                                                    fontSize:  mediumScreen() ? "0.7rem" : "0.8rem",
                                                    boxShadow:
                                                      "rgba(128, 128, 128, 0.53) 1px 1px 7px",
                                                    marginRight: "10px",
                                                    color: "red",
                                                    fontWeight: 500,
                                                  }}
                                                >
                                                  Reject
                                                  {/* <CancelOutlinedIcon color="error" /> */}
                                                </Button>
                                              </Tooltip>
                                            </>
                                          ) : (
                                            singleDispatch.approval_status
                                          );
                                        };

                                        singleDispatch["Arrival Date"] = moment(
                                          singleDispatch.arrival_datetime
                                        ).format('DD MMM YYYY');

                                        singleDispatch["Miller Bill Amount"] = 
                                          singleDispatch.arrival_table_details?.miller_bill_amount || "";

                                        singleDispatch["Dispatch Other Amount"] =
                                          singleDispatch.arrival_table_details?.dispatch_other_amount || "";
                                        
                                        singleDispatch["Dispatch Other Deduction"] =
                                          singleDispatch.arrival_table_details?.dispatch_other_deduction || "";

                                        singleDispatch["Remark"] = singleDispatch?.remarks || ""

                                        singleDispatch["Dispatch Miller Total Amount"] = 
                                          singleDispatch.arrival_table_details?.dispatch_miller_total_amount || "";

                                        singleDispatch["Gross Weight"] =
                                          singleDispatch.gross_weight;

                                        singleDispatch["Tare Weight"] =
                                          singleDispatch.tare_weight;
                                        singleDispatch["Net Weight"] =
                                          singleDispatch.net_weight;
                                        singleDispatch["Total Bags"] =
                                          singleDispatch.arrival_table_details.total_bags;
                                        singleDispatch["Returned Bags"] =
                                          singleDispatch.returned_bags
                                            ? singleDispatch.returned_bags
                                            : 0;
                                        singleDispatch['Contract Rate'] = 
                                          singleDispatch?.arrival_table_details?.po_contract_rate
                                          singleDispatch["Contract RateUI"] =
                                    `${singleDispatch?.arrival_table_details?.po_contract_rate}` +
                                  `${singleDispatch?.arrival_table_details?.po_unit ? "/" + singleDispatch?.arrival_table_details?.po_unit : ""}`
                                        singleDispatch['Contract Date'] = 
                                          moment(singleDispatch?.arrival_table_details?.po_contract_date).format('DD MMM YYYY');
                                       
                                        singleDispatch["subList"] =
                                          singleDispatch.subList
                                            ? singleDispatch.subList.map(
                                                (singleSub) => {
                                                  singleSub["Godown"] =
                                                    singleSub.godown
                                                      ? filterData(
                                                          singleSub.godowns_list,
                                                          "id",
                                                          singleSub.godown,
                                                          "onlyOne"
                                                        )
                                                        ? filterData(
                                                            singleSub.godowns_list,
                                                            "id",
                                                            singleSub.godown,
                                                            "onlyOne"
                                                          ).name
                                                        : ""
                                                      : "";
                                                  singleSub["Custom Invoice"] =
                                                    singleSub.custom_invoice_number ||
                                                    "";

                                                  // console.log("singleSub Commercial Invoice", singleSub, singleSub.commercial_invoice_number, !Boolean(singleSub.commercial_invoice_number))
                                                  singleSub[
                                                    "Commercial Invoice"
                                                  ] = () => {
                                                    return (
                                                      <div className="d-flex alignC justifyC">
                                                        <span
                                                          style={{
                                                            color:
                                                              !singleSub?.is_synced
                                                                ? "rgba(0,0,0,0.4)"
                                                                : "inherit",
                                                          }}
                                                        >
                                                          {
                                                            singleSub.commercial_invoice_number
                                                          }
                                                        </span>
                                                        <span
                                                          style={{
                                                            display: "block",
                                                            margin: "0 auto",
                                                            padding:
                                                              "4px 10px 0",
                                                            color: Boolean(
                                                              this.state
                                                                .isSpinning[
                                                                singleSub.id
                                                              ]
                                                            )
                                                              ? lightGreen
                                                              : primary,
                                                            animation:
                                                              Boolean(
                                                                this.state
                                                                  .isSpinning[
                                                                  singleSub.id
                                                                ]
                                                              ) &&
                                                              "spin 1s infinite",
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            this.handleIconClick(
                                                              singleSub.id,
                                                              true
                                                            );
                                                            console.log(
                                                              "SPIN",
                                                              singleSub,
                                                              this.state
                                                                .isSpinning,
                                                              Boolean(
                                                                this.state
                                                                  .isSpinning[
                                                                  singleSub.id
                                                                ]
                                                              )
                                                            );
                                                            let newArr = [];
                                                            singleSub?.newPack.map(
                                                              (sP, i) => {
                                                                let usedWeight =
                                                                  {};

                                                                usedWeight[
                                                                  sP.packaging_details.id
                                                                ] =
                                                                  this.changeUnit(
                                                                    sP
                                                                      .packaging_details
                                                                      .unit,
                                                                    singleSub.so_unit,
                                                                    multiply(
                                                                      sP
                                                                        .packaging_details
                                                                        .used_bags
                                                                        ? sP
                                                                            .packaging_details
                                                                            .used_bags
                                                                        : 0,
                                                                      sP
                                                                        .packaging_details
                                                                        .size
                                                                        ? Number(
                                                                            sP
                                                                              .packaging_details
                                                                              .size
                                                                          )
                                                                        : 0
                                                                    )
                                                                  );

                                                                newArr.push({
                                                                  id:
                                                                    singleSub.packing_list_ids !==
                                                                    ""
                                                                      ? Number(
                                                                          singleSub.packing_list_ids.split(
                                                                            ","
                                                                          )[i]
                                                                        )
                                                                      : null,
                                                                  is_active: true,
                                                                  shipping_date:
                                                                    moment(
                                                                      singleSub.shipping_date ||
                                                                        new Date()
                                                                    ).format(
                                                                      "YYYY-MM-DD"
                                                                    ),
                                                                  unloading_agent:
                                                                    singleSub.unloading_agent,
                                                                  mr_or_leo_no:
                                                                    singleSub.mr_or_leo_no,
                                                                  packaging_count:
                                                                    {
                                                                      [sP
                                                                        .packaging_details
                                                                        .id]:
                                                                        sP
                                                                          .packaging_details
                                                                          .used_bags,
                                                                    },
                                                                  used_weight:
                                                                    usedWeight,
                                                                  custom_invoice:
                                                                    Number(
                                                                      singleSub.custom_invoice
                                                                    ),
                                                                  commercial_invoice:
                                                                    Number(
                                                                      singleSub.commercial_invoice
                                                                    ),
                                                                  brand:
                                                                    singleSub.brand,
                                                                  godown:
                                                                    singleSub
                                                                      ? singleSub.godown
                                                                      : "",
                                                                  port: singleDispatch.portId,
                                                                  sales_order:
                                                                    singleSub.sales_order,
                                                                  bl_number:
                                                                    singleSub?.custom_bl_number ||
                                                                    "",
                                                                  arrival_item:
                                                                    singleSub.id,
                                                                  coming_from:
                                                                    "DIRECT",
                                                                });
                                                              }
                                                            );
                                                            console.log(
                                                              "newArr",
                                                              newArr
                                                            );
                                                            this.props
                                                              .PortsFunction(
                                                                "put",
                                                                "arrival/commercial-invoice/packing-list/bulk-update",
                                                                null,
                                                                "put",
                                                                newArr
                                                              )
                                                              .then((res2) => {
                                                                console.log("RES2", res2);
                                                                if (
                                                                  res2.error
                                                                ) {
                                                                  this.showSnackbar( res2.data, "error")
                                                                  this.handleIconClick(
                                                                    singleSub.id,
                                                                    false
                                                                  );
                                                                } else {
                                                                  this.showSnackbar( "Sync successfully", "success")
                                                                  // this.setState(
                                                                  //   {
                                                                  //     success:
                                                                  //       "Sync successfully",
                                                                  //   }
                                                                  // );
                                                                  setTimeout(
                                                                    () => {
                                                                      this.handleIconClick(
                                                                        singleSub.id,
                                                                        false
                                                                      );
                                                                      this.fetchDispatchList(
                                                                        "Reached"
                                                                      );
                                                                    },
                                                                    2000
                                                                  );
                                                                }
                                                              })
                                                              .catch((e) => {
                                                                console.log(
                                                                  "error500",
                                                                  e
                                                                );
                                                                this.setState({
                                                                  error:
                                                                    "Shipping details are mismatched",
                                                                });
                                                              });
                                                          }}
                                                        >
                                                          {singleSub.commercial_invoice_number &&
                                                          !singleSub?.is_synced ? (
                                                            <Tooltip
                                                              title="Sync"
                                                              placement="right-start"
                                                            >
                                                              <AutorenewOutlinedIcon
                                                                style={{
                                                                  transform:
                                                                    "scale(0.9)",
                                                                }}
                                                              />
                                                            </Tooltip>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </span>
                                                      </div>
                                                    );
                                                  };

                                                  singleSub["Unloading Agent"] =
                                                    singleSub?.unloading_agent
                                                      ?.name
                                                      ? singleSub
                                                          ?.unloading_agent
                                                          ?.name
                                                      : "";
                                                  singleSub["MR/LEO No."] =
                                                    singleSub?.mr_or_leo_no
                                                      ? singleSub?.mr_or_leo_no
                                                      : "";

                                                  singleSub["Brand"] =
                                                    filterData(
                                                      BrandList,
                                                      "id",
                                                      singleSub.brand,
                                                      "onlyOne"
                                                    )
                                                      ? filterData(
                                                          BrandList,
                                                          "id",
                                                          singleSub.brand,
                                                          "onlyOne"
                                                        ).brand_name
                                                      : "";

                                                  singleSub["Item Category"] =
                                                    filterData(
                                                      ItemCateoryList,
                                                      "id",
                                                      singleSub.item_category,
                                                      "onlyOne"
                                                    )
                                                      ? filterData(
                                                          ItemCateoryList,
                                                          "id",
                                                          singleSub.item_category,
                                                          "onlyOne"
                                                        ).name
                                                      : "";
                                                  singleSub["Item Type"] =
                                                    filterData(
                                                      ItemList,
                                                      "id",
                                                      singleSub.item_type,
                                                      "onlyOne"
                                                    )
                                                      ? `${Number(
                                                          filterData(
                                                            ItemList,
                                                            "id",
                                                            singleSub.item_type,
                                                            "onlyOne"
                                                          ).broken_percentage
                                                        ).toFixed(2)} %`
                                                      : "";
                                                  singleSub["Packaging"] =
                                                    singleSub.newPack &&
                                                    singleSub.newPack.length > 0
                                                      ? `  ${
                                                          singleSub.newPack.map(
                                                            (sP) => {
                                                              const singlePack =
                                                                sP.packaging_details;

                                                              return singlePack
                                                                ? `${
                                                                    singlePack.size
                                                                  }${
                                                                    singlePack.unit
                                                                  }${" - "}${
                                                                    singlePack.bag_type
                                                                  }`
                                                                : "";
                                                            }
                                                          )[0]
                                                        } Total (...s)`
                                                      : "-";
                                                  singleSub["Arrival Status"] =
                                                    singleSub.arrival_item_status;
                                                  console.log(
                                                    singleSub,
                                                    "singleSub",
                                                    singleDispatch
                                                  );
                                                  singleSub["PackagingUI"] = (
                                                    <ShowPackagingPort
                                                      // noTotalbags={true}
                                                      // details={singleSub.dispatch_packaging.map(
                                                      //   (sP) => {
                                                      //     const singlePack =
                                                      //       sP.packaging_details;
                                                      //     singlePack.used_bags =
                                                      //       sP.total_bags_dispatched;

                                                      //     return singlePack;
                                                      //   }
                                                      // )}
                                                      noTotalbags={true}
                                                      returned_bags={true}
                                                      details={singleSub.dispatch_packaging.map(
                                                        (sP) => {
                                                          const singlePack =
                                                            sP.packaging_details;
                                                          singlePack.used_bags =
                                                            sP.total_bags_dispatched;

                                                          singlePack.returned_bags =
                                                            sP.returned_bags;
                                                          return singlePack;
                                                        }
                                                      )}
                                                    />
                                                  );
                                                  singleSub["PackagingUIPdf"] =
                                                    singleSub.dispatch_packaging &&
                                                    singleSub.dispatch_packaging
                                                      .length > 0
                                                      ? singleSub.dispatch_packaging
                                                          .map((sP) => {
                                                            const singlePack =
                                                              sP.packaging_details;

                                                            return singlePack
                                                              ? `${
                                                                  singlePack.size
                                                                }${
                                                                  singlePack.unit
                                                                }${" - "}${
                                                                  singlePack.bag_type
                                                                }`
                                                              : "";
                                                          })
                                                          .join(", ")
                                                      : "-";
                                                  return singleSub;
                                                }
                                              )
                                            : [];
                                        return singleDispatch;
                                      })
                                      // .sort((a, b) => b.id - a.id)
                                      .sort((a, b) => {
                                        var dateB = moment(
                                          b.modified_at
                                        ).format("MMM D YYYY h:mm:ss A");
                                        var dateA = moment(
                                          a.modified_at
                                        ).format("MMM D YYYY h:mm:ss A");
                                        // console.log(
                                        //   dateA,
                                        //   dateB,
                                        //   "SSSSSSSSSSSSSSSSSSSSS"
                                        // );
                                        return (
                                          new Date(dateB) - new Date(dateA)
                                        );
                                      })
                                      .filter(
                                        (s) =>
                                          s.arrival_table_details.port ===
                                          singlelistView.name
                                      )
                                      .filter(
                                        (s) => s.arrival_status === "reached"
                                      )
                                      .map((s) => {
                                        // console.log(
                                        //   moment(s.arrival_datetime).format(
                                        //     "MMM D YYYY h:mm:ss A"
                                        //   ),
                                        //   "dddddddddddddddddd"
                                        // );
                                        return s;
                                      })
                                  : []
                              }
                            />
                          ),
                        },
                      ]}
                    />
                  ),
                },

                {
                  name: "Inventory",
                  component:
                    loadingDispatch === true ? (
                      <div className="alignC justiyC d-flex width-100 height-100">
                        <StyledCircularProgress color="rgb(241 154 48)" />
                      </div>
                    ) : (
                      <Inventory
                        {...this.state}
                        updateAgain={() => {
                          this.handleGodownChange(selectGodown);
                        }}
                        fetchGodownHistory={this.fetchGodownHistory}
                        {...this.props}
                        filterChild={filterChild}
                        PortsFunction={this.props.PortsFunction}
                        TablePagination={true}
                        paginationData={paginationData}
                        // pageChangeAPI={this.pageChangeAPI}
                        resetPage={resetPage}
                        selectGodown={selectGodown}
                      />
                    ),
                },
                {
                  name: "Shipping",
                  component: (
                    <TableComponent
                      module={"Shipping"}
                      noAddButton={true}
                      // subMenu={true}
                      // subMenuheader={[
                      //   "Sr.No.",
                      //   "Arrival Status",
                      //   "Brand",
                      //   "Item Category",
                      //   "Item Type",
                      //   "Packaging",
                      // ]}
                      {...this.state}
                      loader={loadingDispatch === true ? true : false}
                      actions={[]}
                      header={[
                        "Sr.No.",
                        // "Date",
                        "Shipping Date",
                        "Sales Order",
                        "Commercial Invoice No.",
                        "Custom Invoice No.",
                        "Unloading Agent",
                        "MR/LEO No.",
                        "Total Bags",
                        "Total Quantity",
                        "Brand",
                        // "Item Category",
                        // "Item Type",
                        "Packaging",
                      ]}
                      tableHeight="calc(100% - 95px)"
                      tablePagination={true}
                      paginationData={paginationData}
                      pageChangeAPI={this.pageChangeAPI}
                      resetPage={resetPage}
                      tableList={
                        ShippingList && ShippingList.length
                          ? ShippingList.map((singleDispatch) => {
                              singleDispatch["Date"] = moment(
                                singleDispatch.created_at
                              ).format('DD MMM YYYY');

                              singleDispatch["Sales Order"] = filterData(
                                masters.salesOrderList,
                                "id",
                                singleDispatch.sales_order,
                                "onlyOne"
                              )
                                ? filterData(
                                    masters.salesOrderList,
                                    "id",
                                    singleDispatch.sales_order,
                                    "onlyOne"
                                  ).so_number
                                : "";
                              singleDispatch["Shipping Date"] =
                                singleDispatch.date
                                  ? moment(singleDispatch.date).format(
                                      "DD MMM YYYY"
                                    )
                                  : "";
                              singleDispatch["Unloading Agent"] =
                                singleDispatch?.unloading_agent
                                  ? singleDispatch?.unloading_agent
                                  : "";
                              singleDispatch["MR/LEO No."] =
                                singleDispatch?.mr_or_leo_no
                                  ? singleDispatch?.mr_or_leo_no
                                  : "";

                              singleDispatch["Commercial Invoice No."] = () => (
                                <span>
                                  {singleDispatch.commercial_invoice ? (
                                    filterData(
                                      commercialList,
                                      "id",
                                      singleDispatch.commercial_invoice,
                                      "onlyOne"
                                    ) ? (
                                      filterData(
                                        commercialList,
                                        "id",
                                        singleDispatch.commercial_invoice,
                                        "onlyOne"
                                      ).invoice_number
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    <HourglassTopIcon
                                      style={{
                                        color: "rgb(241 154 48)",
                                        transform: "scale(0.8)",
                                      }}
                                    />
                                  )}
                                </span>
                              );

                              singleDispatch["Custom Invoice No."] = filterData(
                                customList,
                                "id",
                                singleDispatch.custom_invoice,
                                "onlyOne"
                              )
                                ? filterData(
                                    customList,
                                    "id",
                                    singleDispatch.custom_invoice,
                                    "onlyOne"
                                  ).invoice_number
                                : "";
                              singleDispatch["Total Bags"] =
                                singleDispatch.total_bags;
                              singleDispatch["Total Quantity"] =
                                singleDispatch.total_quantity;

                              // singleDispatch.subList
                              //   ? singleDispatch.subList.map(
                              //       (singleSub) => {
                              console.log(
                                filterData(
                                  BrandList,
                                  "id",
                                  singleDispatch.brand,
                                  "onlyOne"
                                ),
                                singleDispatch,
                                singleDispatch.brand,
                                BrandList,
                                ItemCateoryList,
                                ItemList
                              );
                              let BRAND = filterData(
                                BrandList,
                                "id",
                                singleDispatch.brand,
                                "onlyOne"
                              );
                              singleDispatch["Brand"] = filterData(
                                BrandList,
                                "id",
                                singleDispatch.brand,
                                "onlyOne"
                              )
                                ? filterData(
                                    BrandList,
                                    "id",
                                    singleDispatch.brand,
                                    "onlyOne"
                                  ).brand_name
                                : "";

                              singleDispatch["Item Category"] = !Boolean(singleDispatch?.item_category)
                              ? ""
                              : filterData(
                                ItemCateoryList,
                                "id",
                                singleDispatch.item_category,
                                "onlyOne"
                              )
                                ? filterData(
                                    ItemCateoryList,
                                    "id",
                                    singleDispatch.item_category,
                                    "onlyOne"
                                  ).name
                                : "";
                              singleDispatch["Item Type"] = !Boolean(singleDispatch?.item_type)
                              ? ""
                              : filterData(
                                ItemList,
                                "id",
                                singleDispatch.item_type,
                                "onlyOne"
                              )
                                ? `${Number(
                                    filterData(
                                      ItemList,
                                      "id",
                                      singleDispatch.item_type,
                                      "onlyOne"
                                    ).broken_percentage
                                  ).toFixed(2)} %`
                                : "";
                              singleDispatch["Packaging"] =
                                singleDispatch.newPack &&
                                singleDispatch.newPack.length > 0
                                  ? `  ${
                                      singleDispatch.newPack.map((sP) => {
                                        const singlePack = sP.packaging_details;

                                        return singlePack
                                          ? `${singlePack.size}${
                                              singlePack.unit
                                            }${" - "}${singlePack.bag_type}`
                                          : "";
                                      })[0]
                                    } Total (...s)`
                                  : "-";
                              // singleDispatch["Arrival Status"] =
                              //   singleDispatch.arrival_item_status;
                              console.log(
                                singleDispatch,
                                "singleDispatch",
                                singleDispatch
                              );
                              singleDispatch["PackagingUI"] = (
                                <ShowPackagingPort
                                  // noTotalbags={true}
                                  // details={singleDispatch.dispatch_packaging.map(
                                  //   (sP) => {
                                  //     const singlePack =
                                  //       sP.packaging_details;
                                  //     singlePack.used_bags =
                                  //       sP.total_bags_dispatched;

                                  //     return singlePack;
                                  //   }
                                  // )}
                                  // noTotalbags={true}
                                  // returned_bags={true}
                                  noRemainingBags={true}
                                  noTotalbags={true}
                                  details={
                                    filterData(
                                      masters.packagingList,
                                      "id",
                                      singleDispatch.packaging,
                                      "onlyOne"
                                    )
                                      ? [
                                          filterData(
                                            masters.packagingList,
                                            "id",
                                            singleDispatch.packaging,
                                            "onlyOne"
                                          ),
                                        ]
                                      : []
                                  }
                                />
                              );
                              singleDispatch["PackagingUIPdf"] =
                                singleDispatch.dispatch_packaging &&
                                singleDispatch.dispatch_packaging.length > 0
                                  ? singleDispatch.dispatch_packaging
                                      .map((sP) => {
                                        const singlePack = sP.packaging_details;

                                        return singlePack
                                          ? `${singlePack.size}${
                                              singlePack.unit
                                            }${" - "}${singlePack.bag_type}`
                                          : "";
                                      })
                                      .join(", ")
                                  : "-";

                              //       return singleSub;
                              //     }
                              //   )
                              // : [];

                              return singleDispatch;
                            }).sort((a, b) => {
                              var dateB = moment(b.created_at).format(
                                "MMM D YYYY h:mm:ss A"
                              );
                              var dateA = moment(a.created_at).format(
                                "MMM D YYYY h:mm:ss A"
                              );
                              // console.log(
                              //   dateA,
                              //   dateB,
                              //   "SSSSSSSSSSSSSSSSSSSSS"
                              // );
                              return new Date(dateB) - new Date(dateA);
                            })
                          : // .filter(
                            //   (s) =>
                            //     s.arrival_table_details.port ===
                            //     singlelistView.name
                            // )
                            // .filter((s) => s.arrival_status === "in_transit")
                            []
                      }
                      {...this.props}
                    />
                  ),
                },
                {
                  name: "port Details",
                  component: (
                    <>
                      <Paper
                        className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <div className="width-100 d-flex justifyFE">
                          <Tooltip title="Edit">
                            <EditIcon
                              onClick={() => {
                                this.setState(
                                  {
                                    New: [""],
                                    UnloadAgentArr: [""],
                                  },
                                  () => {
                                    this.setState({
                                      editList: singlelistView,
                                      New:
                                        singlelistView.godown_details &&
                                        singlelistView.godown_details.length > 0
                                          ? singlelistView.godown_details
                                          : [""],
                                      UnloadAgentArr:
                                        singlelistView?.unloading_agent_details
                                          ? singlelistView?.unloading_agent_details
                                          : [""],
                                    });
                                  }
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </Tooltip>
                        </div>

                        <div
                          className="overflowYA scrollBluePrimary"
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            padding: "0 50px",
                            height: "calc(100% - 31.7px)",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}
                          >
                            <p
                              style={{
                                fontSize: mediumScreen() ? "0.8rem" : "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}
                            >
                              Port Name :{" "}
                            </p>
                            <p
                              style={{
                                fontSize: mediumScreen() ? "0.8rem" : "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                                wordBreak: "break-all",
                              }}
                            >
                              {singlelistView ? singlelistView.name : ""}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}
                          >
                            <p
                              style={{
                                fontSize: mediumScreen() ? "0.8rem" : "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}
                            >
                              Address :
                            </p>{" "}
                            <p
                              style={{
                                fontSize: mediumScreen() ? "0.8rem" : "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                                wordBreak: "break-all",
                              }}
                            >
                              {singlelistView ? singlelistView.address : ""}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}
                          >
                            <p
                              style={{
                                fontSize: mediumScreen() ? "0.8rem" : "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}
                            >
                              City :{" "}
                            </p>{" "}
                            <p
                              style={{
                                fontSize: mediumScreen() ? "0.8rem" : "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                                wordBreak: "break-all",
                              }}
                            >
                              {singlelistView ? singlelistView.city : ""}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}
                          >
                            <p
                              style={{
                                fontSize: mediumScreen() ? "0.8rem" : "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}
                            >
                              State :{" "}
                            </p>{" "}
                            <p
                              style={{
                                fontSize: mediumScreen() ? "0.8rem" : "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                              }}
                            >
                              {singlelistView ? singlelistView.state : ""}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}
                          >
                            <p
                              style={{
                                fontSize: mediumScreen() ? "0.8rem" : "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}
                            >
                              Pincode :{" "}
                            </p>{" "}
                            <p
                              style={{
                                fontSize: mediumScreen() ? "0.8rem" : "18px",
                                margin: "10px 0 10px 10px",
                                color: "#808080",
                              }}
                            >
                              {singlelistView ? singlelistView.pincode : ""}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}
                          >
                            <p
                              style={{
                                fontSize: mediumScreen() ? "0.8rem" : "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}
                            >
                              Godown Name :{" "}
                            </p>
                            <div
                              style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                              }}
                            >
                              {singlelistView &&
                                singlelistView.godown_details &&
                                singlelistView.godown_details.length > 0 &&
                                singlelistView.godown_details.map((a) => (
                                  <p
                                    style={{
                                      fontSize: mediumScreen() ? "0.8rem" : "18px",

                                      margin: "10px 0 10px 10px",
                                      color: "#808080",
                                    }}
                                  >
                                    {a.name}
                                  </p>
                                ))}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                            }}
                          >
                            <p
                              style={{
                                fontSize: mediumScreen() ? "0.8rem" : "18px",
                                margin: "10px 0 ",
                                minWidth: "200px",
                              }}
                            >
                              Unloading Agent :
                            </p>{" "}
                            <div
                              style={{
                                flexDirection: "column",
                                justifyContent: "flex-start",
                              }}
                            >
                              {singlelistView &&
                                singlelistView.unloading_agent_details &&
                                singlelistView.unloading_agent_details.length >
                                  0 &&
                                singlelistView.unloading_agent_details.map(
                                  (a) => (
                                    <p
                                      style={{
                                        fontSize: mediumScreen() ? "0.8rem" : "18px",

                                        margin: "10px 0 10px 10px",
                                        color: "#808080",
                                      }}
                                    >
                                      {a.name}
                                    </p>
                                  )
                                )}
                            </div>
                          </div>
                        </div>
                      </Paper>
                    </>
                  ),
                },
              ]}
            />
          )}
        </Paper>
      </Layout>
    );
  }
}

export default PortsComponent;
