import React, { Component } from "react";
import Layout from "../../layouts/Layout";
import TabComponent from "../../layoutComponents/TabComponent";

import {
  CssDialog,
  CssTextField95,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import TableComponent from "../../layoutComponents/TableComponent";
import CreateDialog from "../../layoutComponents/CreateDialog";
import NoData from "../../styles/Illustrations/NoData";
import { MenuItem, Paper, Tooltip } from "@material-ui/core";
import { EndPoints } from "../../utils/EndPoints";

import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import InvoiceCardUi from "../../layoutComponents/InvoiceCardUi";
import { filterData } from "../../Regex";
import BasicDateRangePicker from "../../layoutComponents/BasicDateRangePicker";
import ShowPackaging from "../../layoutComponents/ShowPackaging";
import { stateArray } from "../../Defaults";
export class MillersComponent extends Component {
  constructor(props) {
    const templateTabs = [
      {
        label: EndPoints["Millers"],
        tooltip: `Click to approve pending ${EndPoints["Millers"]}`,
        data: [],
        route: `/Millers/${EndPoints["Millers"].split("/")[0]}`,
        goBackRoute: "/Millers",
        primaryField: "name",
        secondaryField: "is_active",
        primaryFieldThirdFallback: "total_net_weight_pending_trips",
        avatarField: "src",
        status: false,
        decorators: {
          conditionField: "status",
          options: ["pending", "approved", "unidentified"],
          colors: ["yellow", "#1a9520", "#FF3D00"],
        },
      },
    ];
    super(props);
    this.setStateOfPopup.bind(this);
    this.state = {
      tabs: templateTabs,
      Millers: [],
      singlelistView: null,
      listViewArray: [],
      loading: false,
      isPopup: false,
      isNew: false,
      openDialog: false,

      dispatchList: [],
      dispatchListCopy: [],
      loader: false,
      invoiceListArr: [],

      invoiceListArrCopy: [],
      openStatusDialog: false,
      selectedListArray: [],
      showInvoice: false,

      selectedRange: [null, null],
      selectedContractType: "",
      selectedContractTypeId: "",
      downloadInvoicePdf: false,
      selctedTab: "unpaid",
      paginationData: {},
      resetPage: "",
      userRole:
        this.props.user && this.props.user.roles
          ? this.props.user.roles.toString().toLowerCase()
          : "",
    };
  }

  setStateOfPopup = (value) => {
    this.setState({ isPopup: value, isNew: false });
  };

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }

  filterDataAccDate = (dispatchList) => {
    const oldArray =
      dispatchList && dispatchList.length > 0
        ? dispatchList.map((s) => {
            s.contract_type_id = s.purchase_order_details.contract_type_details
              ? s.purchase_order_details.contract_type_details.contract_type
              : "";
            return s;
          })
        : [];
    const newArr =
      this.state.selectedContractType === "All"
        ? oldArray
        : filterData(
            oldArray,
            "contract_type_id",
            this.state.selectedContractType
          )
        ? filterData(
            oldArray,
            "contract_type_id",
            this.state.selectedContractType
          )
        : [];

    return newArr ? newArr : [];
  };
  filterDataAccDate1 = (invoiceList) => {
    const { selectedRange } = this.state;
    const oldArray = invoiceList && invoiceList.length > 0 ? invoiceList : [];

    const dateFrom =
      selectedRange && selectedRange[0]
        ? moment(selectedRange[0]).format("MMM D YYYY")
        : moment(new Date()).format("MMM D YYYY");

    const dateTo =
      selectedRange && selectedRange[1]
        ? moment(selectedRange[1]).format("MMM D YYYY")
        : moment(new Date()).format("MMM D YYYY");
    const newArr = oldArray.filter((singleObj) => {
      const createdAt = moment(singleObj.invoice_date).format("MMM D YYYY");

      return (
        createdAt === dateFrom ||
        createdAt === dateTo ||
        (new Date(createdAt) > new Date(dateFrom) &&
          new Date(dateTo) > new Date(createdAt))
      );
    });
    return newArr ? newArr : [];
  };
  UNSAFE_componentWillMount() {
    this.fetchAllData();
    const next = this.props;
    if (next.params && next.params.tab) {
      let tab = next.params.tab;
      if (tab === EndPoints["Millers"] && next.params.id) {
        this.listClickHandler(this.state.tabs[0].data[next.params.id]);
      } else if (tab === "NEW") {
        this.setState({ singlelistView: null });
      }
    } else {
      this.setState({ singlelistView: null });
    }
    this.setInitialValues(this.props);
  }
  listClickHandler = (value) => {
    this.singlelistView && this.fetchDispatchList("UNPAID");
    const { selctedTab } = this.state;
    this.setState(
      {
        singlelistView: value,
      },

      () => {
        if (this.state.singlelistView && this.state.singlelistView.id) {
          console.log(selctedTab, "selctedTab");
          if (selctedTab === "unpaid") {
            this.fetchDispatchList("UNPAID");
          }
          if (selctedTab === "pending") {
            this.fetchDispatchList("PENDING");
          }
          if (selctedTab === "paid") {
            this.fetchDispatchList("paid");
          }
        }
      }
    );
  };
  fetchAllData = () => {
    this.setState({ loader: true });
    this.props
      .MillersFunction(
        "get",
        "masters/item-category/list",
        null,
        "ItemCateoryList",
        null,
        {
          is_active: "True",
        }
      )
      .then((ItemCateoryList) => {
        if (ItemCateoryList) {
          this.setState({ ItemCateoryList }, () => {
            this.props
              .MillersFunction(
                "get",
                "masters/item-type/list",
                null,
                "ItemList",
                null,
                {
                  is_active: "True",
                }
              )
              .then((ItemList) => {
                if (ItemList) {
                  this.setState({ ItemList }, () => {
                    this.props
                      .MillersFunction(
                        "get",
                        "masters/brand/list",
                        null,
                        "BrandList",
                        null,
                        {
                          is_active: "True",
                        }
                      )
                      .then((BrandList) => {
                        if (BrandList) {
                          this.setState({
                            loader: false,
                            BrandList: BrandList.map((sBrand) => {
                              if (
                                this.state.ItemList.length > 0 &&
                                this.state.ItemCateoryList.length > 0
                              ) {
                                sBrand.item_category_name =
                                  this.state.ItemCateoryList.filter(
                                    (singleItem) =>
                                      singleItem.id === sBrand.item_category
                                  )
                                    ? this.state.ItemCateoryList.filter(
                                        (singleItem) =>
                                          singleItem.id === sBrand.item_category
                                      )[0].name
                                    : "";
                                sBrand.item_type_name =
                                  this.state.ItemList.filter(
                                    (singleItem) =>
                                      singleItem.id === sBrand.item_type
                                  )
                                    ? this.state.ItemList.filter(
                                        (singleItem) =>
                                          singleItem.id === sBrand && sBrand.item_type
                                      )[0].broken_percentage
                                    : "";

                                return sBrand;
                              } else {
                                return sBrand;
                              }
                            }),
                          });
                        } else {
                          this.setState({ loader: false });
                        }
                      });
                  });
                } else {
                  this.setState({ loader: false });
                }
              });
          });
        } else {
          this.setState({ loader: false });
        }
      });
  };
  searchHandler = (value) => {
    this.listClickHandler(value);
  };
  fetchDispatchList = (type, urlText = false, field = false) => {
    this.setState({ loader: true });
    if (type === "paid") {
      this.props
        .MillersFunction(
          "get",
          "dispatch/invoice/list",
          null,
          "invoice",
          null,
          {
            miller_id: this.state.singlelistView.id,
          }
        )
        .then((invoiceArr) => {
          this.setState({
            invoiceListArr:
              invoiceArr && invoiceArr.length > 0
                ? this.state.selectedRange &&
                  this.state.selectedRange.length > 0 &&
                  ((this.state.selectedRange[0] &&
                    this.state.selectedRange[0] !== null) ||
                    (this.state.selectedRange[1] &&
                      this.state.selectedRange[1] !== null))
                  ? this.filterDataAccDate1(invoiceArr)
                  : invoiceArr
                : [],
            invoiceListArrCopy:
              invoiceArr && invoiceArr.length > 0 ? invoiceArr : [],
            loader: false,
          });
        });
    } else {
      this.props
        .MillersFunction(
          "get", 
          field === "pagination" ? urlText : "dispatch/list", 
          null, 
          "dispatchList", 
          null, 
          field === "pagination"
          ? null
          : {
          miller_id: this.state.singlelistView.id,
          payment_status: type,
        },
        field === "pagination" ? true : false
      )
        .then((dispatchDataList) => {
          let dispatchList = dispatchDataList?.results ? dispatchDataList.results : dispatchDataList;
          const newArrId = [];
          const newArr = [];
          if (dispatchList && dispatchList.length > 0) {
            dispatchList.map((sR) => {
              if (sR.id) {
                this.props
                  .MillersFunction(
                    "get",
                    "dispatch/dispatch-items/list",
                    null,
                    "dispatch-item",
                    null,
                    {
                      dispatch_id: sR.id,
                    }
                  )
                  .then((res2) => {
                    if (res2) {
                      sR.subList = res2;
                      if (!newArrId.includes(sR.id)) {
                        newArr.push(sR);
                        newArrId.push(sR.id);
                      }

                      if (newArr.length === dispatchList.length) {
                        newArr.map((sN) => {
                          sN.miller = sN.purchase_order_details.miller;
                          return sN;
                        });

                        this.setState({
                          dispatchList: newArr,
                          dispatchListCopy: newArr,
                          loader: false,
                        });

                        return sR;
                      }
                    } else {
                      dispatchList.map((sN) => {
                        sN.miller = sN.purchase_order_details.miller;
                        return sN;
                      });
                      this.setState({
                        dispatchListCopy: dispatchList,
                        loader: false,
                      });
                    }
                  });
              }
            });
            if (dispatchDataList?.results) {
              this.setState({
                paginationData: {
                  count: dispatchDataList?.count_on_page || 0,
                  total_count: dispatchDataList?.total_count || 0,
                  next: dispatchDataList?.next || null,
                  previous: dispatchDataList?.previous || null,
                },
              });
            } else {
              this.setState({ paginationData: {} });
            }
          } else {
            this.setState({
              dispatchList: [],
              dispatchListCopy: [],
              loader: false,
              paginationData: {}
            });
          }
        });
    }
  };

  pageChangeAPI = (url) => {
    const { selctedTab } = this.state;
    if (selctedTab === "unpaid") {
      this.fetchDispatchList("UNPAID", url, "pagination");
    }
    if (selctedTab === "pending") {
      this.fetchDispatchList("PENDING", url, "pagination");
    }
    if (selctedTab === "paid") {
      this.fetchDispatchList("paid", url, "pagination");
    }
  };
  resetPaginationPage = (field) => {
    this.setState({ resetPage: field });
  };

  onEditUpdate = (res) => {
    if (res) {
      this.setState({ singlelistView: res });
    }
  };

  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data =
        props[EndPoints["Millers"]] && props[EndPoints["Millers"]].length > 0
          ? props[EndPoints["Millers"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [];
      return tab;
    });
    this.setState({
      userRole: this.props.user.roles?this.props.user.roles.toString().toLowerCase():"admin",
      listViewArray:
        props[EndPoints["Millers"]] && props[EndPoints["Millers"]].length > 0
          ? props[EndPoints["Millers"]].sort((a, b) => {
              var dateA = moment(b.created_at).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.created_at).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            })
          : [],
      tabs,
      contractType: props.contractType,
      loading: props.loading,
    });
  }
  render() {
    const {
      tabs,
      listViewArray,
      singlelistView,
      loading,
      dispatchList,
      invoiceListArr,
      invoiceListArrCopy,
      editList,
      loader,
      openDialog,
      ItemCateoryList,
      ItemList,
      BrandList,
      dispatchListCopy,
      contractType,
      openStatusDialog,
      showInvoice,
      selectedContractType,
      selectedRange,
      selectedContractTypeId,
      downloadInvoicePdf,
      userRole,
      paginationData,
    } = this.state;
    const filterChild = () => {
      return (
        <CssTextField95
          required={true}
          autoComplete="off"
          value={
            selectedContractType ? selectedContractType : "" //
          }
          onChange={(e) => {
            this.setState(
              {
                selectedContractTypeId: e.currentTarget.id,
                selectedContractType: e.target.value,
              },
              () => {
                const oldArray =
                  dispatchListCopy && dispatchListCopy.length > 0
                    ? dispatchListCopy.map((s) => {
                        s.contract_type_id = s.purchase_order_details
                          .contract_type_details
                          ? s.purchase_order_details.contract_type_details
                              .contract_type
                          : "";
                        return s;
                      })
                    : [];
                const newArr =
                  this.state.selectedContractType === "All"
                    ? oldArray
                    : filterData(
                        oldArray,
                        "contract_type_id",
                        this.state.selectedContractType
                      )
                    ? filterData(
                        oldArray,
                        "contract_type_id",
                        this.state.selectedContractType
                      )
                    : [];

                this.setState({
                  dispatchList: newArr.length > 0 ? newArr : [],
                });
              }
            );
          }}
          style={
            { width: "200px", margin: "0px" } //disabled={tp}
          }
          id="outlined-select-currency-native"
          select
          label="Contract Type"
          variant="outlined"
          InputLabelProps={{}}
        >
          {" "}
          <MenuItem key={"All"} value={"All"} id={"All"}>
            All
          </MenuItem>
          {contractType &&
            contractType.length > 0 &&
            contractType.map((option) => (
              <MenuItem
                key={option.contract_type}
                value={option.contract_type}
                id={option.id}
              >
                {option.contract_type}
              </MenuItem>
            ))}
        </CssTextField95>
      );
    };
    const filterChild2 = () => {
      return (
        <BasicDateRangePicker
          selectedRange={selectedRange}
          onClear={() => {
            this.setState({
              invoiceListArr:
                invoiceListArrCopy.length > 0 ? invoiceListArrCopy : [],
              selectedRange: [null, null],
            });
          }}
          onChange={(val) => {
            const oldArray =
              invoiceListArrCopy && invoiceListArrCopy.length > 0
                ? invoiceListArrCopy
                : [];

            const dateFrom =
              val && val[0]
                ? moment(val[0]).format("MMM D YYYY")
                : moment(new Date()).format("MMM D YYYY");

            const dateTo =
              val && val[1]
                ? moment(val[1]).format("MMM D YYYY")
                : moment(new Date()).format("MMM D YYYY");
            const newArr = oldArray.filter((singleObj) => {
              const createdAt = moment(singleObj.invoice_date).format(
                "MMM D YYYY"
              );

              return (
                createdAt === dateFrom ||
                createdAt === dateTo ||
                (new Date(createdAt) > new Date(dateFrom) &&
                  new Date(dateTo) > new Date(createdAt))
              );
            });
            this.setState({
              invoiceListArr: newArr.length > 0 ? newArr : [],
              selectedRange: val,
            });
          }}
        />
      );
    };
    var labelField;

    labelField = "name";
    const setData = (singleDispatch) => {
      const unit =
        singleDispatch.purchase_order_details &&
        singleDispatch.purchase_order_details.unit &&
        singleDispatch.purchase_order_details.unit;
      singleDispatch["PO#"] = singleDispatch.purchase_order_details
        ? singleDispatch.purchase_order_details.po_number
        : "-";
      singleDispatch["Dispatch date"] = moment(
        singleDispatch.dispatch_date
      ).format("ddd, MMM Do YYYY");

      singleDispatch["Bill Amount"] = singleDispatch.miller_bill_amount
        ? `Rs ${Number(singleDispatch.miller_bill_amount).toFixed(2)}`
        : "-";

      singleDispatch["Bill Number"] =
        singleDispatch.miller_bill_number &&
        singleDispatch.miller_bill_number.length > 0
          ? singleDispatch.miller_bill_number
          : "-";
      singleDispatch["Bags qty"] =
        singleDispatch.subList &&
        singleDispatch.subList.length > 0 &&
        singleDispatch.subList.reduce(
          (previousValue, currentValue) =>
            Number(previousValue) +
            Number(
              currentValue.dispatch_packaging &&
                currentValue.dispatch_packaging.length > 0
                ? currentValue.dispatch_packaging.reduce(
                    (previousValue1, currentValue1) =>
                      Number(previousValue1) +
                      (currentValue1.total_bags_dispatched
                        ? Number(currentValue1.total_bags_dispatched)
                        : 0),
                    0
                  )
                : 0
            ),
          0
        );
      singleDispatch["Tcs"] =
        singleDispatch.miller_tcs && singleDispatch.miller_tcs.length > 0
          ? `${Number(singleDispatch.miller_tcs).toFixed(2)} %`
          : "-";
      singleDispatch["Dispatch qty"] = singleDispatch.dispatch_weight
        ? `${Number(
            singleDispatch.dispatch_weight ? singleDispatch.dispatch_weight : 0
          ).toFixed(3)} ${unit}`
        : "-";
      singleDispatch["Advance"] = singleDispatch.miller_advance
        ? `Rs ${Number(singleDispatch.miller_advance).toFixed(2)}`
        : "-";
      singleDispatch["Total Amount"] =
        singleDispatch.miller_total_amount &&
        singleDispatch.miller_total_amount.length > 0
          ? `Rs ${Number(singleDispatch.miller_total_amount).toFixed(2)}`
          : "-";
      singleDispatch["totalamount"] =
        singleDispatch.miller_total_amount &&
        singleDispatch.miller_total_amount.length > 0
          ? Number(singleDispatch.miller_total_amount).toFixed(2)
          : "-";
      singleDispatch["Dispatch Status"] = singleDispatch.dispatch_status
        ? singleDispatch.dispatch_status
        : "-";
      singleDispatch["Status"] = singleDispatch.dispatch_status
        ? singleDispatch.dispatch_status.charAt(0).toUpperCase() +
          singleDispatch.dispatch_status.slice(1)
        : "-";

      singleDispatch["subList"] = singleDispatch.subList
        ? singleDispatch.subList.map((singleSub) => {
            singleSub["Brand"] = filterData(
              BrandList,
              "id",
              singleSub.brand,
              "onlyOne"
            )
              ? filterData(BrandList, "id", singleSub.brand, "onlyOne")
                  .brand_name
              : "";

            singleSub["Item Category"] = filterData(
              ItemCateoryList,
              "id",
              singleSub.item_category,
              "onlyOne"
            )
              ? filterData(
                  ItemCateoryList,
                  "id",
                  singleSub.item_category,
                  "onlyOne"
                ).name
              : "";
            singleSub["Item Type"] = filterData(
              ItemList,
              "id",
              singleSub.item_type,
              "onlyOne"
            )
              ? `${Number(
                  filterData(ItemList, "id", singleSub.item_type, "onlyOne")
                    .broken_percentage
                ).toFixed(2)} %`
              : "";
            singleSub["Packaging"] =
              singleSub.dispatch_packaging &&
              singleSub.dispatch_packaging.length > 0
                ? `  ${
                    singleSub.dispatch_packaging.map((sP) => {
                      const singlePack = sP.packaging_details;

                      return singlePack
                        ? `${singlePack.size}${singlePack.unit}${" - "}${
                            singlePack.bag_type
                          }`
                        : "";
                    })[0]
                  } Total (...s)`
                : "-";
            singleSub["PackagingUI"] = (
              <ShowPackaging
                noTotalbags={true}
                details={singleSub.dispatch_packaging.map((sP) => {
                  const singlePack = sP.packaging_details;
                  singlePack.used_bags = sP.total_bags_dispatched;

                  return singlePack;
                })}
              />
            );
            singleSub["PackagingUIPdf"] =
              singleSub.dispatch_packaging &&
              singleSub.dispatch_packaging.length > 0
                ? singleSub.dispatch_packaging
                    .map((sP) => {
                      const singlePack = sP.packaging_details;

                      return singlePack
                        ? `${singlePack.size}${singlePack.unit}${" - "}${
                            singlePack.bag_type
                          }`
                        : "";
                    })
                    .join(", ")
                : "-";
            return singleSub;
          })
        : [];
      return singleDispatch;
    };
    return (
      <Layout
        loading={loading[EndPoints["Millers"]]}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: listViewArray && listViewArray.length > 0 ? listViewArray : [],
          hintText: `Search Millers`,
          labelField: labelField,
        }}
        listClickHandler={this.listClickHandler}
        // fabClickHandler={() => {
        //   this.setState({
        //     isPopup: true,
        //     isNew: true,
        //   });
        // }}
        fabClickHandler={(value) => {
          this.setState({
            openDialog: true,
            loadingFun: false,
            editList: false,
          });
        }}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="465px"
        closeWidth="287px"
      >
        {openDialog === true ? (
          <CreateDialog
            textFieldWidth="calc(50% - 20px)"
            heightNew="500px"
            maxHeight="calc(100vh - 40px)"
            widthNew="900px"
            noAddAnother={true}
            onEditUpdate={(res) => {
              this.onEditUpdate(res);
            }}
            // addFieldsToAdd={[{ name: "is_active", value: true }]}
            module={"Millers"}
            selectedList={editList ? editList : false}
            editList={editList ? editList : false}
            handleClose={() => {
              this.setState({
                openDialog: false,
                loadingFun: false,
                editList: true,
              });
            }}
            postApi="masters/miller/list"
            editApi="masters/miller"
            inputArray={[
              {
                name: "Active",
                type: "toggle",
                defaultValue: {
                  value: editList ? editList.is_active : true,
                },
                api: "is_active",
              },
              {
                name: "name",
                type: "textField",
                dataType: "text",
                api: "name",
                defaultValue: {
                  value: editList && editList.name,
                },
              },
              {
                name: "phone number",
                type: "textField",
                dataType: "number",
                onInput: "phone_number",
                only_number: true,
                not_required: true,
                api: "phone",
                defaultValue: {
                  value: editList && editList.phone,
                },
              },
              {
                name: "address",
                type: "addressTextField",
                dataType: "text",
                api: "address",
                defaultValue: {
                  value: editList && editList.address,
                },
              },
              {
                name: "GST IN",
                type: "textField",
                dataType: "text",
                onKeyPress: "alphaNum",
                onInput: "gst_in",
                api: "gstin",
                defaultValue: {
                  value: editList && editList.gstin,
                },
                helperText: "ex: 22AAAAA0000A1Z5",
              },
              {
                name: "contact person",
                type: "textField",
                dataType: "text",
                api: "contact_person",
                not_required:true,
                defaultValue: {
                  value: editList && editList.contact_person,
                },
              },

              {
                name: "state",
                type: "select",
                array: stateArray,
                value: "name",
                api: "state",
                defaultValue: {
                  value: editList && editList.state,
                },
              },
              {
                name: "city",
                type: "textField",
                dataType: "text",
                api: "city",
                onKeyPress: "textOnly",
                defaultValue: {
                  value: editList && editList.city,
                },
              },

              {
                name: "pincode",
                type: "textField",
                onInput: "pincode",
                dataType: "number",
                api: "pincode",
                only_number: true,
                defaultValue: {
                  value: editList && editList.pincode,
                },
              },

              {
                name: "Rice Bag Seller",
                type: "toggle",
                defaultValue: {
                  value: editList ? editList?.is_ricebag_seller : true,
                },
                api: "is_ricebag_seller",
              },
            ]}
            postData={this.props.MillersFunction}
            fetchAgain={() => {
              this.props.MillersFunction("get", EndPoints["Millers"]);
            }}
          />
        ) : (
          ""
        )}

        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {openStatusDialog === true && (
            <CssDialog
              height={showInvoice ? "calc(100% - 63px)" : "250px"}
              width={showInvoice ? "calc(100% - 150px)" : "400px"}
              noReset={true}
              header={
                showInvoice !== false
                  ? "Invoice"
                  : this.state.selectedListArray[0] &&
                    this.state.selectedListArray[0].transporter_payment ===
                      "PENDING"
                  ? "Approve Payment"
                  : "Mark as Paid"
              }
              onClose={() => {
                this.setState({
                  openStatusDialog: false,
                  editDialog: false,
                  added: false,
                  selectedListArray: false,
                  showInvoice: false,
                });
              }}
              children={() => {
                return (
                  <div className="d-flex justifyC alignC width-100 height-100 fontSize1 ">
                    <div
                      style={{ height: "0", width: "0", overflow: "hidden" }}
                    >
                      {downloadInvoicePdf === true ? (
                        <InvoiceCardUi
                          onlyShow={false}
                          setLoader={() => {
                            this.setState({ loader: false });
                          }}
                          onDownload={() => {
                            this.setState({
                              openStatusDialog: false,
                              editDialog: false,
                              added: false,
                              selectedListArray: false,
                              showInvoice: false,
                              loader: false,
                              downloadInvoicePdf: false,
                              broken_amount: "",
                            });
                          }}
                          onlyDownload={showInvoice}
                          singlelistView={singlelistView}
                          module="miller"
                          filterChild={() => {
                            return filterChild2();
                          }}
                          pdfHeader={[
                            "Sr.No.",
                            "PO#",
                            "Dispatch date",
                            "Bill Number",
                            "Bags qty",
                            "Dispatch qty",
                            "Bill Amount",
                            "Tcs",
                            "Advance",
                            "Total Amount",
                            "Dispatch Status",
                          ]}
                          header={[
                            "Sr.No.",
                            "PO#",
                            "Dispatch date",
                            "Bill Number",
                            "Bags qty",
                            "Dispatch qty",
                            "Bill Amount",
                            "Tcs",
                            "Advance",
                            "Total Amount",
                            "Dispatch Status",
                          ]}
                          setData={setData}
                          loader={loader ? loader : false}
                          fetchFunction={this.props.MillersFunction}
                          listArr={
                            invoiceListArr && invoiceListArr.length
                              ? invoiceListArr
                              : []
                          }
                          listArrCopy={
                            invoiceListArrCopy && invoiceListArrCopy.length
                              ? invoiceListArrCopy
                              : []
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {showInvoice !== false ? (
                      <InvoiceCardUi
                        onlyShow={true}
                        setLoader={() => {
                          this.setState({ loader: false });
                        }}
                        onDownload={() => {
                          this.setState({
                            openStatusDialog: false,
                            editDialog: false,
                            added: false,
                            selectedListArray: false,
                            showInvoice: false,
                            loader: false,
                            downloadInvoicePdf: false,
                          });
                        }}
                        onlyDownload={showInvoice}
                        singlelistView={singlelistView}
                        module="miller"
                        filterChild={() => {
                          return filterChild2();
                        }}
                        pdfHeader={[
                          "Sr.No.",
                          "PO#",
                          "Dispatch date",
                          "Bill Number",
                          "Bags qty",
                          "Dispatch qty",
                          "Bill Amount",
                          "Tcs",
                          "Advance",
                          "Total Amount",
                          "Dispatch Status",
                        ]}
                        header={[
                          "Sr.No.",
                          "PO#",
                          "Dispatch date",
                          "Bill Number",
                          "Bags qty",
                          "Dispatch qty",
                          "Bill Amount",
                          "Tcs",
                          "Advance",
                          "Total Amount",
                          "Dispatch Status",
                        ]}
                        setData={setData}
                        loader={loader ? loader : false}
                        fetchFunction={this.props.MillersFunction}
                        listArr={
                          invoiceListArr && invoiceListArr.length
                            ? invoiceListArr
                            : []
                        }
                        listArrCopy={
                          invoiceListArrCopy && invoiceListArrCopy.length
                            ? invoiceListArrCopy
                            : []
                        }
                      />
                    ) : (
                      <>
                        <div
                          style={{
                            fontSize: "0.9rem",
                            justifyContent: "center",
                            marginTop: "20px",
                            fontFamily: "Poppins",
                          }}
                        >
                          {this.state.selectedListArray[0] &&
                          this.state.selectedListArray[0].miller_payment ===
                            "PENDING"
                            ? "Are you sure you want to approve and  mark these dispatches as paid ?"
                            : "Are you sure you want to mark these dispatches as paid ?"}
                        </div>
                        {/* <CssTextField
                          required={false}
                          type="number"
                          autoComplete="off"
                          style={{ margin: "40px 0" }}
                          id="outlined-basic"
                          label="Brokerage Amount"
                          variant="outlined"
                          name="Rate"
                          value={broken_amount ? broken_amount : ""}
                          onChange={(e) => {
                            this.setState({ broken_amount: e.target.value });
                          }}
                        /> */}
                      </>
                    )}
                  </div>
                );
              }}
              bottomBarContent={() => {
                return showInvoice ? (
                  <>
                    <StyledButton
                      name="Download pdf"
                      width="150px"
                      padding="2px 5px"
                      onClick={() => {
                        this.setState({
                          downloadInvoicePdf: true,
                          loader: true,
                        });
                      }}
                    />
                    {/* <StyledButton
                      withBg={true}
                      name="Download CSV"
                      width="150px"
                      padding="2px 5px"
                    /> */}
                  </>
                ) : (
                  <>
                    <StyledButton
                      padding="2px 5px"
                      onClick={() => {
                        this.setState({
                          openStatusDialog: false,
                          editDialog: false,
                          added: false,
                          selectedListArray: false,
                        });
                      }}
                      name="No"
                    />
                    <StyledButton
                      onClick={() => {
                        if (
                          this.state.selectedListArray &&
                          this.state.selectedListArray.length > 0
                        ) {
                          this.setState({ loader: true });
                          const data = this.state.selectedListArray;
                          const newInvoice = {
                            invoice_date: moment(new Date()).format(
                              "YYYY-MM-DD"
                            ),
                            // invoice_number: `${data[0].id.toString()}${data.length.toString()}`,
                            invoice_for: "MILLER",
                            amount: 0,
                            is_active: true,
                            miller: singlelistView.id,
                          };
                          const apiData = [
                            { name: "id" },
                            { name: "dispatch_date" },
                            { name: "total_bags" },
                            { name: "dispatch_weight" },
                            { name: "miller_bill_amount" },
                            { name: "miller_tcs" },
                            { name: "miller_advance" },
                            { name: "miller_total_amount" },
                            { name: "bilty_number" },
                            { name: "bilty_date" },
                            { name: "truck_number" },
                            { name: "gross_weight" },
                            { name: "net_weight" },
                            { name: "tare_weight" },
                            { name: "freight_rate" },
                            { name: "total_freight" },
                            { name: "transporter_advance" },
                            { name: "transporter_balance" },
                            { name: "last_survey" },
                            { name: "miller_payment" },
                            { name: "transporter_payment" },
                            { name: "broker_payment" },
                            { name: "dispatch_status" },
                            { name: "miller_bill_number" },
                            { name: "is_active" },
                            { name: "unit" },
                            { name: "dispatched_from" },
                            { name: "dispatched_to" },
                            { name: "purchase_order" },
                            { name: "sales_order" },
                            { name: "transporter" },
                            { name: "surveyor" },
                          ];
                          if (
                            this.state.selectedListArray[0].miller_payment ===
                            "PENDING"
                          ) {
                            this.props
                              .MillersFunction(
                                "post",
                                "dispatch/invoice/list",
                                null,
                                "invoiceUpdate",
                                newInvoice
                              )
                              .then((invoiceId) => {
                                if (invoiceId && invoiceId.id) {
                                  const newArr = [];

                                  data.map((sData) => {
                                    const newObj = {};
                                    apiData.map((s) => {
                                      return (newObj[s.name] = s.value
                                        ? sData[s.value]
                                        : sData[s.name]
                                        ? sData[s.name]
                                        : s.name === "transporter"
                                        ? ""
                                        : s.name === "transporter_payment" ||
                                          s.name === "broker_payment"
                                        ? false
                                        : null);
                                    });
                                    newObj.invoice = invoiceId.id;
                                    newObj.invoice_number = 0;
                                    newObj.miller_payment = "PAID";

                                    newObj.invoice_date = moment(
                                      new Date()
                                    ).format("YYYY-MM-DD");
                                    return newArr.push(newObj);
                                  });

                                  this.props
                                    .MillersFunction(
                                      "put",
                                      "dispatch/bulk-update",
                                      null,
                                      "dispatchListBulk",
                                      newArr
                                    )
                                    .then((dispatchListBulk) => {
                                      this.fetchDispatchList("PENDING");
                                      this.setState({
                                        loader: false,
                                        showInvoice: invoiceId,
                                      });
                                      // this.setState({
                                      //   openStatusDialog: false,
                                      //   editDialog: false,
                                      //   added: false,
                                      //   selectedListArray: false,
                                      //   showInvoice: false,
                                      // });
                                    });
                                }
                              });
                          } else if (
                            this.state.selectedListArray[0].miller_payment ===
                            "UNPAID"
                          ) {
                            if (userRole === "admin") {
                              console.log("admin user");
                              this.props
                                .MillersFunction(
                                  "post",
                                  "dispatch/invoice/list",
                                  null,
                                  "invoiceUpdate",
                                  newInvoice
                                )
                                .then((invoiceId) => {
                                  if (invoiceId && invoiceId.id) {
                                    const newArr = [];

                                    data.map((sData) => {
                                      const newObj = {};
                                      apiData.map((s) => {
                                        return (newObj[s.name] = s.value
                                          ? sData[s.value]
                                          : sData[s.name]
                                          ? sData[s.name]
                                          : s.name === "transporter"
                                          ? ""
                                          : s.name === "transporter_payment" ||
                                            s.name === "broker_payment"
                                          ? false
                                          : null);
                                      });
                                      newObj.invoice = invoiceId.id;
                                      newObj.invoice_number = 0;
                                      newObj.miller_payment = "PAID";

                                      newObj.invoice_date = moment(
                                        new Date()
                                      ).format("YYYY-MM-DD");
                                      return newArr.push(newObj);
                                    });

                                    this.props
                                      .MillersFunction(
                                        "put",
                                        "dispatch/bulk-update",
                                        null,
                                        "dispatchListBulk",
                                        newArr
                                      )
                                      .then((dispatchListBulk) => {
                                        this.fetchDispatchList("UNPAID");
                                        this.setState({
                                          loader: false,
                                          showInvoice: invoiceId,
                                        });
                                        // this.setState({
                                        //   openStatusDialog: false,
                                        //   editDialog: false,
                                        //   added: false,
                                        //   selectedListArray: false,
                                        //   showInvoice: false,
                                        // });
                                      });
                                  }
                                });
                            } else {
                              // this.props
                              //   .MillersFunction(
                              //     "post",
                              //     "dispatch/invoice/list",
                              //     null,
                              //     "invoiceUpdate",
                              //     newInvoice
                              //   )
                              //   .then((invoiceId) => {
                              //     if (invoiceId && invoiceId.id) {
                              //       const newArr = [];

                              //       data.map((sData) => {
                              //         const newObj = {};
                              //         apiData.map((s) => {
                              //           return (newObj[s.name] = s.value
                              //             ? sData[s.value]
                              //             : sData[s.name]
                              //             ? sData[s.name]
                              //             : s.name === "transporter"
                              //             ? ""
                              //             : s.name === "transporter_payment" ||
                              //               s.name === "broker_payment"
                              //             ? false
                              //             : null);
                              //         });
                              //         newObj.invoice = invoiceId.id;
                              //         newObj.invoice_number = 0;
                              //         newObj.miller_payment = "PENDING";

                              //         newObj.invoice_date = moment(
                              //           new Date()
                              //         ).format("YYYY-MM-DD");
                              //         return newArr.push(newObj);
                              //       });

                              //     }
                              //   });

                              const newArr = [];
                              data.map((sData) => {
                                const newObj = {};
                                apiData.map((s) => {
                                  return (newObj[s.name] = s.value
                                    ? sData[s.value]
                                    : sData[s.name]
                                    ? sData[s.name]
                                    : s.name === "transporter"
                                    ? ""
                                    : s.name === "transporter_payment" ||
                                      s.name === "broker_payment"
                                    ? false
                                    : null);
                                });
                                // newObj.invoice = invoiceId.id;
                                // newObj.invoice_number = 0;
                                newObj.miller_payment = "PENDING";

                                // newObj.invoice_date = moment(
                                //   new Date()
                                // ).format("YYYY-MM-DD");
                                return newArr.push(newObj);
                              });

                              this.props
                                .MillersFunction(
                                  "put",
                                  "dispatch/bulk-update",
                                  null,
                                  "dispatchListBulk",
                                  newArr
                                )
                                .then((dispatchListBulk) => {
                                  this.fetchDispatchList("UNPAID");
                                  this.setState({
                                    openStatusDialog: false,
                                    loader: false,

                                    // showInvoice: invoiceId,
                                  });
                                  // this.setState({
                                  //   openStatusDialog: false,
                                  //   editDialog: false,
                                  //   added: false,
                                  //   selectedListArray: false,
                                  //   showInvoice: false,
                                  // });
                                });
                            }
                          }
                        }
                      }}
                      name="Yes"
                      withBg={true}
                      padding="2px 5px"
                    />
                  </>
                );
              }}
              loadingDialog={loader}
            />
          )}

          {singlelistView === null ? (
            <div className="d-flex width-100 height-100 justifyC alignC">
              <NoData />
            </div>
          ) : loading.Millers === true ? (
            <div className="alignC justiyC d-flex width-100 height-100">
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <TabComponent
              onChange={(newVal) => {
                const selctedTab =
                  newVal === 0
                    ? "unpaid"
                    : newVal === 1
                    ? "pending"
                    : newVal === 2
                    ? "paid"
                    : newVal === 3 && "Miller Details";
                this.setState({ selctedTab }, () => {
                  if (newVal === 0) {
                    this.fetchDispatchList("UNPAID");
                  }
                  if (newVal === 1) {
                    this.fetchDispatchList("PENDING");
                  }
                  if (newVal === 2) {
                    this.fetchDispatchList("paid");
                  }
                });
              }}
              list={[
                {
                  name: "Unpaid",
                  component: (
                    <TableComponent
                      loader={loader ? loader : false}
                      onCheckSubmit={async (selectedListArray) => {
                        return this.setState({
                          openStatusDialog: true,
                          selectedListArray: selectedListArray,
                        });
                      }}
                      bottomBar={true}
                      subMenu={true}
                      filterChild={() => {
                        return filterChild("unpaid");
                      }}
                      checkbox={true}
                      noAddButton={true}
                      module={"Dispatch"}
                      {...this.state}
                      header={[
                        "Sr.No.",
                        "PO#",
                        "Dispatch date",
                        "Bill Number",
                        "Bags qty",
                        "Dispatch qty",
                        "Bill Amount",
                        "Tcs",
                        "Advance",
                        "Total Amount",
                        "Dispatch Status",
                      ]}
                      pdfHeader={[
                        "Sr.No.",
                        "PO#",
                        "Dispatch date",
                        "Bill Number",
                        "Bags qty",
                        "Dispatch qty",
                        "Bill Amount",
                        "Tcs",
                        "Advance",
                        "Total Amount",
                      ]}
                      subMenuheader={[
                        "Sr.No.",
                        "Brand",
                        "Item Category",
                        "Item Type",
                        "Packaging",
                      ]}
                      actions={[]}
                      tablePagination={true}
                      paginationData={paginationData}
                      pageChangeAPI={this.pageChangeAPI}
                      {...this.props}
                      tableList={
                        dispatchList && dispatchList.length
                          ? filterData(
                              dispatchList,
                              "miller",
                              this.state.singlelistView.id
                            ) &&
                            filterData(
                              filterData(
                                dispatchList,
                                "miller",
                                this.state.singlelistView.id
                              ),
                              "miller_payment",
                              "UNPAID"
                            ) &&
                            filterData(
                              filterData(
                                dispatchList,
                                "miller",
                                this.state.singlelistView.id
                              ),
                              "miller_payment",
                              "UNPAID"
                            )
                              .sort((a, b) => {
                                var dateB = moment(b.dispatch_date).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                var dateA = moment(a.dispatch_date).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                return new Date(dateB) - new Date(dateA);
                              })
                              .map((singleDispatch) => {
                                return setData(singleDispatch);
                              })
                          : []
                      }
                    />
                  ),
                },
                {
                  name: "Pending",
                  component: (
                    <TableComponent
                      loader={loader ? loader : false}
                      onCheckSubmit={async (selectedListArray) => {
                        return this.setState({
                          openStatusDialog: true,
                          selectedListArray: selectedListArray,
                        });
                      }}
                      bottomBar={true}
                      subMenu={true}
                      filterChild={() => {
                        return filterChild("PENDING");
                      }}
                      checkbox={true}
                      noAddButton={true}
                      module={"Dispatch"}
                      {...this.state}
                      header={[
                        "Sr.No.",
                        "PO#",
                        "Dispatch date",
                        "Bill Number",
                        "Bags qty",
                        "Dispatch qty",
                        "Bill Amount",
                        "Tcs",
                        "Advance",
                        "Total Amount",
                        "Dispatch Status",
                      ]}
                      pdfHeader={[
                        "Sr.No.",
                        "PO#",
                        "Dispatch date",
                        "Bill Number",
                        "Bags qty",
                        "Dispatch qty",
                        "Bill Amount",
                        "Tcs",
                        "Advance",
                        "Total Amount",
                      ]}
                      subMenuheader={[
                        "Sr.No.",
                        "Brand",
                        "Item Category",
                        "Item Type",
                        "Packaging",
                      ]}
                      actions={[]}
                      {...this.props}
                      tableList={
                        dispatchList && dispatchList.length
                          ? filterData(
                              dispatchList,
                              "miller",
                              this.state.singlelistView.id
                            ) &&
                            filterData(
                              filterData(
                                dispatchList,
                                "miller",
                                this.state.singlelistView.id
                              ),
                              "miller_payment",
                              "PENDING"
                            ) &&
                            filterData(
                              filterData(
                                dispatchList,
                                "miller",
                                this.state.singlelistView.id
                              ),
                              "miller_payment",
                              "PENDING"
                            )
                              .sort((a, b) => {
                                var dateB = moment(b.dispatch_date).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                var dateA = moment(a.dispatch_date).format(
                                  "MMM D YYYY h:mm:ss A"
                                );
                                return new Date(dateB) - new Date(dateA);
                              })
                              .map((singleDispatch) => {
                                return setData(singleDispatch);
                              })
                          : []
                      }
                    />
                  ),
                },
                {
                  name: "Paid",
                  component: (
                    <InvoiceCardUi
                      singlelistView={singlelistView}
                      module="miller"
                      filterChild={() => {
                        return filterChild2();
                      }}
                      pdfHeader={[
                        "Sr.No.",
                        "PO#",
                        "Dispatch date",
                        // "Bill Number",
                        "Bags qty",
                        "Dispatch qty",
                        "Bill Amount",
                        "Tcs",
                        "Advance",
                        "Total Amount",
                        "Status",
                      ]}
                      header={[
                        "Sr.No.",
                        "PO#",
                        "Dispatch date",
                        "Bill Number",
                        "Bags qty",
                        "Dispatch qty",
                        "Bill Amount",
                        "Tcs",
                        "Advance",
                        "Total Amount",
                        "Dispatch Status",
                      ]}
                      setData={setData}
                      loader={loader ? loader : false}
                      fetchFunction={this.props.MillersFunction}
                      listArr={
                        invoiceListArr && invoiceListArr.length
                          ? invoiceListArr
                          : []
                      }
                      listArrCopy={
                        invoiceListArrCopy && invoiceListArrCopy.length
                          ? invoiceListArrCopy
                          : []
                      }
                    />
                  ),
                },
                {
                  name: "Miller Details",
                  component: (
                    <Paper
                      className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <div className="width-100 d-flex justifyFE">
                        <Tooltip title="Edit">
                          <EditIcon
                            onClick={() => {
                              this.setState({
                                openDialog: true,
                                editList: singlelistView,
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                      </div>

                      <div
                        className="overflowYA scrollBluePrimary"
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          padding: "0 50px",
                          height: "calc(100% - 31.7px)",
                          textTransform: "capitalize",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            Name:{" "}
                          </p>
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              textTransform: "capitalize",
                              wordBreak: "break-all",
                            }}
                          >
                            {singlelistView.name}
                          </p>
                        </div>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            Address:
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}
                          >
                            {singlelistView.address}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            City:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}
                          >
                            {singlelistView.city}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            State:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}
                          >
                            {singlelistView.state}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            GSTIN:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}
                          >
                            {singlelistView.gstin}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            Phone:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}
                          >
                            {singlelistView.phone}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            Contact Person:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                              wordBreak: "break-all",
                            }}
                          >
                            {singlelistView.contact_person}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            Pincode:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}
                          >
                            {singlelistView.pincode}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 ",
                              minWidth: "200px",
                            }}
                          >
                            Rice Bag Seller:{" "}
                          </p>{" "}
                          <p
                            style={{
                              fontSize: "18px",
                              margin: "10px 0 10px 10px",
                              color: "#808080",
                            }}
                          >
                            {singlelistView?.is_ricebag_seller ? "Yes" : "No"}
                          </p>
                        </div>
                      </div>
                    </Paper>
                  ),
                },
              ]}
            />
          )}
        </Paper>
        {/* <Popup
          isNew={this.state.isNew}
          openPopup={this.state.isPopup}
          setOpenPopup={this.setStateOfPopup}
        >
          {this.state.isNew ? (
            <NewForm
              closePopup={this.setStateOfPopup}
              getFn={() =>
                this.props.MillersFunction(
                  "get",
                  EndPoints["Millers"],
                  null,
                  null,
                  null,
                  {
                    is_active: "True",
                  }
                )
              }
            />
          ) : (
            singlelistView !== null && (
              <EditForm
                getFn={(response) => {
                  if (response.data) {
                    this.setState({ singlelistView: response.data });
                  }
                  this.props.MillersFunction(
                    "get",
                    EndPoints["Millers"],
                    null,
                    null,
                    null
                    // {
                    //   is_active: "True",
                    // }
                  );
                }}
                id={singlelistView.id}
                name={singlelistView.name}
                address={singlelistView.address}
                city={singlelistView.city}
                state={singlelistView.state}
                gst={singlelistView.gstin}
                phone={singlelistView.phone}
                contact={singlelistView.contact_person}
                pincode={singlelistView.pincode}
                status={singlelistView.is_active}
                closePopup={this.setStateOfPopup}
              />
            )
          )}
        </Popup> */}
      </Layout>
    );
  }
}

export default MillersComponent;
