import { Snackbar, Alert } from "@mui/material";
import React, { createContext, useState, useContext } from "react";
// import Alert from '@mui/material/Alert';
// import Snackbar from '@mui/material/Snackbar';

export const SnackbarContext = createContext();

export function useSnackbar() {
  return useContext(SnackbarContext);
}

const MuiAlert = React.forwardRef(function MuiAlert(props, ref) {

  const { severity, style, ...otherProps } = props;
  const customStyles = {
    success: { backgroundColor: "#f19a30", color: "#fff" }, // Orange
    warning: { backgroundColor: "#f19a30", color: "#fff" }, // Orange
    info: { backgroundColor: "#f19a30", color: "#fff" },   // Orange
  };

  // Merge custom styles for non-error severities
  const alertStyle =
    severity !== "error" ? { ...customStyles[severity], ...style } : style;


  return <Alert elevation={6} variant="filled" {...otherProps} ref={ref} style={{ fontFamily: "Poppins", ...alertStyle }} />;
});

const styles = {
  root: {
    "& > * + *": {
      marginTop: 2,
    },
    "& .MuiAlert-icon": {
      padding: "7px 0px"
    }
  },
};

export function SnackbarProvider({ children }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    type: "success",
    duration: 4000
  });

  const handleSnackbarMessageBox = (data) => {
    console.log(data)
    return (
      data.map((item, index) => {
        return (
          <p key={index} style={{ margin: 0, fontFamily: 'Poppins' }}>
            {item}
          </p>
        )
      })
    )
  }
  
  const checkApiError = (inputValue) => {
    let errorResp = inputValue?.response
    let errorArray = [];
    console.log("here", errorResp?.status, errorResp)
    if (errorResp?.status !== undefined && [500, 404].includes(errorResp?.status)) {
      errorArray.push(errorResp?.statusText + "  :  " + errorResp?.status)
      return errorArray;
    }
    else {
      let input = errorResp?.data !== undefined ? errorResp?.data : errorResp
      for (let key in input) {
        if (typeof (input[key][0]) !== typeof (String())) {
          for (let newkey in input[key][0]) {
            errorArray.push(newkey + "  :  " + (input[key][0][newkey]))
          }
        }
        else {
          errorArray.push(key + "  :  " + input[key])
        }
      }
    }
    return errorArray;
  }

  const showSnackbar = (message, type = "success", duration = 4000) => {
    console.log("CALEDD SNACKBAR", message, type, duration)
    let messageText = message
    if (type === "error") {
      // messageText = typeof message === 'string' 
      // ? message[0]?.message 
      // : typeof message === Object
      // ? message?.message
      // : message
      messageText = handleSnackbarMessageBox(checkApiError(message))
    }
    setSnackbar({ 
      open: true, 
      message: messageText, 
      type, 
      duration: duration ? duration : type === 'error' ? 5000 : 4000
    });
  };

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <div style={styles.root}>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={snackbar.duration}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <MuiAlert onClose={handleClose} severity={snackbar.type}>
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </div>
    </SnackbarContext.Provider>
  );
}
