import { Paper, withStyles } from "@material-ui/core";
// import React from "react";
import {
  CssDialog,
  CssTextField,
  CssTextField95,
  StyledButton,
  StyledCircularProgress,
} from "../../styles/StyledComponents";
import React, { useEffect, useState } from "react";
import { MenuItem } from "@material-ui/core";
import { color1, color3, color4, color5, primary } from "../../styles/colors";
import { filterData, StopScroll } from "../../Regex";
import SnackbarComponent from "../../layoutComponents/SnackbarComponent";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { BottomArrow, EditIcon, TopArrow } from "../../styles/Icons";
import { sum } from "lodash";
import ExcelComponent from "./PDFS/ExcelComponent";
import { Edit } from "@mui/icons-material";
export const CssKeyboardDatePicker = withStyles({
  root: {
    "& .PickerWithState-root-47 .MuiOutlinedInput-adornedEnd": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-input ": {
      padding: "9.5px",
    },
    "& .MuiIconButton-root ": {
      padding: "0px",
    },
  },
})(KeyboardDatePicker);
function EXpenseInput(props) {
  const { selectedCustomInvoice, noAddButton, postData, updateValues } = props;

  const allExType = [
    "Ocean Freight",
    "THC",
    "CHA",
    "INSPECTION",
    "OTHER EXPENSES",
  ];
  const [selectedExType, setSelectedExType] = useState("Ocean Freight");
  const [selectedCustomIn, setSelectedCustomIn] = useState(
    selectedCustomInvoice ? selectedCustomInvoice : {}
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [selctedExpense, setSelctedExpense] = useState(false);
  const [edit, setEdit] = useState(false);
  const getStepIndex = (type) => {
    switch (type) {
      case "ocean_freight":
        return "Ocean Freight";
      case "thc":
        return "THC";
      case "cha":
        return "CHA";
      case "inspection":
        return "INSPECTION";
      case "other":
        return "OTHER EXPENSES";
      case "Ocean Freight":
        return "Ocean Freight";
      case "THC":
        return "THC";
      case "CHA":
        return "CHA";
      case "INSPECTION":
        return "INSPECTION";
      case "OTHER EXPENSES":
        return "OTHER EXPENSES";
      default:
    }
  };
  const getStepIndexValue = (type) => {
    console.log(type, "typetype");
    switch (type) {
      case "Ocean Freight":
        return "ocean_freight";
      case "THC":
        return "thc";
      case "CHA":
        return "cha";
      case "INSPECTION":
        return "inspection";
      case "OTHER EXPENSES":
        return "other";
      default:
    }
  };

  const [inputFields, setInputFields] = useState([
    // "CUSTOM INV",
    "PARTY NAME",
    "INVOICE NO",
    "INVOICE DATE",
    "USD AMOUNT",
    "INR AMOUNT",
    "NO OF CONTAINER",
  ]);
  const [loader, setLoad] = useState(false);
  const [submittedExpenses, setSubmittedExpenses] = useState([]);
  const [submittedExpensesCopy, setSubmittedExpensesCopy] = useState([]);
  const [calenderOpen, setCalenderOpen] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState({});
  const [error, setError] = useState("");
  const removeGet = (arr, values) => {
    values.map((sV) => {
      if (arr.indexOf(sV) > -1) {
        arr.splice(arr.indexOf(sV), 1);
      }
      return sV;
    });
    return arr;
  };
  const getInputs = (selected) => {
    const arr = [
      "PARTY NAME",
      "INVOICE NO",
      "INVOICE DATE",
      "USD AMOUNT",
      "AMOUNT",
      "INR AMOUNT",
      "NO OF CONTAINER",
      "DETAIL",
    ];
    switch (selected) {
      case "Ocean Freight":
        return removeGet(arr, ["AMOUNT", "DETAIL"]);

      case "THC":
        return removeGet(arr, ["USD AMOUNT", "INR AMOUNT", "DETAIL"]);

      case "CHA":
        return removeGet(arr, ["USD AMOUNT", "INR AMOUNT", "DETAIL"]);

      case "INSPECTION":
        return removeGet(arr, ["USD AMOUNT", "INR AMOUNT", "DETAIL"]);

      case "OTHER EXPENSES":
        return removeGet(arr, ["USD AMOUNT", "INR AMOUNT", "NO OF CONTAINER"]);

      default:
        return arr;
    }
  };
  const loadData = () => {
    if (selectedCustomIn) {
      setLoad(true);
      postData("get", "arrival/expense/list", null, "docs", null, {
        commercial_invoice_id:
          selectedCustomIn && selectedCustomIn.id && selectedCustomIn.id,
      }).then((docs) => {
        if (docs) {
          console.log(docs, "docs");
          const arr = docs.map((d) => {
            return {
              ...d,
              "PARTY NAME": d.party_name,
              "INVOICE NO": d.invoice_details
                ? d.invoice_details.invoice_number
                : "",
              "INVOICE DATE": d.invoice_details
                ? d.invoice_date
                : new Date(),
              "USD AMOUNT": d.amount_usd,
              AMOUNT: d.amount_inr,
              "INR AMOUNT": d.amount_inr,
              "NO OF CONTAINER": d.no_of_containers,
              DETAIL: d.details,
              type: getStepIndex(d.expense_type),
            };
          });
          setSubmittedExpenses(arr.length > 0 ? arr : []);
          setSubmittedExpensesCopy(arr.length > 0 ? arr : []);
        } else {
          setSubmittedExpenses([]);
          setSubmittedExpensesCopy([]);
        }
        setLoad(false);
      });
    }
  };
  useEffect(() => {
    if (selectedCustomIn) {
      loadData();
    }
  }, [selectedCustomIn]);
  useEffect(() => {
    if (selectedCustomInvoice) {
      setSelectedCustomIn(selectedCustomInvoice);
    }
  }, [selectedCustomInvoice]);
  const clearFields = () => {
    setErrorMsgs({});
    setAllSumittedData({ "INVOICE DATE": new Date() });
    setSelctedExpense(false);
  };
  const submitExpense = () => {
    const newArr = [];
    const formData = new FormData();
    formData.append("is_active", true);
    formData.append(
      "commercial_invoice",
      selectedCustomIn ? selectedCustomIn.id : ""
    );

    submittedExpenses
      .filter((s) => !s.id)
      .map((single) => {
        newArr.push({
          expense_type: getStepIndexValue(single.type),
          party_name: single["PARTY NAME"] ? single["PARTY NAME"] : "",
          amount_usd: single["USD AMOUNT"] ? single["USD AMOUNT"] : 0,
          amount_inr: single["INR AMOUNT"]
            ? single["INR AMOUNT"]
            : single["AMOUNT"]
            ? single["AMOUNT"]
            : 0,
          no_of_containers: single["NO OF CONTAINER"]
            ? single["NO OF CONTAINER"]
            : 0,
          details: single["DETAIL"] ? single["DETAIL"] : "",
          paid: false,
          is_active: true,
          commercial_invoice: selectedCustomIn.id,

          invoice_date: moment(single["INVOICE DATE"]).format("YYYY-MM-DD"),
          invoice_number: single["INVOICE NO"] ? single["INVOICE NO"] : "",
        });
        return single;
      });
    const ONFinal = () => {
      // if (selectedCustomIn && selectedCustomIn.shipment_process_status === "DOCUMENTS UPLOADED") {
      //   postData("patch", "arrival/custom-invoice", selectedCustomIn.id, "patch", {
      //     shipment_process_status: "EXPENSES CALCULATED",
      //   }).then((res2) => {
      //     if (res2.error) {
      //       setLoad(false);
      //       setError(res2.error);
      //     } else {
      //       if (updateValues) {
      //         updateValues(res2);
      //       }
      //       setLoad(false);
      //       setOpenDialog(false);
      //     }
      //   });
      // } else {
      setLoad(false);
      setOpenDialog(false);
      // }
    };
    const onEDit = () => {
      const newArr2 = [];
      submittedExpenses
        .filter((s) => s.id)
        .map((single) => {
          newArr2.push({
            expense_type: getStepIndexValue(single.type),
            party_name: single["PARTY NAME"] ? single["PARTY NAME"] : "",
            amount_usd: single["USD AMOUNT"] ? single["USD AMOUNT"] : 0,
            amount_inr: single["INR AMOUNT"]
              ? single["INR AMOUNT"]
              : single["AMOUNT"]
              ? single["AMOUNT"]
              : 0,
            no_of_containers: single["NO OF CONTAINER"]
              ? single["NO OF CONTAINER"]
              : 0,
            details: single["DETAIL"] ? single["DETAIL"] : "",
            paid: false,
            is_active: true,
            commercial_invoice: selectedCustomIn.id,

            invoice_date: moment(single["INVOICE DATE"]).format("YYYY-MM-DD"),
            invoice_number: single["INVOICE NO"] ? single["INVOICE NO"] : "",
            id: single.id,
          });
          return single;
        });
      postData("put", "arrival/expense/bulk-update", null, "put", newArr2).then(
        (res3) => {
          if (res3.error) {
            setLoad(false);
            setError(res3.error);
          } else {
            ONFinal();
          }
        }
      );
    };
    if (submittedExpenses.length) {
      if (postData) {
        setLoad(true);
        if (newArr.length > 0) {
          postData(
            "post",
            "arrival/expense/bulk-create",
            null,
            "post",
            newArr
          ).then((res) => {
            if (res.error) {
              setLoad(false);
              setError(
                typeof res.data === "string"
                  ? res.data
                  : res.data.length > 0
                  ? res.data
                      .map((s) => {
                        return `${s.field ? `${s.field} :` : ""} ${
                          typeof s.message === "string"
                            ? s.message
                            : s.message.length > 0 &&
                              s.message[0] &&
                              s.message[0]
                        }`;
                      })
                      .join(" ,")
                  : res.data
              );
            } else {
              if (submittedExpenses.filter((s) => s.id).length > 0) {
                onEDit();
              } else {
                ONFinal();
              }
            }
          });
        } else if (submittedExpenses.filter((s) => s.id).length > 0) {
          onEDit();
        }
      }
    }
  };
  const [allSumittedData, setAllSumittedData] = useState({
    "INVOICE DATE": new Date(),
  });
  const design = () => {
    return (
      <div className="width-100 height-100 d-flex flexWrap fontSize07 overflowYA">
        {/* <ExcelComponent
          header={[
            "CUSTOM INV",
            "Type",
            "PARTY NAME",
            "INVOICE NO",
            "INVOICE DATE",
            "USD AMOUNT",
            "AMOUNT",
            "INR AMOUNT",
            "NO OF CONTAINER",
            "DETAIL",
          ]}
          array={
            submittedExpenses.length > 0
              ? [
                  {
                    "CUSTOM INV": "CUSTOM INV",
                    Type: "Type",
                    "PARTY NAME": "PARTY NAME",
                    "INVOICE NO": "INVOICE NO",
                    "INVOICE DATE": "INVOICE DATE",
                    "USD AMOUNT": "USD AMOUNT",
                    AMOUNT: "AMOUNT",
                    "INR AMOUNT": "INR AMOUNT",
                    "NO OF CONTAINER": "NO OF CONTAINER",
                    DETAIL: "DETAIL",
                  },
                ].concat(
                  submittedExpenses.map((data) => {
                    return {
                      "CUSTOM INV":
                        selectedCustomIn && selectedCustomIn.invoice_number && selectedCustomIn.invoice_number,
                      Type: data.type,
                      "PARTY NAME": data["PARTY NAME"],
                      "INVOICE NO": data["INVOICE NO"],
                      "INVOICE DATE": data["INVOICE DATE"],
                      "USD AMOUNT": data["USD AMOUNT"],
                      AMOUNT: data["AMOUNT"],
                      "INR AMOUNT": data["INR AMOUNT"],
                      "NO OF CONTAINER": data["NO OF CONTAINER"],
                      DETAIL: data["DETAIL"],
                    };
                  })
                )
              : []
          }
          selectedCustomInvoice={selectedCustomInvoice}
        /> */}
        {allExType &&
          allExType.map(
            (uploaded, i) =>
              filterData(submittedExpenses, "type", uploaded) && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    margin: "20px 10px",
                    height: "fit-content",
                    width: "fit-content",
                    border: "1px solid rgba(158, 158, 158, 0.48)",
                    fontSize: "0.7rem",
                  }}
                  className="borderRadius7 minWidthFC"
                >
                  <div
                    className="width-100 padding10 d-flex justifyC alignC fontWeightBold fontSize09"
                    style={{
                      background:
                        i === 0
                          ? color1
                          : i === 1
                          ? color4
                          : i === 2
                          ? color3
                          : i === 3
                          ? primary
                          : color5,
                      borderRadius: "7px 7px 0 0",
                    }}
                  >
                    {`${uploaded} Total ${
                      filterData(submittedExpenses, "type", uploaded).length
                    } ${
                      filterData(submittedExpenses, "type", uploaded).length > 1
                        ? "Expenses"
                        : "Expense"
                    }`}
                  </div>
                  <div
                    className="width-100 d-flex fontWeightBold"
                    style={{ background: "rgba(158, 158, 158, 0.48)" }}
                  >
                    <div
                      className="padding10 d-flex justifyC alignC "
                      style={{
                        width: "100px",
                        borderRight: "1px solid rgba(158, 158, 158, 0.48)",
                      }}
                    >
                      CUSTOM INV
                    </div>
                    {getInputs(uploaded)
                      ? getInputs(uploaded).map((ss, i) => (
                          <div
                            key={ss}
                            className="padding10 d-flex justifyC alignC"
                            style={{
                              width: i === 5 ? "123.81px" : i === 2 ? '145px' : "100px",
                              borderRight:
                                i === getInputs(uploaded).length - 1
                                  ? ""
                                  : "1px solid rgba(158, 158, 158, 0.48)",
                              wordBreak: "break-all",
                            }}
                          >
                            {ss}
                          </div>
                        ))
                      : ""}
                  </div>
                  {filterData(submittedExpenses, "type", uploaded).length >
                  0 ? (
                    <div className="width-100">
                      {filterData(submittedExpenses, "type", uploaded).map(
                        (singleExp) =>
                          getInputs(uploaded) ? (
                            <div className="width-100 d-flex">
                              <div
                                className="  padding10  textOEllipse textAC" //d-flex justifyC alignC
                                style={{
                                  width: "100px",
                                  borderRight:
                                    "1px solid rgba(158, 158, 158, 0.48)",
                                  wordBreak: "break-all",
                                }}
                              >
                                {selectedCustomIn &&
                                  selectedCustomIn.invoice_number &&
                                  selectedCustomIn.invoice_number}
                              </div>
                              {getInputs(uploaded).map((ss, i) => (
                                <div
                                  key={ss}
                                  className="  padding10  textOEllipse textAC" //d-flex justifyC alignC
                                  style={{
                                    width: i === 5 ? "123.81px" : i === 2 ? '145px' : "100px",
                                    wordBreak: "break-all",

                                    borderRight:
                                      i === getInputs(uploaded).length - 1
                                        ? ""
                                        : "1px solid rgba(158, 158, 158, 0.48)",
                                  }}
                                >
                                  {ss === "INVOICE DATE"
                                    ? moment(singleExp["INVOICE DATE"]).format(
                                        "ddd, MMM Do YYYY"
                                      )
                                    : singleExp[ss]
                                    ? singleExp[ss]
                                    : "-"}
                                </div>
                              ))}
                            </div>
                          ) : (
                            ""
                          )
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )
          )}
      </div>
    );
  };

  return (
    <Paper
      className="padding15 backgroundWhite postionR overflowYA scrollBluePrimary d-flex d-flex-column height-100 width-100 textC"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {error ? (
        <SnackbarComponent
          error={error}
          autoHideDuration={5000}
          // success={success}
          snackbarClose={() => {
            setError(false);
          }}
        />
      ) : (
        ""
      )}
      {openDialog === true ? (
        <CssDialog
          headerPrimary={true}
          resetText={"Cancel"}
          height="calc(100% - 150px)"
          width="832.5px"
          maxWidth={"calc(100% - 150px)"}
          header={`Are you sure you want to submit only  ${
            submittedExpenses
              ? submittedExpenses.length && submittedExpenses.length > 1
                ? `${submittedExpenses.length} Expenses`
                : `${submittedExpenses.length} Expense`
              : ""
          }  ?`}
          addButtonWidth="60px"
          addButton={"Upload"}
          reset={() => {
            setOpenDialog(false);
          }}
          onClose={() => {
            setOpenDialog(false);
          }}
          snackbarClose={() => {
            setOpenDialog(false);
          }}
          onSubmit={() => {
            submitExpense();
          }}
          children={() => {
            return loader ? <StyledCircularProgress /> : design();
          }}
        />
      ) : (
        ""
      )}

      {loader ? (
        <StyledCircularProgress />
      ) : noAddButton ? (
        // selectedCustomIn &&
        // || selectedCustomIn.status === "CLOSED"
        // selectedCustomIn.shipment_process_status &&
        // selectedCustomIn.shipment_process_status !== "DOCUMENTS UPLOADED"
        design()
      ) : (
        <>
          {/* <ExcelComponent
            header={[
              "CUSTOM INV",
              "Type",
              "PARTY NAME",
              "INVOICE NO",
              "INVOICE DATE",
              "USD AMOUNT",
              "AMOUNT",
              "INR AMOUNT",
              "NO OF CONTAINER",
              "DETAIL",
            ]}
            array={
              submittedExpenses.length > 0
                ? [
                    {
                      "CUSTOM INV": "CUSTOM INV",
                      Type: "Type",
                      "PARTY NAME": "PARTY NAME",
                      "INVOICE NO": "INVOICE NO",
                      "INVOICE DATE": "INVOICE DATE",
                      "USD AMOUNT": "USD AMOUNT",
                      AMOUNT: "AMOUNT",
                      "INR AMOUNT": "INR AMOUNT",
                      "NO OF CONTAINER": "NO OF CONTAINER",
                      DETAIL: "DETAIL",
                    },
                  ].concat(
                    submittedExpenses.map((data) => {
                      return {
                        "CUSTOM INV":
                          selectedCustomIn && selectedCustomIn.invoice_number && selectedCustomIn.invoice_number,
                        Type: data.type,
                        "PARTY NAME": data["PARTY NAME"],
                        "INVOICE NO": data["INVOICE NO"],
                        "INVOICE DATE": data["INVOICE DATE"],
                        "USD AMOUNT": data["USD AMOUNT"],
                        AMOUNT: data["AMOUNT"],
                        "INR AMOUNT": data["INR AMOUNT"],
                        "NO OF CONTAINER": data["NO OF CONTAINER"],
                        DETAIL: data["DETAIL"],
                      };
                    })
                  )
                : []
            }
            selectedCustomInvoice={selectedCustomInvoice}
          /> */}
          <div className="width-100 d-flex alignC flexWrap">
            <CssTextField
              required={true}
              autoComplete="off"
              value={
                selectedExType ? selectedExType : "" //
              }
              onChange={(e) => {
                setSelectedExType(e.target.value);
                setInputFields(getInputs(e.target.value));
                setSubmittedExpenses(submittedExpensesCopy);

                clearFields();
              }}
              style={
                { width: "230px", marginRight: "20px" } //disabled={tp}
              }
              id="outlined-select-currency-native"
              select
              label="Expense Type"
              variant="outlined"
              InputLabelProps={{}}
            >
              {allExType && allExType.length > 0 ? (
                allExType.map((option) => (
                  <MenuItem key={option} value={option} id={option}>
                    {option}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  disabled={true}
                  key={"No data!"}
                  value={"No data!"}
                  id={"No data!"}
                >
                  No data!
                </MenuItem>
              )}
            </CssTextField>{" "}
            {allExType &&
              allExType.map(
                (uploaded, i) =>
                  filterData(submittedExpenses, "type", uploaded) && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedExType(uploaded);
                        setInputFields(getInputs(uploaded));
                        clearFields();
                      }}
                      style={{
                        height: "30px",
                        background:
                          i === 0
                            ? color1
                            : i === 1
                            ? color4
                            : i === 2
                            ? color3
                            : i === 3
                            ? primary
                            : color5,
                        width: "fit-content",
                        margin: "10px",
                      }}
                      className="padding5 borderRadius7 minWidthFC textCW cursorP"
                    >
                      {`${uploaded} Total ${
                        filterData(submittedExpenses, "type", uploaded).length
                      } ${
                        filterData(submittedExpenses, "type", uploaded).length >
                        1
                          ? "Expenses"
                          : "Expense"
                      }`}
                    </div>
                  )
              )}
          </div>
          <div className="width-100">
            {filterData(submittedExpenses, "type", selectedExType) && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                style={{
                  margin: "20px 10px",
                  height: "fit-content",
                  width: "fit-content",
                  border: "1px solid rgba(158, 158, 158, 0.48)",
                  fontSize: "0.7rem",
                }}
                className="borderRadius7 minWidthFC"
              >
                {/* <div
                  className="width-100 padding10 d-flex justifyC alignC fontWeightBold fontSize09"
                  style={{
                    background: color1,
                    borderRadius: "7px 7px 0 0",
                  }}
                >
                  {`${selectedExType} Total ${filterData(submittedExpenses, "type", selectedExType).length} ${
                    filterData(submittedExpenses, "type", selectedExType).length > 1 ? "Expenses" : "Expense"
                  }`}
                </div> */}
                <div
                  className="width-100 d-flex fontWeightBold"
                  style={{ background: "rgba(158, 158, 158, 0.48)" }}
                >
                  <div
                    className=" padding10 d-flex justifyC alignC"
                    style={{
                      width: "100px",
                      borderRight: "1px solid rgba(158, 158, 158, 0.48)",
                    }}
                  >
                    CUSTOM INV
                  </div>
                  {getInputs(selectedExType)
                    ? getInputs(selectedExType).map((ss, i) => (
                        <div
                          key={ss}
                          className=" padding10 d-flex justifyC alignC textOEllipse"
                          style={{
                            width: i === 5 ? "123.81px" : i=== 2 ? "145px" : "100px",
                            borderRight: "1px solid rgba(158, 158, 158, 0.48)",
                            wordBreak: "break-all",
                          }}
                        >
                          {ss}
                        </div>
                      ))
                    : ""}
                  <div
                    className=" padding10 d-flex justifyC alignC"
                    style={{
                      width: "60px",
                    }}
                  >
                    Edit
                  </div>
                </div>
                {filterData(submittedExpenses, "type", selectedExType).length >
                0 ? (
                  <div className="width-100">
                    {filterData(submittedExpenses, "type", selectedExType).map(
                      (singleExp) =>
                        getInputs(selectedExType) ? (
                          <div className="width-100 d-flex">
                            <div
                              className=" padding10  textOEllipse textAC" //d-flex justifyC alignC
                              style={{
                                width: "100px",
                                borderRight:
                                  "1px solid rgba(158, 158, 158, 0.48)",
                                wordBreak: "break-all",
                              }}
                            >
                            {console.log("sss", selectedCustomIn)}
                              {selectedCustomIn &&
                                selectedCustomIn?.custom_invoice_details[0]?.invoice_number &&
                                selectedCustomIn?.custom_invoice_details[0]?.invoice_number}
                            </div>
                            {getInputs(selectedExType).map((ss, i) => (
                              <div
                                key={ss}
                                className=" padding10  textOEllipse textAC" //d-flex justifyC alignC
                                style={{
                                  width: i === 5 ? "123.81px" : i === 2 ? '145px' : "100px",
                                  borderRight:
                                    "1px solid rgba(158, 158, 158, 0.48)",
                                  wordBreak: "break-all",
                                }}
                              >
                                {ss === "INVOICE DATE"
                                  ? moment(singleExp["INVOICE DATE"]).format(
                                      "ddd, MMM Do YYYY"
                                    )
                                  : singleExp[ss]
                                  ? singleExp[ss]
                                  : "-"}
                              </div>
                            ))}
                            <div
                              className=" padding10 d-flex justifyC alignC"
                              style={{
                                width: "60px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                const submittedObj = {};
                                setEdit(true)
                                inputFields.map((sField) => {
                                  if (singleExp && singleExp[sField]) {
                                    submittedObj[sField] = singleExp[sField];
                                  }
                                  return sField;
                                });
                                setAllSumittedData(submittedObj);
                                setSelctedExpense(singleExp);
                                const newData = submittedExpensesCopy
                                  .map((s) => {
                                    if (
                                      (singleExp.id || singleExp.id === 0) &&
                                      (s.id || s.id === 0) &&
                                      s.id === singleExp.id
                                    ) {
                                      return false;
                                    } else if (
                                      (singleExp.lid || singleExp.lid === 0) &&
                                      (s.lid || s.lid === 0) &&
                                      singleExp.lid === s.lid
                                    ) {
                                      return false;
                                    } else {
                                      return s;
                                    }
                                  })
                                  .filter((sd) => sd !== false);
                                setSubmittedExpenses(newData);
                              }}
                            >
                              <EditIcon height="18" />
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <div
            style={{}}
            className="d-flex width-100 fontWeight600 marginTB20 fontSize1"
          >
            All values of {selectedExType}
          </div>
          <div className="d-flex flexWrap">
            {inputFields &&
              inputFields.map((singleExp, i) =>
                singleExp === "INVOICE DATE" ? (
                  <MuiPickersUtilsProvider
                    libInstance={moment()}
                    utils={DateFnsUtils}
                  >
                    <CssKeyboardDatePicker
                      label={"Invoice Date"}
                      variant="inline"
                      onOpen={() => {
                        setCalenderOpen(true);
                      }}
                      onClose={() => {
                        setCalenderOpen(false);
                      }}
                      
                      
                      // minDate={"2022-01-22"}
                      invalidLabel=""
                      onKeyPress={(ev) => {
                        ev.preventDefault();
                      }}
                      onKeyDown={(evt) => evt.preventDefault()}
                      allowKeyboardControl={false}
                      keyboardIcon={
                        calenderOpen ? <TopArrow /> : <BottomArrow />
                      }
                      autoOk
                      inputVariant="outlined"
                      style={{ width: "230px", margin: "20px" }}
                      value={
                        allSumittedData
                          ? allSumittedData[singleExp]
                            ? allSumittedData[singleExp]
                            : new Date()
                          : new Date()
                      }
                      error={errorMsgs[singleExp] ? true : false}
                      helperText={
                        errorMsgs[singleExp] ? errorMsgs[singleExp] : ""
                      }
                      format="do MMM, yyyy"
                      //views={['year', 'month']}
                      InputAdornmentProps={{ position: "end" }}
                      onChange={(newValue) => {
                        var errorMsg = errorMsgs;
                        errorMsg[singleExp] = false;
                        setErrorMsgs(errorMsg);
                        setAllSumittedData({
                          ...allSumittedData,
                          [singleExp]: newValue,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                ) : (
                  <CssTextField95
                    key={i}
                    onFocus={StopScroll}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                      endAdornment:
                        singleExp === "USD AMOUNT"
                          ? "USD"
                          : singleExp === "INR AMOUNT"
                          ? "INR"
                          : singleExp === "AMOUNT"
                          ? "/"
                          : "",
                    }}
                    onKeyDown={(evt) => {
                      if (singleExp === "NO OF CONTAINER") {
                        evt.key === "." && evt.preventDefault();
                      }
                      console.log(singleExp, "singleExp");
                      if (
                        singleExp === "PARTY NAME" ||
                        i === 0 ||
                        singleExp === "DETAIL" ||
                        singleExp === "INVOICE NO"
                      ) {
                      } else {
                        evt.key === "e" && evt.preventDefault();

                        evt.key === "E" && evt.preventDefault();
                        evt.key === "-" && evt.preventDefault();
                        evt.keyCode === 38 && evt.preventDefault();
                        evt.keyCode === 40 && evt.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      if (singleExp === "NO OF CONTAINER") {
                        e.target.value = e.target.value
                          .replace(/[^\w]|_/g, "")
                          .toLowerCase();
                      }
                    }}
                    autoComplete="off"
                    type={
                      singleExp === "PARTY NAME" ||
                      singleExp === "DETAIL" ||
                      singleExp === "INVOICE NO"
                        ? "text"
                        : "number"
                    }
                    style={{ width: "230px", margin: "20px" }}
                    error={errorMsgs[singleExp] ? true : false}
                    helperText={
                      errorMsgs[singleExp] ? errorMsgs[singleExp] : ""
                    }
                    id="outlined-basic"
                    label={singleExp}
                    variant="outlined"
                    name="singleFileName"
                    value={
                      allSumittedData
                        ? allSumittedData[singleExp]
                          ? allSumittedData[singleExp]
                          : ""
                        : ""
                    }
                    onChange={(e) => {
                      var errorMsg = errorMsgs;
                      errorMsg[singleExp] = false;
                      setErrorMsgs(errorMsg);
                      setAllSumittedData({
                        ...allSumittedData,
                        [singleExp]: e.target.value,
                      });
                    }}
                  />
                )
              )}
            <StyledButton
              name={edit ? "Update" : "Add" }
              maxHeight={"37.98px"}
              withBg={true}
              margin="20px"
              onClick={(e) => {
                e.stopPropagation();
                setEdit(false)
                const newArr = [];
                const correctData = [];
                const submittedObj = {};
                inputFields.map((sField) => {
                  if (allSumittedData && allSumittedData[sField]) {
                    correctData.push(sField);
                    submittedObj[sField] = allSumittedData[sField];
                  } else {
                    var errorMsg = errorMsgs;
                    errorMsg[sField] = `Please add ${sField} !`;
                    setErrorMsgs(errorMsg);
                    setInputFields(getInputs(selectedExType));
                  }
                  return sField;
                });
                if (correctData.length === inputFields.length) {
                  submittedObj.type = selectedExType;
                  if (
                    selctedExpense &&
                    (selctedExpense.id || selctedExpense.id === 0)
                  ) {
                    submittedObj.id = selctedExpense.id;
                  } else if (
                    selctedExpense &&
                    (selctedExpense.lid || selctedExpense.lid === 0)
                  ) {
                    submittedObj.lid = selctedExpense.lid;
                  } else {
                    submittedObj.lid =
                      submittedExpensesCopy.length > 0
                        ? sum([submittedExpensesCopy.length, 11])
                        : 0;
                  }
                  submittedExpensesCopy.map((s) => {
                    if (s.type === selectedExType) {
                      if (
                        (submittedObj.id || submittedObj.id === 0) &&
                        (s.id || s.id === 0) &&
                        s.id === submittedObj.id
                      ) {
                        return s;
                      } else if (
                        (submittedObj.lid || submittedObj.lid === 0) &&
                        (s.lid || s.lid === 0) &&
                        submittedObj.lid === s.lid
                      ) {
                        console.log("lid same");
                        return s;
                      } else {
                        console.log("lid d");
                        newArr.push(s);
                        return s;
                      }
                    } else {
                      newArr.push(s);
                    }
                  });
                  console.log(
                    submittedExpensesCopy,
                    newArr,
                    submittedObj,
                    "submittedExpensesCopy"
                  );
                  newArr.push(submittedObj);

                  console.log(newArr, "newArr");
                  setSubmittedExpenses(newArr);
                  setSubmittedExpensesCopy(newArr);

                  clearFields();
                }
              }}
            />
            <StyledButton
              name="Clear"
              onClick={(e) => {
                e.stopPropagation();
                setSubmittedExpensesCopy(submittedExpenses);
                clearFields();
              }}
            />
          </div>
          <div className="width-100 d-flex marginTB20">
            <StyledButton
              name="Submit"
              withBg={true}
              onClick={(e) => {
                e.stopPropagation();
                if (submittedExpenses.length > 0) {
                  setOpenDialog(true);
                } else {
                  setError("Please add atleast one Expense!");
                }
              }}
            />
          </div>
        </>
      )}
    </Paper>
  );
}

export default EXpenseInput;
